import React from "react"

import { Row, Col, Card, CardBody, Button } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.css';

// Carousel images
import img3 from "../../assets/images/small/img-3.jpg"
import img4 from "../../assets/images/small/img-4.jpg"
import img5 from "../../assets/images/small/img-5.jpg"

const items = [
  {
    src: img3,
    altText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    caption: "First slide label",
    subText: '300 Goals'
  },
  {
    src: img4,
    altText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    caption: "Second slide label",
    subText: '300 Goals'
  },
  {
    src: img5,
    altText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    caption: "Third slide label",
    subText: '300 Goals'
  },
]


const WelcomeComp = () => {

  const swiperRef = React.useRef(null);
  console.log('swiperRef.current.swiper', swiperRef.current);

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <Row>
          <Col xs="12">
            <div className="text-primary p-3 d-flex flex-direction-row w-100 justify-content-sm-between">
              <h5 className="text-white">Trending now!</h5>
              <div className={'swiper-navigation-container'}>
                <div id="previousButton" onClick={() => swiperRef.current.swiper.slidePrev()} className={'text-white'}><i className={'bx bx-left-arrow-alt'} /></div>
                <div id="nextButton" onClick={() => swiperRef.current.swiper.slideNext()} className={'text-white'}><i className={'bx bx-right-arrow-alt'} /></div>
              </div>
            </div>
          </Col>
        </Row>
        <CardBody className="pt-0">
          <Row>
            <Swiper
              ref={swiperRef}
              spaceBetween={50}
              slidesPerView={1}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
              loop={true}
            >
              {
                items.map(item => {
                  return (
                    <>
                      <SwiperSlide>
                        <div className={'swiper-container-inner'}>
                          <img
                            src={item.src}
                            className="d-block rounded-circle"
                            alt={item.altText}
                          />
                          <h2>{item.caption}</h2>
                          <p>{item.subText}</p>
                          <Button
                            color="primary"
                            outline
                            className="waves-effect waves-light btn-rounded"
                          >
                            View Profile
                          </Button>
                        </div>
                      </SwiperSlide>
                    </>
                  )

                })
              }
            </Swiper>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
