import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { 
        GET_NOTIFICATION,
      } from "./actionTypes"
import {
  getNotificationSuccess,
  getNotificationFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  get,
} from "../../helpers/api_helper"


import { GET_NOTIFICATIONS, 
      } from "helpers/url_helper"


function* fetchNotification() {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  let body = JSON.stringify({
    query: GET_NOTIFICATIONS,
    variables: {
      options: {
        paginateOptions: {
          page: 1,
          limit: 50,
          populate: [
            {path: "notifyTo"},
            {path: "space"},
            {path: "payload", populate: [
              {path: "answerAuthor", model: "Student"},
              {path: "questionAuthor", model: "Student"},
              {path: "commentAuthor", model: "Student"},
              {path: "answer", model: "Answer", populate: {path: "question"}},
              {path: "comment", model: "Comment", populate: {path: "question"}},
              {path: "questionComment", model: "QuestionComment", populate: {path: "question"}}
            ]
          }
          ],
          sort: { createdAt: "desc" }
        },
        queryVals: `{notifyTo: ${authUser?._id}, space: ${currentSpace?._id}}`
      }
    }
  });

  const response =  yield call(get, { payload: { body, history } })
  console.log('response, error', response);
  if (response) {
    yield put(getNotificationSuccess(response?.getPaginatedNotifications?.docs))
  } else {
    yield put(getNotificationFail(response.errors))
  }
}

function* projectsSaga() {
  yield takeEvery(GET_NOTIFICATION, fetchNotification)
}

export default projectsSaga
