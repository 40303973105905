import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Form,
  Input,
  Alert
} from "reactstrap"
import { withRouter } from "react-router-dom"
import { connect, useSelector, useDispatch } from "react-redux"
import { submitChangePassword } from "../../store/actions"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

const PasswordTab = (props) => {
  const dispatch = useDispatch()
  const [passwordError, setPasswordError] = useState('')
  const [paaswordSuccessMsg, SetPaaswordSuccessMsg] = useState(false)

  const {
    getChangePasswordData,
  } = useSelector(state => {
      return {
        getChangePasswordData: 
              state.changePassword && state.changePassword.change_password && state.changePassword.change_password.changePassword
                  ? state.changePassword.change_password.changePassword
                  : '',
      }
  });

  // handleSubmit
  const handleSubmit = (e) => {
    e.preventDefault()
    let newPassword = e.target.newpassword.value
    let newConfirmPassword = e.target.confirmpassword.value

    if (newPassword != '' && newConfirmPassword != '') {
      if (newPassword.length >= 8 && newConfirmPassword.length >= 8) {
        if (newPassword === newConfirmPassword) {
          let values = {
            username: "father_john",
            password: e.target.newpassword.value
          }
          dispatch(submitChangePassword(values))
        }else{
          setPasswordError('Password & confirm password does not match.')
        }
      }else{
        setPasswordError('Password must have minimum 8 characters.')
      }
    }else{
      if (newPassword == '' && newConfirmPassword == '') {
        setPasswordError('Please enter valid password.')
      }else{
        if (newPassword == '') {
          setPasswordError('Please enter password.')
        }else{
          setPasswordError('Please enter confirm password.')
        }
      }
    }
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    if (getChangePasswordData != '') {
      setPasswordError('')
      SetPaaswordSuccessMsg(true)
    }
  }, [getChangePasswordData])

  const logout = () => {
    window.location.href = "/logout";
  }

  return (
    <>
      <div>
        <Card>
          <CardBody className="pb-5 pt-5">
            <div className={"mb-4 text-center"}>
              <Row className="justify-content-center pt-2">
                <Col xl={5} lg={4} className="pt-1">
                  <Form 
                    onSubmit={(e) => {
                      handleSubmit(e)
                    }}>
                    {passwordError && typeof passwordError === "string" ? (
                        <Alert color="danger">{passwordError}</Alert>
                      ) : ''}

                    <div className="mb-4" style={{textAlign: 'left'}}>
                      <Label htmlFor="formrow-old-password-Input">Old password</Label>
                      <Input
                        type="password"
                        name="oldpassword"
                        className="form-control p-4"
                        id="formrow-old-password-Input"
                        placeholder="Old password"
                        style={{borderRadius : '30px', height: '62px'}}
                      />
                    </div>

                    <div className="mb-4" style={{textAlign: 'left'}}>
                      <Label htmlFor="formrow-new-password-Input">New password</Label>
                      <Input
                        type="password"
                        name="newpassword"
                        className="form-control p-4"
                        id="formrow-new-password-Input"
                        placeholder="New password"
                        style={{borderRadius : '30px', height: '62px'}}
                      />
                    </div>

                    <div className="mb-4" style={{textAlign: 'left'}}>
                      <Label htmlFor="formrow-confirm-password-Input">Confirm password</Label>
                      <Input
                        type="password"
                        name="confirmpassword"
                        className="form-control p-4"
                        id="formrow-confirm-password-Input"
                        placeholder="Confirm password"
                        style={{borderRadius : '30px', height: '62px'}}
                      />
                    </div>

                    <div style={{marginTop : '70px'}}>
                      <button type="submit" className="btn bg-blue-color btn-rounded text-white btn-lg w-md">
                        Change Password
                      </button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </div>
      { paaswordSuccessMsg ? (
        <SweetAlert
          title="Success"
          success
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            logout()
          }}
          onCancel={() => {
            logout()
          }}
        >
          Password Changed Successfully!
        </SweetAlert>
      ) : null}
    </>
  )
}

const mapStateToProps = state => {
  const { error, change_password } = state.changePassword
  return { }
}

export default withRouter(
  connect(mapStateToProps, { submitChangePassword })(PasswordTab)
)

// PasswordTab.propTypes = {
//   error: PropTypes.any,
//   change_password: PropTypes.object,
//   submitChangePassword: PropTypes.func
// }

//export default PasswordTab
