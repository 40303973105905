import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Row,
  Progress,
} from "reactstrap"

import { Link } from "react-router-dom"

// users
import user2 from "../../assets/images/users/avatar-2.jpg"
//medals
import gold from "../../assets/images/nimo-rising-star/gold.png"
import silver from "../../assets/images/nimo-rising-star/silver.png"
import bronze from "../../assets/images/nimo-rising-star/bronze.png"

import classnames from "classnames"
function AccordionComponent(props) {
  const { leaderboardList } = props;
  const [col1, setcol1] = useState(null)

  const t_col1 = (id) => {
    if(id == col1) {
      setcol1(null)
    } else {
      setcol1(id)
    }
  }

  return (
    <>
      <Card>
          <Row>
            <Col lg={12}>
              <div className="accordion nimo-rising-star-accordion" id="accordion">
                {
                  leaderboardList?.docs?.length > 0 &&
                  leaderboardList.docs.map((item, index) => {
                    return (
                      <div className="accordion-item" key={index}>
                        <h2 className="accordion-header" id="headingOne">
                          <div
                            className={classnames("accordion-button rounded-3", "fw-medium", {
                              collapsed: col1 == index,
                            })}
                            type="button"
                            onClick={() => t_col1(index)}
                            style={{ cursor: "pointer",
                          }}
                          >
                              <div className="d-flex justify-content-between align-items-center w-100">
                                  <div>
                                      <span>{index+1}</span>
                                  </div>
                                  <div>
                                    {
                                      item?.student?.realProfilePicUrl ?
                                          <img
                                              className="rounded-circle avatar-xs"
                                              src={item?.student?.realProfilePicUrl}
                                              alt={item.student?.userName}
                                          />
                                      :
                                          <img
                                              className="rounded-circle avatar-xs"
                                              src={user2}
                                              alt="Avatar"
                                          />
                                    }
                                  </div>
                                  <div className="user-details">
                                      <span>
                                          <p className="mb-0">{item.student?.userName}</p>
                                          {/* <p className="mb-0 user-details-subheading">Sri iyanar shcool</p> */}
                                      </span>
                                  </div>
                                  <div className="d-flex justify-content-around align-items-center medals-section">
                                      <div className="medals">
                                          <img
                                              src={gold}
                                              alt="Gold Medals"
                                          />
                                          <span>{item.reputations}</span>
                                      </div>
                                      <div className="medals">
                                          <img
                                              src={silver}
                                              alt="Silver Medals"
                                          />
                                          <span>{item.commitments}</span>
                                      </div>
                                      {/* <div className="medals">
                                          <img
                                              src={bronze}
                                              alt="Bronze Medals"
                                          />
                                          <span>32</span>
                                      </div> */}
                                  </div>
                                  <div className="points-section text-center">
                                      <span>{item.score} pts.</span>
                                  </div>
                                  {/* <div className="view-details">
                                      <Link to="/nimorisingstarsingle">
                                          <span>View details</span>
                                      </Link>
                                  </div> */}
                              </div>
                          </div>
                        </h2>
      
                        <Collapse isOpen={col1 == index} className="accordion-collapse">
                          <div className="accordion-body">
                            <Row>
                              <Col lg={12} className="d-flex justify-content-between align-items-center flex-column progress-section">
                                <div className="progress-section-sub">
                                    <span>Question Asked = <span className="blue-color">{item.questionsAsked}</span></span>
                                    {/* <Progress color="primary" value={85} /> 
                                    <span className="blue-color">85%</span> */}
                                </div>
                                <div className="progress-section-sub">
                                    <span>Question Answered = <span className="blue-color">{item.questionsAnswered}</span></span>
                                    {/* <Progress color="primary" value={65} />
                                    <span className="blue-color">65%</span> */}
                                </div>
                                <div className="progress-section-sub">
                                    <span>Accepted Answer = <span className="blue-color">{item.answersAccepted}</span></span>
                                    {/* <Progress color="primary" value={25} />
                                    <span className="blue-color">25%</span> */}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                      </div>
                    )
                  })
                }
              </div>
            </Col>
          </Row>
      </Card>
    </>
  )
}

export default AccordionComponent
