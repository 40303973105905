import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import Truncate from "react-truncate-html"
import CommonFunction from "common/CommonFunction"
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Media,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import prism from "../../assets/images/prism.png"
import img1 from "../../assets/images/small/img-1.jpg"
import img8 from "../../assets/images/camera.png"
// Form Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { map } from "lodash"

import { withRouter, Link, useParams } from "react-router-dom"

import { connect, useSelector, useDispatch } from "react-redux"
import SimpleBar from "simplebar-react"

import "../../assets/css/custom.css"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import QuestionMiniCard from "./QuestionMiniCard"
import AnswerMiniCard from "./AnswerMiniCard"
import AddQuestionModal from "./AddQuestionModal"
import { MentionsInput, Mention } from 'react-mentions'
import logoWhite from "./../../assets/images/anonimo-logo-white.png"

import {
  getSpaces,
  getStudentSpaces,
  setCurrentSpace,
  joinUserSpace,
  getUnsolvedQuestion,
  getPopularTopic,
  getQuestions,
  upvoteQuestions,
  clearUpvoteQuestionsSuccess,
  downvoteQuestions,
  clearDownvoteQuestionsSuccess,
  getAnswerByQuestionId,
  getAnswerComments,
  getQuestionComments,
  getQuestionsSuccess,
  upvoteAnswers,
  downvoteAnswers,
  upvoteAnswersSuccess,
  downvoteAnswersSuccess,
  getAllSavedQuestions,
  saveQuestions,
  getAllSavedQuestionsSuccess,
  saveQuestionsSuccess,
  saveQuestionComment,
  saveQuestionCommentSuccess,
  saveAnswerComment,
  saveAnswerCommentSuccess,
  deleteQuestion,
  deleteQuestionSuccess,
  deleteAnswer,
  deleteAnswerSuccess,
  getAnswersSuccess,
  deleteQuestionComment,
  deleteQuestionCommentSuccess,
  deleteAnswerComment,
  deleteAnswerCommentSuccess,
  getQuestionCommentsSuccess,
  getAnswerCommentsSuccess,
  report,
  acceptAnswer,
  getSubjects,
  getTopicsBySubject,
  filterQuestions,
  getSingleQuestion,
  getAllusers
} from "../../store/actions"
import toastr, { success } from "toastr"
import "toastr/build/toastr.min.css"

//i18n
import { withTranslation } from "react-i18next"
import AddAnswerModal from "./AddAnswerModal"
import Header from "components/VerticalLayout/Header"

const Dashboard = props => {
  const params = useParams();


  const parseMarkup = (textWithMentions) => {

    // Regular expression to find mentions in the text
    const mentionRegex = /\@\[([^\]]+)\]/g;
    const mentions = [];

    //const mentionRegex = /@(\w+)/g;
    const plainText = textWithMentions.replace(mentionRegex, (match, username) => {
      return '@' + username; // Replace @username with just the username
    });
    //console.log(textWithMentions, plainText);
    return plainText;

  }

  const {
    current_space,
    onGetUnsolvedQuestion,
    onGetPopularTopic,
    onGetQuestions,
    unsolvedQuestions,
    popularTopics,
    questions,
    onUpvoteQuestions,
    upvoteQuestionSuccessMessage,
    onUpvoteQuestionsSuccess,
    onGetAnswers,
    onDownvoteQuestions,
    downvoteQuestionSuccessMessage,
    onDownvoteQuestionsSuccess,
    answerList,
    onGetQuestionComments,
    questionComments,
    onGetQuestionSuccess,
    onGetAnswersSuccess,
    onUpvoteAnswers,
    onDownvoteAnswers,
    upvoteAnswerSuccessMessage,
    downvoteAnswerSuccessMessage,
    onUpvoteAnswerSuccess,
    onDownvoteAnswerSuccess,
    onGetAnswerComments,
    answerComments,
    onGetAllSavedQuestions,
    allSavedQuestionsList,
    onSaveQuestions,
    saveQuestionsSuccessMessage,
    onGetAllSavedQuestionsSuccess,
    onSaveQuestionComment,
    onSaveQuestionCommentSuccess,
    onSaveAnswerComment,
    onSaveAnswerCommentSuccess,
    saveQuestionCommentMessage,
    saveAnswerCommentMessage,
    onDeleteQuestion,
    onDeleteQuestionSuccess,
    deleteQuestionMessage,
    onDeleteAnswer,
    onDeleteAnswerSuccess,
    deleteAnswerMesssage,
    deleteAnswerCommentMessage,
    onDeleteQuestionComment,
    onDeleteQuestionCommentSuccess,
    deleteQuestionCommentMessage,
    onGetQuestionCommentsSuccess,
    onGetAnswerCommentsSuccess,
    onDeleteAnswerComment,
    onDeleteAnswerCommentSuccess,
    onReport,
    reportMessage,
    onAcceptAnswer,
    acceptAnswerMessage,
    onGetSubjects,
    subjectList,
    onGetAllTopicsBySubject,
    allTopicsBySubjectList,
    onFilterQuestions,
    filterQuestions,
    onGetSingleQuestions,
    oneQuestion,
    allUserList,
    onGetAllUsers,
    reportedPostQuestion,
    reportedPostQuestionError,
    reportedPostAnswer,
    reportedPostAnswerError,
    reportedPostQuestionComment,
    reportedPostQuestionCommentError,
    reportedPostAnswerComment,
    reportedPostAnswerCommentError,
    reportSuccessMessage
  } = props

  //console.log('allSavedQuestionsList.......................', allSavedQuestionsList);

  const [singlebtn, setSinglebtn] = useState(false)
  const [topicbtn, setTopicbtn] = useState(false)

  const [modal, setmodal] = useState(false)
  const [answerModal, setAnswerModal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [menuIndex, setMenuIndex] = useState(0)
  const [otherMenu, setOtherMenu] = useState(false)
  const [selectQuestion, setSelectQuestion] = useState(null)
  const [questionModal, setQuestionModal] = useState(null)
  const [answerId, setAnswerId] = useState(null)
  const [togggle, setTogggle] = useState(true)
  const [questionComment, setQuestionComment] = useState("")
  const [answerComment, setAnswerComment] = useState("")
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteQuestionId, setDeleteQuestionId] = useState(null)
  const [deleteAnswerId, setDeleteAnswerId] = useState(null)
  const [deleteQuestionCommentId, setDeleteQuestionCommentId] = useState(null)
  const [deleteAnswerCommentId, setDeleteAnswerCommentId] = useState(null)
  const [reportQuestionId, setReportQuestionId] = useState(null)
  const [reportAnswerId, setReportAnswerId] = useState(null)
  const [reportQuestionCommentId, setReportQuestionCommentId] = useState(null)
  const [reportAnswerCommentId, setReportAnswerCommentId] = useState(null)
  const [reportModal, setReportModal] = useState(null)
  const [reportReason, setReportReason] = useState("")
  const [bookmarkedQuestionList, setBookmarkedQuestionList] = useState([])
  const [sortDropdown, setSortDropdown] = useState(false);
  const [userData, setUserData] = useState(false);

  const [isEditAnswer, setIsEditAnswer] = useState(false);
  const [selectAnswer, setSelectAnswer] = useState(null);
  const [isEditQuestion, setIsEditQuestion] = useState(false);
  const [userFilteredData, setUserFilteredData] = useState([]);
  const [viewAllQuestionComment, setViewAllQuestionComment] = useState(false);
  const [viewAllAnswerComment, setViewAllAnswerComment] = useState([]);

  const [filterData, setFilterData] = useState({
    subject: '',
    topic: '',
    sort: 1,
    myQuestions: false,
    unsolvedQuestions: false,
    savedQuestions: false,
    solvedQuestions: false,
  });

  const clearFilter = () => {
    setFilterData({
      subject: '',
      topic: '',
      sort: 1,
      myQuestions: false,
      unsolvedQuestions: false,
      savedQuestions: false,
      solvedQuestions: false,
    });
  }

  useEffect(() => {
    let userD = JSON.parse(localStorage.getItem("authUser"))
    setUserData(JSON.parse(localStorage.getItem("authUser")));

    if (params?.id) {
      if (userD != null) {
        window.location.href = "/singleQuestion/" + params.id + "/home";
      }
      console.log('params.id..............', params.id);
      onGetSingleQuestions(params?.id);
      onSingleQuestionClick(params?._id)
    }
  }, [params.id])


  useEffect(() => {
    //console.log('oneQuestion.................................', oneQuestion);
    if (Object.keys(oneQuestion).length != 0) {
      window.scrollTo(0, 0);
      setSelectQuestion(oneQuestion);
      //onGetAllSavedQuestions();
      onSingleQuestionClick(oneQuestion)
    }
  }, [oneQuestion])


  // useEffect(() => {
  //   console.log('oneQuestion.................................', oneQuestion);
  //   // if(selectQuestion != {} && oneQuestion != {}) {
  //   //   setSelectQuestion(oneQuestion);
  //   // }
  // }, [oneQuestion])

  //console.log('singleQuestion.............', selectQuestion);

  const dispatch = useDispatch()

  const reportList = [
    { id: 1, title: "Its spam" },
    { id: 2, title: "Nudity or sexual activity" },
    { id: 3, title: "Hate picture or symbols" },
    { id: 4, title: "I just don’t like it" },
    { id: 5, title: "Bullying or harassment" },
    { id: 6, title: "Fasle information" },
    { id: 7, title: "Violence or dangerous" },
    { id: 8, title: "Scam or fraud" },
    { id: 9, title: "Something else" }
  ];

  const onSingleQuestionClick = questionId => {
    //setSelectQuestion(questionId)
    onGetAnswers(questionId?._id)
    onGetQuestionComments(questionId?._id)
  }

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
    let currentSpace = localStorage.getItem("currentSpace")
    dispatch(setCurrentSpace(JSON.parse(currentSpace)))
    onGetAllUsers()
  }, [])




  const upvoteQuestion = question_id => {
    onUpvoteQuestions(question_id)
  }

  const downvoteQuestion = question_id => {
    onDownvoteQuestions(question_id)
  }

  const upvoteAnswer = answer_id => {
    onUpvoteAnswers(answer_id)
  }

  const downvoteAnswer = answer_id => {
    onDownvoteAnswers(answer_id)
  }

  const bookmarkQuestion = (question_id, isBookmark) => {
    onSaveQuestions(question_id, isBookmark)
    //onGetAllSavedQuestions();
  }

  const deleteQuestion = question_id => {
    setDeleteQuestionId(question_id)
    setDeleteModal(true)
  }

  const handleQuestionDelete = () => {
    if (deleteQuestionId) {
      onDeleteQuestion(deleteQuestionId)
      setTimeout(() => {
        setDeleteQuestionId(null)
      }, 1000)
    }
  }

  const deleteAnswer = answer_id => {
    setDeleteAnswerId(answer_id)
    setDeleteModal(true)
  }

  const handleAnswerDelete = () => {
    if (deleteAnswerId) {
      onDeleteAnswer(deleteAnswerId)
      setTimeout(() => {
        setDeleteAnswerId(null)
      }, 1000)
    }
  }

  useEffect(() => {
    //console.log('hello...........', Object.keys(upvoteQuestionSuccessMessage).length !== 0, upvoteQuestionSuccessMessage?.title != '')
    if (
      Object.keys(upvoteQuestionSuccessMessage).length != 0 &&
      upvoteQuestionSuccessMessage?._id != ""
    ) {
      setTogggle(false)
      let question_id = upvoteQuestionSuccessMessage?._id
      //console.log('userData?._id', userData?._id);
      //console.log("qIndex...............", qIndex)
      //questionData.docs[qIndex].liked = likedStatus;
      if (
        selectQuestion.upvotedBy.some(s => s._id == userData?._id)
      ) {
        selectQuestion.upvotedBy = selectQuestion.upvotedBy.filter(s => s._id !== userData?._id)
        selectQuestion.upvoteCount =
          selectQuestion.upvoteCount - 1
      } else {
        selectQuestion.upvotedBy = [
          ...selectQuestion.upvotedBy,
          { _id: userData?._id },
        ]
        selectQuestion.upvoteCount =
          selectQuestion.upvoteCount + 1
      }

      if (
        selectQuestion.downvotedBy.some(
          s => s._id == userData?._id
        )
      ) {
        selectQuestion.downvoteCount =
          selectQuestion.downvoteCount - 1
        selectQuestion.downvotedBy = selectQuestion.downvotedBy.filter(s => s._id !== userData?._id)
      }

      //dispatch(onGetQuestionSuccess(questionData))
      setTogggle(true)
      //console.log('upvoteQuestionSuccess............', upvoteQuestionSuccessMessage);
      toastr.success("Question upvoted successfully!")
      onUpvoteQuestionsSuccess()
    }
  }, [upvoteQuestionSuccessMessage])

  useEffect(() => {
    //console.log('hello...........', Object.keys(upvoteQuestionSuccessMessage).length !== 0, upvoteQuestionSuccessMessage?.title != '')
    if (
      Object.keys(downvoteQuestionSuccessMessage).length != 0 &&
      downvoteQuestionSuccessMessage?._id != ""
    ) {
      setTogggle(false)
      let question_id = downvoteQuestionSuccessMessage?._id
      //console.log('userData?._id', userData?._id);
      let questionData = questions
      if (
        selectQuestion.downvotedBy.some(
          s => s._id == userData?._id
        )
      ) {
        selectQuestion.downvotedBy = selectQuestion.downvotedBy.filter(s => s._id !== userData?._id)
        selectQuestion.downvoteCount =
          selectQuestion.downvoteCount - 1
      } else {
        selectQuestion.downvotedBy = [
          ...selectQuestion.downvotedBy,
          { _id: userData?._id },
        ]
        selectQuestion.downvoteCount =
          selectQuestion.downvoteCount + 1
      }
      if (
        selectQuestion.upvotedBy.some(s => s._id == userData?._id)
      ) {
        selectQuestion.upvotedBy = selectQuestion.upvotedBy.filter(s => s._id !== userData?._id)
        selectQuestion.upvoteCount =
          selectQuestion.upvoteCount - 1
      }

      dispatch(onGetQuestionSuccess(questionData))
      setTogggle(true)

      toastr.success("Question downvoted successfully!")
      onUpvoteQuestionsSuccess()
    }
  }, [downvoteQuestionSuccessMessage])

  useEffect(() => {
    //console.log('upvoteAnswerSuccessMessage.......', upvoteAnswerSuccessMessage)
    if (
      Object.keys(upvoteAnswerSuccessMessage).length != 0 &&
      upvoteAnswerSuccessMessage?._id != ""
    ) {
      let qID = upvoteAnswerSuccessMessage?._id
      setTogggle(false)
      let qData = answerList
      let qIndex = qData.docs.findIndex(x => x._id === qID)
      if (qData.docs[qIndex].upvotedBy.some(s => s._id == userData?._id)) {
        qData.docs[qIndex].upvotedBy = qData.docs[qIndex].upvotedBy.filter(
          s => s._id !== userData?._id
        )
        qData.docs[qIndex].upvoteCount = qData.docs[qIndex].upvoteCount - 1
      } else {
        qData.docs[qIndex].upvotedBy = [
          ...qData.docs[qIndex].upvotedBy,
          { _id: userData?._id },
        ]
        qData.docs[qIndex].upvoteCount = qData.docs[qIndex].upvoteCount + 1
      }
      if (qData.docs[qIndex].downvotedBy.some(s => s._id == userData?._id)) {
        qData.docs[qIndex].downvoteCount = qData.docs[qIndex].downvoteCount - 1
        qData.docs[qIndex].downvotedBy = qData.docs[qIndex].downvotedBy.filter(
          s => s._id !== userData?._id
        )
      }
      onUpvoteAnswerSuccess(qData)
    }
  }, [upvoteAnswerSuccessMessage])

  useEffect(() => {
    if (
      Object.keys(deleteQuestionMessage).length != 0 &&
      deleteQuestionMessage?._id != ""
    ) {
      setDeleteModal(false)
      let id = deleteQuestionMessage?._id
      setTogggle(false)
      let qData = questions
      let qDocs = qData.docs.filter(x => x._id != id)
      ////console.log('qDocs..............................', qDocs);
      qData.docs = qDocs
      //qData.getPaginatedQuestionComments.docs.push(qDocs);
      onGetQuestionSuccess(qData)
      toastr.success("Question deleted successfully!")
      setTogggle(true)
    }
  }, [deleteQuestionMessage])

  useEffect(() => {
    if (
      Object.keys(deleteAnswerMesssage).length != 0 &&
      deleteAnswerMesssage?._id != ""
    ) {
      setDeleteModal(false)
      let id = deleteAnswerMesssage?._id
      setTogggle(false)
      let qData = answerList
      let qDocs = qData.docs.filter(x => x._id != id)
      qData.docs = qDocs
      onGetAnswersSuccess(qData)
      toastr.success("Answer deleted successfully!")
      setTogggle(true)
    }
  }, [deleteAnswerMesssage])

  useEffect(() => {
    //console.log('upvoteAnswerSuccessMessage.......', upvoteAnswerSuccessMessage)
    if (
      Object.keys(downvoteAnswerSuccessMessage).length != 0 &&
      downvoteAnswerSuccessMessage?._id != ""
    ) {
      let qID = downvoteAnswerSuccessMessage?._id
      setTogggle(false)
      let qData = answerList
      let qIndex = qData.docs.findIndex(x => x._id === qID)
      if (qData.docs[qIndex].downvotedBy.some(s => s._id == userData?._id)) {
        qData.docs[qIndex].downvotedBy = qData.docs[qIndex].downvotedBy.filter(
          s => s._id !== userData?._id
        )
        qData.docs[qIndex].downvoteCount = qData.docs[qIndex].downvoteCount - 1
      } else {
        qData.docs[qIndex].downvotedBy = [
          ...qData.docs[qIndex].downvotedBy,
          { _id: userData?._id },
        ]
        qData.docs[qIndex].downvoteCount = qData.docs[qIndex].downvoteCount + 1
      }
      if (qData.docs[qIndex].upvotedBy.some(s => s._id == userData?._id)) {
        qData.docs[qIndex].upvotedBy = qData.docs[qIndex].upvotedBy.filter(
          s => s._id !== userData?._id
        )
        qData.docs[qIndex].upvoteCount = qData.docs[qIndex].upvoteCount - 1
      }

      onGetAnswersSuccess(qData)
    }
  }, [downvoteAnswerSuccessMessage])

  useEffect(() => {
    if (
      Object.keys(saveQuestionsSuccessMessage).length != 0 &&
      saveQuestionsSuccessMessage?._id != ""
    ) {
      setTogggle(false)
      let qID = saveQuestionsSuccessMessage?._id
      let qData = bookmarkedQuestionList;
      //console.log('qData................in add save question1', allSavedQuestionsList);
      if (qData.length == 0) {
        qData = [qID]
      } else if (qData.includes(qID)) {
        qData = qData.filter(i => i !== qID)
      } else {
        qData.push(qID)
      }
      setBookmarkedQuestionList(qData)
      //console.log('qData................in add save question2', qData, allSavedQuestionsList);
      setTogggle(true)
    }
  }, [saveQuestionsSuccessMessage])

  const inputFile = useRef(null)

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click()
  }

  const removeAddQuestionImg = item => {
    //console.log('remove index....................', index);
    let dataArray = addQuestionImage
    dataArray = dataArray.filter(function (e) {
      return e !== item
    })
    setAddQuestionImage(dataArray)
  }

  const getAnswerComment = answer_id => {
    //console.log("answer_id.............", answer_id)
    setAnswerId(answer_id)
    onGetAnswerComments(answer_id)
  }

  //console.log("answer_id.............", answerId)

  const onQuestionCommentChange = e => {
    //console.log('e.target.value..................', e.target.value);
    setQuestionComment(e.target.value)
  }

  const createQuestionComment = question_id => {
    if (questionComment == '') {
      toastr.error("Comment should not be empty!");
    } else {
      onSaveQuestionComment(questionComment, question_id)
    }
  }

  const createAnswerComment = () => {
    //console.log('answerId.............',answerId);
    if (answerComment == '') {
      toastr.error("Comment should not be empty!");
    } else {
      onSaveAnswerComment(answerComment, answerId)
    }
  }

  useEffect(() => {
    if (
      Object.keys(saveQuestionCommentMessage).length != 0 &&
      saveQuestionCommentMessage?._id != ""
    ) {
      setQuestionComment("")
      onGetQuestionComments(selectQuestion?._id)
      onSaveQuestionCommentSuccess({})
    }
  }, [saveQuestionCommentMessage])

  useEffect(() => {
    if (
      Object.keys(saveAnswerCommentMessage).length != 0 &&
      saveAnswerCommentMessage?._id != ""
    ) {
      setAnswerComment("")
      onGetAnswers(selectQuestion?._id)
      onSaveAnswerCommentSuccess({})
    }
  }, [saveAnswerCommentMessage])

  const deleteQuestionComment = (getQuestionCommentId) => {
    setDeleteQuestionCommentId(getQuestionCommentId)
    setDeleteModal(true);
  }

  useEffect(() => {
    if (
      Object.keys(deleteQuestionCommentMessage).length != 0 &&
      deleteQuestionCommentMessage?._id != ""
    ) {
      setDeleteModal(false)
      let qID = deleteQuestionCommentMessage?._id
      setTogggle(false)
      let qData = questionComments
      let qDocs = questionComments.docs.filter(x => x._id != qID);
      questionComments.docs = qDocs;

      onGetQuestionCommentsSuccess(qData);
      onDeleteQuestionCommentSuccess([]);

      toastr.success("Question comment deleted successfully!");
      setDeleteAnswerCommentId(null)
    }

  }, [deleteQuestionCommentMessage])



  const deleteAnswerComment = (getAnswerCommentId) => {
    setDeleteAnswerCommentId(getAnswerCommentId)
    setDeleteModal(true);
  }

  useEffect(() => {
    if (
      Object.keys(deleteAnswerCommentMessage).length != 0 &&
      deleteAnswerCommentMessage?._id != ""
    ) {
      setDeleteModal(false)

      let qID = deleteAnswerCommentMessage?._id
      setTogggle(false)
      let qData = answerComments
      let qDocs = qData.docs.filter(x => x._id != qID);
      qData.docs = qDocs;

      onGetAnswerCommentsSuccess(qData);
      onDeleteAnswerCommentSuccess([]);

      toastr.success("Answer comment deleted successfully!");
      setDeleteAnswerCommentId(null)
    }

  }, [deleteAnswerCommentMessage])

  useEffect(() => {
    if (
      Object.keys(acceptAnswerMessage).length != 0 &&
      acceptAnswerMessage?._id != ""
    ) {
      setTogggle(false);
      let answerId = acceptAnswerMessage?._id
      let qData = questions;

      selectQuestion.acceptedAnswer = { _id: answerId };
      toastr.success("Answer accepted successfully!");
      setTogggle(true);
    }
  }, [acceptAnswerMessage])

  const handleDelete = () => {
    if (deleteQuestionId) {
      handleQuestionDelete()
    } else if (deleteAnswerId) {
      handleAnswerDelete()
    } else if (deleteQuestionCommentId) {
      onDeleteQuestionComment(deleteQuestionCommentId);
    } else if (deleteAnswerCommentId) {
      onDeleteAnswerComment(deleteAnswerCommentId)
    }
  }

  const resetDeleteId = () => {
    setDeleteQuestionId(null)
    setDeleteAnswerId(null)
    setDeleteQuestionCommentId(null)
    setDeleteAnswerCommentId(null)
  }

  const reportQuestion = (question_id) => {
    setReportQuestionId(question_id)
    setReportReason("")
    setReportModal(true)
  }

  const reportAnswer = (answer_id) => {
    setReportAnswerId(answer_id)
    setReportReason("")
    setReportModal(true)
  }

  const reportQuestionComment = (question_comment_id) => {
    setReportQuestionCommentId(question_comment_id)
    setReportReason("")
    setReportModal(true)
  }

  const reportAnswerComment = (answer_comment_id) => {
    setReportAnswerCommentId(answer_comment_id)
    setReportReason("")
    setReportModal(true)
  }

  const handleAcceptAnswer = (question_id, answer_id) => {
    onAcceptAnswer(question_id, answer_id)
  }


  const handleAllTypesReport = () => {
    let payload = {}
    if (reportQuestionId) {
      payload = {
        id: reportQuestionId,
        reportReason: reportReason,
        type: "QUESTION"
      }
    } else if (reportAnswerId) {
      payload = {
        id: reportAnswerId,
        reportReason: reportReason,
        type: "ANSWER"
      }
    } else if (reportQuestionCommentId) {
      payload = {
        id: reportQuestionCommentId,
        reportReason: reportReason,
        type: "QUESTION_COMMENT"
      }
    } else if (reportAnswerCommentId) {
      payload = {
        id: reportAnswerCommentId,
        reportReason: reportReason,
        type: "ANSWER_COMMENT"
      }
    }
    onReport(payload)
  }

  useEffect(() => {
    if (
      Object.keys(reportMessage).length != 0 &&
      reportMessage?._id != ""
    ) {
      handleResetReport()
      setReportModal(false)
      toastr.success("Report sent successfully!")
    }
  }, [reportMessage])

  const handleResetReport = () => {
    setReportReason("")
    setReportQuestionId(null)
    setReportAnswerId(null)
    setReportQuestionCommentId(null)
    setReportAnswerCommentId(null)
  }

  const closeQuestionModal = () => {
    setQuestionModal(false);
  }

  const closeAnswerModal = () => {
    setAnswerModal(false);
  }

  const openAskQuestionModal = () => {
    setQuestionModal(true);
  }

  const onChangeQuestionFilter = (e, type) => {
    if (type == 'subject') {
      setFilterData({ ...filterData, subject: e.target.value });
      onGetAllTopicsBySubject(e.target.value);
    }
    if (type == 'topic') {
      setFilterData({ ...filterData, topic: e.target.value });
    }
    if (type == 'unsolvedQuestions') {
      setFilterData({ ...filterData, unsolvedQuestions: !filterData.unsolvedQuestions });
    }
    if (type == 'savedQuestions') {
      setFilterData({ ...filterData, savedQuestions: !filterData.savedQuestions });
    }
    if (type == 'solvedQuestions') {
      setFilterData({ ...filterData, solvedQuestions: !filterData.solvedQuestions });
    }
    if (type == 'myQuestions') {
      setFilterData({ ...filterData, myQuestions: !filterData.myQuestions });
    }
    //console.log('onChangeSubjectFilter..................', e);
  }

  // useEffect(() => {
  //   //console.log('filterData............on chagne', filterData);
  //   onGetUnsolvedQuestion(filterData);
  //   onGetPopularTopic(filterData);
  //   onGetQuestions(filterData);
  // }, [filterData])

  const onSelectPopularTopics = (topic_id, subject_id) => {
    onGetAllTopicsBySubject(subject_id);
    setFilterData({ ...filterData, subject: subject_id, topic: topic_id });
  }

  //console.log('filterData............', filterData);
  const handleAddQuestionSuccess = (succRespone) => {
    closeQuestionModal();
    toastr.success("Question added successfully!");
    setIsEditQuestion(false);
    onGetUnsolvedQuestion(filterData);
    onGetPopularTopic(filterData);
    onGetQuestions(filterData);
  }

  const handleAddAnswerSuccess = (succRespone) => {
    closeAnswerModal();
    toastr.success("Answer added successfully!");
    onGetQuestions(filterData);
    onGetAnswers(selectQuestion?._id)
    onGetQuestionComments(selectQuestion?._id)
  }

  const editAnswer = answer => {
    setSelectAnswer(answer)
    setIsEditAnswer(true)
    setAnswerModal(true);
    //onGetAnswer(answer_id)
  }

  const handleUpdateAnswerSuccess = (succRespone) => {
    closeAnswerModal();
    toastr.success("Answer updated successfully!");
    setIsEditAnswer(false);
    setSelectAnswer(null)
    onGetQuestions(filterData);
    onGetAnswers(selectQuestion?._id)
    onGetQuestionComments(selectQuestion?._id)
  }

  const editQuestion = question => {
    setSelectQuestion(question)
    setIsEditQuestion(true)
    setQuestionModal(true);
    //onGetAnswer(answer_id)
  }

  const handleUpdateQuestionSuccess = (succRespone) => {
    closeQuestionModal();
    toastr.success("Question updated successfully!");
    setIsEditQuestion(false);
    setSelectQuestion(null);
    onGetUnsolvedQuestion(filterData);
    onGetPopularTopic(filterData);
    onGetQuestions(filterData);
  }

  const filterUsers = (query, data, searchKey) => {
    //console.log('query, data, searchKey............', query, data, searchKey);
    if (!query) return data; // Return all data if the query is empty

    const filteredData = data.filter(item =>
      item[searchKey]?.toLowerCase().includes(query.toLowerCase())
    );
    return filteredData;
  };

  useEffect(() => {
    let data = allUserList.map((item) => {
      item.display = item.userName;
      item.id = item._id;
      return item;
    });
    setUserFilteredData(data);

  }, [allUserList])

  const changeViewAnswerComment = (ans_id) => {
    let comment = viewAllAnswerComment;
    if (comment.includes(ans_id)) {
      comment = comment.filter(item => item != ans_id)
      setViewAllAnswerComment(comment);
    } else {
      comment.push(ans_id);
      setViewAllAnswerComment(comment);
    }
  }

  return (
    <React.Fragment>
      <div className="mt-4 bg-profile">
        <MetaTags>
          <title>Student Doubt Resolution App</title>
        </MetaTags>
        <Container>
          <div>
            <img
              src={logoWhite}
              alt=""
              className="navbar-img"
            />
          </div>
          <Row className="header mt-0 p-0 justify-content-center" id="myHeader">

            <Col
              lg="7"
              className="mt-0"
              style={{ position: "relative" }}
            >
              <Card>
                {selectQuestion != null && (
                  <>
                    <div className="m-3">
                      <QuestionMiniCard
                        questionItem={selectQuestion}
                        onClick={onSingleQuestionClick}
                        selectQuestion={selectQuestion}
                        upvoteQuestion={upvoteQuestion}
                        downvoteQuestion={downvoteQuestion}
                        userData={userData}
                        savedQuestions={bookmarkedQuestionList}
                        bookmarkQuestion={bookmarkQuestion}
                        deleteQuestion={deleteQuestion}
                        reportQuestion={reportQuestion}
                        isSingle={false}
                        editQuestion={editQuestion}
                      ></QuestionMiniCard>

                      <Link to="/login">
                        <div className={'d-flex justify-content-between'}>
                          <p
                            className="mt-3 text-white"
                            style={{ fontStyle: "italic" }}
                          >
                            Comments
                            <span className="ms-1">
                              ( {selectQuestion.commentsCount} )
                            </span>
                          </p>
                          {
                            questionComments?.docs?.length > 3 &&
                            <Link
                              className="mt-3 like-color"
                              style={{ fontStyle: "bold" }}
                              onClick={() => setViewAllQuestionComment(!viewAllQuestionComment)}
                            >
                              {
                                viewAllQuestionComment == true ?
                                  'View less'
                                  :
                                  'View all'
                              }

                            </Link>
                          }
                        </div>

                        <div className="">
                          <div class="card question-comment-card mb-0">
                            <div
                              class="card-body d-flex "
                              style={{
                                backgroundColor: "#1D2226",
                                borderRadius: "10px",
                                border: "1px solid",
                              }}
                            >
                              <div class="card-text w-100">

                                <div className="row">
                                  {questionComments?.docs?.length > 0 &&
                                    (
                                      viewAllQuestionComment == true ?
                                        questionComments?.docs.map((item, index) => {
                                          return (
                                            !(reportedPostQuestionComment?.length > 0 && reportedPostQuestionComment?.includes(item._id)) &&
                                            <>
                                              <div className="d-flex justify-content-lg-between align-items-start">
                                                <div className="d-flex">
                                                  <div className="mt-1" style={{ width: 50 }}>
                                                    {item?.author
                                                      ?.realProfilePicUrl ? (
                                                      <img
                                                        src={
                                                          item?.author
                                                            ?.realProfilePicUrl
                                                        }
                                                        alt=""
                                                        className="width-40-px rounded-circle img-thumbnail"
                                                      />
                                                    ) : (
                                                      <img
                                                        src={avatar1}
                                                        alt=""
                                                        className="width-40-px rounded-circle img-thumbnail"
                                                      />
                                                    )}
                                                  </div>
                                                  <div className=" mt-2 ms-3" style={{ width: '80%' }}>
                                                    <span className="fs-6 text-white">
                                                      {item?.author?.userName}
                                                    </span>
                                                    <p
                                                      className="mt-1 mb-2 text-white"
                                                      style={{
                                                        fontSize:
                                                          "10px",
                                                      }}
                                                    >
                                                      {CommonFunction.dateFormat(item?.createdAt)}
                                                    </p>
                                                    <p
                                                      className="mt-1 mb-2 text-white"
                                                      style={{ fontSize: "11px" }}
                                                    >
                                                      {/* <ReactMentionParser
                                                      renderMention={(mentionValue) => (
                                                          <div className="mention">{mentionValue}</div>
                                                      )}
                                                  >
                                                      {item.body}
                                                  </ReactMentionParser> */}
                                                      {parseMarkup(item.body)}
                                                    </p>
                                                  </div>
                                                </div>
                                                <Dropdown
                                                  isOpen={otherMenu == item._id}
                                                  toggle={() => {
                                                    //console.log('otherMenu, item._id............', otherMenu, item._id);
                                                    if (otherMenu == item._id) {
                                                      setOtherMenu(null)
                                                    } else {
                                                      setOtherMenu(item._id)
                                                    }
                                                  }}
                                                >
                                                  <DropdownToggle
                                                    tag="i"
                                                    className="btn nav-btn"
                                                    type="button"
                                                  >
                                                    <i className="bx bx-dots-vertical-rounded mt-0 fs-3 " />
                                                  </DropdownToggle>
                                                  <DropdownMenu className="dropdown-menu-end bg-card">
                                                    {userData?._id === item?.author?._id ? (
                                                      <>
                                                        {/* <DropdownItem href="#">
                                                    Edit Question
                                                  </DropdownItem> */}
                                                        <DropdownItem onClick={() => deleteQuestionComment(item._id)}>
                                                          Delete Comment
                                                        </DropdownItem>
                                                      </>
                                                    )
                                                      :
                                                      <DropdownItem onClick={() => reportQuestionComment(item._id)}>
                                                        Report Comment
                                                      </DropdownItem>
                                                    }
                                                  </DropdownMenu>
                                                </Dropdown>
                                              </div>
                                              <hr
                                                style={{
                                                  backgroundColor: "#5C5C5C",
                                                  height: 2,
                                                }}
                                              />
                                            </>
                                          )
                                        })
                                        :
                                        questionComments?.docs.slice(0, 3).map((item, index) => {
                                          return (
                                            !(reportedPostQuestionComment?.length > 0 && reportedPostQuestionComment?.includes(item._id)) &&
                                            <>
                                              <div className="d-flex justify-content-lg-between align-items-start">
                                                <div className="d-flex">
                                                  <div className="mt-1" style={{ width: 50 }}>
                                                    {item?.author
                                                      ?.realProfilePicUrl ? (
                                                      <img
                                                        src={
                                                          item?.author
                                                            ?.realProfilePicUrl
                                                        }
                                                        alt=""
                                                        className="width-40-px rounded-circle img-thumbnail"
                                                      />
                                                    ) : (

                                                      <Avatar name={item?.author?.userName + ' ' + item?.author?.lastName} size="40" round="100px" />
                                                    )}
                                                  </div>
                                                  <div className=" mt-2 ms-3" style={{ width: '80%' }}>
                                                    <span className="fs-6 text-white">
                                                      {item?.author?.userName}
                                                    </span>
                                                    <p
                                                      className="mt-1 mb-2 text-white"
                                                      style={{
                                                        fontSize:
                                                          "10px",
                                                      }}
                                                    >
                                                      {CommonFunction.dateFormat(item?.createdAt)}
                                                    </p>
                                                    <p
                                                      className="mt-1 mb-2 text-white"
                                                      style={{ fontSize: "11px" }}
                                                    >
                                                      {/* <ReactMentionParser
                                                      renderMention={(mentionValue) => (
                                                          <div className="mention">{mentionValue}</div>
                                                      )}
                                                  >
                                                      {item.body}
                                                  </ReactMentionParser> */}
                                                      {parseMarkup(item.body)}
                                                    </p>
                                                  </div>
                                                </div>
                                                <Dropdown
                                                  isOpen={otherMenu == item._id}
                                                  toggle={() => {
                                                    //console.log('otherMenu, item._id............', otherMenu, item._id);
                                                    if (otherMenu == item._id) {
                                                      setOtherMenu(null)
                                                    } else {
                                                      setOtherMenu(item._id)
                                                    }
                                                  }}
                                                >
                                                  <DropdownToggle
                                                    tag="i"
                                                    className="btn nav-btn"
                                                    type="button"
                                                  >
                                                    <i className="bx bx-dots-vertical-rounded mt-0 fs-3 " />
                                                  </DropdownToggle>
                                                  <DropdownMenu className="dropdown-menu-end bg-card">
                                                    {userData?._id === item?.author?._id ? (
                                                      <>
                                                        {/* <DropdownItem href="#">
                                                    Edit Question
                                                  </DropdownItem> */}
                                                        <DropdownItem onClick={() => deleteQuestionComment(item._id)}>
                                                          Delete Comment
                                                        </DropdownItem>
                                                      </>
                                                    )
                                                      :
                                                      <DropdownItem onClick={() => reportQuestionComment(item._id)}>
                                                        Report Comment
                                                      </DropdownItem>
                                                    }
                                                  </DropdownMenu>
                                                </Dropdown>
                                              </div>
                                              <hr
                                                style={{
                                                  backgroundColor: "#5C5C5C",
                                                  height: 2,
                                                }}
                                              />
                                            </>
                                          )
                                        })
                                    )
                                  }
                                  <div className="w-100 justify-content-center ">
                                    <div class="input-group auth-pass-inputgroup comment-inputgroup w-100 input-mention">
                                      <MentionsInput
                                        value={questionComment}
                                        onChange={e => onQuestionCommentChange(e)}
                                        placeholder="Add your comment..."
                                        className="custom-mention-input"
                                        style={{
                                          backgroundColor: "#38434F",
                                          borderRadius: "12px",
                                          width: '90%',
                                          padding: '1px',
                                        }}
                                        a11ySuggestionsListLabel={"Suggested mentions"}
                                      >
                                        <Mention
                                          //markup="@__display__"
                                          trigger="@"
                                          //regex={/@(\S+)/}
                                          data={userFilteredData}
                                          renderSuggestion={(suggestion, search, highlightedDisplay) => (
                                            <div className="highlightDisplay">{highlightedDisplay}</div>
                                          )}
                                          displayTransform={(id, display) => `@${display}`}
                                          markup="@[__display__]"
                                          appendSpaceOnAdd={true}
                                          filter={filterUsers} // Use the custom filter function
                                          style={{ color: '#70B5F9', position: 'absolute', zIndex: 9, padding: '1px' }}
                                        />
                                      </MentionsInput>
                                      <button
                                        class="btn btn-light comment-send-btn"
                                        type="button"
                                        id="password-addon"
                                        onClick={() =>
                                          createQuestionComment(
                                            selectQuestion._id
                                          )
                                        }
                                      >
                                        <i class="bx bxs-navigation"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <CardBody>
                      {answerList?.docs?.length > 0 &&
                        answerList.docs.map((ans, index) => {
                          return (
                            <div>

                              <div>
                                <p className="fs-5 mt-3 mb-2" style={{ color: '#F9D581' }}>Answer
                                </p>
                                <div
                                  className="mt-0"
                                  style={{
                                    height: 1,
                                    backgroundColor: '#414141',
                                    width: "100%",
                                  }}
                                ></div>
                              </div>
                              <AnswerMiniCard
                                answerItem={ans}
                                key={"ans_" + index}
                                getAnswerComment={getAnswerComment}
                                upvoteAnswer={upvoteAnswer}
                                downvoteAnswer={downvoteAnswer}
                                userData={userData}
                                deleteAnswer={deleteAnswer}
                                selectQuestion={selectQuestion}
                                reportAnswer={reportAnswer}
                                handleAcceptAnswer={handleAcceptAnswer}
                              />
                              { //answerId == item._id && 
                                (

                                  <Link to="/login">
                                    <div className="">
                                      {
                                        ans?.comments?.length > 3 &&
                                        <div className="d-flex justify-content-end mb-2 mt-1">
                                          <Link
                                            className="mt-3 like-color"
                                            style={{ fontStyle: "bold" }}
                                            onClick={() => changeViewAnswerComment(ans?._id)}
                                          >
                                            {
                                              viewAllAnswerComment.includes(ans?._id) ?
                                                'View less'
                                                :
                                                'View all'
                                            }

                                          </Link>
                                        </div>
                                      }
                                      <div class="card question-comment-card mb-0">
                                        <div
                                          class="card-body "
                                          style={{
                                            backgroundColor: "#1D2226",
                                            borderRadius: "10px",
                                            border: "1px solid",
                                          }}
                                        >
                                          {
                                            <>
                                              <div className="row">

                                                {

                                                  viewAllAnswerComment.includes(ans?._id) ?
                                                    ans?.comments?.length > 0 &&
                                                    ans?.comments?.map(
                                                      (item, index) => {
                                                        return (
                                                          <>
                                                            <div className="d-flex justify-content-lg-between ">
                                                              <div className="d-flex">
                                                                <div className="mt-1">
                                                                  {item?.author
                                                                    ?.realProfilePicUrl ? (
                                                                    <img
                                                                      src={
                                                                        item?.author
                                                                          ?.realProfilePicUrl
                                                                      }
                                                                      alt=""
                                                                      className="width-40-px rounded-circle img-thumbnail"
                                                                    />
                                                                  ) : (
                                                                    <img
                                                                      src={avatar1}
                                                                      alt=""
                                                                      className="width-40-px rounded-circle img-thumbnail"
                                                                    />
                                                                  )}
                                                                </div>
                                                                <div className=" mt-2 ms-3">
                                                                  <span className="fs-6 text-white">
                                                                    {
                                                                      item?.author
                                                                        ?.userName
                                                                    }
                                                                  </span>
                                                                  <p
                                                                    className="mt-1 mb-2 text-white"
                                                                    style={{
                                                                      fontSize:
                                                                        "10px",
                                                                    }}
                                                                  >
                                                                    {CommonFunction.dateFormat(item?.createdAt)}
                                                                  </p>
                                                                  <p
                                                                    className="mt-1 mb-2 text-white"
                                                                    style={{
                                                                      fontSize:
                                                                        "11px",
                                                                    }}
                                                                  >
                                                                    {parseMarkup(item.body)}
                                                                  </p>
                                                                </div>
                                                              </div>

                                                              <Dropdown
                                                                isOpen={otherMenu == item._id}
                                                                toggle={() => {
                                                                  //console.log('otherMenu, item._id............', otherMenu, item._id);
                                                                  if (otherMenu == item._id) {
                                                                    setOtherMenu(null)
                                                                  } else {
                                                                    setOtherMenu(item._id)
                                                                  }
                                                                }}
                                                              >
                                                                <DropdownToggle
                                                                  tag="i"
                                                                  className="btn nav-btn"
                                                                  type="button"
                                                                >
                                                                  <i className="bx bx-dots-vertical-rounded mt-0 fs-3 " />
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end">
                                                                  {userData?._id === item?.author?._id ? (
                                                                    <>
                                                                      <DropdownItem onClick={() => deleteAnswerComment(item._id)}>
                                                                        Delete Comment
                                                                      </DropdownItem>
                                                                    </>
                                                                  )
                                                                    :
                                                                    <DropdownItem onClick={() => reportAnswerComment(item._id)}>
                                                                      Report Comment
                                                                    </DropdownItem>
                                                                  }
                                                                </DropdownMenu>
                                                              </Dropdown>
                                                            </div>
                                                            <hr
                                                              style={{
                                                                backgroundColor:
                                                                  "#5C5C5C",
                                                                height: 2,
                                                              }}
                                                            />
                                                          </>
                                                        )
                                                      }
                                                    )
                                                    :
                                                    ans?.comments?.length > 0 &&
                                                    ans?.comments.slice(0, 3).map(
                                                      (item, index) => {
                                                        return (
                                                          !(reportedPostAnswerComment?.length > 0 && reportedPostAnswerComment?.includes(item._id)) &&
                                                          <>
                                                            <div className="d-flex justify-content-lg-between ">
                                                              <div className="d-flex">
                                                                <div className="mt-1">
                                                                  {item?.author
                                                                    ?.realProfilePicUrl ? (
                                                                    <img
                                                                      src={
                                                                        item?.author
                                                                          ?.realProfilePicUrl
                                                                      }
                                                                      alt=""
                                                                      className="width-40-px rounded-circle img-thumbnail"
                                                                    />
                                                                  ) : (
                                                                    <Avatar name={item?.author?.userName + ' ' + item?.author?.lastName} size="40" round="100px" />
                                                                    // <img
                                                                    //   src={avatar1}
                                                                    //   alt=""
                                                                    //   className="width-40-px rounded-circle img-thumbnail"
                                                                    // />
                                                                  )}
                                                                </div>
                                                                <div className=" mt-2 ms-3">
                                                                  <span className="fs-6 text-white">
                                                                    {
                                                                      item?.author
                                                                        ?.userName
                                                                    }
                                                                  </span>
                                                                  <p
                                                                    className="mt-1 mb-2 text-white"
                                                                    style={{
                                                                      fontSize:
                                                                        "10px",
                                                                    }}
                                                                  >
                                                                    {CommonFunction.dateFormat(item?.createdAt)}
                                                                  </p>
                                                                  <p
                                                                    className="mt-1 mb-2 text-white"
                                                                    style={{
                                                                      fontSize:
                                                                        "11px",
                                                                    }}
                                                                  >
                                                                    {parseMarkup(item.body)}
                                                                  </p>
                                                                </div>
                                                              </div>

                                                              <Dropdown
                                                                isOpen={otherMenu == item._id}

                                                                toggle={() => {
                                                                  //console.log('otherMenu, item._id............', otherMenu, item._id);
                                                                  if (otherMenu == item._id) {
                                                                    setOtherMenu(null)
                                                                  } else {
                                                                    setOtherMenu(item._id)
                                                                  }
                                                                }}
                                                              >
                                                                <DropdownToggle
                                                                  tag="i"
                                                                  className="btn nav-btn"
                                                                  type="button"
                                                                >
                                                                  <i className="bx bx-dots-vertical-rounded mt-0 fs-3 " />
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end">
                                                                  {userData?._id === item?.author?._id ? (
                                                                    <>
                                                                      <DropdownItem onClick={() => deleteAnswerComment(item._id)}>
                                                                        Delete Comment
                                                                      </DropdownItem>
                                                                    </>
                                                                  )
                                                                    :
                                                                    <DropdownItem onClick={() => reportAnswerComment(item._id)}>
                                                                      Report Comment
                                                                    </DropdownItem>
                                                                  }
                                                                </DropdownMenu>
                                                              </Dropdown>
                                                            </div>
                                                            <hr
                                                              style={{
                                                                backgroundColor:
                                                                  "#5C5C5C",
                                                                height: 2,
                                                              }}
                                                            />
                                                          </>
                                                        )
                                                      }
                                                    )
                                                }
                                              </div>

                                              <div className="w-100 justify-content-center">
                                                <div class="input-group auth-pass-inputgroup comment-inputgroup w-100">
                                                  {/* <input
                                                    type=""
                                                    class="form-control comment-input rounded-3"
                                                    id=""
                                                    aria-describedby=""
                                                    placeholder="Add your comment..."
                                                    style={{
                                                      backgroundColor: "#38434F",
                                                      borderRadius: "12px",
                                                    }}
                                                    onChange={e =>
                                                      setAnswerComment(
                                                        e.target.value
                                                      )
                                                    }
                                                    value={answerComment}
                                                  /> */}

                                                  <MentionsInput
                                                    value={answerComment}
                                                    onChange={e =>
                                                      setAnswerComment(
                                                        e.target.value
                                                      )
                                                    }
                                                    placeholder="Add your comment..."
                                                    className="custom-mention-input"
                                                    style={{
                                                      backgroundColor: "#38434F",
                                                      borderRadius: "12px",
                                                      width: '90%'
                                                    }}
                                                    a11ySuggestionsListLabel={"Suggested mentions"}
                                                  >
                                                    <Mention
                                                      //markup="@__display__"
                                                      trigger="@"
                                                      //regex={/@(\S+)/}
                                                      data={userFilteredData}
                                                      renderSuggestion={(suggestion, search, highlightedDisplay) => (
                                                        <div className="highlightDisplay">{highlightedDisplay}</div>
                                                      )}
                                                      displayTransform={(id, display) => `@${display}`}
                                                      markup="@[__display__]"
                                                      appendSpaceOnAdd={true}
                                                      filter={filterUsers} // Use the custom filter function
                                                      style={{ color: '#70B5F9', position: 'absolute', zIndex: 9 }}
                                                    />
                                                  </MentionsInput>
                                                  <button
                                                    class="btn btn-light comment-send-btn"
                                                    type="button"
                                                    id="password-addon"
                                                    onClick={createAnswerComment}
                                                  >
                                                    <i class="bx bxs-navigation"></i>
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                )}
                            </div>
                          )
                        })}
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = state => {
  //console.log("state ----------", state.talks)
  const { error, newsPosts, spaces, talks, users } = state
  return {
    talks: newsPosts,
    error: error,
    current_space: spaces.current_space,
    unsolvedQuestions: talks.unsolvedQuestions,
    popularTopics: talks.popularTopics,
    questions: talks.questions,
    upvoteQuestionSuccessMessage: talks.upvoteQuestionSuccess,
    downvoteQuestionSuccessMessage: talks.downvoteQuestionSuccess,
    upvoteAnswerSuccessMessage: talks.upvoteAnswerSuccess,
    downvoteAnswerSuccessMessage: talks.downvoteAnswerSuccess,
    answerList: talks.answers,
    questionComments: talks.questionComments,
    answerComments: talks.answerComments,
    allSavedQuestionsList: talks.allSavedQuestions,
    saveQuestionsSuccessMessage: talks.saveQuestion,
    saveQuestionCommentMessage: talks.saveQuestionComment,
    saveAnswerCommentMessage: talks.saveAnswerComment,
    deleteQuestionMessage: talks.deleteQuestion,
    deleteAnswerMesssage: talks.deleteAnswer,
    deleteQuestionCommentMessage: talks.deleteQuestionComment,
    deleteAnswerCommentMessage: talks.deleteAnswerComment,
    reportMessage: talks.reportSuccess,
    acceptAnswerMessage: talks.acceptSuccess,
    subjectList: talks.subjectsSuccess,
    allTopicsBySubjectList: talks.topics,
    oneQuestion: talks.singleQuestion,
    allUserList: users.allUsers,
    reportedPostQuestion: talks.reportedPostQuestion,
    reportedPostQuestionError: talks.reportedPostQuestionError,
    reportedPostAnswer: talks.reportedPostAnswer,
    reportedPostAnswerError: talks.reportedPostAnswerError,
    reportedPostQuestionComment: talks.reportedPostQuestionComment,
    reportedPostQuestionCommentError: talks.reportedPostQuestionCommentError,
    reportedPostAnswerComment: talks.reportedPostAnswerComment,
    reportedPostAnswerCommentError: talks.reportedPostAnswerCommentError,
    reportSuccessMessage: talks.reportSuccess
  }
}

const mapDispatchToProps = dispatch => ({
  onGetUnsolvedQuestion: (optionData) => dispatch(getUnsolvedQuestion(optionData)),
  onGetPopularTopic: (optionData) => dispatch(getPopularTopic(optionData)),
  onGetQuestions: (options) => dispatch(getQuestions(options)),
  onUpvoteQuestions: question_id => dispatch(upvoteQuestions(question_id)),
  onDownvoteQuestions: question_id => dispatch(downvoteQuestions(question_id)),
  onUpvoteQuestionsSuccess: () => dispatch(clearUpvoteQuestionsSuccess()),
  onDownvoteQuestionsSuccess: () => dispatch(clearDownvoteQuestionsSuccess()),
  onGetQuestionSuccess: questionData =>
    dispatch(getQuestionsSuccess(questionData)),
  onGetAnswers: question_id => dispatch(getAnswerByQuestionId(question_id)),
  onGetAnswerComments: answer_id => dispatch(getAnswerComments(answer_id)),
  onGetQuestionComments: question_id =>
    dispatch(getQuestionComments(question_id)),
  onUpvoteAnswers: question_id => dispatch(upvoteAnswers(question_id)),
  //onUpvoteAnswersSuccess: () => dispatch(clearUpvoteAnswersSuccess()),
  onDownvoteAnswers: question_id => dispatch(downvoteAnswers(question_id)),
  onUpvoteAnswersSuccess: () => dispatch(upvoteAnswersSuccess()),
  onDownvoteAnswersSuccess: () => dispatch(downvoteAnswersSuccess()),
  onUpvoteAnswerSuccess: () => dispatch(clearUpvoteQuestionsSuccess()),
  onDownvoteAnswerSuccess: () => dispatch(clearDownvoteQuestionsSuccess()),
  onGetAllSavedQuestions: () => dispatch(getAllSavedQuestions()),
  onGetAllSavedQuestionsSuccess: data =>
    dispatch(getAllSavedQuestionsSuccess(data)),
  onSaveQuestions: (question_id, isBookmark) =>
    dispatch(saveQuestions(question_id, isBookmark)),
  onSaveQuestionsSuccess: data => dispatch(saveQuestionsSuccess(data)),
  onSaveQuestionComment: (comment, question_id) =>
    dispatch(saveQuestionComment(comment, question_id)),
  onSaveQuestionCommentSuccess: data =>
    dispatch(saveQuestionCommentSuccess(data)),
  onSaveAnswerComment: (comment, answer_id) =>
    dispatch(saveAnswerComment(comment, answer_id)),
  onSaveAnswerCommentSuccess: data => dispatch(saveAnswerCommentSuccess(data)),
  onDeleteQuestion: question_id => dispatch(deleteQuestion(question_id)),
  onDeleteQuestionSuccess: data => dispatch(deleteQuestionSuccess(data)),
  onDeleteAnswer: answer_id => dispatch(deleteAnswer(answer_id)),
  onDeleteAnswerSuccess: data => dispatch(deleteAnswerSuccess(data)),
  onGetAnswersSuccess: data => dispatch(getAnswersSuccess(data)),
  onDeleteQuestionComment: comment_id => dispatch(deleteQuestionComment(comment_id)),
  onDeleteQuestionCommentSuccess: data => dispatch(deleteQuestionCommentSuccess(data)),
  onDeleteAnswerComment: comment_id => dispatch(deleteAnswerComment(comment_id)),
  onDeleteAnswerCommentSuccess: data => dispatch(deleteAnswerCommentSuccess(data)),
  onGetQuestionCommentsSuccess: data => dispatch(getQuestionCommentsSuccess(data)),
  onGetAnswerCommentsSuccess: data => dispatch(getAnswerCommentsSuccess(data)),
  //onDownvoteAnswersSuccess: () => dispatch(clearDownvoteAnswersSuccess()),
  onReport: data => dispatch(report(data)),
  onAcceptAnswer: (question_id, answer_id) => dispatch(acceptAnswer(question_id, answer_id)),
  onGetSubjects: () => dispatch(getSubjects()),
  onGetAllTopicsBySubject: (subject_id) => dispatch(getTopicsBySubject(subject_id)),
  onFilterQuestions: (data) => dispatch(filterQuestions(data)),
  onGetSingleQuestions: (question_id) => dispatch(getSingleQuestion(question_id)),
  onGetAllUsers: () => dispatch(getAllusers())
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
)
