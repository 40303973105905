import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"
import { connect } from "react-redux"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter, useLocation } from "react-router-dom"
import { Link } from "react-router-dom"
import logo from "../../assets/images/logo.svg"
import logoutimg from "../../assets/images/logout-img.png"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  exitSpace,
  setCurrentSpace,
} from "../../store/actions"

import { useDispatch } from "react-redux"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import {
  Button,
  Modal,
} from "reactstrap"
//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const { current_space } = props;
  const dispatch = useDispatch();
  const ref = useRef()
  const location = useLocation();
  const [menuSideClass, setMenuSideClass] = useState('');
  const [menuLiClass, setMenuLiClass] = useState('');
  const [menuLinkClass, setMenuLinkClass] = useState('');
  const [logoutConfirmMsg, setLogoutConfirmMsg] = useState(false);
  const [modal, setmodal] = useState(false);
  const [modalexit, setexitmodal] = useState(false);

  
    const isMenuActive = (to) => {
      return location.pathname === to;
    };

    useEffect(() => {
      let currentSpace = localStorage.getItem("currentSpace")
      dispatch(setCurrentSpace(JSON.parse(currentSpace)))
    }, [])
  

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  // useEffect(() => {
  //   const pathName = props.history.location.pathname

  //   const initMenu = () => {
  //     new MetisMenu("#side-menu")
  //     let matchingMenuItem = null
  //     const ul = document.getElementById("side-menu")
  //     const items = ul.getElementsByTagName("a")
  //     for (let i = 0; i < items.length; ++i) {
  //       if (pathName === items[i].pathname) {
  //         matchingMenuItem = items[i]
  //         break
  //       }
  //     }
  //     if (matchingMenuItem) {
  //       activateParentDropdown(matchingMenuItem)
  //     }
  //   }
  //   initMenu()
  // }, [props.history.location.pathname])

  useEffect(() => {
    ref.current.recalculate();
    getLinkClass("/nimorisingstarsingle");
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  const getLinkClass = path => {
    if (location.pathname === path) {
      setMenuSideClass('mm-show mm-active');
      setMenuLiClass('mm-active');
      setMenuLinkClass('active');
    }
  };

  const logout = () => {
    window.location.href = "/logout";
  }

  const exitUserSpace = () => {
    dispatch(exitSpace());
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref} className="">
        <div className="navbar-brand-box">
          <div className="d-flex backToSpace mb-2">
            <Link to="/space" className="backToSpace">Go to Spaces</Link>
          </div>
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <h1>Engineering</h1>
            </span>
          </Link>
          {
            props.isSpaceScreen ?
              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <i className="fa fa-fw fa-angle-left" style={{ fontSize: 20 }} />
                </span>
                <span className="logo-lg">
                  <h1>NIMO</h1>
                </span>
              </Link>
              :
              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <i className="fa fa-fw fa-angle-left" style={{ fontSize: 20 }} />
                </span>
                <span className="logo-lg">
                  <h4 className="mb-0">{current_space.name}</h4>
                </span>
                <a href="/space" className="logo logo-dark"></a>
              </Link>
          }
        </div>
        <div id="sidebar-menu">
          <ul className={`metismenu list-unstyled ${menuSideClass}`} id="side-menu">
          <li className={` ${isMenuActive('/dashboard') ? 'active-sidebar' : ''} mb-2  `} >
              <Link to="/dashboard" >
                <i className="bx bx-show"></i>
                <span className="">{props.t("Nimo Ask")}</span></Link>
            </li>
            <li className={` ${isMenuActive('/leaderboard') ? 'active-sidebar' : ''} mb-2  `}>
              <Link to="/leaderboard" >
                <i className="bx bx-group"></i>
                <span>{props.t("Leaderboard")}</span></Link>
            </li>
            <li className={` ${menuLiClass} ${isMenuActive('/profile') ? 'active-sidebar' : ''} mb-2  `}>
              <Link to="/profile" className={menuLinkClass}>
                <i className="bx bx-user-circle"></i>
                {/* <span className="badge rounded-pill bg-info float-end">
                  04
                </span> */}
                <span>{props.t("My Profile")}</span></Link>
            </li>
            <li className={`${isMenuActive('/exit-space') ? 'active-sidebar' : ''} mb-2  `}>
              <span className="logout-menu"
                onClick={() => {
                  //setLogoutConfirmMsg(true)
                  setexitmodal(!modalexit)
                }}>
                <i className="bx bx-log-in"></i>
                <span>{props.t("Exit Space")}</span>
              </span>

              <Modal
                isOpen={modalexit}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                tabIndex="-1"
                toggle={() => {
                  setexitmodal(!modalexit)
                }}
              >
                <div className="join-model-content border-top-0  text-center rounded-3" >
                  <div className="modal-header justify-content-end border-bottom-0 pb-0 ">

                    <button type="button" onClick={() => {
                      setexitmodal(false)
                    }} class="close  font-icon-comment" data-dismiss="modalexit" aria-label="Close"><span aria-hidden="true">×</span></button>

                  </div>
                  <img
                    src={logoutimg}
                    alt=""
                    className="bg-transparent success"
                    width={150}
                    height={100}
                  />

                  <h3 className="mb-5 mt-3 font-weight-400" style={{ color: '#0073B1', fontSize: 20 }}>
                    Do you really want to Exit?

                  </h3>
                  <div className='d-flex justify-content-around align-items-center mx-3 mb-3'>
                    <Button
                      outline
                      className='model-btn'
                      type="button"
                      onClick={() => {
                        setexitmodal(!modalexit)
                      }}
                      style={{ color: '#0073B1', border: '1px solid 0073B1', borderRadius: '100px', height: '42px' }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className='model-btn'
                      type="button"
                      color=""
                      onClick={() => {
                        exitUserSpace()
                      }}
                      style={{ color: '#ffffff', background: '#0073B1', borderRadius: '100px', height: '42px' }}
                    >
                      Exit
                    </Button>
                  </div>
                </div>
              </Modal>
            </li>

            <li className={isMenuActive('/exit-space') ? 'active-sidebar mb-2' : ''}>
              <span className="logout-menu"
                onClick={() => {
                  //setLogoutConfirmMsg(true)
                  setmodal(!modal)
                }}>
                <i className="bx bx-power-off"></i>
                <span>{props.t("Logout")}</span>
              </span>

              <Modal
                isOpen={modal}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                tabIndex="-1"
                toggle={() => {
                  setmodal(!modal)
                }}
              >
                <div className="join-model-content border-top-0  text-center rounded-3" >
                  <div className="modal-header justify-content-end border-bottom-0 pb-0 ">

                    <button type="button" onClick={() => {
                      setmodal(false)
                    }} class="close  font-icon-comment" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>

                  </div>
                  <img
                    src={logoutimg}
                    alt=""
                    className="bg-transparent success"
                    width={150}
                    height={100}
                  />

                  <h3 className="mb-5 mt-3 font-weight-400" style={{ color: '#0073B1', fontSize: 20 }}>
                    Do you really want to logout?

                  </h3>
                  <div className='d-flex justify-content-around align-items-center mx-3 mb-3'>
                    <Button
                      outline
                      className='model-btn'
                      type="button"
                      onClick={() => {
                        setmodal(!modal)
                      }}
                      style={{ color: '#0073B1', border: '1px solid 0073B1', borderRadius: '100px', height: '42px' }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className='model-btn'
                      type="button"
                      color=""
                      onClick={() => {
                        logout()
                      }}
                      style={{ color: '#ffffff', background: '#0073B1', borderRadius: '100px', height: '42px' }}
                    >
                      Logout
                    </Button>
                  </div>
                </div>
              </Modal>
            </li>

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}



const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout

  const {
    current_space
  } = state.spaces
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType, current_space }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  exitSpace
})(withTranslation()(SidebarContent))


// export default withRouter(withTranslation()(SidebarContent))
