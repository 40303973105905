import PropTypes from 'prop-types'
import React, { useState } from "react"
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import classnames from "classnames"

import ProfileTab from "./ProfileTab"
import PasswordTab from "./PasswordTab"
import StudentSettingsTab from "./StudentSettingsTab"
import TeacherSettingTab from "./TeacherSettingTab"

const Setting = props => {
  const [activeTab, setactiveTab] = useState("1")
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Student Doubt Resolution App</title>
        </MetaTags>
        <Container fluid>
            {/* <Breadcrumbs
                title={props.t("Settings")}
                breadcrumbItem={props.t("User Profile")}
            /> */}
          <h1 className="mb-5">User Profile</h1>
          <Row className="justify-content-center">
            <Col xl="10">
              <Nav pills justified>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggleTab("1")
                    }}
                  >
                    <i className="bx bx-user font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">Profile</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggleTab("2")
                    }}
                  >
                    <i className="bx bx-lock-alt font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">Password</span>
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "3",
                    })}
                    onClick={() => {
                      toggleTab("3")
                    }}
                  >
                    <i className="bx bx-book-content font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">Student settings</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "4",
                    })}
                    onClick={() => {
                      toggleTab("4")
                    }}
                  >
                    <i className="bx bx-book-content font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">Teacher setting</span>
                  </NavLink>
                </NavItem> */}
              </Nav>
              <TabContent activeTab={activeTab} className="py-4">
                <TabPane tabId="1">
                    <ProfileTab />
                </TabPane>

                <TabPane tabId="2">
                  <PasswordTab />
                </TabPane>

                <TabPane tabId="3">
                  <StudentSettingsTab />
                </TabPane>

                <TabPane tabId="4">
                  <TeacherSettingTab />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Setting.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Setting)
