import {
  GET_SAVED_QUESTIONS_FAIL,
  GET_SAVED_QUESTIONS_SUCCESS,
  GET_MY_ACTIVITIES_FAIL,
  GET_MY_ACTIVITIES_SUCCESS,
  GET_STUDENT_DETAILS_FAIL,
  GET_STUDENT_DETAILS_SUCCESS,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  savedQuestions: [],
  errorSavedQuestions: {},
  myActivities: [],
  errorMyActivities: {},
  studentDetails: {},
  errorStudentDetails: {},
  allUsers: [],
  allusersErrors: {},
  updateUserSuccess: {},
  updateUserError: {}
}

const projects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SAVED_QUESTIONS_SUCCESS:
      return {
        ...state,
        savedQuestions: action.payload,
      }

    case GET_SAVED_QUESTIONS_FAIL:
      return {
        ...state,
        errorSavedQuestions: action.payload,
      }

    case GET_MY_ACTIVITIES_SUCCESS:
      return {
        ...state,
        myActivities: action.payload,
      }

    case GET_MY_ACTIVITIES_FAIL:
      return {
        ...state,
        errorMyActivities: action.payload,
      }

    case GET_STUDENT_DETAILS_SUCCESS:
      return {
        ...state,
        studentDetails: action.payload,
      }

    case GET_STUDENT_DETAILS_FAIL:
      return {
        ...state,
        errorStudentDetails: action.payload,
      }

    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        allUsers: action.payload,
      }

    case GET_ALL_USERS_FAIL:
      return {
        ...state,
        allusersErrors: action.payload,
      }


    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserSuccess: action.payload,
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        updateUserError: action.payload,
      }

    default:
      return state
  }
}

export default projects
