import PropTypes from 'prop-types'
import React from "react"
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

import TopQuestions from "./TopQuestions"
import Badge from "./Badge";
import Status from "./Status";
import UserProfile from './UserProfile'
const Dashboard = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Student Doubt Resolution App</title>
        </MetaTags>
        <Container fluid>
            {/* <Breadcrumbs
                title={props.t("Nimo Rising Star")}
                breadcrumbItem={props.t("Nimo Rising Star")}
            /> */}
          <Row>
            <Col xl="8">
                <UserProfile />
                <TopQuestions />
            </Col>
            <Col xl="4">
              <Badge />
              <Status />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)
