import {
  SEARCH_QUESTIONS,
  SEARCH_QUESTIONS_FAIL,
  SEARCH_QUESTIONS_SUCCESS,
  SEARCH_ANSWERS,
  SEARCH_ANSWERS_FAIL,
  SEARCH_ANSWERS_SUCCESS,
  SEARCH_FILTER
} from "./actionTypes"

export const searchQuestions = (optionData) => ({
  type: SEARCH_QUESTIONS,
  payload: optionData
})

export const searchQuestionsSuccess = data => ({
  type: SEARCH_QUESTIONS_SUCCESS,
  payload: data,
})

export const searchQuestionsFail = error => ({
  type: SEARCH_QUESTIONS_FAIL,
  payload: error,
})

export const searchAnswers = optionData => ({
  type: SEARCH_ANSWERS,
  payload: optionData,
})

export const searchAnswersSuccess = invoices => ({
  type: SEARCH_ANSWERS_SUCCESS,
  payload: invoices,
})

export const searchAnswersFail = error => ({
  type: SEARCH_ANSWERS_FAIL,
  payload: error,
})

export const searchFilter = (optionData) => ({
  type: SEARCH_FILTER,
  payload: optionData,
})
