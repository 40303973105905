import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  SEND_MOBILE_OTP,
  SEND_MOBILE_OTP_SUCCESS,
  SEND_MOBILE_OTP_FAIL,
  VERIFY_MOBILE_OTP,
  VERIFY_MOBILE_OTP_SUCCESS,
  VERIFY_MOBILE_OTP_FAIL,
  SET_NEW_PASSWORD,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAIL
} from "./actionTypes"

export const userForgetPassword = (user, history) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user, history },
  }
}

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  }
}

export const sendMobileOtp = phone => {
  return {
    type: SEND_MOBILE_OTP,
    payload: phone,
  }
}

export const sendMobileOtpSuccess = data => {
  return {
    type: SEND_MOBILE_OTP_SUCCESS,
    payload: data,
  }
}

export const sendMobileOtpFail = error => {
  return {
    type: SEND_MOBILE_OTP_FAIL,
    payload: error,
  }
}

export const verifyMobileOtp = ( otp, phone ) => {
  return {
    type: VERIFY_MOBILE_OTP,
    payload: { otp, phone },
  }
}

export const verifyMobileOtpSuccess = data => {
  return {
    type: VERIFY_MOBILE_OTP_SUCCESS,
    payload: data,
  }
}

export const verifyMobileOtpFail = error => {
  return {
    type: VERIFY_MOBILE_OTP_FAIL,
    payload: error,
  }
}

export const setNewPassword = ( new_password, phone ) => {
  return {
    type: SET_NEW_PASSWORD,
    payload: { new_password, phone },
  }
}

export const setNewPasswordSuccess = data => {
  return {
    type: SET_NEW_PASSWORD_SUCCESS,
    payload: data,
  }
}

export const setNewPasswordFail = error => {
  return {
    type: SET_NEW_PASSWORD_FAIL,
    payload: error,
  }
}

