import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD, SEND_MOBILE_OTP, VERIFY_MOBILE_OTP, SET_NEW_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError, sendMobileOtpSuccess, sendMobileOtpFail, verifyMobileOtpSuccess, verifyMobileOtpFail, setNewPasswordSuccess, setNewPasswordFail } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeForgetPwd,
  postJwtForgetPwd,
} from "../../../helpers/fakebackend_helper"

//Include Both Helper File with needed methods
import { get, getWithouAuth } from "../../../helpers/api_helper"
import {
  GET_STUDENT,
  PHONE_VERIFICATION_QUERY,
  VERIFY_OTP,
  RESET_PASSWORD
} from "../../../helpers/url_helper"

const fireBaseBackend = getFirebaseBackend()

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.forgetPassword, user.email)
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtForgetPwd, "/jwt-forget-pwd", {
        email: user.email,
      })
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    } else {
      const response = yield call(postFakeForgetPwd, "/fake-forget-pwd", {
        email: user.email,
      })
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    }
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

function* postSendMobileOtp({ payload: phone }) {

  let phone2 = phone.length == 10 ? parseInt('91' + phone) : parseInt(phone);

  let body = JSON.stringify({
    query: GET_STUDENT,
    variables: {
      options: {
        phone: phone2,
      },
    },
  });

  let response = yield call(get, { payload: { body, history } })
  if (response && response?.getStudent) {
    let created_id = response.getStudent._id;

    body = JSON.stringify({
      query: PHONE_VERIFICATION_QUERY,
      variables: {
        input: {
          phone: phone2,
        },
      },
    });

    const response2 = yield call(get, { payload: { body, history } });
    if (response2 && response2?.sendOTP == true) {
      const otp_success_msg = 'OTP sent on mobile number '+phone2;
      yield put(sendMobileOtpSuccess({data : { phone: phone2, id: created_id, sendOTP: true, message: otp_success_msg }}))
    } else {
      yield put(sendMobileOtpFail(response?.errors))
    }
  } else {
    if (response == null) {
      response = { errors: [{message : ''}] };
      response.errors[0].message = 'Student with phone: '+phone2+' does not exist in DB.'
    }
    yield put(sendMobileOtpFail(response?.errors))
  }
}

function* postVerifyMobileOtp({ payload: { otp, phone } }) {

  let phone2 = parseInt('91' + phone);

  let body = JSON.stringify({
    query: VERIFY_OTP,
    variables: {
      input: {
        phone: phone2,
        verificationCode: otp
      }
    }
  });

  const response = yield call(get, { payload: { body, history } });
  //console.log('response,..............', response);
  if (response && response?.verifyOTP == true) {
    yield put(verifyMobileOtpSuccess({data : { phone: phone2, verifyOTP: true }}))
  } else if (response && response?.verifyOTP == false) {
    yield put(verifyMobileOtpFail({data : { phone: phone2, verifyOTP: false }}))
  } else {
    yield put(verifyMobileOtpFail(response?.errors))
  }
}

function* postSetNewPasswrd({ payload: { new_password, phone } }) {

  let phone2 = parseInt('91' + phone);

  let body = JSON.stringify({
    query: RESET_PASSWORD,
    variables: {
      input: {
        phone: phone2,
        password: new_password,
        userType: "student",
      },
    },
  });

  const response = yield call(get, { payload: { body, history } });
  if (response && response?.changePassword == true) {
    yield put(setNewPasswordSuccess({data : { phone: phone2, changePassword: true }}))
  } else if (response && response?.changePassword == false) {
    yield put(setNewPasswordFail({data : { phone: phone2, changePassword: false }}))
  } else {
    yield put(setNewPasswordFail(response?.errors))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
  yield takeEvery(SEND_MOBILE_OTP, postSendMobileOtp)
  yield takeEvery(VERIFY_MOBILE_OTP, postVerifyMobileOtp)
  yield takeEvery(SET_NEW_PASSWORD, postSetNewPasswrd)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
