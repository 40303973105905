import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Media,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import Truncate from "react-truncate-html"
import CommonFunction from "common/CommonFunction"
import Avatar from "react-avatar"

const UnsolvedQuestion = (props) => {

  let responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }
  return (
    <React.Fragment>

      <Col lg="12" className="mb-2">
        <div className="pt-3">
          <h4 className="mb-4">Unsolved Questions</h4>
        </div>
        <Row>
          <Carousel responsive={responsive} className="unsolved-row">
            {props.unsolvedQuestions.length > 0 &&
              props.unsolvedQuestions.map((uq, index) => {
                return (
                  <Link to={`/singleQuestion/`+uq._id+'/'+'home'}>
                    <Col className="unsolved-col">
                      <Card style={{ minHeight: 200 }}>
                        <CardBody>
                          <h4 className="bx-xs que-title">
                            {uq.title.length > 78
                              ? uq.title.substring(0, 75) + "..."
                              : uq.title}
                          </h4>
                          <p
                            className="que-p cream-white-color"
                            style={{ fontSize: "0.9rem!important" }}
                          >
                            <Truncate
                              debounce="100"
                              dangerouslySetInnerHTML={{
                                __html:
                                  uq.body != null
                                    ? uq.body.length > 100
                                      ? uq.body.substring(0, 70) + "..."
                                      : uq.body
                                    : "",
                              }}
                            />
                          </p>
                          <Badge
                            className="rounded-pill py-1 px-3 badge mb-1"
                            style={{
                              color: uq?.subject?.color
                                ? "#" + uq.subject.color
                                : "blue",
                              borderColor: uq?.subject?.color
                                ? "#" + uq.subject.color
                                : "blue",
                              borderStyle: "solid",
                              borderWidth: 1,
                              backgroundColor: "transparent",
                            }}
                          >
                            {uq.topic.topicName}
                          </Badge>
                          <div className="me-2 d-flex align-items-center">
                            <div className="w-50">

                              <Badge
                                className="rounded-pill py-1 px-3"
                                style={{
                                  color: "#fff",
                                  borderColor: uq?.subject?.color
                                    ? "#" + uq.subject.color
                                    : "blue",
                                  borderStyle: "solid",
                                  borderWidth: 1,
                                  backgroundColor: uq?.subject?.color
                                    ? "#" + uq.subject.color
                                    : "blue",
                                }}
                              >
                                {uq.subject.subjectName}
                              </Badge>
                            </div>
                            <Col lg="">
                              <Media>
                                <div className="me-2">
                                  {uq?.author?.realProfilePicUrl != null ? (
                                    <img
                                      src={uq?.author?.realProfilePicUrl}
                                      alt=""
                                      className="avatar-sm rounded-circle img-thumbnail"
                                    />
                                  ) : (
                                    <Avatar name={uq?.author?.userName+' '+uq?.author?.lastName} size="35" round="100px" />
                                  )}
                                </div>
                                <Media className="align-self-center" body>
                                  <div className="text-muted">
                                    <h6
                                      className="mb-0"
                                      style={{ lineHeight: "18px" }}
                                    >
                                      {uq?.author?.userName}
                                    </h6>
                                    <p
                                      className="mb-0 text-white"
                                      style={{ fontSize: "11px" }}
                                    >
                                      {CommonFunction.dateFormat(
                                        uq.createdAt
                                      )}
                                    </p>
                                  </div>
                                </Media>
                              </Media>
                            </Col>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Link>
                )
              })}
          </Carousel>
        </Row>
      </Col>
    </React.Fragment>
  )
}

export default UnsolvedQuestion
