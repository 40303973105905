import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"

//medals
import gold from "../../assets/images/nimo-rising-star/gold.png"
import silver from "../../assets/images/nimo-rising-star/silver.png"
import bronze from "../../assets/images/nimo-rising-star/bronze.png"

const Badge = () => {
  return (
    <React.Fragment>
      {" "}
      <Card className="user-profile-card">
        <CardBody>
          <CardTitle className="mb-4 text-fff">
            Badge Of Jessica jasmin
          </CardTitle>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-grid text-center">
              <img src={gold} alt="Gold Medals" />
              <span>42</span>
            </div>
            <div className="d-grid text-center">
              <img src={silver} alt="Silver Medals" />
              <span>52</span>
            </div>
            <div className="d-grid text-center">
              <img src={bronze} alt="Bronze Medals" />
              <span>32</span>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default Badge
