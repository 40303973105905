import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Label,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Modal,
  Button,
  FormGroup,
  Input,
} from "reactstrap"
import CommonFunction from "common/CommonFunction"
import { uploadFile } from 'helpers/UploadToServerHelper';
import gold from "../../assets/images/nimo-rising-star/gold.png"
import silver from "../../assets/images/nimo-rising-star/silver.png"
import userAvatr from "../../assets/images/user-profile.jpg"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import sampque1 from "../../assets/images/sampque1.png"
import sampque2 from "../../assets/images/samque2.png"
import editIcon from "../../assets/images/edit_icon.png"

import { useHistory } from "react-router"

//i18n
import { withTranslation } from "react-i18next"
import classnames from "classnames"

import { withRouter, Link } from "react-router-dom"

import { connect, useSelector, useDispatch } from "react-redux"

import Truncate from "react-truncate-html"

import "../../assets/css/custom.css"

import toastr, { success } from "toastr"
import "toastr/build/toastr.min.css"

import AccordionComponent from "./AccordionComponent"
import {
  getMyActivitiesDetail,
  getSavedQuestions,
  getStudentDetails,
  upvoteQuestions,
  clearUpvoteQuestionsSuccess,
  downvoteQuestions,
  clearDownvoteQuestionsSuccess,
  getSavedQuestionsSuccess,
  getAvatars,
  updateUser,
  updateUserSuccess
} from "store/actions"
import QuestionMiniCard from "./QuestionMiniCard"
const Profile = props => {
  const {
    onGetStudentDetails,
    current_space,
    leaderboardList,
    myActivitiesList,
    savedQuestionsList,
    studentDetails,
    onGetSavedQuestions,
    onGetMyActivitiesDetail,
    upvoteQuestionSuccessMessage,
    downvoteQuestionSuccessMessage,
    onUpvoteQuestions,
    onDownvoteQuestions,
    onUpvoteQuestionsSuccess,
    onDownvoteQuestionsSuccess,
    onGetSavedQuestionsSuccess,
    onGetAvatars,
    getAvatarList,
    onUpdateAccountSubmit,
    updateUserSuccessMessage,
    updateUserError
  } = props

  useEffect(() => {
    onGetStudentDetails()
    onGetSavedQuestions()
    onGetMyActivitiesDetail()
    onGetAvatars()
  }, [])

  const history = useHistory();
  const dispatch = useDispatch();

  let userData = JSON.parse(localStorage.getItem("authUser"))

  const [activeTab, setActiveTab] = useState("1")
  const [otherMenu, setOtherMenu] = useState(false)
  const [selectQuestion, setSelectQuestion] = useState(null)
  const [togggle, setTogggle] = useState(true)
  const [leaderboardScore, setLeaderboardScore] = useState(null)
  const [modal_standard, setmodal_standard] = useState(false)
  const [addProfileImage, setAddProfileImage] = useState(null);
  let authUser = JSON.parse(localStorage.getItem("authUser"))
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"))
  const [selectedImageAvatar, setSelectedImageAvatar] = useState('https://school-student-app.s3.ap-south-1.amazonaws.com/avatar_images/curious_peers/curious_men9.png');
  const [createProfileFormdata, setCreateProfileFormdata] = useState({
    'firstname' : authUser?.firstName,
    'lastname' : authUser?.lastName,
    //'password' : '',
    'username' : authUser?.userName,
    'about': authUser?.profileDesc,
    'id': authUser?._id
})

const handleCreateAccountSubmit = async() => {

  var re = /^\w+$/;
  if (!re.test(createProfileFormdata.username)) {
    toastr.error("Username should contain not contain any spaces or special characters except underscore!")
    return false;
  }
  let data = createProfileFormdata;
  if (addProfileImage?.uri != null && addProfileImage?.url != null) {
    const uploadResult = await uploadFile(addProfileImage);
    if(uploadResult?.location != '') {
      data.realProfilePicUrl = uploadResult.location,
      onUpdateAccountSubmit(data);
    }
  } else if(addProfileImage?.uri == null && addProfileImage?.url != null) {
    data.realProfilePicUrl = addProfileImage?.url,
    onUpdateAccountSubmit(data);
  } else {
    onUpdateAccountSubmit(data);
  }
}

const HandleTextChange = (val, type) => {
    setCreateProfileFormdata({...createProfileFormdata, [type]: val})
}

  //console.log('studentDetails...........', studentDetails);

  useEffect(() => {
    if (studentDetails?.leaderboardScores) {
      //console.log('studentDetails...........current_space', studentDetails?.leaderboardScores, currentSpace );
      let index = studentDetails?.leaderboardScores.findIndex(
        x => x.space._id === currentSpace._id
      )
      //console.log('index.......', index);
      setLeaderboardScore(studentDetails.leaderboardScores[index])
    }
  }, [studentDetails])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  function tog_standard() {
    setmodal_standard(!modal_standard)
    //removeBodyCss()
  }


  const [isModalAvatar, setIsModalAvatar] = useState(false);
  const toggleModal = () => {
    setIsModalAvatar(!isModalAvatar);
  }
  const titleList = {
    CREATE_QUESTION: "You asked a Question",
    UPVOTE_QUESTION: "You upvoted a Question",
    DOWNVOTE_QUESTION: "You downvoted a Question",
    CREATE_ANSWER: "You added an Answer",
    UPVOTE_ANSWER: "You upvoted an Answer",
    DOWNVOTE_ANSWER: "You downvoted an Answer",
    CREATE_COMMENT: "You commented on an Answer",
    UPVOTE_COMMENT: "You upvoted an Answer Comment",
    DOWNVOTE_COMMENT: "You downvoted an Answer Comment",
    CREATE_QUESTION_COMMENT: "You commented on a Question",
    UPVOTE_QUESTION_COMMENT: "You upvoted a Question Comment",
    DOWNVOTE_QUESTION_COMMENT: "You downvoted a Question Comment",
  }
  const inputFile = useRef(null)
  const onButtonClick = () => {
    inputFile.current.click();
  };
  const upvoteQuestion = question_id => {
    onUpvoteQuestions(question_id)
  }

  const downvoteQuestion = question_id => {
    onDownvoteQuestions(question_id)
  }
  const [file, setFile] = useState(null)

  const handleFileChange = event => {
    const selectedFile = event.target.files[0]
    setFile(selectedFile)
  }

  const handleRemoveFile = () => {
    setFile(null)
  }

  useEffect(() => {
    //console.log('hello...........', Object.keys(upvoteQuestionSuccessMessage).length !== 0, upvoteQuestionSuccessMessage?.title != '')
    if (
      Object.keys(upvoteQuestionSuccessMessage).length != 0 &&
      upvoteQuestionSuccessMessage?._id != ""
    ) {
      setTogggle(false)
      let question_id = upvoteQuestionSuccessMessage?._id
      //console.log('userData?._id', userData?._id);
      let questionData = savedQuestionsList
      if (questionData?.docs?.length > 0) {
        let qIndex = questionData.docs.findIndex(x => x._id === question_id)
        //console.log('qIndex...............', qIndex);
        //questionData.docs[qIndex].liked = likedStatus;
        if (
          questionData.docs[qIndex].upvotedBy.some(s => s._id == userData?._id)
        ) {
          questionData.docs[qIndex].upvotedBy = questionData.docs[
            qIndex
          ].upvotedBy.filter(s => s._id !== userData?._id)
          questionData.docs[qIndex].upvoteCount =
            questionData.docs[qIndex].upvoteCount - 1
        } else {
          questionData.docs[qIndex].upvotedBy = [
            ...questionData.docs[qIndex].upvotedBy,
            { _id: userData?._id },
          ]
          questionData.docs[qIndex].upvoteCount =
            questionData.docs[qIndex].upvoteCount + 1
        }
        if (
          questionData.docs[qIndex].downvotedBy.some(
            s => s._id == userData?._id
          )
        ) {
          questionData.docs[qIndex].downvoteCount =
            questionData.docs[qIndex].downvoteCount - 1
          questionData.docs[qIndex].downvotedBy = questionData.docs[
            qIndex
          ].downvotedBy.filter(s => s._id !== userData?._id)
        }

        onGetSavedQuestionsSuccess(questionData)
        setTogggle(true)
      }
      //console.log('upvoteQuestionSuccess............', upvoteQuestionSuccessMessage);
      toastr.success("Question upvoted successfully!")
      onUpvoteQuestionsSuccess()
    }
  }, [upvoteQuestionSuccessMessage])

  useEffect(() => {
    //console.log('hello...........', Object.keys(upvoteQuestionSuccessMessage).length !== 0, upvoteQuestionSuccessMessage?.title != '')
    if (
      Object.keys(downvoteQuestionSuccessMessage).length != 0 &&
      downvoteQuestionSuccessMessage?._id != ""
    ) {
      setTogggle(false)
      let question_id = downvoteQuestionSuccessMessage?._id
      //console.log('userData?._id', userData?._id);
      let questionData = savedQuestionsList
      if (questionData?.docs?.length > 0) {
        let qIndex = questionData.docs.findIndex(x => x._id === question_id)
        console.log("qIndex...............", qIndex)
        if (
          questionData.docs[qIndex].downvotedBy.some(
            s => s._id == userData?._id
          )
        ) {
          questionData.docs[qIndex].downvotedBy = questionData.docs[
            qIndex
          ].downvotedBy.filter(s => s._id !== userData?._id)
          questionData.docs[qIndex].downvoteCount =
            questionData.docs[qIndex].downvoteCount - 1
        } else {
          questionData.docs[qIndex].downvotedBy = [
            ...questionData.docs[qIndex].downvotedBy,
            { _id: userData?._id },
          ]
          questionData.docs[qIndex].downvoteCount =
            questionData.docs[qIndex].downvoteCount + 1
        }
        if (
          questionData.docs[qIndex].upvotedBy.some(s => s._id == userData?._id)
        ) {
          questionData.docs[qIndex].upvotedBy = questionData.docs[
            qIndex
          ].upvotedBy.filter(s => s._id !== userData?._id)
          questionData.docs[qIndex].upvoteCount =
            questionData.docs[qIndex].upvoteCount - 1
        }

        onGetSavedQuestionsSuccess(questionData)
        setTogggle(true)
      }

      toastr.success("Question downvoted successfully!")
      onUpvoteQuestionsSuccess()
    }
  }, [downvoteQuestionSuccessMessage])

  const onSingleQuestionClick = questionId => {
    history.push('singleQuestion/' + questionId._id+'/'+'profile')
    // setSelectQuestion(questionId);
    // onGetAnswers(questionId?._id);
    // onGetQuestionComments(questionId?._id);
  }

  const onaddProfileImageChange = (event) => {
    let files = event.target.files;
    let dataArray = []
    if (files.length > 0) {
      const file = {
        uri: event.target.files[0],
        url: URL.createObjectURL(event.target.files[0]),
        name: files[0].name,
        type: files[0].type,
      };
      setAddProfileImage(file)
    }
    event.target.value = '';
  }

  const onSelectAvatar = () => {
    const file = {
      uri: null,
      url: selectedImageAvatar,
      name: null,
      type: null,
    };
    setAddProfileImage(file)
    setIsModalAvatar(false);
  }

  //console.log("leaderboardScore..............", leaderboardScore)
  useEffect(() => {
    if (updateUserSuccessMessage?.data == true) {
      toastr.success("Profile updated successfully!")
      setmodal_standard(false)
      dispatch(updateUserSuccess(null));
    }
  }, [updateUserSuccessMessage])

  useEffect(() => {
    //console.log('updateUserError...........',updateUserError)
    if (updateUserError?.message != '' && updateUserError?.message != null) {
      toastr.error(updateUserError?.message)
    }
  }, [updateUserError])

  
  const slider = document.querySelector('.items_1');
  let isDown = false;
  let startX;
  let scrollLeft;

  slider?.addEventListener('mousedown', (e) => {
    isDown = true;
    slider.classList.add('active');
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider?.addEventListener('mouseleave', () => {
    isDown = false;
    slider.classList.remove('active');
  });
  slider?.addEventListener('mouseup', () => {
    isDown = false;
    slider.classList.remove('active');
  });
  slider?.addEventListener('mousemove', (e) => {
    if(!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 2; //scroll-fast
    slider.scrollLeft = scrollLeft - walk;
    //console.log(walk);
  });

  const slider2 = document.querySelector('.items_2');
  let isDown2 = false;
  let startX2;
  let scrollLeft2;

  slider2?.addEventListener('mousedown', (e) => {
    isDown2 = true;
    slider2.classList.add('active');
    startX2 = e.pageX - slider2.offsetLeft;
    scrollLeft2 = slider2.scrollLeft;
  });
  slider2?.addEventListener('mouseleave', () => {
    isDown2 = false;
    slider2.classList.remove('active');
  });
  slider2?.addEventListener('mouseup', () => {
    isDown2 = false;
    slider2.classList.remove('active');
  });
  slider2?.addEventListener('mousemove', (e) => {
    if(!isDown2) return;
    e.preventDefault();
    const x = e.pageX - slider2.offsetLeft;
    const walk = (x - startX2) * 2; //scroll-fast
    slider2.scrollLeft = scrollLeft2 - walk;
    //console.log(walk);
  });


  const slider3 = document.querySelector('.items_3');
  let isDown3 = false;
  let startX3;
  let scrollLeft3;

  slider3?.addEventListener('mousedown', (e) => {
    isDown3 = true;
    slider3.classList.add('active');
    startX3 = e.pageX - slider3.offsetLeft;
    scrollLeft3 = slider3.scrollLeft;
  });
  slider3?.addEventListener('mouseleave', () => {
    isDown3 = false;
    slider3.classList.remove('active');
  });
  slider3?.addEventListener('mouseup', () => {
    isDown3 = false;
    slider3.classList.remove('active');
  });
  slider3?.addEventListener('mousemove', (e) => {
    if(!isDown3) return;
    e.preventDefault();
    const x = e.pageX - slider3.offsetLeft;
    const walk = (x - startX3) * 2; //scroll-fast
    slider3.scrollLeft = scrollLeft3 - walk;
    console.log(walk);
  });



  const slider4 = document.querySelector('.items_4');
  let isDowm4 = false;
  let startX4;
  let scrollLeft4;

  slider4?.addEventListener('mousedown', (e) => {
    isDowm4 = true;
    slider4.classList.add('active');
    startX4 = e.pageX - slider4.offsetLeft;
    scrollLeft4 = slider4.scrollLeft;
  });
  slider4?.addEventListener('mouseleave', () => {
    isDowm4 = false;
    slider4.classList.remove('active');
  });
  slider4?.addEventListener('mouseup', () => {
    isDowm4 = false;
    slider4.classList.remove('active');
  });
  slider4?.addEventListener('mousemove', (e) => {
    if(!isDowm4) return;
    e.preventDefault();
    const x = e.pageX - slider4.offsetLeft;
    const walk = (x - startX4) * 2; //scroll-fast
    slider4.scrollLeft = scrollLeft4 - walk;
    //console.log(walk);
  });

  
  const slider5 = document.querySelector('.items_5');
  let isDowm5 = false;
  let startX5;
  let scrollLeft5;

  slider5?.addEventListener('mousedown', (e) => {
    isDowm5 = true;
    slider5.classList.add('active');
    startX5 = e.pageX - slider5.offsetLeft;
    scrollLeft5 = slider5.scrollLeft;
  });
  slider5?.addEventListener('mouseleave', () => {
    isDowm5 = false;
    slider5.classList.remove('active');
  });
  slider5?.addEventListener('mouseup', () => {
    isDowm5 = false;
    slider5.classList.remove('active');
  });
  slider5?.addEventListener('mousemove', (e) => {
    if(!isDowm5) return;
    e.preventDefault();
    const x = e.pageX - slider5.offsetLeft;
    const walk = (x - startX5) * 2; //scroll-fast
    slider5.scrollLeft = scrollLeft5 - walk;
    //console.log(walk);
  });

  const goToSinglePage = (question) => {
    let question_id;
    let answer_id = null;
    let comment_type = '';

    if(question.dataResolve == 'Question') {
      question_id = question?.data?._id;
    }

    if(question.dataResolve == 'Answer') {
      question_id = question?.data?.question?._id;
      answer_id = question?.data?._id;
    }

    if(question.dataResolve == 'QuestionComment') {
      question_id = question?.data?.question?._id;
      comment_type = 'question_comment';
    }

    if(question.dataResolve == 'Comment') {
      question_id = question?.data?.question?._id;
      answer_id = question?.data?.answer?._id;
      comment_type = 'comment';
    }

    // console.log('question_id..........', question_id);
    // let q_id;
    // if (question_id?.payload?.question != undefined) {
    //   q_id = question_id?.payload?.question?._id;
    // } else if (question_id?.payload?.answer?.question != undefined) {
    //   q_id = question_id?.payload?.answer?.question?._id;
    // } else {
    //   q_id = question_id?.payload?._id;
    // }
    // console.log('q_id..................', q_id);
    if(question_id == undefined) {
      toastr.error("No Question found with this id!")
    } else {
      history.push('/singleQuestion/'+question_id+'/profile');
    }
  }

  return (
    <React.Fragment>
      <div className="page-content  bg-profile">
        <MetaTags>
          <title>Student Doubt Resolution App</title>
        </MetaTags>
        <Container fluid>
          {/* <Breadcrumbs
                title={props.t("Nimo Rising Star")}
                breadcrumbItem={props.t("Nimo Rising Star")}
            /> */}
          <Row>
            <Col
              lg={12}
              className="nimo-rising-star-header d-flex justify-content-between align-items-center mb-5"
            >
              <div className="pt-3">
                <h3>
                  My profile{" "}
                  <img
                    alt="icon"
                    onClick={() => setmodal_standard(true)}
                    width={20}
                    src={editIcon}
                    className="ml-2"
                  />
                </h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="9">
              <div className="user-profile-avatar  justify-content-center align-items-center ">
                <div className="">
                  {authUser?.realProfilePicUrl ? (
                    <img
                      src={authUser?.realProfilePicUrl}
                      alt=""
                      width={120}
                      height={120}
                      className="img-thumbnail rounded-circle "
                    />
                  ) : (
                    <img
                      src={avatar1}
                      alt=""
                      width={120}
                      height={120}
                      className="img-thumbnail rounded-circle"
                    />
                  )}

                  <h3 style={{ color: "#ffffff", paddingTop: "10px" }}>
                    {authUser.userName}
                  </h3>
                </div>
              </div>
            </Col>
            <Col xs="3">
              <div style={{ background: "#1D2226", borderRadius: "14px" }}>
                <div className="p-4">
                  <strong style={{ color: "#ffffff" }}>
                    Leaderboard Scores
                  </strong>
                </div>
                <div className="badge-wrapper d-flex justify-content-center align-items-center  w-100 ">
                  <div className="gold-medal px-5  ">
                    <img src={gold} alt="Gold Medals" width={30} />
                    <span className="text-center ">
                      {leaderboardScore != null && leaderboardScore?.commitments
                        ? leaderboardScore?.commitments
                        : 0}
                    </span>
                  </div>
                  <div className="silver-medal px-5">
                    <img src={silver} alt="Silver Medals" width={30} />
                    <span>
                      {leaderboardScore != null && leaderboardScore?.reputations
                        ? leaderboardScore?.reputations
                        : 0}
                    </span>
                  </div>
                </div>
                <div className="p-3"></div>
              </div>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col xs="9">
              <div>
                <h5> About me</h5>
                <p style={{ color: "#ffffff" }}>{authUser.profileDesc}</p>
              </div>

              <div className="tabs-wrapper pt-2 ">
                <Nav tabs className="nav-tabs-custom " role="tablist">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggleTab("1")
                      }}
                    >
                      Saved Questions
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        toggleTab("2")
                      }}
                    >
                      My Activities
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  style={{ border: "none" }}
                  activeTab={activeTab}
                  className="crypto-buy-sell-nav-content p-4 ps-0"
                >
                  <TabPane tabId="1" id="buy">
                    <Col sm="8">
                      {/* <div className='d-flex justify-content-center align-items-center'> */}
                      {savedQuestionsList?.docs?.length > 0 &&
                        savedQuestionsList?.docs.map((item, index) => {
                          return (
                            <QuestionMiniCard
                              questionItem={item}
                              onClick={onSingleQuestionClick}
                              selectQuestion={selectQuestion}
                              upvoteQuestion={upvoteQuestion}
                              downvoteQuestion={downvoteQuestion}
                              userData={userData}
                            />
                          )
                        })}
                      {/* </div> */}
                    </Col>
                  </TabPane>

                  <TabPane tabId="2">
                    <Col sm="8">
                      <div className="activities-wrapper">
                        {myActivitiesList?.docs?.length > 0 &&
                          myActivitiesList?.docs.map((item, index) => {
                            return (
                              <Card className="w-100" onClick={() => goToSinglePage(item)}>
                                <CardBody>
                                  <div className="d-flex  justify-content-between ">
                                    <i style={{ color: "#F52B67" }}>
                                      {titleList[item.activityType]}
                                    </i>
                                    <small style={{ color: "#D9D9D9" }}>
                                      {CommonFunction.dateFormat(
                                        item.createdAt
                                      )}
                                    </small>
                                  </div>
                                  <div className="pt-3">
                                    <h5 style={{ color: "#78A9EF" }}>
                                      {item?.data?.title}
                                    </h5>
                                  </div>
                                  <p style={{ color: "#D9D9D9" }}>
                                    {item?.data?.questionBody && (
                                      <Truncate
                                        debounce="1000"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item?.data?.questionBody != null
                                              ? item?.data?.questionBody
                                              : "",
                                        }}
                                      />
                                    )}
                                    {item?.data?.body && (
                                      <Truncate
                                        debounce="1000"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item?.data?.body != null
                                              ? item?.data?.body
                                              : "",
                                        }}
                                      />
                                    )}
                                  </p>
                                </CardBody>
                              </Card>
                            )
                          })}
                      </div>
                    </Col>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
            <Col xs="3">
              <div
                style={{ background: "#1D2226", borderRadius: "14px" }}
                className="py-4"
              >
                <div className="question-wrapper p-4  w-100 ">
                  <div className="d-flex justify-content-between align-items-center ">
                    <h5>Question Asked</h5>
                    <span>
                      {" "}
                      <h5>
                        {leaderboardScore != null &&
                          leaderboardScore?.questionsAsked
                          ? leaderboardScore?.questionsAsked
                          : 0}
                      </h5>
                    </span>
                  </div>
                  <div className="d-flex pt-4 justify-content-between align-items-center ">
                    <h5>Question Answered</h5>
                    <span>
                      {" "}
                      <h5>
                        {leaderboardScore != null &&
                          leaderboardScore?.questionsAnswered
                          ? leaderboardScore?.questionsAnswered
                          : 0}
                      </h5>
                    </span>
                  </div>
                  <div className="d-flex pt-4 justify-content-between align-items-center ">
                    <h5>Answers Accepted</h5>
                    <span>
                      {" "}
                      <h5>
                        {leaderboardScore != null &&
                          leaderboardScore?.answersAccepted
                          ? leaderboardScore?.answersAccepted
                          : 0}
                      </h5>
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal isOpen={modal_standard} className="edit-profile">
        <div className="modal-body modal-content-white-bg rounded-5">
          <div className="d-flex justify-content-between">
            <h3 className="text-black">Edit Profile</h3>
            <div>
              <span aria-hidden="true">
                <i
                  class="mdi mdi-close text-black"
                  onClick={() => {
                    setmodal_standard(false)
                  }}
                  font-size="20"
                ></i>
              </span>
            </div>
          </div>
          <div className="user-profile-avatar  justify-content-center align-items-center position-relative">
            <div className="">
              {addProfileImage?.url ? (
                <img
                  src={addProfileImage?.url}
                  alt=""
                  width={120}
                  height={120}
                  className="img-thumbnail rounded-circle "
                />
              ) : (
                authUser?.realProfilePicUrl ?
                  <img
                    src={authUser?.realProfilePicUrl}
                    alt=""
                    width={120}
                    height={120}
                    className="img-thumbnail rounded-circle"
                  />
                  :
                  <img
                    src={avatar1}
                    alt=""
                    width={120}
                    height={120}
                    className="img-thumbnail rounded-circle"
                  />
              )}
              {/* <div className="position-relative postion-set">
                  {!file ? (
                    <>
                      <label
                        htmlFor="file-upload"
                        className="file-upload-label"
                      >
                        <i className="mdi mdi-account text-white display-6 upload-icon"></i>
                      </label>
                      <input
                        type="file"
                        id="file-upload"
                        className="file-upload-input d-none"
                        onChange={handleFileChange}
                      />
                    </>
                  ) : (
                    <div>
                      <p>Selected file: {file.name}</p>
                      <button onClick={handleRemoveFile}>Remove File</button>
                    </div>
                  )}
                </div> */}
              <input type='file' id='file' onChange={onaddProfileImageChange} ref={inputFile} style={{ display: 'none' }} />
              <Dropdown
                isOpen={otherMenu}
                toggle={() => {
                  setOtherMenu(!otherMenu)
                }}
              >
                <DropdownToggle
                  tag="i"
                  className="btn nav-btn"
                  type="button"
                >
                  <i className="mdi mdi-face-shimmer mt-0 editprofile-avatar" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end bg-card">
                  <>
                    <DropdownItem onClick={onButtonClick}>
                      Select Image
                    </DropdownItem>
                    <DropdownItem onClick={toggleModal}>
                      Select Avatar
                    </DropdownItem>
                  </>
                </DropdownMenu>
              </Dropdown>
              <h3 style={{ color: "#ffffff", paddingTop: "10px" }}>
                {authUser.userName}
              </h3>
            </div>
          </div>{" "}
          <div>
            <Row>
              <Col md={12}>
                <FormGroup className="mb-3">
                  <Label
                    htmlFor="formrow-email-Input label-color"
                    className="profile-color"
                  >
                    User Name
                  </Label>
                  <Input
                    value={createProfileFormdata.username}
                    type="text"
                    className="form-control rounded-3 bg-white text-black"
                    id="formrow-email-Input"
                    onChange={(e) => HandleTextChange(e.target.value, 'username')} 
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup className="mb-3">
                  <Label htmlFor="" className="profile-color">
                    First Name
                  </Label>
                  <Input
                    value={createProfileFormdata.firstname}
                    type="text"
                    className="rounded-3 bg-white text-black"
                    onChange={(e) => HandleTextChange(e.target.value, 'firstname')}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-3">
                  <Label
                    htmlFor="formrow-password-Input label-color"
                    className="profile-color"
                  >
                    Last Name
                  </Label>
                  <Input
                    value={createProfileFormdata.lastname}
                    type="text"
                    className="form-control bg-white text-black"
                    id="formrow-password-Input"
                    onChange={(e) => HandleTextChange(e.target.value, 'lastname')}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup className="mb-3">
                  <Label
                    htmlFor="formrow-email-Input label-color"
                    className="profile-color"
                  >
                    Shot Bio
                  </Label>
                  <Input
                    type="textarea"
                    className="form-control bg-white text-black"
                    id="formrow-email-Input"
                    value={createProfileFormdata.about}
                    onChange={(e) => HandleTextChange(e.target.value, 'about')}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className=" d-flex justify-content-center mb-3">
            <button
              type="button"
              className="btn-rounded padding-button pb-2 pt-2 btn color-answer-btn text-white"
              onClick={handleCreateAccountSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isModalAvatar}
        fullscreen
        size='lg'
        className="avatarModal"
        backdrop="static"
        centered={true}
        toggle={toggleModal}
      >

        <div className='modal-header border-bottom-0' style={{ backgroundColor: '#1D2226', paddingLeft: '40px' }}>
          <h1 className=" text-left modal-title mt-0" id="myModalLabel" style={{ color: ' #76A9FF' }}>Select Avatar</h1>   <button
            type="button"
            onClick={() => {
              setIsModalAvatar(!isModalAvatar)
            }}
            data-dismiss="modal"
            aria-label="Close"
            style={{ right: '0', top: '18px', color: '#ffffff', backgroundColor: '#1D2226', fontSize: '18px' }}
            className="border-0"
          >
            <span aria-hidden="true">&#10006;</span>
          </button>
        </div>
        <div className="modal-body pt-4 " style={{ backgroundColor: '#1D2226' }}>
          <Container style={{ width: '100%' }}>
            <div className='text-center'>
              <img
                width="100px"
                height="100px"
                style={{ borderRadius: '50%' }}
                src={selectedImageAvatar}
              />
            </div>

            <div className="selectAvtarBody">
              {
                getAvatarList.length > 0 &&
                getAvatarList.map((item, index) => {
                  return (
                    item.urls.length > 0 &&
                    <div key={"avt_" + index} >
                      <h4 className="mb-2 mt-4 ms-3">{item.category}</h4>
                      <div className={'avt_img_row items_'+index}>
                        {
                          item.urls.map((i, ind) => {
                            return (
                              <img
                                width="100px"
                                height="100px"
                                src={'https://school-student-app.s3.ap-south-1.amazonaws.com/' + i}
                                key={"avt_img_" + index + '__' + ind}
                                className={`https://school-student-app.s3.ap-south-1.amazonaws.com/${i}` == selectedImageAvatar ? 'active item' : 'item'}
                                onClick={() => setSelectedImageAvatar('https://school-student-app.s3.ap-south-1.amazonaws.com/' + i)}
                              />
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>

          </Container>
          <Row>
            <div className='d-flex justify-content-end pt-4'>
              <button className='let_get-started-btn'
                onClick={onSelectAvatar}
              > Select
                <svg viewBox="0 0 16 16" class="bi bi-arrow-right" fill="currentColor" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" fill-rule="evenodd"></path>
                </svg>
              </button>
            </div>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Profile.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = state => {
  //console.log("state ----------", state)
  const { error, users, spaces, talks, Account } = state
  return {
    error: error,
    current_space: spaces.current_space,
    myActivitiesList: users.myActivities,
    savedQuestionsList: users.savedQuestions,
    studentDetails: users.studentDetails,
    upvoteQuestionSuccessMessage: talks.upvoteQuestionSuccess,
    downvoteQuestionSuccessMessage: talks.downvoteQuestionSuccess,
    getAvatarList: Account.getAvatarSuccess,
    updateUserSuccessMessage: users.updateUserSuccess,
    updateUserError: users.updateUserError
  }
}

const mapDispatchToProps = dispatch => ({
  onGetStudentDetails: () => dispatch(getStudentDetails()),
  onGetSavedQuestions: () => dispatch(getSavedQuestions()),
  onGetMyActivitiesDetail: () => dispatch(getMyActivitiesDetail()),
  onUpvoteQuestions: question_id => dispatch(upvoteQuestions(question_id)),
  onDownvoteQuestions: question_id => dispatch(downvoteQuestions(question_id)),
  onUpvoteQuestionsSuccess: () => dispatch(clearUpvoteQuestionsSuccess()),
  onDownvoteQuestionsSuccess: () => dispatch(clearDownvoteQuestionsSuccess()),
  onGetSavedQuestionsSuccess: questions =>
    dispatch(getSavedQuestionsSuccess(questions)),
  onGetAvatars: () => dispatch(getAvatars()),
  onUpdateAccountSubmit: (data) => dispatch(updateUser(data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile))
