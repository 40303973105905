import PropTypes from "prop-types"
import React, { useEffect, useState, useRef, useCallback } from "react"
import MetaTags from "react-meta-tags"
import Truncate from "react-truncate-html"
import CommonFunction from "common/CommonFunction"
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Media,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner
} from "reactstrap"
import InfiniteScroll from "react-infinite-scroll-component"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import prism from "../../assets/images/prism.png"
import img1 from "../../assets/images/small/img-1.jpg"
import img8 from "../../assets/images/camera.png"
// Form Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import UnsolvedQuestion from "./UnsolvedQuestion"
import PopularTopics from "./PopularTopics"
import Avatar from "react-avatar";

import { map } from "lodash"

import { withRouter, Link } from "react-router-dom"

import { connect, useSelector, useDispatch } from "react-redux"
import SimpleBar from "simplebar-react"

import "../../assets/css/custom.css"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import QuestionMiniCard from "./QuestionMiniCard"
import AnswerMiniCard from "./AnswerMiniCard"
import AddQuestionModal from "./AddQuestionModal"
import { MentionsInput, Mention } from 'react-mentions'

import {
  getSpaces,
  getStudentSpaces,
  setCurrentSpace,
  joinUserSpace,
  getUnsolvedQuestion,
  getPopularTopic,
  getQuestions,
  upvoteQuestions,
  clearUpvoteQuestionsSuccess,
  downvoteQuestions,
  clearDownvoteQuestionsSuccess,
  getAnswerByQuestionId,
  getAnswerComments,
  getQuestionComments,
  getQuestionsSuccess,
  upvoteAnswers,
  downvoteAnswers,
  upvoteAnswersSuccess,
  downvoteAnswersSuccess,
  getAllSavedQuestions,
  saveQuestions,
  getAllSavedQuestionsSuccess,
  saveQuestionsSuccess,
  saveQuestionComment,
  saveQuestionCommentSuccess,
  saveAnswerComment,
  saveAnswerCommentSuccess,
  deleteQuestion,
  deleteQuestionSuccess,
  deleteAnswer,
  deleteAnswerSuccess,
  getAnswersSuccess,
  deleteQuestionComment,
  deleteQuestionCommentSuccess,
  deleteAnswerComment,
  deleteAnswerCommentSuccess,
  getQuestionCommentsSuccess,
  getAnswerCommentsSuccess,
  report,
  acceptAnswer,
  getSubjects,
  getTopicsBySubject,
  filterQuestions,
  getAllusers,
  getReportedPostQuestion,
  getReportedPostAnswer,
  getReportedPostQuestionComment,
  getReportedPostAnswerComment,
  getReportedPostQuestionSuccess,
  getReportedPostAnswerSuccess,
  getReportedPostQuestionCommentSuccess,
  getReportedPostAnswerCommentSuccess,
  addQuestionSuccess,
  reportSuccess,
} from "../../store/actions"
import toastr, { success } from "toastr"
import "toastr/build/toastr.min.css"

//i18n
import { withTranslation } from "react-i18next"
import AddAnswerModal from "./AddAnswerModal"

const Dashboard = props => {
  const {
    current_space,
    onGetUnsolvedQuestion,
    onGetPopularTopic,
    onGetQuestions,
    unsolvedQuestions,
    popularTopics,
    questions,
    onUpvoteQuestions,
    upvoteQuestionSuccessMessage,
    onUpvoteQuestionsSuccess,
    onGetAnswers,
    onDownvoteQuestions,
    downvoteQuestionSuccessMessage,
    onDownvoteQuestionsSuccess,
    answerList,
    onGetQuestionComments,
    questionComments,
    onGetQuestionSuccess,
    onGetAnswersSuccess,
    onUpvoteAnswers,
    onDownvoteAnswers,
    upvoteAnswerSuccessMessage,
    downvoteAnswerSuccessMessage,
    onUpvoteAnswerSuccess,
    onDownvoteAnswerSuccess,
    onGetAnswerComments,
    answerComments,
    onGetAllSavedQuestions,
    allSavedQuestionsList,
    onSaveQuestions,
    saveQuestionsSuccessMessage,
    onGetAllSavedQuestionsSuccess,
    onSaveQuestionComment,
    onSaveQuestionCommentSuccess,
    onSaveAnswerComment,
    onSaveAnswerCommentSuccess,
    saveQuestionCommentMessage,
    saveAnswerCommentMessage,
    onDeleteQuestion,
    onDeleteQuestionSuccess,
    deleteQuestionMessage,
    onDeleteAnswer,
    onDeleteAnswerSuccess,
    deleteAnswerMesssage,
    deleteAnswerCommentMessage,
    onDeleteQuestionComment,
    onDeleteQuestionCommentSuccess,
    deleteQuestionCommentMessage,
    onGetQuestionCommentsSuccess,
    onGetAnswerCommentsSuccess,
    onDeleteAnswerComment,
    onDeleteAnswerCommentSuccess,
    onReport,
    reportMessage,
    onAcceptAnswer,
    acceptAnswerMessage,
    onGetSubjects,
    subjectList,
    onGetAllTopicsBySubject,
    allTopicsBySubjectList,
    onFilterQuestions,
    filterQuestions,
    onGetAllUsers,
    allUserList,
    onGetReportedPostQuestion,
    reportedPostQuestion,
    onGetReportedPostAnswer,
    reportedPostAnswer,
    onGetReportedPostQuestionComment,
    onGetReportedPostAnswerComment,
    reportedPostQuestionComment,
    reportedPostAnswerComment,
    reportSuccessMessage
  } = props

  //console.log('allSavedQuestionsList.......................', allSavedQuestionsList);

  const [singlebtn, setSinglebtn] = useState(false)
  const [topicbtn, setTopicbtn] = useState(false)

  const [modal, setmodal] = useState(false)
  const [answerModal, setAnswerModal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [menuIndex, setMenuIndex] = useState(0)
  const [otherMenu, setOtherMenu] = useState(false)
  const [selectQuestion, setSelectQuestion] = useState(null)
  const [questionModal, setQuestionModal] = useState(null)
  const [answerId, setAnswerId] = useState(null)
  const [togggle, setTogggle] = useState(true)
  const [questionComment, setQuestionComment] = useState("")
  const [answerComment, setAnswerComment] = useState("")
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteQuestionId, setDeleteQuestionId] = useState(null)
  const [deleteAnswerId, setDeleteAnswerId] = useState(null)
  const [deleteQuestionCommentId, setDeleteQuestionCommentId] = useState(null)
  const [deleteAnswerCommentId, setDeleteAnswerCommentId] = useState(null)
  const [reportQuestionId, setReportQuestionId] = useState(null)
  const [reportAnswerId, setReportAnswerId] = useState(null)
  const [reportQuestionCommentId, setReportQuestionCommentId] = useState(null)
  const [reportAnswerCommentId, setReportAnswerCommentId] = useState(null)
  const [reportModal, setReportModal] = useState(null)
  const [reportReason, setReportReason] = useState("")
  const [sortDropdown, setSortDropdown] = useState(false);
  const [isEditAnswer, setIsEditAnswer] = useState(false);
  const [selectAnswer, setSelectAnswer] = useState(null);
  const [isEditQuestion, setIsEditQuestion] = useState(false);
  const [hasMoreQuestions, setHasMoreQuestions] = useState(true)
  const [allquestions, setAllQuestions] = useState([])
  const [bookmarkedQuestionList, setBookmarkedQuestionList] = useState([])

  const [hasMoreAnswers, setHasMoreAnswers] = useState(true)
  const [allAnswer, setAllAnswer] = useState([]);
  const [userFilteredData, setUserFilteredData] = useState([]);
  const [viewAllQuestionComment, setViewAllQuestionComment] = useState(false);
  const [viewAllAnswerComment, setViewAllAnswerComment] = useState([]);
  

  const answerCardRef = useRef();

  const [filterData, setFilterData] = useState({
    subject: '',
    topic: '',
    sort: 1,
    myQuestions: false,
    unsolvedQuestions: false,
    savedQuestions: false,
    solvedQuestions: false,
    page: 1
  });

  const parseMarkup = (textWithMentions) => {

    // Regular expression to find mentions in the text
    const mentionRegex = /\@\[([^\]]+)\]/g;
    const mentions = [];

    //const mentionRegex = /@(\w+)/g;
    const plainText = textWithMentions.replace(mentionRegex, (match, username) => {
      return '@' + username; // Replace @username with just the username
    });
    console.log(textWithMentions, plainText);
    return plainText;

  }

  const clearFilter = () => {
    setFilterData({
      subject: '',
      topic: '',
      sort: 1,
      myQuestions: false,
      unsolvedQuestions: false,
      savedQuestions: false,
      solvedQuestions: false,
      page: 1
    });
  }

  let userData = JSON.parse(localStorage.getItem("authUser"))

  const dispatch = useDispatch()

  const reportList = [
    { id: 1, title: "Its spam" },
    { id: 2, title: "Nudity or sexual activity" },
    { id: 3, title: "Hate picture or symbols" },
    { id: 4, title: "I just don’t like it" },
    { id: 5, title: "Bullying or harassment" },
    { id: 6, title: "Fasle information" },
    { id: 7, title: "Violence or dangerous" },
    { id: 8, title: "Scam or fraud" },
    { id: 9, title: "Something else" }
  ];

  const emailRegex = /(([^\s@]+@[^\s@]+\.[^\s@]+))$/

  const onSingleQuestionClick = questionId => {
    setSelectQuestion(questionId)
    answerCardRef?.current?.scrollIntoView({ behavior: 'smooth', top: 0 })
    onGetAnswers(questionId?._id)
    onGetQuestionComments(questionId?._id)
  }

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
    let currentSpace = localStorage.getItem("currentSpace")
    dispatch(setCurrentSpace(JSON.parse(currentSpace)))
  }, [])

  useEffect(() => {
    if (unsolvedQuestions.length > 0) {
      windowScroll()
    }
  }, [unsolvedQuestions])

  const windowScroll = () => {
    let stickyHeader = document.getElementById("sticky-question-header")
    let questionContainer = document.getElementById("question-container")
    let parentQuestionContainer = document.getElementById(
      "parent-question-container"
    )
    let offsetTop = stickyHeader ? stickyHeader.offsetTop - 70 : 0
    //console.log(document.height);
    window.addEventListener("scroll", event => {
      let scrolledFromTop = window.scrollY
      if (parentQuestionContainer) {
        //console.log("TEST", window.innerHeight);
        questionContainer.style.width = `${parentQuestionContainer.clientWidth - 24
          }px`
        questionContainer.style.maxHeight = `${window.innerHeight - 100}px`
      }

      if (stickyHeader) {
        //console.log('scrolledFromTop ,offsetTop', scrolledFromTop, offsetTop)
        if (scrolledFromTop > offsetTop) {
          stickyHeader.classList.add("sticky-question-header")
          questionContainer.classList.add("question-container")
        } else {
          stickyHeader.classList.remove("sticky-question-header")
          questionContainer.classList.remove("question-container")
        }
        // console.log(offsetTop);
      }
    })
  }

  useEffect(() => {
    setTogggle(false);
    onGetAllSavedQuestions();
    onGetUnsolvedQuestion(filterData);
    onGetPopularTopic(filterData);
    onGetAllUsers()

    setTogggle(false);
    const updatedFilterData = { ...filterData, page: 1, old_questions: questions };
    onGetQuestions(updatedFilterData);
    onGetSubjects();

    onGetReportedPostQuestion()
    onGetReportedPostAnswer()
    onGetReportedPostQuestionComment()
    onGetReportedPostAnswerComment()
  }, [])

  useEffect(() => {
    let data = allUserList.map((item) => {
      item.display = item.userName;
      item.id = item._id;
      return item;
    });
    setUserFilteredData(data);

  }, [allUserList])

  const upvoteQuestion = question_id => {
    onUpvoteQuestions(question_id)
  }

  const downvoteQuestion = question_id => {
    onDownvoteQuestions(question_id)
  }

  const upvoteAnswer = answer_id => {
    onUpvoteAnswers(answer_id)
  }

  const downvoteAnswer = answer_id => {
    onDownvoteAnswers(answer_id)
  }

  const bookmarkQuestion = (question_id, isBookmark) => {
    onSaveQuestions(question_id, isBookmark)
  }

  const deleteQuestion = question_id => {
    setDeleteQuestionId(question_id)
    setDeleteModal(true)
  }

  const handleQuestionDelete = () => {
    if (deleteQuestionId) {
      onDeleteQuestion(deleteQuestionId)
      setTimeout(() => {
        setDeleteQuestionId(null)
      }, 1000)
    }
  }

  const deleteAnswer = answer_id => {
    setDeleteAnswerId(answer_id)
    setDeleteModal(true)
  }

  const handleAnswerDelete = () => {
    if (deleteAnswerId) {
      onDeleteAnswer(deleteAnswerId)
      setTimeout(() => {
        setDeleteAnswerId(null)
      }, 1000)
    }
  }

  useEffect(() => {
    if (
      Object.keys(upvoteQuestionSuccessMessage).length != 0 &&
      upvoteQuestionSuccessMessage?._id != ""
    ) {
      setTogggle(false)
      let question_id = upvoteQuestionSuccessMessage?._id;
      let questionData = allquestions;
      if (questionData?.docs?.length > 0) {
        let qIndex = questionData.docs.findIndex(x => x._id === question_id)
        console.log("qIndex...............", qIndex)
        //questionData.docs[qIndex].liked = likedStatus;
        if (
          questionData.docs[qIndex]?.upvotedBy?.some(s => s._id == userData?._id)
        ) {
          questionData.docs[qIndex].upvotedBy = questionData.docs[
            qIndex
          ].upvotedBy.filter(s => s._id !== userData?._id)
          questionData.docs[qIndex].upvoteCount =
            questionData.docs[qIndex].upvoteCount - 1
        } else {
          questionData.docs[qIndex].upvotedBy = [
            ...questionData.docs[qIndex]?.upvotedBy,
            { _id: userData?._id },
          ]
          questionData.docs[qIndex].upvoteCount =
            questionData.docs[qIndex].upvoteCount + 1
        }
        if (
          questionData.docs[qIndex].downvotedBy.some(
            s => s._id == userData?._id
          )
        ) {
          questionData.docs[qIndex].downvoteCount =
            questionData.docs[qIndex].downvoteCount - 1
          questionData.docs[qIndex].downvotedBy = questionData.docs[
            qIndex
          ].downvotedBy.filter(s => s._id !== userData?._id)
        }

        dispatch(onGetQuestionSuccess(questionData))
        setTogggle(true)
      }
      //console.log('upvoteQuestionSuccess............', upvoteQuestionSuccessMessage);
      toastr.success("Question upvoted successfully!")
      onUpvoteQuestionsSuccess()
    }
  }, [upvoteQuestionSuccessMessage])

  useEffect(() => {
    //console.log('hello...........', Object.keys(upvoteQuestionSuccessMessage).length !== 0, upvoteQuestionSuccessMessage?.title != '')
    if (
      Object.keys(downvoteQuestionSuccessMessage).length != 0 &&
      downvoteQuestionSuccessMessage?._id != ""
    ) {
      setTogggle(false)
      let question_id = downvoteQuestionSuccessMessage?._id
      //console.log('userData?._id', userData?._id);
      let questionData = allquestions
      if (questionData?.docs?.length > 0) {
        let qIndex = questionData.docs.findIndex(x => x._id === question_id)
        console.log("qIndex...............", qIndex)
        if (
          questionData.docs[qIndex].downvotedBy.some(
            s => s._id == userData?._id
          )
        ) {
          questionData.docs[qIndex].downvotedBy = questionData.docs[
            qIndex
          ].downvotedBy.filter(s => s._id !== userData?._id)
          questionData.docs[qIndex].downvoteCount =
            questionData.docs[qIndex].downvoteCount - 1
        } else {
          questionData.docs[qIndex].downvotedBy = [
            ...questionData.docs[qIndex].downvotedBy,
            { _id: userData?._id },
          ]
          questionData.docs[qIndex].downvoteCount =
            questionData.docs[qIndex].downvoteCount + 1
        }
        if (
          questionData.docs[qIndex].upvotedBy.some(s => s._id == userData?._id)
        ) {
          questionData.docs[qIndex].upvotedBy = questionData.docs[
            qIndex
          ].upvotedBy.filter(s => s._id !== userData?._id)
          questionData.docs[qIndex].upvoteCount =
            questionData.docs[qIndex].upvoteCount - 1
        }

        dispatch(onGetQuestionSuccess(questionData))
        setTogggle(true)
      }

      toastr.success("Question downvoted successfully!")
      onUpvoteQuestionsSuccess()
    }
  }, [downvoteQuestionSuccessMessage])

  useEffect(() => {
    //console.log('upvoteAnswerSuccessMessage.......', upvoteAnswerSuccessMessage)
    if (
      Object.keys(upvoteAnswerSuccessMessage).length != 0 &&
      upvoteAnswerSuccessMessage?._id != ""
    ) {
      let qID = upvoteAnswerSuccessMessage?._id
      setTogggle(false)
      let qData = answerList
      let qIndex = qData.docs.findIndex(x => x._id === qID)
      if (qData.docs[qIndex].upvotedBy.some(s => s._id == userData._id)) {
        qData.docs[qIndex].upvotedBy = qData.docs[qIndex].upvotedBy.filter(
          s => s._id !== userData._id
        )
        qData.docs[qIndex].upvoteCount = qData.docs[qIndex].upvoteCount - 1
      } else {
        qData.docs[qIndex].upvotedBy = [
          ...qData.docs[qIndex].upvotedBy,
          { _id: userData._id },
        ]
        qData.docs[qIndex].upvoteCount = qData.docs[qIndex].upvoteCount + 1
      }
      if (qData.docs[qIndex].downvotedBy.some(s => s._id == userData._id)) {
        qData.docs[qIndex].downvoteCount = qData.docs[qIndex].downvoteCount - 1
        qData.docs[qIndex].downvotedBy = qData.docs[qIndex].downvotedBy.filter(
          s => s._id !== userData._id
        )
      }
      onUpvoteAnswerSuccess(qData)
    }
  }, [upvoteAnswerSuccessMessage])

  useEffect(() => {
    if (
      Object.keys(deleteQuestionMessage).length != 0 &&
      deleteQuestionMessage?._id != ""
    ) {
      setDeleteModal(false)
      let id = deleteQuestionMessage?._id
      setTogggle(false)
      let qData = allquestions
      let qDocs = qData.docs.filter(x => x._id != id)
      ////console.log('qDocs..............................', qDocs);
      qData.docs = qDocs
      //qData.getPaginatedQuestionComments.docs.push(qDocs);
      setAllQuestions(qData)
      toastr.success("Question deleted successfully!")
      dispatch(deleteQuestionSuccess({}));
      setTogggle(true)
    }
  }, [deleteQuestionMessage])

  useEffect(() => {
    if (
      Object.keys(deleteAnswerMesssage).length != 0 &&
      deleteAnswerMesssage?._id != ""
    ) {
      setDeleteModal(false)
      let id = deleteAnswerMesssage?._id
      setTogggle(false)
      let qData = answerList
      let qDocs = qData.docs.filter(x => x._id != id)
      qData.docs = qDocs
      onGetAnswersSuccess(qData)
      toastr.success("Answer deleted successfully!")
      setTogggle(true)
    }
  }, [deleteAnswerMesssage])

  useEffect(() => {
    //console.log('upvoteAnswerSuccessMessage.......', upvoteAnswerSuccessMessage)
    if (
      Object.keys(downvoteAnswerSuccessMessage).length != 0 &&
      downvoteAnswerSuccessMessage?._id != ""
    ) {
      let qID = downvoteAnswerSuccessMessage?._id
      setTogggle(false)
      let qData = answerList
      let qIndex = qData.docs.findIndex(x => x._id === qID)
      if (qData.docs[qIndex].downvotedBy.some(s => s._id == userData._id)) {
        qData.docs[qIndex].downvotedBy = qData.docs[qIndex].downvotedBy.filter(
          s => s._id !== userData._id
        )
        qData.docs[qIndex].downvoteCount = qData.docs[qIndex].downvoteCount - 1
      } else {
        qData.docs[qIndex].downvotedBy = [
          ...qData.docs[qIndex].downvotedBy,
          { _id: userData._id },
        ]
        qData.docs[qIndex].downvoteCount = qData.docs[qIndex].downvoteCount + 1
      }
      if (qData.docs[qIndex].upvotedBy.some(s => s._id == userData._id)) {
        qData.docs[qIndex].upvotedBy = qData.docs[qIndex].upvotedBy.filter(
          s => s._id !== userData._id
        )
        qData.docs[qIndex].upvoteCount = qData.docs[qIndex].upvoteCount - 1
      }

      onGetAnswersSuccess(qData)
    }
  }, [downvoteAnswerSuccessMessage])

  useEffect(() => {
    if (
      Object.keys(saveQuestionsSuccessMessage).length != 0 &&
      saveQuestionsSuccessMessage?._id != ""
    ) {
      setTogggle(false)
      let qID = saveQuestionsSuccessMessage?._id
      let qData = bookmarkedQuestionList;
      //console.log('qData................in add save question1', allSavedQuestionsList);
      if (qData.length == 0) {
        qData = [qID]
        setBookmarkedQuestionList(qData)
        //setTogggle(true)
      } else if (qData.includes(qID)) {
        qData = qData.filter(i => i !== qID)
        setBookmarkedQuestionList(qData)
        //setTogggle(true)
      } else {
        qData.push(qID)
        setBookmarkedQuestionList(qData)
        //setTogggle(true)
      }
    }
  }, [saveQuestionsSuccessMessage])

  useEffect(() => {
    console.log('allSavedQuestionsList.........', allSavedQuestionsList);
    if(allSavedQuestionsList.length > 0 ) {
      setBookmarkedQuestionList(allSavedQuestionsList);
    }

  }, [allSavedQuestionsList])

  const inputFile = useRef(null)

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click()
  }

  const removeAddQuestionImg = item => {
    //console.log('remove index....................', index);
    let dataArray = addQuestionImage
    dataArray = dataArray.filter(function (e) {
      return e !== item
    })
    setAddQuestionImage(dataArray)
  }

  const getAnswerComment = answer_id => {
    //console.log("answer_id.............", answer_id)
    setAnswerId(answer_id)
    onGetAnswerComments(answer_id)
  }

  //console.log("answer_id.............", answerId)

  const onQuestionCommentChange = e => {
    //console.log('e.target.value..................', e.target.value);
    setQuestionComment(e.target.value)
  }

  const createQuestionComment = question_id => {
    if (questionComment == '') {
      toastr.error("Comment should not be empty!");
    } else {
      onSaveQuestionComment(questionComment, question_id)
    }
  }

  const createAnswerComment = (ansId) => {
    //console.log('answerId.............',answerId);
    if (answerComment == '') {
      toastr.error("Comment should not be empty!");
    } else {
      onSaveAnswerComment(answerComment, ansId)
    }
  }

  useEffect(() => {
    if (
      Object.keys(saveQuestionCommentMessage).length != 0 &&
      saveQuestionCommentMessage?._id != ""
    ) {
      setQuestionComment("")
      onGetQuestionComments(selectQuestion?._id)
      onSaveQuestionCommentSuccess({})
    }
  }, [saveQuestionCommentMessage])

  useEffect(() => {
    if (
      Object.keys(saveAnswerCommentMessage).length != 0 &&
      saveAnswerCommentMessage?._id != ""
    ) {
      setAnswerComment("")
      onGetAnswers(selectQuestion?._id)
      onSaveAnswerCommentSuccess({})
    }
  }, [saveAnswerCommentMessage])

  const deleteQuestionComment = (getQuestionCommentId) => {
    setDeleteQuestionCommentId(getQuestionCommentId)
    setDeleteModal(true);
  }

  useEffect(() => {
    if (
      Object.keys(deleteQuestionCommentMessage).length != 0 &&
      deleteQuestionCommentMessage?._id != ""
    ) {
      setDeleteModal(false)
      let qID = deleteQuestionCommentMessage?._id
      setTogggle(false)
      let qData = questionComments
      let qDocs = questionComments.docs.filter(x => x._id != qID);
      questionComments.docs = qDocs;

      onGetQuestionCommentsSuccess(qData);
      onDeleteQuestionCommentSuccess([]);

      toastr.success("Question comment deleted successfully!");
      setDeleteAnswerCommentId(null)
    }

  }, [deleteQuestionCommentMessage])



  const deleteAnswerComment = (getAnswerCommentId) => {
    setDeleteAnswerCommentId(getAnswerCommentId)
    setDeleteModal(true);
  }

  useEffect(() => {
    if (
      Object.keys(deleteAnswerCommentMessage).length != 0 &&
      deleteAnswerCommentMessage?._id != ""
    ) {
      setDeleteModal(false)

      let qID = deleteAnswerCommentMessage?._id
      setTogggle(false)
      let qData = answerComments
      let qDocs = qData.docs.filter(x => x._id != qID);
      qData.docs = qDocs;

      onGetAnswerCommentsSuccess(qData);
      onDeleteAnswerCommentSuccess([]);

      toastr.success("Answer comment deleted successfully!");
      setDeleteAnswerCommentId(null)
    }

  }, [deleteAnswerCommentMessage])

  useEffect(() => {
    if (
      Object.keys(acceptAnswerMessage).length != 0 &&
      acceptAnswerMessage?._id != ""
    ) {
      setTogggle(false);
      let answerId = acceptAnswerMessage?._id
      let qData = questions;
      let qIndex = qData.docs.findIndex(x => x._id === selectQuestion._id);
      console.log('qIndex................', qIndex);
      qData.docs[qIndex].acceptedAnswer = { _id: answerId };
      toastr.success("Answer accepted successfully!");
      onGetQuestionSuccess(qData);
      setTogggle(true);
    }
  }, [acceptAnswerMessage])

  const handleDelete = () => {
    if (deleteQuestionId) {
      handleQuestionDelete()
    } else if (deleteAnswerId) {
      handleAnswerDelete()
    } else if (deleteQuestionCommentId) {
      onDeleteQuestionComment(deleteQuestionCommentId);
    } else if (deleteAnswerCommentId) {
      onDeleteAnswerComment(deleteAnswerCommentId)
    }
  }

  const resetDeleteId = () => {
    setDeleteQuestionId(null)
    setDeleteAnswerId(null)
    setDeleteQuestionCommentId(null)
    setDeleteAnswerCommentId(null)
  }

  const reportQuestion = (question_id) => {
    setReportQuestionId(question_id)
    setReportReason("")
    setReportModal(true)
  }

  const reportAnswer = (answer_id) => {
    setReportAnswerId(answer_id)
    setReportReason("")
    setReportModal(true)
  }

  const reportQuestionComment = (question_comment_id) => {
    setReportQuestionCommentId(question_comment_id)
    setReportReason("")
    setReportModal(true)
  }

  const reportAnswerComment = (answer_comment_id) => {
    setReportAnswerCommentId(answer_comment_id)
    setReportReason("")
    setReportModal(true)
  }

  const handleAcceptAnswer = (question_id, answer_id) => {
    onAcceptAnswer(question_id, answer_id)
  }


  const handleAllTypesReport = () => {
    let payload = {}
    if (reportQuestionId) {
      payload = {
        id: reportQuestionId,
        reportReason: reportReason,
        type: "QUESTION"
      }
    } else if (reportAnswerId) {
      payload = {
        id: reportAnswerId,
        reportReason: reportReason,
        type: "ANSWER"
      }
    } else if (reportQuestionCommentId) {
      payload = {
        id: reportQuestionCommentId,
        reportReason: reportReason,
        type: "QUESTION_COMMENT"
      }
    } else if (reportAnswerCommentId) {
      payload = {
        id: reportAnswerCommentId,
        reportReason: reportReason,
        type: "ANSWER_COMMENT"
      }
    }
    onReport(payload)
  }

  useEffect(() => {
    if (
      Object.keys(reportMessage).length != 0 &&
      reportSuccessMessage?.data?._id != ""
    ) {
      handleResetReport()
      setReportModal(false)
      toastr.success("Report sent successfully!")
    }
  }, [reportMessage])

  const handleResetReport = () => {
    setReportReason("")
    setReportQuestionId(null)
    // setReportAnswerId(null)
    // setReportQuestionCommentId(null)
    // setReportAnswerCommentId(null)
  }

  const closeQuestionModal = () => {
    setIsEditQuestion(false);
    setQuestionModal(false);
  }

  const closeAnswerModal = () => {
    setAnswerModal(false);
  }

  const openAskQuestionModal = () => {
    setQuestionModal(true);
  }

  const onChangeQuestionFilter = (e, type, value = null) => {
    setSelectQuestion(null);
    if (type == 'sort') {
      setFilterData({ ...filterData, sort: value, page: 1 });
    }
    if (type == 'subject') {
      setFilterData({ ...filterData, subject: e.target.value, page: 1 });
      onGetAllTopicsBySubject(e.target.value);
    }
    if (type == 'topic') {
      setFilterData({ ...filterData, topic: e.target.value, page: 1 });
    }
    if (type == 'unsolvedQuestions') {
      setFilterData({ ...filterData, unsolvedQuestions: !filterData.unsolvedQuestions, page: 1 });
    }
    if (type == 'savedQuestions') {
      setFilterData({ ...filterData, savedQuestions: !filterData.savedQuestions, page: 1 });
    }
    if (type == 'solvedQuestions') {
      setFilterData({ ...filterData, solvedQuestions: !filterData.solvedQuestions, page: 1 });
    }
    if (type == 'myQuestions') {
      setFilterData({ ...filterData, myQuestions: !filterData.myQuestions, page: 1 });
    }
    //console.log('onChangeSubjectFilter..................', e);
  }

    //console.log('filterData............on chagne', filterData);

  useEffect(() => {
    //console.log('filterData............on chagne', filterData);
    onGetUnsolvedQuestion(filterData);
    onGetPopularTopic(filterData);

    setTogggle(false);
    const updatedFilterData = { ...filterData, page: 1, old_questions: [] };
    onGetQuestions(updatedFilterData);
  }, [filterData])

  const onSelectPopularTopics = (topic_id, subject_id) => {
    onGetAllTopicsBySubject(subject_id);
    setFilterData({ ...filterData, subject: subject_id, topic: topic_id });
  }

  //console.log('filterData............', filterData);
  const handleAddQuestionSuccess = (succRespone) => {
    closeQuestionModal();
    setTogggle(false);
    dispatch(addQuestionSuccess({}));
    toastr.success("Question added successfully!");
    setFilterData({ ...filterData, page: 1, myQuestions : true});
    const updatedFilterData = { ...filterData, page: 1, myQuestions : true, old_questions: [] };
    console.log(updatedFilterData)
    setIsEditQuestion(false);
    onGetUnsolvedQuestion(filterData);
    onGetPopularTopic(filterData);
    onGetQuestions(updatedFilterData);
  }

  const handleAddAnswerSuccess = (succRespone) => {
    closeAnswerModal();
    toastr.success("Answer added successfully!");
    setTogggle(false);
    onGetQuestions(filterData);
    onGetAnswers(selectQuestion?._id)
    onGetQuestionComments(selectQuestion?._id)
  }

  const editAnswer = answer => {
    setSelectAnswer(answer)
    setIsEditAnswer(true)
    setAnswerModal(true);
    //onGetAnswer(answer_id)
  }

  const handleUpdateAnswerSuccess = (succRespone) => {
    closeAnswerModal();
    toastr.success("Answer updated successfully!");
    setIsEditAnswer(false);
    setSelectAnswer(null)
    setTogggle(false);
    onGetQuestions(filterData);
    onGetAnswers(selectQuestion?._id)
    onGetQuestionComments(selectQuestion?._id)
  }

  const editQuestion = question => {
    setSelectQuestion(question)
    setIsEditQuestion(true)
    setQuestionModal(true);
    //onGetAnswer(answer_id)
  }

  const handleUpdateQuestionSuccess = (succRespone) => {
    closeQuestionModal();
    toastr.success("Question updated successfully!");
    setIsEditQuestion(false);
    setSelectQuestion(null);
    onGetUnsolvedQuestion(filterData);
    onGetPopularTopic(filterData);
    setTogggle(false);
    onGetQuestions(filterData);
  }

  const loadMoreQuestions = () => {
    //console.log('inisde scroll====================')
    if (questions?.page == questions?.totalPages) {
      setTogggle(false);
      setHasMoreQuestions(false)
    } else {
      let currentPage = questions?.page
      setTimeout(() => {
        setTogggle(false);
        const updatedFilterData = { ...filterData, page: currentPage + 1, old_questions: questions };
        onGetQuestions(updatedFilterData);
        setHasMoreQuestions(true)
      }, 1000)
    }
  }


  const loadMoreAnswers = () => {
    //console.log('inisde scroll====================')
    if (answerList?.page == answerList?.totalPages) {
      setTogggle(false);
      setHasMoreAnswers(false)
    } else {
      let currentPage = answerList?.page
      setTimeout(() => {
        setTogggle(false);
        const updatedFilterData = { ...filterData, page: 1, old_questions: answerList };
        onGetAnswers(updatedFilterData);
        setHasMoreAnswers(true)
      }, 1000)
    }
  }

  useEffect(() => {
    if (allquestions?.docs?.length > 0 && questions.page > 1) {
      let qData = allquestions;
      let A = qData.docs;
      let B = questions.docs;
      let qdocs = A.concat(B.filter(bo => A.every(ao => ao._id != bo._id)));
      qData.page = allquestions?.page;
      qData.docs = qdocs;
      setAllQuestions(qData);
    } else {
      setAllQuestions(questions);
      if(questions?.docs?.length == 0) {
        //console.log('test........................', questions?.docs);
        setHasMoreQuestions(false)
      } 
    }
    setTogggle(true);
  }, [questions])

  useEffect(() => {
    setTogggle(false);
      //console.log('reportSuccessMessage..............', reportSuccessMessage, reportedPostQuestion);
      if(reportSuccessMessage?.data?._id != null) {
         if(reportSuccessMessage.type == "QUESTION") {
           let questions = reportedPostQuestion;
           //questions.push(reportSuccessMessage?.data?._id);
           dispatch(getReportedPostQuestionSuccess([...questions, reportSuccessMessage?.data?._id]));
           dispatch(reportSuccess({}))
           setTogggle(true);
         }
         if(reportSuccessMessage.type == "ANSWER") {
          let questions = reportedPostQuestion;
          //questions.push(reportSuccessMessage?.data?._id);
          dispatch(getReportedPostAnswerSuccess([...questions, reportSuccessMessage?.data?._id]))
          dispatch(reportSuccess({}))
          setTogggle(true);
        }
        if(reportSuccessMessage.type == "QUESTION_COMMENT") {
          let questions = reportedPostQuestion;
          //questions.push(reportSuccessMessage?.data?._id);
          dispatch(getReportedPostQuestionCommentSuccess([...questions, reportSuccessMessage?.data?._id]))
          dispatch(reportSuccess({}))
          setTogggle(true);
        }
        if(reportSuccessMessage.type == "ANSWER") {
          let questions = reportedPostQuestion;
          //questions.push(reportSuccessMessage?.data?._id);
          dispatch(getReportedPostAnswerCommentSuccess([...questions, reportSuccessMessage?.data?._id]))
          dispatch(reportSuccess({}))
          setTogggle(true);
        }
      }

  },[reportSuccessMessage])


  useEffect(() => {
    if (answerList?.docs?.length > 0 && allAnswer.page > 1) {
      let qData = allAnswer;
      let A = qData.docs;
      let B = answerList.docs;
      let qdocs = A.concat(B.filter(bo => A.every(ao => ao._id != bo._id)));
      qData.page = allAnswer?.page;
      qData.docs = qdocs;
      setAllAnswer(qData);
    } else {
      setAllAnswer(answerList);
    }
    setTogggle(true);
  }, [answerList])


  const filterUsers = (query, data, searchKey) => {
    //console.log('query, data, searchKey............', query, data, searchKey);
    if (!query) return data; // Return all data if the query is empty

    const filteredData = data.filter(item =>
      item[searchKey]?.toLowerCase().includes(query.toLowerCase())
    );
    return filteredData;
  };

  const clearSingleFilter = (type) => {
    if (type == "subject") {
      setFilterData({ ...filterData, subject: '' });
    }

    if (type == 'topic') {
      setFilterData({ ...filterData, topic: '' });
    }
  }

  //console.log('allUserList..................', allUserList);

  // useEffect(() => {
  //   console.log('reportedPostQuestion...........', reportedPostQuestion)
  //   if (reportedPostQuestion?.length > 0) {

  //   }
  // }, [reportedPostQuestion])

  // useEffect(() => {
  //   if (reportedPostAnswer?.length > 0) {
  //     console.log('reportedPostAnswer...........', reportedPostAnswer)
  //   }
  // }, [reportedPostAnswer])

  // useEffect(() => {
  //   if (reportedPostQuestionComment?.length > 0) {
  //     console.log('reportedPostQuestionComment...........', reportedPostQuestionComment)
  //   }
  // }, [reportedPostQuestionComment])

  // useEffect(() => {
  //   if (reportedPostAnswerComment?.length > 0) {
  //     console.log('reportedPostAnswerComment...........', reportedPostAnswerComment)
  //   }
  // }, [reportedPostAnswerComment])

  const changeViewAnswerComment = (ans_id) => {
    let comment = viewAllAnswerComment;
    if(comment.includes(ans_id)){
      comment = comment.filter(item => item != ans_id)
      setViewAllAnswerComment(comment);
    } else {
      comment.push(ans_id);
      setViewAllAnswerComment(comment);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content bg-profile">
        <MetaTags>
          <title>Student Doubt Resolution App</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          /> */}

          <Row className="mb-3">
            <Col xl="10">
              <div className="dashboard-menu top-filters">
                <div className="d-inline-flex align-items-center mobile-flex-column pt-2">
                  <Dropdown
                    className={'me-2'}
                    isOpen={sortDropdown}
                    toggle={() => setSortDropdown(!sortDropdown)}
                  >
                    <DropdownToggle
                      tag="span"
                      onClick={() => {
                        setSortDropdown(1)
                      }}
                      className={
                        sortDropdown === true || filterData.sort
                          ? "dashboard-menu-active"
                          : "subject-p-color"
                      }
                    >
                      Sort <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>

                    <DropdownMenu className="subject-dropdown custom-dropdown-style p-2">
                      <div class="dropdown-header noti-title">
                        <h4 class=" text-truncate mn-0">Filter</h4>
                        <i className="mdi mdi-close font-size-20 text-white" onClick={() => { setSortDropdown(!sortDropdown) }} />
                      </div>
                      <hr className="mb-2 mt-0" />

                      <SimpleBar style={{ maxHeight: "130px" }}>
                        <div className="card-body ps-3 pe-3 pt-1">
                          <div className="subject-div" key={'sort_1'}>
                            <label className="" htmlFor="sort_1">{'Recently Posted'}</label>
                            <input className="form-check-input" id={'sort_1'} type="radio" name="subject" value={1} onChange={(e) => onChangeQuestionFilter(e, 'sort', 1)} defaultChecked />
                          </div>
                          <div className="subject-div" key={'sort_2'}>
                            <label className="" htmlFor="sort_2">{'Most Answered'}</label>
                            <input className="form-check-input" id={'sort_2'} type="radio" name="subject" value={2} onChange={(e) => onChangeQuestionFilter(e, 'sort', 2)} />
                          </div>
                        </div>
                      </SimpleBar>
                    </DropdownMenu>
                  </Dropdown>

                  <Dropdown
                    isOpen={singlebtn}
                    className={'me-2'}
                    toggle={() => setSinglebtn(!singlebtn)}
                  >
                    <DropdownToggle
                      tag="span"
                      onClick={() => {
                        setMenuIndex(1)
                      }}
                      className={
                        singlebtn == true || filterData.subject != ''
                          ? "dashboard-menu-active"
                          : "subject-p-color"
                      }
                    >
                      Subject <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>

                    <DropdownMenu className="subject-dropdown custom-dropdown-style p-2">
                      <div class="dropdown-header noti-title">
                        <h4 class=" text-truncate mn-0">Filter</h4>
                        <i className="mdi mdi-close font-size-20 text-white" onClick={() => { setSinglebtn(!singlebtn) }} />
                      </div>
                      <hr className="mb-2 mt-0" />

                      <SimpleBar style={{ maxHeight: "200px" }}>
                        <div className="card-body ps-3 pe-3 pt-1">
                          {
                            subjectList.length > 0 &&
                            subjectList.map((item, index) => {
                              return (
                                <div className="subject-div" key={index}>
                                  <label className="" htmlFor={'subject_check_' + index}>{item.subjectName}</label>
                                  <input className="form-check-input" type="radio" name="subject" id={'subject_check_' + index} value={item._id} onChange={(e) => onChangeQuestionFilter(e, 'subject')} checked={filterData.subject == item._id} />
                                </div>
                              )
                            })
                          }
                        </div>
                      </SimpleBar>

                      <div className="d-flex justify-content-end mt-2">
                        <Button
                          color="info"
                          outline
                          className="btn-rounded padding-button pb-2 pt-2"
                          onClick={() => clearSingleFilter('subject')}
                        >
                          Clear
                        </Button>
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                  {
                    filterData.subject != '' &&
                    <Dropdown
                      isOpen={topicbtn}
                      className={'me-2'}
                      toggle={() => setTopicbtn(!topicbtn)}
                    >
                      <DropdownToggle
                        tag="span"
                        onClick={() => {
                          setMenuIndex(2)
                        }}
                        className={
                          topicbtn === true || filterData.topic != ''
                            ? "dashboard-menu-active"
                            : "subject-p-color"
                        }
                      >
                        Topic <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>

                      <DropdownMenu className="subject-dropdown custom-dropdown-style p-2">
                        <div class="dropdown-header noti-title">
                          <h4 class=" text-truncate mn-0">Filter</h4>
                          <i className="mdi mdi-close font-size-20 text-white" onClick={() => { setTopicbtn(!topicbtn) }} />
                        </div>
                        <hr className="mb-2 mt-0" />

                        <SimpleBar style={{ maxHeight: "300px" }}>
                          <div className="card-body ps-3 pe-3 pt-1">
                            {
                              allTopicsBySubjectList.length > 0 &&
                              allTopicsBySubjectList.map((item, index) => {
                                return (
                                  <div className="subject-div" key={'topic_' + index}>
                                    <label className="">{item.topicName}</label>
                                    <input
                                      onChange={(e) => onChangeQuestionFilter(e, 'topic')}
                                      name="topicFilter"
                                      className="form-check-input"
                                      type="radio"
                                      value={item._id}
                                      checked={filterData.topic == item._id}
                                    />
                                  </div>
                                )
                              })
                            }
                          </div>
                        </SimpleBar>
                        <div className="d-flex justify-content-end mt-2">
                          <Button
                            color="info"
                            outline
                            className="btn-rounded padding-button pb-2 pt-2"
                            onClick={() => clearSingleFilter('topic')}
                          >
                            Clear
                          </Button>
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  }
                  <Link
                    to="#"
                    className={
                      filterData.unsolvedQuestions == true
                        ? "dashboard-menu-active"
                        : ""
                    }
                    onClick={(e) => {
                      onChangeQuestionFilter(e, 'unsolvedQuestions')
                    }}
                  >
                    Unsolved
                  </Link>
                  <Link
                    to="#"
                    className={
                      filterData.myQuestions == true
                        ? "dashboard-menu-active"
                        : ""
                    }
                    onClick={(e) => {
                      onChangeQuestionFilter(e, 'myQuestions')
                    }}
                  >
                    My Questions
                  </Link>
                  <Link
                    to="#"
                    className={
                      filterData.savedQuestions
                        ? "dashboard-menu-active"
                        : ""
                    }
                    onClick={(e) => {
                      onChangeQuestionFilter(e, 'savedQuestions')
                    }}
                  >
                    Saved
                  </Link>
                  <Link
                    to="#"
                    className={
                      filterData.solvedQuestions
                        ? "dashboard-menu-active"
                        : ""
                    }
                    onClick={(e) => {
                      onChangeQuestionFilter(e, 'solvedQuestions')
                    }}
                  >
                    Solved
                  </Link>
                  {
                    (filterData.myQuestions == true
                      || filterData.savedQuestions == true
                      || filterData.solvedQuestions == true
                      || filterData.unsolvedQuestions == true
                      || (filterData.subject != null && filterData.subject != '')
                      || (filterData.topic != null && filterData.topic != '')) &&
                    <Link
                      to="#"
                      className={
                        "dashboard-menu-active"
                      }
                      onClick={(e) => {
                        clearFilter()
                      }}
                    >
                      Clear Filter
                      <i className="mdi mdi-close text-white ms-1" />
                    </Link>
                  }
                </div>
              </div>
            </Col>
            <Col xl="2">
              <div className="dashboard-menu">
                <div className="d-inline-flex pt-2">
                  <Link
                    to="#"
                    className="AddNewQuestionBtn"
                    onClick={() => openAskQuestionModal()}
                  >
                    Ask your Question
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <div>
            <UnsolvedQuestion
              unsolvedQuestions={unsolvedQuestions}
            ></UnsolvedQuestion>
          </div>
          <div>
            <PopularTopics subjectList={subjectList} popularTopics={popularTopics} onSelectPopularTopics={onSelectPopularTopics} selectedTopic={filterData.topic}  selectedSubject={filterData.subject}></PopularTopics>
          </div>
          <div
            className="mt-4"
            style={{ height: 5, backgroundColor: "#1F1F1F" }}
          ></div>
          <Row className="header p-0" id="myHeader">
            <Col lg="6" id="sticky-question-header" >

              <div className="pt-4">
                <h4 className="mb-3" style={{ textTransform: 'uppercase' }}>
                  {
                    (filterData.subject != '' && filterData.topic == '')
                      ?
                      "All " + subjectList.find(o => o._id === filterData.subject)?.subjectName + " QUESTIONS"
                      :
                      (filterData.subject != '' && filterData.topic != '')
                        ?
                        "ALL QUESTIONS FROM " + allTopicsBySubjectList.find(o => o._id === filterData.topic)?.topicName
                        :
                        filterData.sort == 1 ? 'RECENTLY POSTED' : 'MOST ANSWERED'
                  }
                </h4>
              </div>
            </Col>
            <div className={`parent-column-container d-flex justify-content-between ${allquestions?.docs?.length == 0 ? 'parent-column-container-without-before' : ''}`}>
              <Col lg="6">
                <InfiniteScroll
                  dataLength={allquestions?.docs ? allquestions?.docs?.length : 0}
                  next={loadMoreQuestions}
                  hasMore={hasMoreQuestions}
                  loader={
                    <div className={"justify-content-center row"}>
                      <Spinner type="grow" className="ms-2" color="primary" />
                    </div>
                  }
                >
                  {allquestions?.docs?.length > 0 ?
                    allquestions.docs.map((que, index) => {
                      return (
                        !(reportedPostQuestion?.length > 0 && reportedPostQuestion?.includes(que._id)) &&
                        <QuestionMiniCard
                          questionItem={que}
                          onClick={onSingleQuestionClick}
                          selectQuestion={selectQuestion}
                          upvoteQuestion={upvoteQuestion}
                          downvoteQuestion={downvoteQuestion}
                          userData={userData}
                          savedQuestions={bookmarkedQuestionList}
                          bookmarkQuestion={bookmarkQuestion}
                          deleteQuestion={deleteQuestion}
                          reportQuestion={reportQuestion}
                          isSingle={false}
                          editQuestion={editQuestion}
                        ></QuestionMiniCard>
                      )
                    })
                  :
                  <div className="mb-5">
                  <h5>No questions found!</h5>
                  </div>
                  }
                </InfiniteScroll>
              </Col>
              <Col
                lg="5"
                className="mt-0 width-card margin-ans-card"
                id="parent-question-container"
                style={{ position: "relative" }}
              >
                <Card id="question-container" ref={answerCardRef}>
                  {selectQuestion != null && (
                    <>
                      <div className="m-3">
                        <QuestionMiniCard
                          questionItem={selectQuestion}
                          onClick={onSingleQuestionClick}
                          upvoteQuestion={upvoteQuestion}
                          downvoteQuestion={downvoteQuestion}
                          savedQuestions={bookmarkedQuestionList}
                          bookmarkQuestion={bookmarkQuestion}
                          userData={userData}
                          reportQuestion={reportQuestion}
                          isSingle={true}
                          editQuestion={editQuestion}
                        ></QuestionMiniCard>
                        <div className={'d-flex justify-content-between'}>
                          <p
                            className="mt-3 text-white"
                            style={{ fontStyle: "italic" }}
                          >
                            Comments
                            <span className="ms-1">
                              ( {selectQuestion.commentsCount} )
                            </span>
                          </p>
                          {
                            questionComments?.docs?.length > 3 &&
                            <Link
                              className="mt-3 like-color"
                              style={{ fontStyle: "bold" }}
                              onClick={() => setViewAllQuestionComment(!viewAllQuestionComment)}
                            >
                              {
                                viewAllQuestionComment == true ?
                                'View less'
                                :
                                'View all'
                              }
                              
                            </Link>
                          }
                        </div>

                        <div className="">
                          <div class="card question-comment-card mb-0">
                            <div
                              class="card-body d-flex "
                              style={{
                                backgroundColor: "#1D2226",
                                borderRadius: "10px",
                                border: "1px solid",
                              }}
                            >
                              <div class="card-text w-100">
                                <div className="row">
                                  {questionComments?.docs?.length > 0 &&
                                    (
                                      viewAllQuestionComment == true ?
                                      questionComments?.docs.map((item, index) => {
                                        return (
                                          !(reportedPostQuestionComment?.length > 0 && reportedPostQuestionComment?.includes(item._id)) &&
                                          <>
                                            <div className="d-flex justify-content-lg-between align-items-start">
                                              <div className="d-flex">
                                                <div className="mt-1" style={{width: 50}}>
                                                  {item?.author
                                                    ?.realProfilePicUrl ? (
                                                    <img
                                                      src={
                                                        item?.author
                                                          ?.realProfilePicUrl
                                                      }
                                                      alt=""
                                                      className="width-40-px rounded-circle img-thumbnail"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={avatar1}
                                                      alt=""
                                                      className="width-40-px rounded-circle img-thumbnail"
                                                    />
                                                  )}
                                                </div>
                                                <div className=" mt-2 ms-3" style={{width: '80%'}}>
                                                  <span className="fs-6 text-white">
                                                    {item?.author?.userName}
                                                  </span>
                                                  <p
                                                    className="mt-1 mb-2 text-white"
                                                    style={{
                                                      fontSize:
                                                        "10px",
                                                    }}
                                                  >
                                                    {CommonFunction.dateFormat(item?.createdAt)}
                                                  </p>
                                                  <p
                                                    className="mt-1 mb-2 text-white"
                                                    style={{ fontSize: "11px" }}
                                                  >
                                                    {/* <ReactMentionParser
                                                      renderMention={(mentionValue) => (
                                                          <div className="mention">{mentionValue}</div>
                                                      )}
                                                  >
                                                      {item.body}
                                                  </ReactMentionParser> */}
                                                    {parseMarkup(item.body)}
                                                  </p>
                                                </div>
                                              </div>
                                              <Dropdown
                                                isOpen={otherMenu == item._id}
                                                toggle={() => {
                                                  //console.log('otherMenu, item._id............', otherMenu, item._id);
                                                  if(otherMenu == item._id) {
                                                    setOtherMenu(null)
                                                  } else {
                                                    setOtherMenu(item._id)
                                                  }
                                                }}
                                              >
                                                <DropdownToggle
                                                  tag="i"
                                                  className="btn nav-btn"
                                                  type="button"
                                                >
                                                  <i className="bx bx-dots-vertical-rounded mt-0 fs-3 " />
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end bg-card">
                                                  {userData._id === item?.author?._id ? (
                                                    <>
                                                      {/* <DropdownItem href="#">
                                                    Edit Question
                                                  </DropdownItem> */}
                                                      <DropdownItem onClick={() => deleteQuestionComment(item._id)}>
                                                        Delete Comment
                                                      </DropdownItem>
                                                    </>
                                                  )
                                                    :
                                                    <DropdownItem onClick={() => reportQuestionComment(item._id)}>
                                                      Report Comment
                                                    </DropdownItem>
                                                  }
                                                </DropdownMenu>
                                              </Dropdown>
                                            </div>
                                            <hr
                                              style={{
                                                backgroundColor: "#5C5C5C",
                                                height: 2,
                                              }}
                                            />
                                          </>
                                        )
                                      })
                                      :
                                      questionComments?.docs.slice(0, 3).map((item, index) => {
                                        return (
                                          !(reportedPostQuestionComment?.length > 0 && reportedPostQuestionComment?.includes(item._id)) &&
                                          <>
                                            <div className="d-flex justify-content-lg-between align-items-start">
                                              <div className="d-flex">
                                                <div className="mt-1" style={{width: 50}}>
                                                  {item?.author
                                                    ?.realProfilePicUrl ? (
                                                    <img
                                                      src={
                                                        item?.author
                                                          ?.realProfilePicUrl
                                                      }
                                                      alt=""
                                                      className="width-40-px rounded-circle img-thumbnail"
                                                    />
                                                  ) : (
                                                    
                                                    <Avatar name={item?.author?.userName+' '+item?.author?.lastName} size="40" round="100px" />
                                                  )}
                                                </div>
                                                <div className=" mt-2 ms-3" style={{width: '80%'}}>
                                                  <span className="fs-6 text-white">
                                                    {item?.author?.userName}
                                                  </span>
                                                  <p
                                                    className="mt-1 mb-2 text-white"
                                                    style={{
                                                      fontSize:
                                                        "10px",
                                                    }}
                                                  >
                                                    {CommonFunction.dateFormat(item?.createdAt)}
                                                  </p>
                                                  <p
                                                    className="mt-1 mb-2 text-white"
                                                    style={{ fontSize: "11px" }}
                                                  >
                                                    {/* <ReactMentionParser
                                                      renderMention={(mentionValue) => (
                                                          <div className="mention">{mentionValue}</div>
                                                      )}
                                                  >
                                                      {item.body}
                                                  </ReactMentionParser> */}
                                                    {parseMarkup(item.body)}
                                                  </p>
                                                </div>
                                              </div>
                                              <Dropdown
                                                isOpen={otherMenu == item._id}
                                                toggle={() => {
                                                  //console.log('otherMenu, item._id............', otherMenu, item._id);
                                                  if(otherMenu == item._id) {
                                                    setOtherMenu(null)
                                                  } else {
                                                    setOtherMenu(item._id)
                                                  }
                                                }}
                                              >
                                                <DropdownToggle
                                                  tag="i"
                                                  className="btn nav-btn"
                                                  type="button"
                                                >
                                                  <i className="bx bx-dots-vertical-rounded mt-0 fs-3 " />
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end bg-card">
                                                  {userData._id === item?.author?._id ? (
                                                    <>
                                                      {/* <DropdownItem href="#">
                                                    Edit Question
                                                  </DropdownItem> */}
                                                      <DropdownItem onClick={() => deleteQuestionComment(item._id)}>
                                                        Delete Comment
                                                      </DropdownItem>
                                                    </>
                                                  )
                                                    :
                                                    <DropdownItem onClick={() => reportQuestionComment(item._id)}>
                                                      Report Comment
                                                    </DropdownItem>
                                                  }
                                                </DropdownMenu>
                                              </Dropdown>
                                            </div>
                                            <hr
                                              style={{
                                                backgroundColor: "#5C5C5C",
                                                height: 2,
                                              }}
                                            />
                                          </>
                                        )
                                      })
                                    )
                                  }
                                  <div className="w-100 justify-content-center ">
                                    <div class="input-group auth-pass-inputgroup comment-inputgroup w-100 input-mention">
                                      <MentionsInput
                                        value={questionComment}
                                        onChange={e => onQuestionCommentChange(e)}
                                        placeholder="Add your comment..."
                                        className="custom-mention-input"
                                        style={{
                                          backgroundColor: "#38434F",
                                          borderRadius: "12px",
                                          width: '90%',
                                          padding: '1px',
                                        }}
                                        a11ySuggestionsListLabel={"Suggested mentions"}
                                      >
                                        <Mention
                                          //markup="@__display__"
                                          trigger="@"
                                          //regex={/@(\S+)/}
                                          data={userFilteredData}
                                          renderSuggestion={(suggestion, search, highlightedDisplay) => (
                                            <div className="highlightDisplay">{highlightedDisplay}</div>
                                          )}
                                          displayTransform={(id, display) => `@${display}`}
                                          markup="@[__display__]"
                                          appendSpaceOnAdd={true}
                                          filter={filterUsers} // Use the custom filter function
                                          style={{ color: '#70B5F9', position: 'absolute', zIndex: 9, padding: '1px' }}
                                        />
                                      </MentionsInput>
                                      <button
                                        class="btn btn-light comment-send-btn"
                                        type="button"
                                        id="password-addon"
                                        onClick={() =>
                                          createQuestionComment(
                                            selectQuestion._id
                                          )
                                        }
                                      >
                                        <i class="bx bxs-navigation"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <CardBody>
                        <InfiniteScroll
                          dataLength={allAnswer?.docs ? allAnswer?.docs?.length : 0}
                          next={loadMoreAnswers}
                          hasMore={false}
                          loader={
                            <div className={"justify-content-center row"}>
                              <Spinner type="grow" className="ms-2" color="primary" />
                            </div>
                          }
                        >
                          {allAnswer?.docs?.length > 0 &&
                            allAnswer.docs.map((ans, index) => {
                              return (

                                !(reportedPostAnswer?.length > 0 && reportedPostAnswer.includes(ans._id)) &&
                                <div key={'answers_id_' + index}>
                                  <p className="fs-5 mt-3 mb-2" style={{ color: '#F9D581' }}>Answer
                                  </p>
                                  <div
                                    className="mt-0"
                                    style={{
                                      height: 1,
                                      backgroundColor: '#414141',
                                      width: "100%",
                                    }}
                                  ></div>
                                  <AnswerMiniCard
                                    answerItem={ans}
                                    key={"ans_" + index}
                                    getAnswerComment={getAnswerComment}
                                    upvoteAnswer={upvoteAnswer}
                                    downvoteAnswer={downvoteAnswer}
                                    userData={userData}
                                    deleteAnswer={deleteAnswer}
                                    selectQuestion={selectQuestion}
                                    reportAnswer={reportAnswer}
                                    handleAcceptAnswer={handleAcceptAnswer}
                                    editAnswer={editAnswer}
                                  />
                                  { //answerId == item._id && 
                                  (
                                    <div className="">
                                      {
                                        ans?.comments?.length > 3 &&
                                        <div className="d-flex justify-content-end mb-2 mt-1">
                                          <Link
                                            className="mt-3 like-color"
                                            style={{ fontStyle: "bold" }}
                                            onClick={() => changeViewAnswerComment(ans?._id)}
                                          >
                                            {
                                              viewAllAnswerComment.includes(ans?._id) ?
                                              'View less'
                                              :
                                              'View all'
                                            }
                                            
                                          </Link>
                                        </div>
                                      }
                                      <div class="card question-comment-card mb-0">
                                        <div
                                          class="card-body "
                                          style={{
                                            backgroundColor: "#1D2226",
                                            borderRadius: "10px",
                                            border: "1px solid",
                                          }}
                                        >
                                          {
                                            <>
                                              <div className="row">
                                                {
                                                viewAllAnswerComment.includes(ans?._id) ?
                                                ans?.comments?.length > 0 &&
                                                  ans?.comments.map(
                                                    (item, index) => {
                                                      return (
                                                        !(reportedPostAnswerComment?.length > 0 && reportedPostAnswerComment?.includes(item._id)) &&
                                                        <>
                                                          <div className="d-flex justify-content-lg-between ">
                                                            <div className="d-flex">
                                                              <div className="mt-1">
                                                                {item?.author
                                                                  ?.realProfilePicUrl ? (
                                                                  <img
                                                                    src={
                                                                      item?.author
                                                                        ?.realProfilePicUrl
                                                                    }
                                                                    alt=""
                                                                    className="width-40-px rounded-circle img-thumbnail"
                                                                  />
                                                                ) : (
                                                                  <Avatar name={item?.author?.userName+' '+item?.author?.lastName} size="40" round="100px" />
                                                                  // <img
                                                                  //   src={avatar1}
                                                                  //   alt=""
                                                                  //   className="width-40-px rounded-circle img-thumbnail"
                                                                  // />
                                                                )}
                                                              </div>
                                                              <div className=" mt-2 ms-3">
                                                                <span className="fs-6 text-white">
                                                                  {
                                                                    item?.author
                                                                      ?.userName
                                                                  }
                                                                </span>
                                                                <p
                                                                  className="mt-1 mb-2 text-white"
                                                                  style={{
                                                                    fontSize:
                                                                      "10px",
                                                                  }}
                                                                >
                                                                  {CommonFunction.dateFormat(item?.createdAt)}
                                                                </p>
                                                                <p
                                                                  className="mt-1 mb-2 text-white"
                                                                  style={{
                                                                    fontSize:
                                                                      "11px",
                                                                  }}
                                                                >
                                                                  {parseMarkup(item.body)}
                                                                </p>
                                                              </div>
                                                            </div>

                                                            <Dropdown
                                                              isOpen={otherMenu == item._id}
                                                              
                                                              toggle={() => {
                                                                //console.log('otherMenu, item._id............', otherMenu, item._id);
                                                                if(otherMenu == item._id) {
                                                                  setOtherMenu(null)
                                                                } else {
                                                                  setOtherMenu(item._id)
                                                                }
                                                              }}
                                                            >
                                                              <DropdownToggle
                                                                tag="i"
                                                                className="btn nav-btn"
                                                                type="button"
                                                              >
                                                                <i className="bx bx-dots-vertical-rounded mt-0 fs-3 " />
                                                              </DropdownToggle>
                                                              <DropdownMenu className="dropdown-menu-end">
                                                                {userData._id === item?.author?._id ? (
                                                                  <>
                                                                    <DropdownItem onClick={() => deleteAnswerComment(item._id)}>
                                                                      Delete Comment
                                                                    </DropdownItem>
                                                                  </>
                                                                )
                                                                  :
                                                                  <DropdownItem onClick={() => reportAnswerComment(item._id)}>
                                                                    Report Comment
                                                                  </DropdownItem>
                                                                }
                                                              </DropdownMenu>
                                                            </Dropdown>
                                                          </div>
                                                          <hr
                                                            style={{
                                                              backgroundColor:
                                                                "#5C5C5C",
                                                              height: 2,
                                                            }}
                                                          />
                                                        </>
                                                      )
                                                    }
                                                  )
                                                  :
                                                  ans?.comments?.length > 0 &&
                                                  ans?.comments.slice(0, 3).map(
                                                    (item, index) => {
                                                      return (
                                                        !(reportedPostAnswerComment?.length > 0 && reportedPostAnswerComment?.includes(item._id)) &&
                                                        <>
                                                          <div className="d-flex justify-content-lg-between ">
                                                            <div className="d-flex">
                                                              <div className="mt-1">
                                                                {item?.author
                                                                  ?.realProfilePicUrl ? (
                                                                  <img
                                                                    src={
                                                                      item?.author
                                                                        ?.realProfilePicUrl
                                                                    }
                                                                    alt=""
                                                                    className="width-40-px rounded-circle img-thumbnail"
                                                                  />
                                                                ) : (
                                                                  <Avatar name={item?.author?.userName+' '+item?.author?.lastName} size="40" round="100px" />
                                                                  // <img
                                                                  //   src={avatar1}
                                                                  //   alt=""
                                                                  //   className="width-40-px rounded-circle img-thumbnail"
                                                                  // />
                                                                )}
                                                              </div>
                                                              <div className=" mt-2 ms-3">
                                                                <span className="fs-6 text-white">
                                                                  {
                                                                    item?.author
                                                                      ?.userName
                                                                  }
                                                                </span>
                                                                <p
                                                                  className="mt-1 mb-2 text-white"
                                                                  style={{
                                                                    fontSize:
                                                                      "10px",
                                                                  }}
                                                                >
                                                                  {CommonFunction.dateFormat(item?.createdAt)}
                                                                </p>
                                                                <p
                                                                  className="mt-1 mb-2 text-white"
                                                                  style={{
                                                                    fontSize:
                                                                      "11px",
                                                                  }}
                                                                >
                                                                  {parseMarkup(item.body)}
                                                                </p>
                                                              </div>
                                                            </div>

                                                            <Dropdown
                                                              isOpen={otherMenu == item._id}
                                                              
                                                              toggle={() => {
                                                                //console.log('otherMenu, item._id............', otherMenu, item._id);
                                                                if(otherMenu == item._id) {
                                                                  setOtherMenu(null)
                                                                } else {
                                                                  setOtherMenu(item._id)
                                                                }
                                                              }}
                                                            >
                                                              <DropdownToggle
                                                                tag="i"
                                                                className="btn nav-btn"
                                                                type="button"
                                                              >
                                                                <i className="bx bx-dots-vertical-rounded mt-0 fs-3 " />
                                                              </DropdownToggle>
                                                              <DropdownMenu className="dropdown-menu-end">
                                                                {userData._id === item?.author?._id ? (
                                                                  <>
                                                                    <DropdownItem onClick={() => deleteAnswerComment(item._id)}>
                                                                      Delete Comment
                                                                    </DropdownItem>
                                                                  </>
                                                                )
                                                                  :
                                                                  <DropdownItem onClick={() => reportAnswerComment(item._id)}>
                                                                    Report Comment
                                                                  </DropdownItem>
                                                                }
                                                              </DropdownMenu>
                                                            </Dropdown>
                                                          </div>
                                                          <hr
                                                            style={{
                                                              backgroundColor:
                                                                "#5C5C5C",
                                                              height: 2,
                                                            }}
                                                          />
                                                        </>
                                                      )
                                                    }
                                                  )
                                                }
                                              </div>
                                              <div className="w-100 justify-content-center">
                                                <div class="input-group auth-pass-inputgroup comment-inputgroup w-100 input-mention">
                                                  <MentionsInput
                                                    value={answerComment}
                                                    onChange={e =>
                                                      setAnswerComment(
                                                        e.target.value
                                                      )
                                                    }
                                                    placeholder="Add your comment..."
                                                    className="custom-mention-input"
                                                    style={{
                                                      backgroundColor: "#38434F",
                                                      borderRadius: "12px",
                                                      width: '90%',
                                                      borderTopRightRadius: '0px',
                                                      borderBottomRightRadius: '0px'
                                                    }}
                                                    a11ySuggestionsListLabel={"Suggested mentions"}
                                                  >
                                                    <Mention
                                                      //markup="@__display__"
                                                      trigger="@"
                                                      //regex={/@(\S+)/}
                                                      data={userFilteredData}
                                                      renderSuggestion={(suggestion, search, highlightedDisplay) => (
                                                        <div className="highlightDisplay">{highlightedDisplay}</div>
                                                      )}
                                                      displayTransform={(id, display) => `@${display}`}
                                                      markup="@[__display__]"
                                                      appendSpaceOnAdd={true}
                                                      filter={filterUsers} // Use the custom filter function
                                                      style={{ color: '#70B5F9', position: 'absolute', zIndex: 9, padding: '1px'}}
                                                    />
                                                  </MentionsInput>
                                                  <button
                                                    class="btn btn-light comment-send-btn"
                                                    type="button"
                                                    id="password-addon"
                                                    onClick={() => createAnswerComment(ans?._id)}
                                                  >
                                                    <i class="bx bxs-navigation"></i>
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )
                            })}
                        </InfiniteScroll>
                      </CardBody>
                      <button
                        type="button"
                        className={`btn border-0 color-answer-btn btn-lg text-white text-end me-3 mb-4 add-answer-button absolutequestionButton d-flex justify-content-center`}
                        onClick={() => setAnswerModal(true)}
                        style={{
                          left: `140vh`,
                        }}
                      >
                        <span className="me-3">+</span>Add Your Answer
                      </button>
                    </>
                  )}
                </Card>
              </Col>
            </div>

            {/* add answer model starts */}
            <AddAnswerModal answerModal={answerModal} closeAnswerModal={closeAnswerModal} selectQuestion={selectQuestion} handleAddAnswerSuccess={handleAddAnswerSuccess} isEditAnswer={isEditAnswer} selectAnswer={selectAnswer} handleUpdateAnswerSuccess={handleUpdateAnswerSuccess} />
            {/* add answer model ends */}

            {/* add question model starts */}
            <AddQuestionModal questionModal={questionModal} closeQuestionModal={closeQuestionModal} subjectList={subjectList} handleAddQuestionSuccess={handleAddQuestionSuccess} isEditQuestion={isEditQuestion} selectQuestion={selectQuestion} handleUpdateQuestionSuccess={handleUpdateQuestionSuccess}></AddQuestionModal>

            {/* add question model ends */}
            {/* <div className="row">
              <Col className="col-6">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-lg-between mt-5">
                      <div className="d-flex">
                        <img
                          src={avatar1}
                          alt=""
                          className="width-40-px rounded-circle img-thumbnail"
                        />
                        <div className="ms-2 mt-2">
                          <h4>Jessian</h4>
                        </div>
                      </div>
                      <div>
                        <i className="bx bx-dots-vertical-rounded mt-2 fs-3 "></i>
                      </div>
                    </div>
                    <p className="fs-5 text-info">
                      Can anyone tell me how to derive v=u+at formula ?
                    </p>
                    <p className="col-12">
                      I have studied this formula but didn't quit uderstand
                      it. Can anyone help me
                    </p>
                    <div className="">
                      <img src={img1} alt="" width="120" height="120" />
                    </div>
                    <div className="d-flex justify-content-around mt-3">
                      <div className="text-warning">
                        <i className="bx bxs-like fs-3"></i>
                        <h6 className="mt-3 text-warning">36</h6>
                      </div>
                      <div>
                        {" "}
                        <i className="bx bxs-dislike text-white fs-3 "></i>{" "}
                        <h6 className="mt-3 text-center">0</h6>
                      </div>
                      <div>
                        <i className="bx bxs-comment text-white fs-3"></i>{" "}
                        <h6 className="mt-3">36</h6>
                      </div>
                      <div>
                        <i className="bx bxs-share-alt fs-3"></i>
                        <h6 className="mt-3">Share</h6>
                      </div>
                      <div className="text-warning">
                        <i className="bx bxs-bookmark fs-3 "></i>
                        <h6 className="mt-3  text-warning">Save</h6>
                      </div>
                    </div>
                    <div
                      className="mt-4"
                      style={{
                        height: 1,
                        backgroundColor: "gray",
                        width: "103%",
                        marginLeft: "-17px",
                      }}
                    ></div>

                    <p
                      for=""
                      className="mt-3 text-white"
                      style={{ fontStyle: "italic" }}
                    >
                      Comments<span className="ms-1">(3)</span>
                    </p>
                    <div className="">
                      <div class="card ">
                        <div
                          class="card-body d-flex "
                          style={{
                            backgroundColor: "#2a2731",
                            borderRadius: "10px",
                          }}
                        >
                          <p class="card-text">
                            <div
                              className="row"
                              style={{ borderBottom: "1px solid gray" }}
                            >
                              <div className="d-flex justify-content-lg-between ">
                                <div className="d-flex">
                                  <div className="mt-1">
                                    <img
                                      src={avatar1}
                                      alt=""
                                      className="width-40-px rounded-circle img-thumbnail"
                                    />
                                  </div>
                                  <div className=" mt-2 ms-3">
                                    <span className="fs-6 text-white">
                                      fak3eer
                                    </span>
                                    <div className="row">
                                      <div className="col-12">
                                        <p
                                          className=" text-white"
                                          style={{ fontSize: "9px" }}
                                        >
                                          Why cant the numerator be negative?{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className=" col-3 d-flex ms-5 text-center text-white"
                                style={{ justifyContent: "space-between" }}
                              >
                                <div className="text-white ms-3 ">
                                  <i className="bx bxs-like "></i>
                                  <h6
                                    className=" text-white"
                                    style={{ fontSize: "9px" }}
                                  >
                                    36
                                  </h6>
                                </div>
                                <div className="px-5">
                                  {" "}
                                  <i className="bx bxs-dislike  "></i>{" "}
                                  <h6
                                    className=""
                                    style={{ fontSize: "9px" }}
                                  >
                                    0
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div
                              className="row"
                              style={{ borderBottom: "1px solid gray " }}
                            >
                              <div className="d-flex justify-content-lg-between ">
                                <div className="d-flex">
                                  <div className="mt-1">
                                    <img
                                      src={avatar1}
                                      alt=""
                                      className="width-40-px rounded-circle img-thumbnail"
                                    />
                                  </div>
                                  <div className=" mt-2 ms-3">
                                    <span className="fs-6 text-white">
                                      fak3eer
                                    </span>
                                    <div className="row">
                                      <div className="col-12">
                                        <p
                                          className=" text-white"
                                          style={{ fontSize: "9px" }}
                                        >
                                          Why cant the numerator be negative?{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className=" col-3 d-flex ms-5 text-center text-white"
                                style={{ justifyContent: "space-between" }}
                              >
                                <div className="text-white ms-3 ">
                                  <i className="bx bxs-like "></i>
                                  <h6
                                    className=" text-white"
                                    style={{ fontSize: "9px" }}
                                  >
                                    36
                                  </h6>
                                </div>
                                <div className="px-5">
                                  {" "}
                                  <i className="bx bxs-dislike"></i>{" "}
                                  <h6
                                    className=""
                                    style={{ fontSize: "9px" }}
                                  >
                                    0
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="d-flex justify-content-lg-between ">
                                <div className="d-flex">
                                  <div className="mt-1">
                                    <img
                                      src={avatar1}
                                      alt=""
                                      className="width-40-px rounded-circle img-thumbnail"
                                    />
                                  </div>
                                  <div className=" mt-2 ms-3">
                                    <span className="fs-6 text-white">
                                      fak3eer
                                    </span>
                                    <div className="row">
                                      <div className="col-12">
                                        <p
                                          className=" text-white"
                                          style={{ fontSize: "9px" }}
                                        >
                                          Why cant the numerator be negative?{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className=" col-3 d-flex ms-5 text-center text-white"
                                style={{ justifyContent: "space-between" }}
                              >
                                <div className="text-white ms-3 ">
                                  <i className="bx bxs-like "></i>
                                  <h6
                                    className="text-white "
                                    style={{ fontSize: "9px" }}
                                  >
                                    36
                                  </h6>
                                </div>
                                <div className="px-5">
                                  {" "}
                                  <i className="bx bxs-dislike  "></i>{" "}
                                  <h6
                                    className=""
                                    style={{ fontSize: "9px" }}
                                  >
                                    0
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className=" mt-2">
                                <input
                                  type=""
                                  class="form-control "
                                  id=""
                                  aria-describedby=""
                                  placeholder="Add your comment..."
                                  style={{
                                    borderRadius: "12px",
                                    width: "95%",
                                  }}
                                />
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </div> */}
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal)
        }}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              setmodal(!modal)
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-right">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-right">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-right">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal)
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>

      <Modal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(!deleteModal)
        }}
        centered={true}
        className={"lightModal"}
      >
        <div className="modal-header justify-content-end border-bottom-0 pb-0">
          <button
            type="button"
            onClick={() => {
              setDeleteModal(false)
              resetDeleteId()
            }}
            data-dismiss="modal"
            aria-label="Close"
            style={{
              right: "0",
              top: "18px",
              color: "#858585",
              fontSize: "20px",
            }}
            className="border-0"
          >
            <span aria-hidden="true">
              <i className="mdi mdi-close" fontSize="20"></i>
            </span>
          </button>
        </div>
        <div className="modal-body pb-3 pt-0">
          <div
            className="join-model-content border-top-0  text-center"
            style={{ border: "1px solid #ffffff", borderRadius: "5px" }}
          >
            <h3 className="font-weight-400" style={{ color: "#565555" }}>
              Are you sure? 😲
            </h3>
            <p
              className="mb-4"
              style={{
                color: "#0073B1",
                lineHeight: "30px",
                fontSize: "18px",
                paddingTop: "10px",
              }}
            >
              You won't be able to revert this!
            </p>
            {/* <h3 className="mb-5 font-weight-400" style={{ color: '#565555', fontSize: 20 }}>
              Lets Get Started!
            </h3> */}
            <div className="d-flex justify-content-around align-items-center mx-3">
              <Button
                outline
                className="model-btn"
                type="button"
                onClick={() => {
                  setDeleteModal(!deleteModal)
                  resetDeleteId()
                }}
                style={{
                  color: "#0073B1",
                  borderRadius: "100px",
                  height: "42px",
                  boder: "1px solid 0073B1"

                }}
              >
                Cancel
              </Button>
              <Button
                className="model-btn"
                type="button"
                color=""
                onClick={() => {
                  handleDelete()
                }}
                style={{
                  color: "#ffffff",
                  background: "#0073B1",
                  borderRadius: "100px",
                  height: "42px",
                }}
              >
                Yes, delete it!
              </Button>
            </div>
          </div>
          <div className="modal-footer border-top-0 p-3"></div>
        </div>
      </Modal>

      <Modal
        isOpen={reportModal}
        toggle={() => {
          setReportModal(!reportModal)
          handleResetReport()
        }}
        centered={true}
        className={"lightModal"}
      >
        <div className="modal-header justify-content-end border-bottom-0 pb-0">
          <button
            type="button"
            onClick={() => {
              setReportModal(false)
              handleResetReport()
            }}
            data-dismiss="modal"
            aria-label="Close"
            style={{
              right: "0",
              top: "18px",
              color: "#858585",
              fontSize: "20px",
            }}
            className="border-0"
          >
            <span aria-hidden="true">
              <i className="mdi mdi-close" fontSize="20"></i>
            </span>
          </button>
        </div>
        <div className="modal-body pb-3 pt-0 ">
          <div
            className="join-model-content border-top-0  text-center report-model"
            style={{ border: "1px solid #ffffff", borderRadius: "5px" }}
          >
            <h3 className="font-weight-400 mb-0" style={{ color: "#565555" }}>
              Why are you reporting this post
            </h3>
            <p
              className="mb-4"
              style={{
                color: "#6F6C6C",
                lineHeight: "30px",
                fontSize: "14px",
                paddingTop: "10px",
              }}
            >
              Your report it anonymous, except if you’re
            </p>
            {/* <h3 className="mb-5 font-weight-400" style={{ color: '#565555', fontSize: 20 }}>
              Lets Get Started!
            </h3> */}
            <div className="d-flex flex-column mb-4 report-modal-items">
              {
                reportList.map((item, index) => {
                  return (
                    <Link
                      key={"report_" + index}
                      to="#"
                      className={
                        reportReason == item.title
                          ? "selectd-report-item"
                          : ""
                      }
                      style={{
                        color: "#6F6C6C",
                        lineHeight: "30px",
                        fontSize: "16px",
                        padding: "5px 10px",
                        textAlign: "left",
                        borderBottom: "1px solid #1F1F1F",
                      }}
                      onClick={() => setReportReason(item.title)}
                    >
                      {item.title}
                    </Link>
                  )
                })
              }
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <Button
                outline
                className="model-btn"
                type="button"
                onClick={() => {
                  setReportModal(!reportModal)
                  handleResetReport()
                }}
                style={{
                  color: "#0073B1",
                  borderRadius: "100px",
                  height: "42px",
                  boder: "1px solid #0073B1"
                }}
              >
                Cancel
              </Button>
              <Button
                className="model-btn"
                type="button"
                color=""
                onClick={() => {
                  handleAllTypesReport()
                }}
                style={{
                  color: "#ffffff",
                  background: "#0073B1",
                  borderRadius: "100px",
                  height: "42px",
                }}
              >
                Submit
              </Button>
            </div>
          </div>
          <div className="modal-footer border-top-0 p-1"></div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = state => {
  //console.log("state ----------", state)
  const { error, newsPosts, spaces, talks, users } = state
  return {
    talks: newsPosts,
    error: error,
    current_space: spaces.current_space,
    unsolvedQuestions: talks.unsolvedQuestions,
    popularTopics: talks.popularTopics,
    questions: talks.questions,
    upvoteQuestionSuccessMessage: talks.upvoteQuestionSuccess,
    downvoteQuestionSuccessMessage: talks.downvoteQuestionSuccess,
    upvoteAnswerSuccessMessage: talks.upvoteAnswerSuccess,
    downvoteAnswerSuccessMessage: talks.downvoteAnswerSuccess,
    answerList: talks.answers,
    questionComments: talks.questionComments,
    answerComments: talks.answerComments,
    allSavedQuestionsList: talks.allSavedQuestions,
    saveQuestionsSuccessMessage: talks.saveQuestion,
    saveQuestionCommentMessage: talks.saveQuestionComment,
    saveAnswerCommentMessage: talks.saveAnswerComment,
    deleteQuestionMessage: talks.deleteQuestion,
    deleteAnswerMesssage: talks.deleteAnswer,
    deleteQuestionCommentMessage: talks.deleteQuestionComment,
    deleteAnswerCommentMessage: talks.deleteAnswerComment,
    reportMessage: talks.reportSuccess,
    acceptAnswerMessage: talks.acceptSuccess,
    subjectList: talks.subjectsSuccess,
    allTopicsBySubjectList: talks.topics,
    addAnswerMessage: talks.addAnswer,
    allUserList: users.allUsers,
    reportedPostQuestion: talks.reportedPostQuestion,
    reportedPostQuestionError: talks.reportedPostQuestionError,
    reportedPostAnswer: talks.reportedPostAnswer,
    reportedPostAnswerError: talks.reportedPostAnswerError,
    reportedPostQuestionComment: talks.reportedPostQuestionComment,
    reportedPostQuestionCommentError: talks.reportedPostQuestionCommentError,
    reportedPostAnswerComment: talks.reportedPostAnswerComment,
    reportedPostAnswerCommentError: talks.reportedPostAnswerCommentError,
    reportSuccessMessage: talks.reportSuccess
  }
}

const mapDispatchToProps = dispatch => ({
  onGetUnsolvedQuestion: (optionData) => dispatch(getUnsolvedQuestion(optionData)),
  onGetPopularTopic: (optionData) => dispatch(getPopularTopic(optionData)),
  onGetQuestions: (options) => dispatch(getQuestions(options)),
  onUpvoteQuestions: question_id => dispatch(upvoteQuestions(question_id)),
  onDownvoteQuestions: question_id => dispatch(downvoteQuestions(question_id)),
  onUpvoteQuestionsSuccess: () => dispatch(clearUpvoteQuestionsSuccess()),
  onDownvoteQuestionsSuccess: () => dispatch(clearDownvoteQuestionsSuccess()),
  onGetQuestionSuccess: questionData =>
    dispatch(getQuestionsSuccess(questionData)),
  onGetAnswers: question_id => dispatch(getAnswerByQuestionId(question_id)),
  onGetAnswerComments: answer_id => dispatch(getAnswerComments(answer_id)),
  onGetQuestionComments: question_id =>
    dispatch(getQuestionComments(question_id)),
  onUpvoteAnswers: question_id => dispatch(upvoteAnswers(question_id)),
  //onUpvoteAnswersSuccess: () => dispatch(clearUpvoteAnswersSuccess()),
  onDownvoteAnswers: question_id => dispatch(downvoteAnswers(question_id)),
  onUpvoteAnswersSuccess: () => dispatch(upvoteAnswersSuccess()),
  onDownvoteAnswersSuccess: () => dispatch(downvoteAnswersSuccess()),
  onUpvoteAnswerSuccess: () => dispatch(clearUpvoteQuestionsSuccess()),
  onDownvoteAnswerSuccess: () => dispatch(clearDownvoteQuestionsSuccess()),
  onGetAllSavedQuestions: () => dispatch(getAllSavedQuestions()),
  onGetAllSavedQuestionsSuccess: data =>
    dispatch(getAllSavedQuestionsSuccess(data)),
  onSaveQuestions: (question_id, isBookmark) =>
    dispatch(saveQuestions(question_id, isBookmark)),
  onSaveQuestionsSuccess: data => dispatch(saveQuestionsSuccess(data)),
  onSaveQuestionComment: (comment, question_id) =>
    dispatch(saveQuestionComment(comment, question_id)),
  onSaveQuestionCommentSuccess: data =>
    dispatch(saveQuestionCommentSuccess(data)),
  onSaveAnswerComment: (comment, answer_id) =>
    dispatch(saveAnswerComment(comment, answer_id)),
  onSaveAnswerCommentSuccess: data => dispatch(saveAnswerCommentSuccess(data)),
  onDeleteQuestion: question_id => dispatch(deleteQuestion(question_id)),
  onDeleteQuestionSuccess: data => dispatch(deleteQuestionSuccess(data)),
  onDeleteAnswer: answer_id => dispatch(deleteAnswer(answer_id)),
  onDeleteAnswerSuccess: data => dispatch(deleteAnswerSuccess(data)),
  onGetAnswersSuccess: data => dispatch(getAnswersSuccess(data)),
  onDeleteQuestionComment: comment_id => dispatch(deleteQuestionComment(comment_id)),
  onDeleteQuestionCommentSuccess: data => dispatch(deleteQuestionCommentSuccess(data)),
  onDeleteAnswerComment: comment_id => dispatch(deleteAnswerComment(comment_id)),
  onDeleteAnswerCommentSuccess: data => dispatch(deleteAnswerCommentSuccess(data)),
  onGetQuestionCommentsSuccess: data => dispatch(getQuestionCommentsSuccess(data)),
  onGetAnswerCommentsSuccess: data => dispatch(getAnswerCommentsSuccess(data)),
  //onDownvoteAnswersSuccess: () => dispatch(clearDownvoteAnswersSuccess()),
  onReport: data => dispatch(report(data)),
  onAcceptAnswer: (question_id, answer_id) => dispatch(acceptAnswer(question_id, answer_id)),
  onGetSubjects: () => dispatch(getSubjects()),
  onGetAllTopicsBySubject: (subject_id) => dispatch(getTopicsBySubject(subject_id)),
  onFilterQuestions: (data) => dispatch(filterQuestions(data)),
  onGetAllUsers: () => dispatch(getAllusers()),
  onGetReportedPostQuestion: () => dispatch(getReportedPostQuestion()),
  onGetReportedPostAnswer: () => dispatch(getReportedPostAnswer()),
  onGetReportedPostQuestionComment: () => dispatch(getReportedPostQuestionComment()),
  onGetReportedPostAnswerComment: () => dispatch(getReportedPostAnswerComment()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
)
