import {
  SEARCH_QUESTIONS_FAIL,
  SEARCH_QUESTIONS_SUCCESS,
  SEARCH_ANSWERS_SUCCESS,
  SEARCH_ANSWERS_FAIL,
  SEARCH_FILTER
} from "./actionTypes"

const INIT_STATE = {
  searchQuestionsData: [],
  searchQuestionError: [],
  searchAnswersData: [],
  searchQuestionError: [],
  searchFilterQuery: {
    subject: '',
    topic: '',
    sort: 1,
    myQuestions: false,
    unsolvedQuestions: false,
    savedQuestions: false,
    solvedQuestions: false,
    searchText: ''
  },
  error: {},
}

const Invoices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEARCH_QUESTIONS_SUCCESS:
      return {
        ...state,
        searchQuestionsData: action.payload,
      }

    case SEARCH_QUESTIONS_FAIL:
      return {
        ...state,
        searchQuestionError: action.payload,
      }

    case SEARCH_ANSWERS_SUCCESS:
      return {
        ...state,
        searchAnswersData: action.payload,
      }

    case SEARCH_ANSWERS_FAIL:
      return {
        ...state,
        searchQuestionError: action.payload,
      }
    
    case SEARCH_FILTER:
      return {
        ...state,
        searchFilterQuery: action.payload
      }

    default:
      return state
  }
}

export default Invoices
