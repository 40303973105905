import React, { Component, useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Media,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert
} from "reactstrap"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Truncate from "react-truncate-html"
import CommonFunction from "common/CommonFunction"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import img1 from "../../assets/images/small/img-1.jpg"

import { withRouter, Link } from "react-router-dom"

import { connect, useSelector, useDispatch } from "react-redux"

import Avatar from "react-avatar";

//Lightbox
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import {
  upvoteQuestions,
  getQuestions,
  clearUpvoteQuestionsSuccess
} from "../../store/actions"


const AnswerMiniCard = (props) => {
  const { answerItem, onClick, selectQuestion, upvoteAnswer, downvoteAnswer, userData, getAnswerComment, deleteAnswer, reportAnswer, handleAcceptAnswer, editAnswer } = props;
  const [otherMenu, setOtherMenu] = useState(false)
  const [isGallery, setisGallery] = useState(false)
  const [isGalleryZoom, setisGalleryZoom] = useState(false)
  const [photoIndex, setphotoIndex] = useState(0)
  const [gallery, setGallery] = useState(null);

  //console.log('selectQuestion.........................',selectQuestion);

  const upvote = () => {
    upvoteAnswer(answerItem._id)
  }

  const downvote = () => {
    //console.log('answerItem............', answerItem);
    downvoteAnswer(answerItem._id)
  }

  const fetchAnswerComment = () => {
    getAnswerComment(answerItem._id);
  }

  const deleteA = () => {
    deleteAnswer(answerItem._id)
  }

  const reportA = () => {
    reportAnswer(answerItem._id)
  }

  const acceptAnswer = () => {
    handleAcceptAnswer(answerItem?.question?._id,answerItem._id)
  }

  const editA = () => {
    editAnswer(answerItem)
  }

  return (
    <Card className={'mini-answer'}>
      <CardBody>
        <div className="d-flex justify-content-lg-between mb-3">
          <div className="d-flex">
            <div>
              {answerItem?.author?.realProfilePicUrl != null ? (
                <img
                  src={answerItem?.author?.realProfilePicUrl}
                  alt=""
                  className="width-50-px rounded-circle img-thumbnail"
                />
              ) : (
                
                <Avatar name={answerItem?.author?.userName+' '+answerItem?.author?.lastName} size="50" round="100px" />
              )}
            </div>
            <div className="ms-2 mt-2">
              <h4 className="mb-1">{answerItem?.author?.userName}</h4>
              <p className="mb-0">
                {CommonFunction.dateFormat(answerItem?.createdAt)}
              </p>
            </div>
          </div>
          <div className={'d-flex align-items-start'}>
            <div className="mt-2">
              {
                (selectQuestion?.acceptedAnswer == null &&
                  selectQuestion.author._id == userData._id &&
                  answerItem.author._id != userData._id) && (
                  <Button
                    type="button"
                    onClick={() => {acceptAnswer()}}
                     color="info"
                     className="btn-rounded pe-3 ps-3 py-1"
                     >
                    Accept Answer
                  </Button> 
                  )
                  }
             
             {
              (selectQuestion?.acceptedAnswer != null &&
              answerItem._id == selectQuestion.acceptedAnswer._id) && (
                < Button
                type="submit"
              color="success"
              className="btn-rounded pe-3 ps-3 py-1"
              >
              Accepted
            </Button>
              )
            }
            </div>
            {/* <i className="bx bx-dots-vertical-rounded mt-2 fs-3 "></i> */}
          <Dropdown
            isOpen={otherMenu}
            toggle={() => {
              setOtherMenu(!otherMenu)
            }}
          >
            <DropdownToggle
              tag="i"
              className="btn nav-btn"
              type="button"
            >
              <i className="bx bx-dots-vertical-rounded mt-0 fs-3 " />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {userData._id === answerItem?.author?._id ? (
                <>
                  <DropdownItem onClick={editA}>
                    Edit Answer
                  </DropdownItem>
                  <DropdownItem onClick={deleteA}>
                    Delete Answer
                  </DropdownItem>
                </>
              )
                :
                <DropdownItem onClick={reportA}>
                  Report Answer
                </DropdownItem>
              }
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <p className="fs-5 text-info">{answerItem?.title}</p>
      <p className="">
        <Truncate
          debounce="1000"
          lines={1000}
          dangerouslySetInnerHTML={{
            __html: answerItem?.body != null ? answerItem?.body : "",
          }}
        />
      </p>
      <div className="d-flex justify-content-between flex-wrap">
        <div className="zoom-gallery zoom-gallery-answer d-flex">
          {
            answerItem?.imageUrls &&
            answerItem?.imageUrls.length > 0 &&
            answerItem?.imageUrls.map((d, i1) => {
              return (
                
                i1 < 3 &&
                <div className={'position-reletive image-with-overlay-answer d-flex me-2'}>
                  <img
                    key={'gallery_' + i1}
                    src={d}
                    onClick={() => {
                      setGallery(answerItem?.imageUrls)
                      setisGallery(true)
                      setphotoIndex(i1)
                    }}
                    alt={d}
                  />
                  {
                    i1 == 2 &&
                    <div className={'image-overlay-answer'}
                      onClick={() => {
                        setGallery(answerItem?.imageUrls)
                        setisGallery(true)
                        setphotoIndex(i1)
                      }}
                    >
                      + {answerItem?.imageUrls.length - 3}
                    </div>
                  }
                </div>
                )
            })
          }
        </div>
      </div>
      {
        isGallery && answerItem?.imageUrls != null &&
        <Lightbox images={answerItem?.imageUrls} 
            onClose={() =>setisGallery(false)}
          />
      }

      <div className="d-flex justify-content-around answer-mini-card-footer mt-5 bottom-action-button">
        {
          answerItem.upvotedBy &&
            answerItem.upvotedBy.length > 0 && userData != null &&
            answerItem.upvotedBy.some((e) => e._id == userData._id) ?
            <div className="like-color text-info text-center d-flex" onClick={upvote}>
              <i className="bx bxs-like fs-3 like-color"></i>
              <h6 className="mt-1 ms-2 like-color ">
                {answerItem?.upvoteCount}
              </h6>
            </div>
            :
            <div className="text-info text-center d-flex" onClick={upvote}>
              <i className="bx bxs-like fs-3 text-white"></i>
              <h6 className="mt-1 ms-2">
                {answerItem?.upvoteCount}
              </h6>
            </div>
        }
        {
          answerItem.downvotedBy &&
            answerItem.downvotedBy.length > 0 && userData != null &&
            answerItem.downvotedBy.some((e) => e._id == userData._id) ?
            <div className="like-color text-center topic-name d-flex" onClick={downvote}>
              <i className="bx bxs-dislike fs-3 like-color"></i>
              <h6 className="mt-1 ms-2 like-color">{answerItem?.downvoteCount}</h6>
            </div>
            :
            <div className="text-center topic-name d-flex" onClick={downvote}>
              <i className="bx bxs-dislike fs-3"></i>
              <h6 className="mt-1 ms-2">
                {answerItem?.downvoteCount}
              </h6>
            </div>
        }
        <div className="text-center topic-name d-flex" onClick={fetchAnswerComment}>
          <i className="bx bxs-comment fs-3"></i>
          <h6 className="mt-1 ms-2">{answerItem?.commentsCount}</h6>
        </div>
      </div>
    </CardBody>
    </Card >
  )
}

const mapStateToProps = state => {
  //console.log("state ----------", state.talks)
  const { error, newsPosts, spaces, talks } = state
  return {
    talks: newsPosts,
    error: error,
    current_space: spaces.current_space,
    unsolvedQuestions: talks.unsolvedQuestions,
    popularTopics: talks.popularTopics,
    questions: talks.questions,
    upvoteQuestionSuccessMessage: talks.upvoteQuestionSuccess
  }
}

const mapDispatchToProps = dispatch => ({
  onUpvoteQuestions: (question_id) => dispatch(upvoteQuestions(question_id)),
  onUpvoteQuestionsSuccess: () => dispatch(clearUpvoteQuestionsSuccess()),
  onGetQuestions: () => dispatch(getQuestions()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnswerMiniCard)
)
