import React, { Component } from "react"
import { 
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Media,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, } from "reactstrap"
import { Link } from "react-router-dom"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import Truncate from "react-truncate-html"
import CommonFunction from "common/CommonFunction"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import { isNonEmptyArray } from "@apollo/client/utilities"

const PopularTopics = (props) => {
  const { popularTopics, onSelectPopularTopics, selectedTopic, subjectList, selectedSubject } = props;

  const responsivePopularTopics = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 6,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const onClickPopularTopics = (topic_id, subject_id) => {
      onSelctPopularTopics(topic_id, subject_id)
  }


    return (
      <React.Fragment>
        <div className="pt-1">
          
            <h4 className="mb-4">
            {
              (selectedSubject != '')
              ?
              "All " + subjectList.find(o => o._id === selectedSubject)?.subjectName + " Topics"
              :
              'All Topics'
            }
            </h4>
          </div>
          <Row className="popularTopics">
            <Carousel
              responsive={responsivePopularTopics}
              className="popularTopicCarousel"
            >
              {popularTopics.length > 0 &&
                popularTopics.map((pt, index) => {
                  return (
                    <div className="popular-topic-col" style={{ textAlign: "center" }} onClick={() => onSelectPopularTopics(pt._id, pt?.subject?._id)}>
                      <img
                        src={pt.imageS3Url}
                        alt=""
                        style={{
                          backgroundColor: "#1D2226",
                          padding: 17,
                          borderRadius: 14,
                          width: 100,
                          height: 100,
                        }}
                        className={`avatar-md img-thumbnail ${selectedTopic == pt._id ? 'popularTopicActive' : ''}`}
                      />
                      <p className="text-center mt-2 topic-name fw-bold">{pt.topicName}</p>
                    </div>
                  )
                })}
            </Carousel>
          </Row>

      </React.Fragment>
    )
}

export default PopularTopics
