import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Form,
  Input,
} from "reactstrap"

// users
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import camera from "../../assets/images/setting/camera.png"

function ProfileTab(props) {
  const [file, setFile] = useState(avatar1);
  function fileHandler(e) {
      console.log(e.target.files);
      setFile(URL.createObjectURL(e.target.files[0]));
  }

  return (
    <div>
      <Card>
        <CardBody className="pb-5 pt-5">
          <div className={"mb-4 text-center"}>
            <Row className="justify-content-center">
              <Col xl={4} lg={3}>
                  <div className={'swiper-container-inner'}>
                      <img
                          src={file}
                          className="d-block avatar-xl rounded-circle mb-4"
                          alt="Profile Image"
                          style={{position: 'relative'}}
                      />
                      <Label htmlFor="formFile" className="form-label" style={{position: 'absolute', top: '22%', left: '44%', cursor: 'pointer'}}>
                        <img
                            src={camera}
                            className="d-block"
                            alt="Camera Image"
                            style={{width: '40px', height: '32px'}}
                        />
                      </Label>
                      <Input className="form-control" type="file" id="formFile" onChange={fileHandler} 
                        style={{ display: "none"}} />
                      
                      <p><span className="text-danger">*</span> The uploaded image must be 500px wide and 500px long</p>
                  </div>
              </Col>
            </Row>
            <Row className="justify-content-center pt-4 mt-1">
              <Col xl={5} lg={4} className="pt-1">
                <Form>
                  <div className="mb-4" style={{textAlign: 'left'}}>
                    <Label htmlFor="formrow-name-Input">Name</Label>
                    <Input
                      type="text"
                      name="name"
                      className="form-control p-4"
                      id="formrow-name-Input"
                      placeholder="Name"
                      style={{borderRadius : '30px', height: '62px'}}
                    />
                  </div>

                  <div className="mb-4" style={{textAlign: 'left'}}>
                    <Label htmlFor="formrow-schoolname-Input">School name</Label>
                    <Input
                      type="text"
                      name="schoolname"
                      className="form-control p-4"
                      id="formrow-schoolname-Input"
                      placeholder="School name"
                      style={{borderRadius : '30px', height: '62px'}}
                    />
                  </div>
                  <div style={{marginTop : '77px'}}>
                    <button type="submit" className="btn bg-blue-color btn-rounded text-white btn-lg w-md">
                      Save Changes
                    </button>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default ProfileTab
