import {
  GET_TALKS_SUCCESS,
  GET_TALKS_FAIL,
  SUBMIT_POST_LIKE_SAVE,
  GET_POST_LIKE_SAVE,
  GET_POST_FAIL,
  GET_UNSOLVED_QUESTION_SUCCESS,
  GET_UNSOLVED_QUESTION_FAIL,
  GET_POPULAR_TOPICS_SUCCESS,
  GET_POPULAR_TOPICS_FAIL,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAIL,
  UPVOTE_QUESTION_SUCCESS,
  UPVOTE_QUESTION_FAIL,
  DOWNVOTE_QUESTION_SUCCESS,
  DOWNVOTE_QUESTION_FAIL,
  CLEAR_UPVOTE_DATA,
  GET_ANSWERS_SUCCESS,
  GET_ANSWERS_FAIL,
  GET_QUESTION_COMMENTS_SUCCESS,
  GET_QUESTION_COMMENTS_FAIL,
  GET_ANSWER_COMMENTS_SUCCESS,
  GET_ANSWER_COMMENTS_FAIL,
  UPVOTE_ANSWER_SUCCESS,
  UPVOTE_ANSWER_FAIL,
  DOWNVOTE_ANSWER_SUCCESS,
  DOWNVOTE_ANSWER_FAIL,
  GET_ALL_SAVED_QUESTIONS_SUCCESS,
  GET_ALL_SAVED_QUESTIONS_FAIL,
  SAVE_QUESTION_SUCCESS,
  SAVE_QUESTION_FAIL,
  SAVE_QUESTION_COMMENT_SUCCESS,
  SAVE_QUESTION_COMMENT_FAIL,
  SAVE_ANSWER_COMMENT_SUCCESS,
  SAVE_ANSWER_COMMENT_FAIL,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAIL,
  DELETE_ANSWER_SUCCESS,
  DELETE_ANSWER_FAIL,
  DELETE_QUESTION_COMMENT_SUCCESS,
  DELETE_QUESTION_COMMENT_FAIL,
  DELETE_ANSWER_COMMENT_SUCCESS,
  DELETE_ANSWER_COMMENT_FAIL,
  REPORT_SUCCESS,
  REPORT_FAIL,
  ACCEPT_ANSWER_SUCCESS,
  ACCEPT_ANSWER_FAIL,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_FAIL,
  GET_TOPICS_BY_SUBJECT_SUCCESS,
  GET_TOPICS_BY_SUBJECT_FAIL,
  ADD_QUESTION_SUCCESS,
  ADD_QUESTION_FAIL,
  ADD_ANSWER_SUCCESS,
  ADD_ANSWER_FAIL,
  GET_ALL_TOPICS_BY_SUBJECT_SUCCESS,
  GET_ALL_TOPICS_BY_SUBJECT_FAIL,
  FILTER_QUESTIONS_SUCCESS,
  FILTER_QUESTIONS_FAIL,
  POST_UPDATE_ANSWER_SUCCESS,
  POST_UPDATE_ANSWER_FAIL,
  POST_UPDATE_QUESTION_SUCCESS,
  POST_UPDATE_QUESTION_FAIL,
  GET_SINGLE_QUESTION_SUCCESS,
  GET_SINGLE_QUESTION_FAIL,
  GET_REPORTED_POST_QUESTION_SUCCESS,
  GET_REPORTED_POST_QUESTION_FAIL,
  GET_REPORTED_POST_ANSWER_SUCCESS,
  GET_REPORTED_POST_ANSWER_FAIL,
  GET_REPORTED_POST_QUESTION_COMMENT_SUCCESS,
  GET_REPORTED_POST_QUESTION_COMMENT_FAIL,
  GET_REPORTED_POST_ANSWER_COMMENT_SUCCESS,
  GET_REPORTED_POST_ANSWER_COMMENT_FAIL
} from "./actionTypes"

const INIT_STATE = {
  newsPosts: [],
  error: {},
  postLikeSave: [],
  postError: {},
  unsolvedQuestions: [],
  unsolvedQuestionError: {},
  popularTopics: [],
  popularTopicError: {},
  questions: [],
  questionError: {},
  answers: [],
  asnwerError: {},
  upvoteQuestionSuccess: {},
  upvoteQuestionError: {},
  downvoteQuestionSuccess: {},
  downvoteQuestionError: {},
  questionComments: [],
  questionCommentError: {},
  answerComments: [],
  answerCommentError: {},
  upvoteAnswerSuccess: {},
  upvoteAnswerError: {},
  downvoteAnswerSuccess: {},
  downvoteAnswerError: {},
  allSavedQuestions: [],
  allSavedQuestionsError: {},
  saveQuestion: {},
  saveQuestionError: {},
  saveQuestionComment: {},
  saveQuestionCommentError: {},
  saveAnswerComment: {},
  saveAnswerCommentError: {},

  deleteQuestion: {},
  deleteQuestionError: {},

  deleteAnswer: {},
  deleteAnswerError: {},

  deleteQuestionComment: {},
  deleteQuestionCommentError: {},

  deleteAnswerComment: {},
  deleteAnswerCommentError: {},

  reportSuccess: {},
  reportError: {},

  acceptSuccess: {},
  acceptError: {},

  subjectsSuccess: [],
  subjectsError: {},

  topics: [],
  topicError: {},

  addQuestion: {},
  addQuestionError: {},

  addAnswer: {},
  addAnswerError: {},

  allTopicsBySubject: [],
  allTopicsBySubjectError: {},

  updateAnswer: {},
  updateAnswerError: {},

  updateQuestion: {},
  updateQuestionError: {},

  singleQuestion: {},
  singleQuestionError: {},

  reportedPostQuestion: {},
  reportedPostQuestionError: {},

  reportedPostAnswer: {},
  reportedPostAnswerError: {},

  reportedPostQuestionComment: {},
  reportedPostQuestionCommentError: {},

  reportedPostAnswerAnswer: {},
  reportedPostAnswerAnswerError: {}
}

const talks = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_UNSOLVED_QUESTION_SUCCESS:
      return {
        ...state,
        unsolvedQuestions: action.payload,
      }

    case GET_UNSOLVED_QUESTION_FAIL:
      return {
        ...state,
        unsolvedQuestionError: action.payload,
      }


    case DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        deleteQuestion: action.payload,
      }

    case DELETE_QUESTION_FAIL:
      return {
        ...state,
        deleteQuestionError: action.payload,
      }

    case DELETE_QUESTION_COMMENT_SUCCESS:
      return {
        ...state,
        deleteQuestionComment: action.payload,
      }

    case DELETE_QUESTION_COMMENT_FAIL:
      return {
        ...state,
        deleteQuestionCommentError: action.payload,
      }

    case DELETE_ANSWER_SUCCESS:
      return {
        ...state,
        deleteAnswer: action.payload,
      }

    case DELETE_ANSWER_FAIL:
      return {
        ...state,
        deleteAnswerError: action.payload,
      }


    case DELETE_ANSWER_COMMENT_SUCCESS:
      return {
        ...state,
        deleteAnswerComment: action.payload,
      }

    case DELETE_ANSWER_COMMENT_FAIL:
      return {
        ...state,
        deleteAnswerCommentError: action.payload,
      }


    case SAVE_QUESTION_COMMENT_SUCCESS:
      return {
        ...state,
        saveQuestionComment: action.payload,
      }

    case SAVE_QUESTION_COMMENT_FAIL:
      return {
        ...state,
        saveQuestionError: action.payload,
      }

    case SAVE_ANSWER_COMMENT_SUCCESS:
      return {
        ...state,
        saveAnswerComment: action.payload,
      }

    case SAVE_ANSWER_COMMENT_FAIL:
      return {
        ...state,
        saveAnswerCommentError: action.payload,
      }

    case SAVE_QUESTION_SUCCESS:
      return {
        ...state,
        saveQuestion: action.payload,
      }

    case SAVE_QUESTION_FAIL:
      return {
        ...state,
        saveQuestionError: action.payload,
      }

    case GET_POPULAR_TOPICS_SUCCESS:
      return {
        ...state,
        popularTopics: action.payload,
      }

    case GET_POPULAR_TOPICS_FAIL:
      return {
        ...state,
        popularTopicError: action.payload,
      }

    case GET_ALL_SAVED_QUESTIONS_SUCCESS:
      return {
        ...state,
        allSavedQuestions: action.payload,
      }

    case GET_ALL_SAVED_QUESTIONS_FAIL:
      return {
        ...state,
        allSavedQuestionsError: action.payload,
      }

    case GET_QUESTION_COMMENTS_SUCCESS:
      return {
        ...state,
        questionComments: action.payload,
      }

    case GET_QUESTION_COMMENTS_FAIL:
      return {
        ...state,
        questionCommentError: action.payload,
      }

    case GET_ANSWER_COMMENTS_SUCCESS:
      return {
        ...state,
        answerComments: action.payload,
      }

    case GET_ANSWER_COMMENTS_FAIL:
      return {
        ...state,
        answerCommentError: action.payload,
      }

    case GET_ANSWERS_SUCCESS:
      return {
        ...state,
        answers: action.payload,
      }

    case GET_ANSWERS_FAIL:
      return {
        ...state,
        answerError: action.payload,
      }


    case GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: action.payload,
      }

    case GET_QUESTIONS_FAIL:
      return {
        ...state,
        questionError: action.payload,
      }

    case FILTER_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: action.payload,
      }

    case FILTER_QUESTIONS_FAIL:
      return {
        ...state,
        questionError: action.payload,
      }

    case GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjectsSuccess: action.payload,
      }

    case GET_SUBJECTS_FAIL:
      return {
        ...state,
        subjectsError: action.payload,
      }

    case GET_TOPICS_BY_SUBJECT_SUCCESS:
      return {
        ...state,
        topics: action.payload,
      }

    case GET_TOPICS_BY_SUBJECT_FAIL:
      return {
        ...state,
        topicError: action.payload,
      }

    case UPVOTE_QUESTION_SUCCESS:
      return {
        ...state,
        upvoteQuestionSuccess: action.payload,
      }

    case CLEAR_UPVOTE_DATA:
      return {
        ...state,
        upvoteQuestionSuccess: {},
        downvoteQuestionSuccess: {},
        upvoteAnswerSuccess: {},
        downvoteAnswerSuccess: {},
      }

    case UPVOTE_QUESTION_FAIL:
      return {
        ...state,
        upvoteQuestionError: action.payload,
      }

    case DOWNVOTE_QUESTION_SUCCESS:
      return {
        ...state,
        downvoteQuestionSuccess: action.payload,
      }

    case DOWNVOTE_QUESTION_FAIL:
      return {
        ...state,
        downvoteQuestionError: action.payload,
      }

    case GET_TALKS_SUCCESS:
      return {
        ...state,
        newsPosts: action.payload,
      }
    case GET_TALKS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case SUBMIT_POST_LIKE_SAVE:
      return {
        ...state,
        postLikeSave: action.payload.likeSaveData,
      }
    case GET_POST_LIKE_SAVE:
      return {
        ...state,
        postLikeSave: action.payload,
      }
    case GET_POST_FAIL:
      return {
        ...state,
        postError: action.payload,
      }

    case UPVOTE_ANSWER_SUCCESS:
      return {
        ...state,
        upvoteAnswerSuccess: action.payload,
      }

    case UPVOTE_ANSWER_FAIL:
      return {
        ...state,
        upvoteAnswerError: action.payload,
      }

    case DOWNVOTE_ANSWER_SUCCESS:
      return {
        ...state,
        downvoteAnswerSuccess: action.payload,
      }

    case DOWNVOTE_ANSWER_FAIL:
      return {
        ...state,
        downvoteAnswerError: action.payload,
      }

    case REPORT_SUCCESS:
      return {
        ...state,
        reportSuccess: action.payload,
      }

    case REPORT_FAIL:
      return {
        ...state,
        reportError: action.payload,
      }

    case ACCEPT_ANSWER_SUCCESS:
      return {
        ...state,
        acceptSuccess: action.payload,
      }

    case ACCEPT_ANSWER_FAIL:
      return {
        ...state,
        acceptError: action.payload,
      }


    case ADD_QUESTION_SUCCESS:
      return {
        ...state,
        addQuestion: action.payload,
      }

    case ADD_QUESTION_FAIL:
      return {
        ...state,
        addQuestionError: action.payload,
      }

    case ADD_ANSWER_SUCCESS:
      return {
        ...state,
        addAnswer: action.payload,
      }

    case ADD_ANSWER_FAIL:
      return {
        ...state,
        addAnswerError: action.payload,
      }

    case GET_ALL_TOPICS_BY_SUBJECT_SUCCESS:
      return {
        ...state,
        allTopicsBySubject: action.payload,
      }

    case GET_ALL_TOPICS_BY_SUBJECT_FAIL:
      return {
        ...state,
        allTopicsBySubjectError: action.payload,
      }

    case POST_UPDATE_ANSWER_SUCCESS:
      return {
        ...state,
        updateAnswer: action.payload,
      }

    case POST_UPDATE_ANSWER_FAIL:
      return {
        ...state,
        updateAnswerError: action.payload,
      }

    case POST_UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        updateQuestion: action.payload,
      }

    case POST_UPDATE_QUESTION_FAIL:
      return {
        ...state,
        updateQuestionError: action.payload,
      }

    case GET_SINGLE_QUESTION_SUCCESS:
      return {
        ...state,
        singleQuestion: action.payload,
      }

    case GET_SINGLE_QUESTION_FAIL:
      return {
        ...state,
        singleQuestionError: action.payload,
      }

    case GET_REPORTED_POST_QUESTION_SUCCESS:
      return {
        ...state,
        reportedPostQuestion: action.payload,
      }

    case GET_REPORTED_POST_QUESTION_FAIL:
      return {
        ...state,
        reportedPostQuestionError: action.payload,
      }

    case GET_REPORTED_POST_ANSWER_SUCCESS:
      return {
        ...state,
        reportedPostAnswer: action.payload,
      }

    case GET_REPORTED_POST_ANSWER_FAIL:
      return {
        ...state,
        reportedPostAnswerError: action.payload,
      }

    case GET_REPORTED_POST_QUESTION_COMMENT_SUCCESS:
      return {
        ...state,
        reportedPostQuestionComment: action.payload,
      }

    case GET_REPORTED_POST_QUESTION_COMMENT_FAIL:
      return {
        ...state,
        reportedPostQuestionCommentError: action.payload,
      }

    case GET_REPORTED_POST_ANSWER_COMMENT_SUCCESS:
      return {
        ...state,
        reportedPostAnswerComment: action.payload,
      }

    case GET_REPORTED_POST_ANSWER_COMMENT_FAIL:
      return {
        ...state,
        reportedPostAnswerCommentError: action.payload,
      }

    default:
      return state
  }
}

export default talks
