import moment from "moment";

class CommonFunction {
  static dateFormat = (createdAt) => {
    return moment.utc(createdAt).local().startOf('seconds').fromNow();
  }

  static isSpaceJoinedByUser = (studentSpaces, currentSpace) => {
    //console.log('studentSpaces, currentSpace............', studentSpaces, currentSpace)
    if (currentSpace != null) {
      return studentSpaces.some((e) => e._id == currentSpace?._id)
    }
    else
      return false
  }

  static isCorrectPasswordFormat = (password) => {
    let pass = true;
    var pattern = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
    );
    if (!pattern.test(password)) {
      pass = false;
    }
    return pass;
  };

}



export default CommonFunction;