import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"
import singleQuestion from "../pages/AuthenticationInner/singleQuestion"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Space from "pages/Space"
import NimoSense from "../pages/Nimo-sense/index"
import NimoRisingStar from "../pages/Nimo-rising-star/index"
import LeaderBoard from "pages/LeaderBoard/index"
import NimoRisingStarSingle from "../pages/Nimo-rising-star/singleView"
import Setting from "../pages/Setting/index"
import UserLogout from "../pages/Logout/index"
import SingleQuestion from "pages/SingleQuestion/index"
import Question from "pages/question/index"
import Search from "pages/Search"

const userRoutes = [
  { path: "/space", component: Space },
  { path: "/dashboard", component: Dashboard },
  { path: "/singleQuestion/:id/:type", component: SingleQuestion },
  { path: "/nimoSense", component: NimoSense},
  { path: "/profile", component: NimoRisingStar},
  { path: "/nimorisingstarsingle", component: NimoRisingStarSingle },
  { path: "/leaderboard", component: LeaderBoard },
  { path: "/setting", component: Setting },
  { path: "/search", component: Search},
  { path: "/logout", component: UserLogout},
  //{ path: "/question/:id", component: SingleQuestion },

  // //profile
  //{ path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/space" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login2 },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/question/:id", component: Question },
  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { userRoutes, authRoutes }
