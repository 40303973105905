import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER, 
          PHONE_VERIFICATION,
          OTP_VERIFICATION,
          GET_AVATARS
      } from "./actionTypes"
import {
  registerUserSuccessful,
  registerUserFailed,
  phoneVerificationSuccessful,
  phoneVerificationFail,
  otpVerificationSuccessful,
  otpVerificationFail,
  getAvatarsSuccessful,
  getAvatarsFail
} from "./actions"

//Include Both Helper File with needed methods
import { get, getWithouAuth } from "../../../helpers/api_helper"
import {
  PHONE_VERIFICATION_QUERY,
  GET_STUDENT,
  CREATE_STUDENT,
  VERIFY_OTP,
  UPDATE_STUDENT,
  GET_AVATARS_QUERY,
  VERIFY_USERNAME
} from "../../../helpers/url_helper"

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: user }) {
  console.log('user.................',user);
  try {
    let body = JSON.stringify({
      query: VERIFY_USERNAME,
      variables: {
        options: {
          userName: user.username
        }
      }
    });
    const response = yield call(getWithouAuth, { payload: { body, history } })
    if (response?.data?.checkUserName == true) {
      let dataBody = {
        id: user.id,
        userName: user.username,
        profileDesc: user.about,
        //approvalStatusHome: 'NOT_INITIATED',
        realProfilePicUrl: user.realProfilePicUrl,
        firstName: user.firstname,
        lastName: user.lastname,
        password: user.password,
        isRegistered: true,
        registrationType: 'SELF'    //values: SELF, PRE
      }
  
      let body = JSON.stringify({
        query: UPDATE_STUDENT,
        variables: {
          options: dataBody
        }
      });
  
      const response1 = yield call(getWithouAuth, { payload: { body, history } })
      if (response1 && response1?.data?.updateStudent) {
        yield put(registerUserSuccessful({data: true}))
      } else {
        yield put(registerUserFailed(response1?.errors))
      }
    } else {
      yield put(registerUserFailed({message: 'Username already exist!'}))
    }
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

// Is user register successfull then direct plot user in redux.
function* postPhoneVarification({ payload: phone }) {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));

  let phone2 = parseInt('91' + phone);

  let body = JSON.stringify({
    query: GET_STUDENT,
    variables: {
      options: {
        phone: phone2,
      },
    },
  });

  // let body1 = JSON.stringify({
  //   query: CREATE_STUDENT,
  //   variables: {
  //     options: {
  //       phone: phone2,
  //       registrationType: 'SELF',
  //     },
  //   },
  // });

  // let body = JSON.stringify({
  //   query: PHONE_VERIFICATION_QUERY,
  //   variables: {
  //     input: {
  //       phone: phone2,
  //     },
  //   },
  // });

  const response = yield call(getWithouAuth, { payload: { body, history } })
  //console.log('response, error GET_WORLD_QUESTION_QUERY.......................hi', response);
  if (response?.data == null) {
    body = JSON.stringify({
      query: CREATE_STUDENT,
      variables: {
        options: {
          phone: phone2,
          registrationType: 'SELF',
        },
      },
    });

    const response2 = yield call(getWithouAuth, { payload: { body, history } });
    //console.log('response, error GET_WORLD_QUESTION_QUERY.......................', response2);
    if (response2?.data != null) {
      let created_id = response2?.data?.createStudent._id;

      body = JSON.stringify({
        query: PHONE_VERIFICATION_QUERY,
        variables: {
          input: {
            phone: phone2,
          },
        },
      });

      const response3 = yield call(getWithouAuth, { payload: { body, history } });
      if (response3 && response3.data?.sendOTP == true) {
        yield put(phoneVerificationSuccessful({data : { phone: phone2, id: created_id }}))
      } else {
        yield put(phoneVerificationFail(response2?.errors))
      }
    } else {
      yield put(phoneVerificationFail(response?.errors))
    }
  } else if ((response?.data != null && response.data.getStudent && (!("isRegistered" in response.data.getStudent)) || (response?.data != null && response?.data.getStudent && response?.data.getStudent.isRegistered == false))) {
    let created_id = response?.data.getStudent._id;
    body = JSON.stringify({
      query: PHONE_VERIFICATION_QUERY,
      variables: {
        input: {
          phone: phone2,
        },
      },
    });
    const response3 = yield call(getWithouAuth, { payload: { body, history } });
    if (response3?.data && response3?.data.sendOTP == true) {
      yield put(phoneVerificationSuccessful({data : { phone: phone2, id: created_id }}))
    } else {
      yield put(phoneVerificationFail(response?.errors))
    }
  } else {
    yield put(phoneVerificationFail([{message: 'User already exist!'}]))
  }
}

// Is user register successfull then direct plot user in redux.
function* postOtpVarification({ payload: { otp, phone, id } }) {
  let body = JSON.stringify({
    query: VERIFY_OTP,
    variables: {
      input: {
        phone: parseInt(phone),
        verificationCode: otp
      }
    }
  });

  const response3 = yield call(getWithouAuth, { payload: { body, history } });
  console.log('response3,..............', response3);
  if (response3?.data && response3?.data?.verifyOTP == true) {
    let body = JSON.stringify({
      query: UPDATE_STUDENT,
      variables: {
        options: {
          id: id,
          isPhoneVerified: true
        }
      }
    });
  const response = yield call(getWithouAuth, { payload: { body, history } });
    if (response?.data.updateStudent) {
      yield put(otpVerificationSuccessful({data : { phone: phone, id: id }}))
    } else {
      yield put(otpVerificationFail(response?.errors))
    }
  } else {
    yield put(otpVerificationFail([{message: 'OTP not matched!'}]))
  }
}


function* getAvatars() {
  try {

    let body = JSON.stringify({
      query: GET_AVATARS_QUERY,
    });

    const response = yield call(getWithouAuth, { payload: { body, history } });
    console.log('response.............', response);
    if (response?.data?.getAvatars?.data) {
      yield put(getAvatarsSuccessful(response?.data?.getAvatars?.data))
    } else {
      yield put(getAvatarsFail(response?.errors))
    }
  } catch (error) {
    yield put(getAvatarsFail(error))
  }
}


export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
  yield takeEvery(PHONE_VERIFICATION, postPhoneVarification)
  yield takeEvery(OTP_VERIFICATION, postOtpVarification)
  yield takeEvery(GET_AVATARS, getAvatars)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
