export const REGISTER_USER = "REGISTER_USER"
export const REGISTER_USER_SUCCESSFUL = "REGISTER_USER_SUCCESSFUL"
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED"

export const PHONE_VERIFICATION = "PHONE_VERIFICATION"
export const PHONE_VERIFICATION_SUCCESSFUL = "PHONE_VERIFICATION_SUCCESSFUL"
export const PHONE_VERIFICATION_FAILED = "PHONE_VERIFICATION_FAILED"

export const OTP_VERIFICATION = "OTP_VERIFICATION"
export const OTP_VERIFICATION_SUCCESSFUL = "OTP_VERIFICATION_SUCCESSFUL"
export const OTP_VERIFICATION_FAILED = "OTP_VERIFICATION_FAILED"

export const GET_AVATARS = "GET_AVATARS"
export const GET_AVATARS_SUCCESSFUL = "GET_AVATARS_SUCCESSFUL"
export const GET_AVATARS_FAILED = "GET_AVATARS_FAILED"