import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"

class Line extends Component {
  getOption = () => {
    return {
      tooltip: {
        trigger: "axis",
      },
      grid: {
        zlevel: 0,
        x: 50,
        x2: 50,
        y: 30,
        y2: 30,
        borderWidth: 0,
      },
      xAxis: {
        //type: "category",
        data: ["1","2","3","4","5","6","7","8"],
        axisLable: {
          color: "#ffffff",
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
      yAxis: {
        type: "value",
        axisLable: {
          color: "#ffffff",
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
      series: [
        {
          data: [7, 5.8, 6.6, 6.3, 7.1, 7.3, 6.7, 7.6],
          type: "line",
        },
      ],
      color: ["#556ee6"],
      textStyle: {
        color: ["#74788d"],
      },
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "186px" }} option={this.getOption()} />
      </React.Fragment>
    )
  }
}
export default Line
