/* TALKS */
export const GET_PAGINATED_NEWS_POSTS = "GET_PAGINATED_NEWS_POSTS"
export const GET_TALKS_SUCCESS = "GET_TALKS_SUCCESS"
export const GET_TALKS_FAIL = "GET_TALKS_FAIL"

export const SUBMIT_POST_LIKE_SAVE = "SUBMIT_POST_LIKE_SAVE"
export const GET_POST_LIKE_SAVE = "GET_POST_LIKE_SAVE"
export const GET_POST_FAIL = "GET_POST_FAIL"


export const GET_UNSOLVED_QUESTION = "GET_UNSOLVED_QUESTION"
export const GET_UNSOLVED_QUESTION_SUCCESS = "GET_UNSOLVED_QUESTION_SUCCESS"
export const GET_UNSOLVED_QUESTION_FAIL = "GET_UNSOLVED_QUESTION_FAIL"

export const GET_POPULAR_TOPICS = "GET_POPULAR_TOPICS"
export const GET_POPULAR_TOPICS_SUCCESS = "GET_POPULAR_TOPICS_SUCCESS"
export const GET_POPULAR_TOPICS_FAIL = "GET_POPULAR_TOPICS_FAIL"

export const GET_QUESTIONS = "GET_QUESTIONS"
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS"
export const GET_QUESTIONS_FAIL = "GET_QUESTIONS_FAIL"

export const UPVOTE_QUESTION = "UPVOTE_QUESTION"
export const UPVOTE_QUESTION_SUCCESS = "UPVOTE_QUESTION_SUCCESS"
export const UPVOTE_QUESTION_FAIL = "UPVOTE_QUESTION_FAIL"
export const CLEAR_UPVOTE_DATA= "CLEAR_UPVOTE_DATA"

export const DOWNVOTE_QUESTION = "DOWNVOTE_QUESTION"
export const DOWNVOTE_QUESTION_SUCCESS = "DOWNVOTE_QUESTION_SUCCESS"
export const DOWNVOTE_QUESTION_FAIL = "DOWNVOTE_QUESTION_FAIL"

export const GET_ANSWERS = 'GET_ANSWERS'
export const GET_ANSWERS_SUCCESS = 'GET_ANSWERS_SUCCESS'
export const GET_ANSWERS_FAIL = 'GET_ANSWERS_FAIL'

export const GET_QUESTION_COMMENTS = "GET_QUESTION_COMMENTS"
export const GET_QUESTION_COMMENTS_SUCCESS = "GET_QUESTION_COMMENTS_SUCCESS"
export const GET_QUESTION_COMMENTS_FAIL = "GET_QUESTION_COMMENTS_FAIL"

export const GET_ANSWER_COMMENTS = "GET_ANSWER_COMMENTS"
export const GET_ANSWER_COMMENTS_SUCCESS = "GET_ANSWER_COMMENTS_SUCCESS"
export const GET_ANSWER_COMMENTS_FAIL = "GET_ANSWER_COMMENTS_FAIL"

export const UPVOTE_ANSWER = "UPVOTE_ANSWER"
export const UPVOTE_ANSWER_SUCCESS = "UPVOTE_ANSWER_SUCCESS"
export const UPVOTE_ANSWER_FAIL = "UPVOTE_ANSWER_FAIL"

export const DOWNVOTE_ANSWER = "DOWNVOTE_ANSWER"
export const DOWNVOTE_ANSWER_SUCCESS = "DOWNVOTE_ANSWER_SUCCESS"
export const DOWNVOTE_ANSWER_FAIL = "DOWNVOTE_ANSWER_FAIL"

export const GET_ALL_SAVED_QUESTIONS = "GET_ALL_SAVED_QUESTIONS"
export const GET_ALL_SAVED_QUESTIONS_SUCCESS = "GET_ALL_SAVED_QUESTIONS_SUCCESS"
export const GET_ALL_SAVED_QUESTIONS_FAIL = "GET_ALL_SAVED_QUESTIONS_FAIL"

export const SAVE_QUESTION = "SAVE_QUESTION"
export const SAVE_QUESTION_SUCCESS = "SAVE_QUESTION_SUCCESS"
export const SAVE_QUESTION_FAIL = "SAVE_QUESTION_FAIL"

export const SAVE_QUESTION_COMMENT = "SAVE_QUESTION_COMMENT"
export const SAVE_QUESTION_COMMENT_SUCCESS = "SAVE_QUESTION_COMMENT_SUCCESS"
export const SAVE_QUESTION_COMMENT_FAIL = "SAVE_QUESTION_COMMENT_FAIL"

export const SAVE_ANSWER_COMMENT = "SAVE_ANSWER_COMMENT"
export const SAVE_ANSWER_COMMENT_SUCCESS = "SAVE_ANSWER_COMMENT_SUCCESS"
export const SAVE_ANSWER_COMMENT_FAIL = "SAVE_ANSWER_COMMENT_FAIL"

export const DELETE_QUESTION = "DELETE_QUESTION"
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS"
export const DELETE_QUESTION_FAIL = "DELETE_QUESTION_FAIL"

export const DELETE_ANSWER = "DELETE_ANSWER"
export const DELETE_ANSWER_SUCCESS = "DELETE_ANSWER_SUCCESS"
export const DELETE_ANSWER_FAIL = "DELETE_ANSWER_FAIL"

export const DELETE_QUESTION_COMMENT = "DELETE_QUESTION_COMMENT"
export const DELETE_QUESTION_COMMENT_SUCCESS = "DELETE_QUESTION_COMMENT_SUCCESS"
export const DELETE_QUESTION_COMMENT_FAIL = "DELETE_QUESTION_COMMENT_FAIL"

export const DELETE_ANSWER_COMMENT = "DELETE_ANSWER_COMMENT"
export const DELETE_ANSWER_COMMENT_SUCCESS = "DELETE_ANSWER_COMMENT_SUCCESS"
export const DELETE_ANSWER_COMMENT_FAIL = "DELETE_ANSWER_COMMENT_FAIL"

export const REPORT = "REPORT"
export const REPORT_SUCCESS = "REPORT_SUCCESS"
export const REPORT_FAIL = "REPORT_FAIL"

export const ACCEPT_ANSWER = "ACCEPT_ANSWER"
export const ACCEPT_ANSWER_SUCCESS = "ACCEPT_ANSWER_SUCCESS"
export const ACCEPT_ANSWER_FAIL = "ACCEPT_ANSWER_FAIL"

export const GET_SUBJECTS = "GET_SUBJECTS"
export const GET_SUBJECTS_SUCCESS = "GET_SUBJECTS_SUCCESS"
export const GET_SUBJECTS_FAIL = "GET_SUBJECTS_FAIL"


export const GET_TOPICS_BY_SUBJECT = "GET_TOPICS_BY_SUBJECT"
export const GET_TOPICS_BY_SUBJECT_SUCCESS = "GET_TOPICS_BY_SUBJECT_SUCCESS"
export const GET_TOPICS_BY_SUBJECT_FAIL = "GET_TOPICS_BY_SUBJECT_FAIL"

export const ADD_QUESTION = "ADD_QUESTION"
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS"
export const ADD_QUESTION_FAIL = "ADD_QUESTION_FAIL"

export const ADD_ANSWER = "ADD_ANSWER"
export const ADD_ANSWER_SUCCESS = "ADD_ANSWER_SUCCESS"
export const ADD_ANSWER_FAIL = "ADD_ANSWER_FAIL"

export const GET_ALL_TOPICS_BY_SUBJECT = "GET_ALL_TOPICS_BY_SUBJECT"
export const GET_ALL_TOPICS_BY_SUBJECT_SUCCESS = "GET_ALL_TOPICS_BY_SUBJECT_SUCCESS"
export const GET_ALL_TOPICS_BY_SUBJECT_FAIL = "GET_ALL_TOPICS_BY_SUBJECT_FAIL"

export const FILTER_QUESTIONS = "FILTER_QUESTIONS"
export const FILTER_QUESTIONS_SUCCESS = "FILTER_QUESTIONS_SUCCESS"
export const FILTER_QUESTIONS_FAIL = "FILTER_QUESTIONS_FAIL"

export const POST_UPDATE_ANSWER = "POST_UPDATE_ANSWER"
export const POST_UPDATE_ANSWER_SUCCESS = "POST_UPDATE_ANSWER_SUCCESS"
export const POST_UPDATE_ANSWER_FAIL = "POST_UPDATE_ANSWER_FAIL"

export const POST_UPDATE_QUESTION = "POST_UPDATE_QUESTION"
export const POST_UPDATE_QUESTION_SUCCESS = "POST_UPDATE_QUESTION_SUCCESS"
export const POST_UPDATE_QUESTION_FAIL = "POST_UPDATE_QUESTION_FAIL"

export const GET_SINGLE_QUESTION = "GET_SINGLE_QUESTION"
export const GET_SINGLE_QUESTION_SUCCESS = "GET_SINGLE_QUESTION_SUCCESS"
export const GET_SINGLE_QUESTION_FAIL = "GET_SINGLE_QUESTION_FAIL"

export const GET_REPORTED_POST_QUESTION = "GET_REPORTED_POST_QUESTION"
export const GET_REPORTED_POST_QUESTION_SUCCESS = "GET_REPORTED_POST_QUESTION_SUCCESS"
export const GET_REPORTED_POST_QUESTION_FAIL = "GET_REPORTED_POST_QUESTION_FAIL"

export const GET_REPORTED_POST_ANSWER = "GET_REPORTED_POST_ANSWER"
export const GET_REPORTED_POST_ANSWER_SUCCESS = "GET_REPORTED_POST_ANSWER_SUCCESS"
export const GET_REPORTED_POST_ANSWER_FAIL = "GET_REPORTED_POST_ANSWER_FAIL"

export const GET_REPORTED_POST_QUESTION_COMMENT = "GET_REPORTED_POST_QUESTION_COMMENT"
export const GET_REPORTED_POST_QUESTION_COMMENT_SUCCESS = "GET_REPORTED_POST_QUESTION_COMMENT_SUCCESS"
export const GET_REPORTED_POST_QUESTION_COMMENT_FAIL = "GET_REPORTED_POST_QUESTION_COMMENT_FAIL"

export const GET_REPORTED_POST_ANSWER_COMMENT = "GET_REPORTED_POST_ANSWER_COMMENT"
export const GET_REPORTED_POST_ANSWER_COMMENT_SUCCESS = "GET_REPORTED_POST_ANSWER_COMMENT_SUCCESS"
export const GET_REPORTED_POST_ANSWER_COMMENT_FAIL = "GET_REPORTED_POST_ANSWER_COMMENT_FAIL"
