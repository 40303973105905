import React from "react"

import { Col, Button } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"

const UserProfile = () => {

  return (
    <React.Fragment>
        
        <div className={"d-flex justify-content-start align-items-center"}>
            {/* <h1>User Profile</h1> */}
            <Link to="/nimorisingstar" className="font-size-14" style={{flex: '0.32'}}>
                <i className="bx bx-left-arrow-circle m-1"></i>
                <span className="align-text-bottom">Back</span>
            </Link>
        </div>
        <div className={"justify-content-center row mb-4"}>
            <Col xl={9} lg={8}>
                <div className={'swiper-container-inner'}>
                    <img
                        src={avatar1}
                        className="d-block avatar-lg rounded-circle mb-4"
                        alt="Profile Image"
                    />
                    <h2>Jessica jasmin</h2>
                    <p>Jessica jasmin@gmail.com</p>
                    <div className="d-flex justify-content-between align-items-center">
                        <Button
                            color="primary"
                            outline
                            className="btn-sm btn-primary text-white waves-effect waves-light btn-rounded w-sm me-2"
                        >
                            Active
                        </Button>
                        <Button
                            color="primary"
                            outline
                            className="btn-sm waves-effect waves-light btn-rounded w-sm"
                        >
                            Block User
                        </Button>
                    </div>
                </div>
            </Col>
        </div>
    </React.Fragment>
  )
}
export default UserProfile