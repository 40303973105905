import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Spinner,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"

//import images
import small from "../../assets/images/small/img-2.jpg"

const TopQuestions = () => {
  const [menu1, setMenu1] = useState(false)
  const [menu2, setMenu2] = useState(false)

  return (
    <React.Fragment>
    <h4>Top Question Asked</h4>
      <div className={"justify-content-center row mt-5 text-white"}>
        <Col xl={9} lg={8}>
          <Card>
            <CardBody>
              <div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <div className="d-flex">
                    <img
                      src={small}
                      className="avatar-sm me-3 rounded-circle"
                      alt="img"
                    />
                    <div>
                      <h5>
                        <Link to="blog-details" className="text-dark">
                          Jessica
                        </Link>
                      </h5>
                      <p className="text-muted mb-0">
                        Yesterday at 14:03{" "}
                        <i className="mdi mdi-earth align-middle text-muted me-1"></i>
                      </p>
                    </div>
                  </div>

                  <div>
                    <Dropdown
                      isOpen={menu1}
                      toggle={() => setMenu1(!menu1)}
                      className="float-end ms-2"
                    >
                      <DropdownToggle tag="i" className="text-muted">
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem href="#">Action</DropdownItem>
                        <DropdownItem href="#">Another action</DropdownItem>
                        <DropdownItem href="#">Something else</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to
                </p>

                <div className="d-flex justify-content-between align-items-center">
                  <span>Joe Soap and 40 others</span>
                  <span>1 comment 1 share</span>
                </div>

                <hr className="mt-2 mb-2" />

                <ul className="list-inline mb-0">
                  <li className="list-inline-item mr-3">
                    <Link to="#" className="text-muted">
                      <i className="mdi mdi-thumb-up-outline align-middle text-muted me-1"></i>{" "}
                      Like
                    </Link>
                  </li>
                  <li className="list-inline-item mr-3">
                    <Link to="#" className="text-muted">
                      <i className="mdi mdi-comment-outline align-middle text-muted me-1"></i>{" "}
                      Comment
                    </Link>
                  </li>
                  <li className="list-inline-item mr-3">
                    <Link to="#" className="text-muted">
                      <i className="mdi mdi-share-outline align-middle text-muted me-1"></i>{" "}
                      Share
                    </Link>
                  </li>
                </ul>
                {/* <div>
                    <Link to="#" className="text-primary">
                        Read more <i className="mdi mdi-arrow-right"></i>
                    </Link>
                </div> */}
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <div className="d-flex">
                    <img
                      src={small}
                      className="avatar-sm me-3 rounded-circle"
                      alt="img"
                    />
                    <div>
                      <h5>
                        <Link to="blog-details" className="text-dark">
                          Jessica
                        </Link>
                      </h5>
                      <p className="text-muted mb-0">
                        Yesterday at 14:03{" "}
                        <i className="mdi mdi-earth align-middle text-muted me-1"></i>
                      </p>
                    </div>
                  </div>

                  <div>
                    <Dropdown
                      isOpen={menu2}
                      toggle={() => setMenu2(!menu2)}
                      className="float-end ms-2"
                    >
                      <DropdownToggle tag="i" className="text-muted">
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem href="#">Action</DropdownItem>
                        <DropdownItem href="#">Another action</DropdownItem>
                        <DropdownItem href="#">Something else</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to
                </p>

                <div className="d-flex justify-content-between align-items-center">
                  <span>Joe Soap and 40 others</span>
                  <span>1 comment 1 share</span>
                </div>

                <hr className="mt-2 mb-2" />

                <ul className="list-inline mb-0">
                  <li className="list-inline-item mr-3">
                    <Link to="#" className="text-muted">
                      <i className="mdi mdi-thumb-up-outline align-middle text-muted me-1"></i>{" "}
                      Like
                    </Link>
                  </li>
                  <li className="list-inline-item mr-3">
                    <Link to="#" className="text-muted">
                      <i className="mdi mdi-comment-outline align-middle text-muted me-1"></i>{" "}
                      Comment
                    </Link>
                  </li>
                  <li className="list-inline-item mr-3">
                    <Link to="#" className="text-muted">
                      <i className="mdi mdi-share-outline align-middle text-muted me-1"></i>{" "}
                      Share
                    </Link>
                  </li>
                </ul>
                {/* <div>
                    <Link to="#" className="text-primary">
                        Read more <i className="mdi mdi-arrow-right"></i>
                    </Link>
                </div> */}
              </div>
            </CardBody>
          </Card>
          <div className={"justify-content-center row"}>
            <Spinner type="grow" className="ms-2" color="primary" />
          </div>
        </Col>
      </div>
    </React.Fragment>
  )
}

export default TopQuestions
