import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_LEADERBOARDS, GET_LEADERBOARD_SCORE } from "./actionTypes"
import { 
  getLeaderboardsSuccess, 
  getLeaderboardsFail,
  getLeaderboardScoreFail,
  getLeaderboardScoreSuccess 
} from "./actions"

//Include Both Helper File with needed methods
import { 
  GET_LEADERBOARD_QUERY,
  GET_LEADERBOARD_STUDENT
} from "helpers/url_helper"

import { get, getWithAuth } from "helpers/api_helper"


function* fetchLeaderboard({payload : options}) {
  let authUser =  JSON.parse(localStorage.getItem("authUser"));
  let currentSpace =  JSON.parse(localStorage.getItem("currentSpace"));
  let data = {
    paginateOptions: {
      page: options?.page == undefined ? 1 : options?.page,
      limit: 20,
      populate: [{ path: "student" }],
      sort: { score: "desc" }
    },
    queryVals: `{scope: 'world', space: ${currentSpace._id}}`
  }
  

  // if (props.questionFilterBy?.subjectQuery?.length > 0) {
  //   data.subjectIds = props.questionFilterBy.subjectQuery
  // }

  let body = JSON.stringify({
    query: GET_LEADERBOARD_QUERY,
    variables: {
      options: data
    },
  });

  const response =  yield call(getWithAuth, { payload: { body, history } })
  console.log('response, error.......................', response);
  if (response) {
    yield put(getLeaderboardsSuccess(response.getPaginatedLeaderboardScores))
  } else {
    yield put(getLeaderboardsFail(response.errors))
  }
}


function* fetchLeaderboardScore() {
  let authUser =  JSON.parse(localStorage.getItem("authUser"));
  let currentSpace =  JSON.parse(localStorage.getItem("currentSpace"));
  
  let body = JSON.stringify({
    query: GET_LEADERBOARD_STUDENT,
    variables: {
      options: {
        studentId: authUser?._id,
        scope: 'world',
        space: currentSpace?._id,
      }
    }
  });

  const response =  yield call(getWithAuth, { payload: { body, history } })
  //console.log('response, error.......................', response);
  if (response) {
    yield put(getLeaderboardScoreSuccess(response.getLeaderboardScores))
  } else {
    yield put(getLeaderboardScoreFail(response.errors))
  }
}


function* tasksSaga() {
  yield takeEvery(GET_LEADERBOARDS, fetchLeaderboard)
  yield takeEvery(GET_LEADERBOARD_SCORE, fetchLeaderboardScore)
}

export default tasksSaga
