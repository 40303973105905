import React, { Component, useEffect, useState, useRef } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Media,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
import Truncate from "react-truncate-html"
// Form Editor
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
//import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import CommonFunction from "common/CommonFunction"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import img1 from "../../assets/images/small/img-1.jpg"

import { withRouter, Link } from "react-router-dom"

import { connect, useSelector, useDispatch } from "react-redux"
import { uploadFile } from "helpers/UploadToServerHelper";

import img8 from "../../assets/images/camera.png"

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import {
  upvoteQuestions,
  getQuestions,
  clearUpvoteQuestionsSuccess,
  getTopicsBySubject,
  addAnswer
} from "../../store/actions"


const PhoneNumberModal = (props) => {
  const { createAccountModel,
    setCreateAccountModel,
    handleValidMobileNumber,
    closeAnswerModal,
    subjectList,
    onGetTopicsBySubject,
    topicList,
    selectQuestion,
    onPostAddAnswer,
    addAnswerSuccess,
    handleAddAnswerSuccess
  } = props;
  const [numberVal, setNumberVal] = useState('')
  const [addAnswerImage, setAddAnswerImage] = useState([])
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedTopicText, setSelectedTopicText] = useState(null);
  const [topicInput, setTopicInput] = useState(null);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  // useEffect(() => {
  //   let dataList = [];
  //   subjectList.map((item, index) => {
  //     let data = {}
  //     data.label = item.subjectName;
  //     data.value = item._id;
  //     dataList.push(data);
  //   })
  //   setSubjectArray(dataList);
  // }, [subjectList])

  const [addQuestionImage, setAddQuestionImage] = useState([])


  const onAddAnserImageChange = event => {
    //console.log('on image change.................', event);
    let dataArray = []
    let files = event.target.files
    if (files.length > 0) {
      for (let i = 0; i <= files.length - 1; i++) {
        //dataArray.push(URL.createObjectURL(event.target.files[i]))
        const file = {
          uri: event.target.files[i],
          url: URL.createObjectURL(event.target.files[i]),
          name: files[i].name,
          type: files[i].type,
        };
        dataArray.push(file);
      }
      setAddAnswerImage([...addAnswerImage, ...dataArray])
    }
  }

  const removeAddAnsImg = item => {
    //console.log('remove index....................', index);
    let dataArray = addAnswerImage
    dataArray = dataArray.filter(function (e) {
      return e !== item
    })
    setAddAnswerImage(dataArray)
  }


  const inputFile = useRef(null)

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click()
  }

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  }

  const getAsnwerDescription = () => {
    if (editorState) {
      const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      return html;
    } else {
      return null
    }
  };

  const onchangeSubject = (e) => {
    //console.log('e............', e.target.checked, e.target.value)
    if (e.target.checked) {
      setSelectedSubject(e.target.value);
    }
  }

  const onchangeTopics = (e, topic) => {
    if (e.target.checked) {
      setSelectedTopicText(topic)
      setSelectedTopic(e.target.value);
    }
  }

  const onInputTopicChange = (e) => {
    onGetTopicsBySubject(selectedSubject, e.target.value);
    setTopicInput(e.target.value);
  }

  useEffect(() => {
    if (selectedSubject != null) {
      onGetTopicsBySubject(selectedSubject, '');
    }
  }, [selectedSubject])

  const onSelectedTopicText = () => {
    setSelectedTopicText(topicInput);
    setSelectedTopic(null);
  }

  //console.log('selectedSubject............', selectedSubject);
  const handleAnswerSubmit = async() => {
    let imgArr = [];
    console.log('addAnswerImage...........', addAnswerImage);
    if (addAnswerImage?.length > 0) {
      let s_path = addAnswerImage.map( async (img) => {
        const uploadResult = await uploadFile(img);
        imgArr.push(uploadResult.location);

        if(addAnswerImage.length == imgArr.length) {

            const description = getAsnwerDescription()
            let payload = {
              description : description,
              questionId : selectQuestion?._id,
              imageUrls: imgArr
            }
            //console.log('payload-----------------',payload)
            onPostAddAnswer(payload)
        }
      })
    }

  }

  useEffect(() => {
    if (Object.keys(addAnswerSuccess).length) {
      handleAddAnswerSuccess(addAnswerSuccess);
      setEditorState(() => EditorState.createEmpty())
    }
  }, [addAnswerSuccess])

  const handleCloseAnswerModal = () => {
    setEditorState(() => EditorState.createEmpty())
    closeAnswerModal(false)
  }

  return (
    <Modal
        isOpen={createAccountModel}
        centered={true}
      >
        <div className="modal-header justify-content-center border-bottom-0" >
          <h1 className=" text-center modal-title mt-2" id="myModalLabel" style={{ margin: '0px auto', color: 'rgb(70 149 217)' }}>Create Account</h1>
          <button
            type="button"
            onClick={() => {
              setCreateAccountModel(false)
            }}
            data-dismiss="modal"
            aria-label="Close"
            style={{ right: '0', top: '10px', color: '#ffffff', backgroundColor: '#1D2226', fontSize: '18px' }}
            className="border-0"
          >
            <span aria-hidden="true">&#10006;</span>
          </button>
        </div>
        <div className="modal-body pt-5 p-2">
          <div className="text-center">
            <h3>Verification</h3>
          </div>
          <div className="p-2">
            <AvForm
              className="form-horizontal mt-3"
              onValidSubmit={(e, v) => handleValidMobileNumber(e, v)}
            >

              <div className='mb-5 d-flex justify-content-center'>
                <Row className='g-1'>
                  <Col xs="3" sm="2">
                    <AvField name="number"
                      className="form-control bg-transparent p-3 border border-white"
                      placeholder="+91"
                      value="+91"
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col xs="9" sm="10" >
                    <AvField
                      name="email"
                      className="form-control bg-transparent p-3 number-feild profile-input border border-white"
                      placeholder="Enter your 10 digit Phone number "
                      type="text"
                      required
                      onInput={(e) => {
                        setNumberVal(e.target.value);
                      }}
                      value={numberVal}
                      validate={{
                        required: { value: true },
                        pattern: { value: '^[0-9]+$' },
                        minLength: { value: 10 },
                        maxLength: { value: 10 }
                      }}
                    />
                  </Col>
                </Row>


              </div>
              <Row className="mb-3">
                <Col className="text-right pt-3">
                  <button
                    className="btn btn-rounded btn-block waves-effect waves-light"
                    type="submit"
                    style={{ backgroundColor: '#0073B1', color: '#ffffff', width: '100%' }}
                  //onClick={() => { getOtpModel() }}
                  >
                    Get OTP
                  </button>
                </Col>
              </Row>
            </AvForm>
          </div>
          <div className="modal-footer border-top-0 p-5"></div>
        </div>

      </Modal>
  )
}

const mapStateToProps = state => {
  //console.log("state ----------", state.talks)
  const { error, newsPosts, spaces, talks } = state
  return {
    talks: newsPosts,
    error: error,
    current_space: spaces.current_space,
    unsolvedQuestions: talks.unsolvedQuestions,
    questions: talks.questions,
    upvoteQuestionSuccessMessage: talks.upvoteQuestionSuccess,
    subjectList: talks.subjectsSuccess,
    topicList: talks.topics,
    addAnswerSuccess: talks.addAnswer
  }
}

const mapDispatchToProps = dispatch => ({
  onUpvoteQuestions: (question_id) => dispatch(upvoteQuestions(question_id)),
  onUpvoteQuestionsSuccess: () => dispatch(clearUpvoteQuestionsSuccess()),
  onGetQuestions: () => dispatch(getQuestions()),
  onGetTopicsBySubject: (subject_id, topicInput) => dispatch(getTopicsBySubject(subject_id, topicInput)),
  onPostAddAnswer: (data) => dispatch(addAnswer(data))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PhoneNumberModal)
)
