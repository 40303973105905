import PropTypes from 'prop-types'
import React from "react"
import { Row, Col, Card, CardBody, Progress } from "reactstrap"
import ReactApexChart from "react-apexcharts"

const MiniWidget = props => {
  return (
    <React.Fragment>
      {props.reports.map((report, key) => (
        <Col sm="4" key={key} className="nimo-sense-mini-widget">
          <Card>
            <CardBody>
              <p className='text-muted mb-2'>
                {report.leftContent}
              </p>
              <p className="text-white text-center font-weight-bold mb-4">
                {/* <i
                  className={
                    report.icon +
                    " h2 text-" +
                    report.color +
                    " align-middle mb-0 me-3"
                  }
                /> */}
                {" "}
                {report.title}{" "}
              </p>

              <Row>
                <Col xs="12">
                  <div>
                    <div className="text-right">
                      <Progress color={report.progressColor} value={report.progressValue} />
                      <span>{report.progressValue}%</span>
                    </div>
                  </div>
                </Col>
                <Col xs="6">
                  <div className='d-flex'>
                    <h6 className={ "text-" + report.color }>{report.value}</h6>
                    <p className="text-muted text-truncate mb-0">
                      {report.desc}{" "}
                      <i className={report.arrowUpDown}/>
                    </p>
                  </div>
                </Col>
                <Col xs="6">
                  <div>
                    <div>
                      <ReactApexChart
                        options={report.options}
                        series={report.series}
                        type="area"
                        height={40}
                        className="apex-charts"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  )
}

export default MiniWidget

MiniWidget.propTypes = {
  reports: PropTypes.array
}