import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"

const Status = () => {
  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardBody>
          <CardTitle className="mb-4 text-fff">
            Status of Jessica jasmin
          </CardTitle>

          <Card className="card-green">
            <CardBody>
              <CardTitle className="mb-4 text-fff">Lorem Ipsum</CardTitle>
              <p className="mb-0 text-fff">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries,
              </p>
            </CardBody>
          </Card>

          <Card className="card-yellow">
            <CardBody>
              <CardTitle className="mb-4 text-fff">Lorem Ipsum</CardTitle>
              <p className="mb-0 text-fff">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard
              </p>
            </CardBody>
          </Card>

          <Card className="card-red">
            <CardBody>
              <CardTitle className="mb-4 text-fff">Lorem Ipsum</CardTitle>
              <p className="mb-0 text-fff">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries,
              </p>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default Status