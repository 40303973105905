import React, { Component, useEffect, useState, useRef } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Media,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert
} from "reactstrap"
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
import Truncate from "react-truncate-html"
// Form Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import CommonFunction from "common/CommonFunction"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import img1 from "../../assets/images/small/img-1.jpg"

import { withRouter, Link } from "react-router-dom"

import { connect, useSelector, useDispatch } from "react-redux"

import img8 from "../../assets/images/camera.png"

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import {
  upvoteQuestions,
  getQuestions,
  clearUpvoteQuestionsSuccess,
  getTopicsBySubject
} from "../../store/actions"


const AddAnswerModal = (props) => {
  const { answerModal,
    closeAnswerModal,
    subjectList,
    onGetTopicsBySubject,
    topicList
  } = props;
  const [addAnswerImage, setAddAnswerImage] = useState([])
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedTopicText, setSelectedTopicText] = useState(null);
  const [topicInput, setTopicInput] = useState(null);

  // useEffect(() => {
  //   let dataList = [];
  //   subjectList.map((item, index) => {
  //     let data = {}
  //     data.label = item.subjectName;
  //     data.value = item._id;
  //     dataList.push(data);
  //   })
  //   setSubjectArray(dataList);
  // }, [subjectList])

  const [addQuestionImage, setAddQuestionImage] = useState([])


  const onAddAnserImageChange = event => {
    //console.log('on image change.................', event);
    let dataArray = []
    let files = event.target.files
    if (files.length > 0) {
      for (let i = 0; i <= files.length - 1; i++) {
        dataArray.push(URL.createObjectURL(event.target.files[i]))
      }
      setAddAnswerImage([...addAnswerImage, ...dataArray])
    }
  }

  const removeAddAnsImg = item => {
    //console.log('remove index....................', index);
    let dataArray = addAnswerImage
    dataArray = dataArray.filter(function (e) {
      return e !== item
    })
    setAddAnswerImage(dataArray)
  }


  const inputFile = useRef(null)

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click()
  }

  const onEditorStateChange = () => {
  }

  const onchangeSubject = (e) => {
    //console.log('e............', e.target.checked, e.target.value)
    if (e.target.checked) {
      setSelectedSubject(e.target.value);
    }
  }

  const onchangeTopics = (e, topic) => {
    if (e.target.checked) {
      setSelectedTopicText(topic)
      setSelectedTopic(e.target.value);
    }
  }

  const onInputTopicChange = (e) => {
    onGetTopicsBySubject(selectedSubject, e.target.value);
    setTopicInput(e.target.value);
  }

  useEffect(() => {
    if (selectedSubject != null) {
      onGetTopicsBySubject(selectedSubject, '');
    }
  }, [selectedSubject])

  const onSelectedTopicText = () => {
    setSelectedTopicText(topicInput);
    setSelectedTopic(null);
  }

  //console.log('selectedSubject............', selectedSubject);

  return (
    <Modal
    isOpen={answerModal}
    role="dialog"
    autoFocus={true}
    centered={true}
    className="answerModal modal-dialog modal-lg"
    tabIndex="-1"
    toggle={() => {
      setAnswerModal(!answerModal)
    }}
  >
    <div className="modal-content" id="add-answer-modal">
      <ModalBody className="p-0">
        <Card>
          <CardBody>
            <Container>
              <div className="d-flex justify-content-lg-between">
                <div className="ms-2 mt-2 mb-3">
                  <span
                    className="text-white"
                    style={{ marginLeft: "-10px" }}
                  >
                    Add your Answer
                  </span>
                </div>

                <div
                  className="text-white"
                  onClick={() => setAnswerModal(false)}
                >
                  <i className=" mdi mdi-close mt-2 fs-3 "></i>
                </div>
              </div>
              <hr />
              <p className="ms-2 mt-4 fs-7 mb-2">
                Please describe your answer
              </p>
              <Editor
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "fontFamily",
                    "list",
                    "textAlign",
                    "colorPicker",
                    "link",
                    "emoji",
                  ],
                }}
              />

              <div className="d-flex flex-wrap mt-3">
                {addAnswerImage.length > 0 &&
                  addAnswerImage.map((img, index) => {
                    return (
                      <div className="position-relative">
                        <i
                          className="mdi mdi-close add-answer-close-icon"
                          onClick={() => removeAddAnsImg(img)}
                        ></i>
                        <img
                          src={img}
                          className="add-answer-img"
                          alt=""
                        />
                      </div>
                    )
                  })}
                <input
                  type="file"
                  id="file"
                  multiple
                  onChange={onAddAnserImageChange}
                  ref={inputFile}
                  style={{ display: "none" }}
                />
                <img
                  src={img8}
                  alt=""
                  onClick={onButtonClick}
                  className="add-answer-img"
                />
              </div>
              <div className="row">
                <div className="">
                  <button
                    type="button"
                    class="btn btn-primary mt-5"
                    style={{
                      width: "100%",
                      borderRadius: "19px",
                      border: "1px solid",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Container>
          </CardBody>
        </Card>
      </ModalBody>
    </div>
  </Modal>
  )
}

const mapStateToProps = state => {
  //console.log("state ----------", state.talks)
  const { error, newsPosts, spaces, talks } = state
  return {
    talks: newsPosts,
    error: error,
    current_space: spaces.current_space,
    unsolvedQuestions: talks.unsolvedQuestions,
    questions: talks.questions,
    upvoteQuestionSuccessMessage: talks.upvoteQuestionSuccess,
    subjectList: talks.subjectsSuccess,
    topicList: talks.topics
  }
}

const mapDispatchToProps = dispatch => ({
  onUpvoteQuestions: (question_id) => dispatch(upvoteQuestions(question_id)),
  onUpvoteQuestionsSuccess: () => dispatch(clearUpvoteQuestionsSuccess()),
  onGetQuestions: () => dispatch(getQuestions()),
  onGetTopicsBySubject: (subject_id, topicInput) => dispatch(getTopicsBySubject(subject_id, topicInput))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddAnswerModal)
)
