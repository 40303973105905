import {
  GET_SAVED_QUESTIONS,
  GET_SAVED_QUESTIONS_FAIL,
  GET_SAVED_QUESTIONS_SUCCESS,
  GET_MY_ACTIVITIES,
  GET_MY_ACTIVITIES_FAIL,
  GET_MY_ACTIVITIES_SUCCESS,
  GET_STUDENT_DETAILS,
  GET_STUDENT_DETAILS_FAIL,
  GET_STUDENT_DETAILS_SUCCESS,
  GET_ALL_USERS,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL
} from "./actionTypes"

export const getSavedQuestions = () => ({
  type: GET_SAVED_QUESTIONS,
})

export const getSavedQuestionsSuccess = projects => ({
  type: GET_SAVED_QUESTIONS_SUCCESS,
  payload: projects,
})

export const getSavedQuestionsFail = error => ({
  type: GET_SAVED_QUESTIONS_FAIL,
  payload: error,
})

export const getMyActivitiesDetail = () => ({
  type: GET_MY_ACTIVITIES,
})

export const getMyActivitiesDetailSuccess = activities => ({
  type: GET_MY_ACTIVITIES_SUCCESS,
  payload: activities,
})

export const getMyActivitiesDetailFail = error => ({
  type: GET_MY_ACTIVITIES_FAIL,
  payload: error,
})


export const getStudentDetails = () => ({
  type: GET_STUDENT_DETAILS,
})

export const getStudentDetailsSuccess = details => ({
  type: GET_STUDENT_DETAILS_SUCCESS,
  payload: details,
})

export const getStudentDetailsFail = error => ({
  type: GET_STUDENT_DETAILS_FAIL,
  payload: error,
})


export const getAllusers = () => ({
  type: GET_ALL_USERS,
})

export const getAllusersSuccess = details => ({
  type: GET_ALL_USERS_SUCCESS,
  payload: details,
})

export const getAllusersFail = error => ({
  type: GET_ALL_USERS_FAIL,
  payload: error,
})

export const updateUser = (user) => ({
  type: UPDATE_USER,
  payload: user
})

export const updateUserSuccess = details => ({
  type: UPDATE_USER_SUCCESS,
  payload: details,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

