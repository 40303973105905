import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  PHONE_VERIFICATION,
  PHONE_VERIFICATION_SUCCESSFUL,
  PHONE_VERIFICATION_FAILED,
  OTP_VERIFICATION_SUCCESSFUL,
  OTP_VERIFICATION_FAILED,
  GET_AVATARS_SUCCESSFUL,
  GET_AVATARS_FAILED
} from "./actionTypes"

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  registeredUser: {},
  phoneVarificationSuccess: {},
  phoneVarificationError: [],
  otpVarificationSuccess: {},
  otpVarificationError: [],
  getAvatarSuccess: [],
  getAvatarError: {},
  
}

const account = (state = initialState, action) => {
  switch (action.type) {

    case GET_AVATARS_SUCCESSFUL:
      state = {
        ...state,
        loading: true,
        getAvatarSuccess: action.payload,
      }
      break
    case GET_AVATARS_FAILED:
      state = {
        ...state,
        loading: false,
        getAvatarError: action.payload,
      }
      break

    case REGISTER_USER:
      state = {
        ...state,
        loading: true,
        registrationError: null,
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        registeredUser: action.payload,
      }
      break
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        user: null,
        registrationError: action.payload,
      }
      break

    case PHONE_VERIFICATION_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        phoneVarificationSuccess: action.payload,
      }
      break
    case PHONE_VERIFICATION_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        phoneVarificationError: action.payload,
      }
      break
    case OTP_VERIFICATION_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        otpVarificationSuccess: action.payload,
      }
      break
    case OTP_VERIFICATION_FAILED:
      state = {
        ...state,
        loading: false,
        otpVarificationError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
