import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Alert,
  UncontrolledAlert,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { connect, useSelector, useDispatch } from "react-redux"
import { map } from "lodash"

import '../../assets/css/custom.css'

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import Talks from "./Talks"


import { getSpaces, getStudentSpaces, setCurrentSpace, joinUserSpace } from "../../store/actions"

//Import Card
import CardContact from "./card-contact"


//i18n
import { withTranslation } from "react-i18next"

const Dashboard = props => {
  //console.log('userData...........', props.userData)

  const { spaces, studentSpaces } = props;

  const dispatch = useDispatch();

  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [menuIndex, setMenuIndex] = useState(0);
  const [currentSpaceItem, setCurrentSpaceItem] = useState(null);

  const reports = [
    { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
    { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    {
      title: "Average Price",
      iconClass: "bx-purchase-tag-alt",
      description: "$16.2",
    },
  ]
  const email = [
    { title: "Week", linkto: "#", isActive: false },
    { title: "Month", linkto: "#", isActive: false },
    { title: "Year", linkto: "#", isActive: true },
  ]

  const horizontalMenu = ['foryou', 'science', 'tech', 'business', 'travel', 'sports', 'youliked', 'saved', 'option9', 'option10'];

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000);
  }, [])

  const openModal = (item, isJoined) => {
    if (isJoined) {
      setCurrentSpaceItem(item);
      dispatch(setCurrentSpace(item));
      localStorage.setItem("currentSpace", JSON.stringify(item));
      props.history.push("/dashboard");
    } else {
      //dispatch(setCurrentSpace(item));
      setCurrentSpaceItem(item);
      setmodal(true);
    }
  }

  useEffect(() => {
    dispatch(getSpaces())
    dispatch(getStudentSpaces())
  }, [props.userData]);

  const joinSpace = () => {
    dispatch(joinUserSpace(currentSpaceItem, props.history))
  }

  // useEffect(() => {

  // }, [joinSpaceSuccess])

  //console.log('props.studentSpaces............', props.studentSpaces);

  return (
    <React.Fragment>
      <div className="page-content bg-black">
        <MetaTags>
          <title>Spaces | School Web App</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          /> */}

          {/* <UncontrolledAlert style={{ background: '#B76D79', color: '#FFFFFF', border: 'none' }} className="alert-dismissible rounded-5">
            <p className="mb-1">Join spaces of your interest and start asking questions</p>
          </UncontrolledAlert> */}
          {
            studentSpaces.length > 0 &&
            <>
              <Row className='mb-3 pt-3'>
                <Col xl="12">
                  <h4 style={{ color: " #76A9FF" }}>My Spaces</h4>
                </Col>
              </Row>

              <Row>
                {map(studentSpaces, (user, key) => (
                  <CardContact item={user} key={"_user_" + key} isJoinedSpace={true} openModal={openModal} showJoinbtn={false} />
                ))}
              </Row>
            </>
          }

          <Row className='mb-2'>
            <Col xl="12">
              <h4 style={{ color: " #76A9FF" }}>All Spaces</h4>
            </Col>
          </Row>

          <Row>
            {map(spaces, (item, key) => (
              <CardContact showJoinbtn={true} item={item} key={"_user_" + key} isJoinedSpace={studentSpaces.length > 0 && studentSpaces.find(e => e._id == item._id) ? true : false} openModal={openModal} />
            ))}
          </Row>
          {/* <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-hourglass bx-spin me-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
      <Modal
        isOpen={modal}
        toggle={() => {
          setmodal(!modal)
        }}
        centered={true}
        className={'lightModal'}
      >
        <div className="modal-header justify-content-end border-bottom-0 pb-0">
          <button
            type="button"
            onClick={() => {
              setmodal(false)
            }}
            data-dismiss="modal"
            aria-label="Close"
            style={{ right: '0', top: '18px', color: '#858585', fontSize: '20px' }}
            className="border-0"
          >
            <span aria-hidden="true"><i className="mdi mdi-close" fontSize="20"></i></span>
          </button>

        </div>
        <div className="modal-body pb-3 pt-0">
          <div className="join-model-content border-top-0  text-center" style={{ border: '1px solid #ffffff', borderRadius: '5px' }}>

            <h3 className="font-weight-400" style={{ color: '#565555' }}>Awesome! 😀</h3>
            <p className="mb-4" style={{ color: '#6F6C6C', lineHeight: '30px', fontSize: '18px', paddingTop: '10px' }}>
              By joining you can not only see the <br />question, but also ask your
              doubts. We<br /> make it easy for you to ask your <br />questions.
            </p>
            <h3 className="mb-5 font-weight-400" style={{ color: '#565555', fontSize: 20 }}>
              Lets Get Started!
            </h3>
            <div className='d-flex justify-content-around align-items-center mx-3'>
              <Button
                outline
                className='model-btn'
                type="button"
                onClick={() => {
                  setmodal(!modal)
                }}
                style={{ color: '#133774', borderRadius: '100px', height: '42px' }}
              >
                Cancel
              </Button>
              <Button
                className='model-btn'
                type="button"
                color=""
                onClick={() => {
                  joinSpace()
                }}
                style={{ color: '#ffffff', background: '#133774', borderRadius: '100px', height: '42px' }}
              >
                Join Space
              </Button>
            </div>

          </div>
          <div className="modal-footer border-top-0 p-3"></div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

const mapStateToProps = (state) => ({
  users: state.contacts.users,
  userData: state.Login.userData,
  spaces: state.spaces.spaces,
  studentSpaces: state.spaces.studentSpaces,
  joinSpaceSuccess: state.spaces.joinSpaceSuccess
})

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard))
