import React, { useState } from "react"
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import "../../assets/css/custom.css"
import classnames from "../../../node_modules/classnames"

const TabComponent = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  return (
    <Row className="align-items-start pt-2 pb-2 mobile-tab-height" style={{height: '216px'}}>
      <Col md="6">
        <Nav pills className="flex-column">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                "mb-2": true,
                active: verticalActiveTab === "1",
              })}
              onClick={() => {
                toggleVertical("1")
              }}
            >
              Workload
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                "mb-2": true,
                active: verticalActiveTab === "2",
              })}
              onClick={() => {
                toggleVertical("2")
              }}
            >
              Subject clarity
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                "mb-2": true,
                active: verticalActiveTab === "3",
              })}
              onClick={() => {
                toggleVertical("3")
              }}
            >
              Career Development
            </NavLink>
          </NavItem>
        </Nav>
      </Col>
      <Col md="6">
        <TabContent
          activeTab={verticalActiveTab}
          className="text-muted mt-4 mt-md-0"
        >
          <TabPane tabId="1" style={{overflowY: 'scroll', height: '200px'}}>
            <h5>Lorem Ipsum</h5>
            <p>
              is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not
              only five centuries, but also the leap into electronic
            </p>
            <p>
              is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not
              only five centuries, but also the leap into electronic
            </p>
          </TabPane>
          <TabPane tabId="2">
            <h5>Subject clarity</h5>
            <p className="mb-0">
              {" "}
              Qui photo booth letterpress, commodo enim craft beer mlkshk
              aliquip jean shorts ullamco ad vinyl cillum PBR. Qui photo booth
              letterpress, commodo enim craft beer mlkshk aliquip jean shorts
              ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda
              labore aesthetic magna 8-bit
            </p>
          </TabPane>
          <TabPane tabId="3">
            <h5>Career Development</h5>
            <p className="mb-0">
              DIY synth PBR banksy irony. Leggings gentrify squid 8-bit cred
              pitchfork. Williamsburg banh mi whatever gluten-free. Leggings
              gentrify squid 8-bit cred pitchfork. Williamsburg banh mi whatever
              gluten-free.
            </p>
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  )
}
export default TabComponent
