import PropTypes from 'prop-types'
import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button,
  Col,
  UncontrolledTooltip,
  CardHeader,
} from "reactstrap"
import images from "assets/images"
import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"
import { whiteList } from 'xss'

const CardContact = props => {
  const { item, openModal, showJoinbtn } = props
  let imageurl = "https://school-student-app.s3.ap-south-1.amazonaws.com/";

  return (
    <React.Fragment>

      <Col xl="3" sm="6">
        <Card className="text-center bg-box border-space" style={{ borderRadius: 14, borderWidth: 3,}}>
        <CardHeader className="bg-transparent py-1 px-1">
            <div className="d-flex mt-1 justify-content-end me-2">
              <div className="d-flex">
                {/* <button class="bg-primary badge badge-secondary">Join Space</button> */}
                {
                  showJoinbtn == true && (
                  props.isJoinedSpace == true 
                  ? 
                  <button
                  type="submit"
                  className="btn-rounded padding-button py-1 px-3 joined-color joinspace-color f-10"
                  onClick={() => openModal(item,true)}
                >
                  Joined
                </button>
                  :
                  <button
                  type="submit"
                  className="btn-rounded padding-button py-1 px-3 joinspace-color f-10"
                  onClick={() => openModal(item,props.isJoinedSpace)}
                >
                  Join Space
                </button>
                  ) 
              }
              </div>
            </div>
          </CardHeader>
       
          <Link onClick={props.isJoinedSpace == true ? () => openModal(item,true) : () => openModal(item,props.isJoinedSpace)} className="text-dark">
            <CardBody className="px-2 pt-3 pb-0">
           
              {!item.bannerImageUrl ? (
                <div className="avatar-md rounded mx-auto mb-4">
                  <span
                    className={
                      "avatar-title bg-soft bg-" +
                      item.color +
                      " text-" +
                      item.color +
                      " font-size-16"
                    }
                  >
                    {item.name.charAt(0)}
                  </span>
                </div>
              ) : (
                <div className="mb-4">
                  <img
                    className="rounded avatar-md object-fit-cover"
                    src={imageurl + item.bannerImageUrl}
                    alt=""
                  />
                </div>
              )}

              <h5 className="font-size-15 mb-1">
                <Link onClick={props.isJoinedSpace == true ? () => openModal(item,true) : () => openModal(item,props.isJoinedSpace)} className="text-dark">
                  {item.name}
                </Link>
              </h5>
              <p className="pt-2 desc-60">{item.description}</p>
            </CardBody>
          </Link>
         
        </Card>
      </Col>

    </React.Fragment>
  )
}

CardContact.propTypes = {
  user: PropTypes.object
}

export default CardContact
