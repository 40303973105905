import {
  GET_SPACES,
  SET_CURRENT_SPACE,
  GET_SPACES_FAIL,
  GET_SPACES_SUCCESS,
  GET_STUDENT_SPACES,
  GET_STUDENT_SPACES_FAIL,
  GET_STUDENT_SPACES_SUCCESS,
  JOIN_SPACE,
  JOIN_SPACE_SUCCESS,
  JOIN_SPACE_FAIL,
  EXIT_SPACE,
  EXIT_SPACE_SUCCESS,
  EXIT_SPACE_FAIL,
} from "./actionTypes"

export const getSpaces = () => ({
  type: GET_SPACES,
})

export const getSpacesSuccess = spaces => ({
  type: GET_SPACES_SUCCESS,
  payload: spaces,
})

export const getSpacesFail = error => ({
  type: GET_SPACES_FAIL,
  payload: error,
})


export const getStudentSpaces = () => ({
  type: GET_STUDENT_SPACES,
})

export const getStudentSpacesSuccess = spaces => ({
  type: GET_STUDENT_SPACES_SUCCESS,
  payload: spaces,
})

export const getStudentSpacesFail = error => ({
  type: GET_STUDENT_SPACES_FAIL,
  payload: error,
})

export const setCurrentSpace = (space) => ({
  type: SET_CURRENT_SPACE,
  payload: space,
})

export const joinUserSpace = (space, history) => ({
  type: JOIN_SPACE,
  payload: { space, history },
})

export const joinSpaceSuccess = user => {
  return {
    type: JOIN_SPACE_SUCCESS,
    payload: user,
  }
}

export const joinSpaceFail = error => {
  return {
    type: JOIN_SPACE_FAIL,
    payload: error,
  }
}


export const exitSpace = (space, history) => ({
  type: EXIT_SPACE,
  payload: { space, history },
})

export const exitSpaceSuccess = user => {
  return {
    type: EXIT_SPACE_SUCCESS,
    payload: user,
  }
}

export const exitSpaceFail = error => {
  return {
    type: EXIT_SPACE_FAIL,
    payload: error,
  }
}
