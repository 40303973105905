import {
  GET_SPACES_FAIL,
  GET_SPACES_SUCCESS,
  GET_STUDENT_SPACES_FAIL,
  GET_STUDENT_SPACES_SUCCESS,
  SET_CURRENT_SPACE,
  JOIN_SPACE,
  JOIN_SPACE_SUCCESS,
  JOIN_SPACE_FAIL,
  EXIT_SPACE_SUCCESS,
  EXIT_SPACE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  spaces: [],
  studentSpaces: [],
  errorSpaces: {},
  errorStudentSpaces: {},
  current_space: {},
  loadingSpace: false,
  joinSpaceSuccess: {},
  joinSpaceFail: {},
  exitSpaceSuccess: {},
  exitSpaceFail: {}
}

const spaces = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SPACES_SUCCESS:
      return {
        ...state,
        spaces: action.payload,
      }

    case GET_SPACES_FAIL:
      return {
        ...state,
        errorSpaces: action.payload,
      }

    case GET_STUDENT_SPACES_SUCCESS:
      return {
        ...state,
        studentSpaces: action.payload,
      }

    case GET_STUDENT_SPACES_FAIL:
      return {
        ...state,
        errorStudentSpaces: action.payload,
      }

    case SET_CURRENT_SPACE:
      return {
        ...state,
        current_space: action.payload,
      }

    case JOIN_SPACE:
      state = {
        ...state,
        loadingSpace: true,
      }

    case JOIN_SPACE_SUCCESS:
      state = {
        ...state,
        joinSpaceSuccess: false,
      }

    case JOIN_SPACE_FAIL:
      state = {
        ...state,
        joinSpaceFail: false,
      }
    
      case EXIT_SPACE_SUCCESS:
        state = {
          ...state,
          exitSpaceSuccess: false,
        }
  
      case EXIT_SPACE_FAIL:
        state = {
          ...state,
          exitSpaceFail: false,
      }
  

    default:
      return state
  }
}

export default spaces
