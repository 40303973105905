//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const LOGIN = `
query Login($input: AuthInput!) {
  login(input: $input) {
    accessToken
    error
  }
}
`

export const GET_STUDENT_LOGIN = `
  query Login($input: AuthInput!) {
    login(input: $input) {
      accessToken
      error
    }
  }
`

export const UPVOTE_QUERY = `
mutation upvoteQuestion($options: voteQuestionInput!) {
  upvoteQuestion(options: $options) {
    _id
    title
  }
}
`

export const DOWNVOTE_QUERY = `
mutation downvoteQuestion($options: voteQuestionInput!) {
  downvoteQuestion(options: $options) {
    _id
    title
  }
}
`

export const BOOKMARK_QUERY = `
mutation saveQuestion($options: saveQuestionInput!) {
  saveQuestion(options: $options) {
    _id
  }
}
`

export const UNBOOKMARK_QUERY = `
mutation unsaveQuestion($options: saveQuestionInput!) {
  unsaveQuestion(options: $options) {
    _id
  }
}
`

export const GET_USER_STUDENT = `
query getStudent($options: FindUserByInput!) {
  getStudent(options: $options) {
    _id
    userName
    firstName
    lastName
    isFirstTimeLogin
    isPhoneVerified
    realProfilePicUrl
    profileDesc
    phone
    registrationType
    approvalStatusHome
    accountStatus
    countQuestions
    isRegistered
  }
}
`

export const GET_STUDENT = `
query getStudent($options: FindUserByInput!) {
  getStudent(options: $options) {
    _id
    userName
    firstName
    lastName
    isFirstTimeLogin
    isPhoneVerified
    realProfilePicUrl
    profileDesc
    phone
    registrationType
    approvalStatusHome
    accountStatus
    countQuestions
    isRegistered
    favouriteSubjects {
      _id
    }
    troublingSubjects {
      _id
    }
    troublingTopics
    leaderboardScores {
      _id
      scope
      space {
          _id
          name
      }
      score
      reputations
      commitments
      questionsAsked
      questionsAnswered
      answersAccepted
    }
    savedQuestions {
        _id
      body
      title
      tags
      imageUrls
      createdAt
      upvoteCount
      downvoteCount
      answerCount
      acceptedAnswer {
          _id
      }
      commentsCount
      schoolClass {
          _id
      }
      subject {
          _id
      }
      upvotedBy{
          _id
      }
      downvotedBy{
          _id
      }
      author{
          _id
          userName
          firstName
          lastName
          realProfilePicUrl
      }
    }
  }
}
`

export const ANSWER_QUERY = `
query getPaginatedAnswers($options: PaginationInput!) {
    getPaginatedAnswers(options: $options)  {
        totalPages
        page
        offset
        docs {
            body
            _id
            createdAt
            upvoteCount
            imageUrls
            upvotedBy {
                _id
            }
            downvotedBy {
                _id
            }
            downvoteCount
            commentsCount
            author {
                _id
                userName
                firstName
                lastName
                realProfilePicUrl
            }
            question {
              _id
            }
            comments {
                _id
                body
                createdAt
                author {
                    _id
                    userName
                    firstName
                    lastName
                    realProfilePicUrl
                }
            }
        }
    }
  }
`

export const UPVOTE_ANSWER_QUERY = `
mutation upvoteAnswer($options: VoteAnswerInput!) {
    upvoteAnswer(options: $options) {
        _id
        body
  }
}
`

export const DOWNVOTE_ANSWER_QUERY = `
mutation downvoteAnswer($options: VoteAnswerInput!) {
    downvoteAnswer(options: $options) {
        _id
        body
  }
}
`

export const DELETE_ANSWER_QUERY = `
mutation deleteAnswerById($options: FindByIdInput!) {
  deleteAnswerById(options: $options) {
        _id
  }
}
`

export const COMMENT_QUERY = `
query getPaginatedComments($options: PaginationInput!) {
    getPaginatedComments(options: $options) {
        totalPages
        page
        offset
        docs {
          _id
          body
          upvoteCount
          upvotedBy {
              _id
          }
          downvotedBy {
              _id
          }
          downvoteCount
          author{
            _id
            firstName
            lastName
            userName
            realProfilePicUrl
          }
          createdAt
        }
    }
  }
`

export const UPVOTE_COMMENT_QUERY = `
mutation upvoteComment($options: voteCommentInput!) {
  upvoteComment(options: $options) {
        body
  }
}
`

export const DOWNVOTE_COMMENT_QUERY = `
mutation downvoteComment($options: voteCommentInput!) {
  downvoteComment(options: $options) {
        body
  }
}
`

//question commets query
export const COMMENT_QUESTION_QUERY = `
query getPaginatedQuestionComments($options: PaginationInput!) 
  {
    getPaginatedQuestionComments(options: $options) 
    {
      totalPages
      page
      offset
      docs 
      {
        body
        _id
        createdAt
        upvoteCount
        upvotedBy {
            _id
        }
        question{
          _id
        }
        downvoteCount
        downvotedBy {
            _id
        }
        author{
            _id
            firstName
            lastName
            userName
            realProfilePicUrl
        }
      }
    }
  }
`

export const UPVOTE_COMMENT_QUESTION_QUERY = `
mutation upvoteQuestionComment($options: voteQuestionCommentInput!) {
  upvoteQuestionComment(options: $options) {
        body
  }
}
`

export const DOWNVOTE_COMMENT_QUESTION_QUERY = `
mutation downvoteQuestionComment($options: voteQuestionCommentInput!) {
  downvoteQuestionComment(options: $options) {
        body
  }
}
`

export const CREATE_QUESTION = `
mutation createQuestion($options: CreateQuestionInput!) {
  createQuestion(options: $options) {
    title
  }
}
`

export const CREATE_ANSWER = `
mutation createAnswer($options: CreateAnswerInput!) {
  createAnswer(options: $options) {
    _id,
    body
  }
}`

export const CREATE_COMMENT = `
mutation createComment($options: CreateCommentInput!) {
  createComment(options: $options) {
    _id,
    body
  }
}`

export const CREATE_QUESTION_COMMENT = `
mutation createQuestionComment($options: CreateQuestionCommentInput!) {
  createQuestionComment(options: $options) {
    _id,
    body
  }
}`

export const DELETE_QUESTION_QUERY = `
mutation deleteQuestionById($options: FindByIdInput!) {
  deleteQuestionById(options: $options) {
        _id
  }
}
`

export const PHONE_VERIFICATION_QUERY = `
mutation sendOTP($input: OtpInput!) {
  sendOTP(input: $input)
}
`

export const VERIFY_OTP = `
mutation verifyOTP($input: OtpInput!) {
  verifyOTP(input: $input)
}
`

export const UPDATE_STUDENT = `
mutation updateStudent($options: UpdateUserInput!) {
  updateStudent(
    options: $options
  ) {
    userName
  }
}
`

export const REPORT_QUESTION = `
mutation reportPost($options: CreatePostReportInput!) {
  reportPost(
    options: $options
  ) {
    _id
    reason
  }
}`

export const DELETE_ANSWER_COMMENT_BY_ID = `
mutation deleteCommentById($options: FindByIdInput!) {
  deleteCommentById(options: $options) {
        _id
  }
}`

export const DELETE_QUESTION_COMMENT_BY_ID = `
mutation deleteQuestionCommentById($options: FindByIdInput!) {
  deleteQuestionCommentById(options: $options) {
        _id
  }
}`

export const ACCEPT_ANSWER_QUERY = `
mutation acceptAnswer($options: AcceptAnswerInput!) {
  acceptAnswer(options: $options) {
        _id
  }
}`

export const GET_QUESTION = `
query getPaginatedQuestions($options: PaginationInput!) {
  getPaginatedQuestions(options: $options) {
    totalPages
    page
    offset
    docs {
      _id
      body
      title
      tags
      imageUrls
      createdAt
      upvoteCount
      downvoteCount
      answerCount
      commentsCount 
      schoolClass {
        _id
      }
      subject {
          _id
          subjectName
      }
      upvotedBy{
          _id
      }
      downvotedBy{
          _id
      }
      topic {
          _id
          topicName
      }
      acceptedAnswer{
        _id
      }
      author{
        _id
        userName
        firstName
        lastName
        realProfilePicUrl
      }
    }
  }
}
`

export const GET_WORLD_QUESTION_QUERY = `
query getPaginatedQuestions($options: PaginationInput!) {
  getPaginatedQuestions(options: $options) {
    totalPages
    page
    offset
    docs {
      _id
      body
      title
      topic {
        _id
        topicName
      }
      imageUrls
      createdAt
      upvoteCount
      downvoteCount
      answerCount
      commentsCount 
      subject {
          _id
          subjectName
          color
      }
      upvotedBy{
          _id
      }
      downvotedBy{
          _id
      }
      acceptedAnswer{
        _id
      }
      author{
        _id
        userName
        firstName
        lastName
        realProfilePicUrl
      }
    }
  }
}
`

export const GET_CLASSES = `
query getSchoolClasses {
  getSchoolClasses {
    className
    _id
  }
}
`

export const GET_SUBJECT = `
query getSubjects {
  getSubjects {
    subjectName
    _id
  }
}
`

export const GET_SUBJECT_BY_SPACE = `

query getSubjectsForSpace($options: FindByIdInput!) {
  getSubjectsForSpace(options: $options) {
    _id
    subjectName
  }
}
`

export const GET_REPORTED_BY_STUDENT = `
query getPaginatedQuestionReports($options: PaginationInput!) {
  getPaginatedQuestionReports(options: $options) {
    totalPages
    page
    offset
    docs {
        _id
        question {
            _id
            title
            body
        }
        student {
            userName
            _id
        }
        reason
    }
  }
}`

export const GET_REPORTED_POST_BY_STUDENT = `
query getPaginatedPostReports($options: PaginationInput!) 
{
  getPaginatedPostReports(options: $options) 
  {
    totalPages
    page
    offset
    docs 
    {
      student 
      {
        userName
        _id
      }
      post 
      {
          ...on Question 
          {
            _id
          }
          
      }
    }
  }
}`

export const GET_REPORTED_POST_ANSWER_BY_STUDENT = `
query getPaginatedPostReports($options: PaginationInput!) 
{
  getPaginatedPostReports(options: $options) 
  {
    totalPages
    page
    offset
    docs 
    {
      student 
      {
        userName
        _id
      }
      post 
      {
          ...on Answer 
          {
            _id
          }
          
      }
    }
  }
}`

export const GET_REPORTED_POST_QUESTION_COMMENT_BY_STUDENT = `
query getPaginatedPostReports($options: PaginationInput!) 
{
  getPaginatedPostReports(options: $options) 
  {
    totalPages
    page
    offset
    docs 
    {
      student 
      {
        userName
        _id
      }
      post 
      {
        ...on QuestionComment {
          _id
        }
          
      }
    }
  }
}`

export const GET_REPORTED_POST_ANSWER_COMMENT_BY_STUDENT = `
query getPaginatedPostReports($options: PaginationInput!) 
{
  getPaginatedPostReports(options: $options) 
  {
    totalPages
    page
    offset
    docs 
    {
      student 
      {
        userName
        _id
      }
      post 
      {
        ...on Comment {
          _id
        }
          
      }
    }
  }
}`

export const GET_SEARCH_QUESTION = `
query searchQuestions($options: SearchInput!) {
  searchQuestions(options: $options)  {
    docs {
      createdAt
      body
      tags
      title
      _id
      imageUrls
      author {
          _id
          userName
          realProfilePicUrl
      }
    }
  }
}
`

export const GET_SEARCH_ANSWER = `
query searchAnswers($options: SearchInput!) {
  searchAnswers(options: $options)  {
    docs {
      body
      _id
      question {
          _id
          title
          body
          upvotedBy { 
              _id
          }
          downvoteCount
          upvoteCount
          downvotedBy {
              _id
          }
          imageUrls
          author {
              _id
          userName
          realProfilePicUrl
          }
      }
      author {
          _id
          userName
          realProfilePicUrl
      }
    }
  }
}`

export const GET_STUDENT_ACTIVITIES = `
query getPaginatedStudentActivities($options: PaginationInput!) {
  getPaginatedStudentActivities(options: $options)  
  {
    totalPages
    page
    offset
    docs {
      _id
      createdAt
      activityType
      student {
          firstName
          lastName
          userName
      }
      data {
          ... on Question {
              _id
              title
              body
              tags
          }
          ... on Answer {
              body
              question {
                  _id
              }
          }
          ... on Comment {
              body
              question {
                  _id
              }
          }
          ... on QuestionComment {
              body
              question {
                  _id
              }
          }
      }
    }
  }
}
`

export const UPDATE_QUESTION = `
mutation updateQuestion($options: UpdateQuestionInput!) {
  updateQuestion(options: $options) {
    _id
    title
  }
}
`

export const UPDATE_ANSWER = `
mutation updateAnswer($options: UpdateAnswerInput!) {
  updateAnswer(options: $options) {
    _id
  }
}
`

export const GET_AVATARS_QUERY = `
query {
  getAvatars
}
`

export const SAVE_TOKEN = `
mutation saveDeviceToken($options: saveDeviceTokenInput!) {
  saveDeviceToken(options: $options)
}
`
export const GET_COUNTRY = `query {
  getAllCountries
}
`

export const GET_STATE = `
query {
  getAllStatesByCountry($options: JSON!) {
    getAllStatesByCountry(options: $options)
  }
}`

export const GET_LEADERBOARD_QUERY = `
  query getPaginatedLeaderboardScores($options: PaginationInput!) {
    getPaginatedLeaderboardScores(options: $options) 
    {

      totalPages
      page
      offset
      docs {
          _id
          student {
              _id
              userName
              firstName
              lastName
              realProfilePicUrl
          }
          reputations
          commitments
          questionsAsked
          questionsAnswered
          answersAccepted
          score
      }
    }
  }
`

export const GET_LEADERBOARD_STUDENT = `
  query getLeaderboardScores($options: getLeaderboardScoresWithRankInput!) {
    getLeaderboardScores(options: $options) 
    {
      leaderboardscores {
        score
        reputations
        commitments
        questionsAsked
        questionsAnswered
        answersAccepted
      }
      rank
    }
  }
`

export const GET_PAGINATED_SCHOOL = `
query getPaginatedSchools($options: PaginationInput!) {
  getPaginatedSchools(options: $options) {
      totalPages
      page
      offset
      docs {
          _id
          schoolName
      }
  }
}`

export const CREATE_STUDENT = `
mutation createStudent($options: CreateUserInput!) {
  createStudent(options: $options){
    _id
    userName
  }
}
`

export const GET_SUBJECT_TOPICS = `
query getSubjectTopics($options: GetSubjectTopicsInput!) {
  getSubjectTopics(options: $options)
}
`

export const RESET_PASSWORD = `
mutation changePassword($input: ChangePasswordInput!) {
  changePassword(input: $input)
}
`

export const GET_SPACE = `
query getSpaces($options: getSpacesInput!) {
  getSpaces(options: $options) {
    _id
    name
    description
    scope
    bannerImageUrl
}
}
`

export const JOIN_SPACE_QUERY = `
mutation joinSpace($options: JoinLeaveSpaceInput!) {
  joinSpace(options: $options) 
  {
    _id
    name
    description
  }
}`

export const LEAVE_SPACE = `
mutation leaveSpace($options: JoinLeaveSpaceInput!) {
  leaveSpace(options: $options) 
  {
    _id
    name
    description
  }
}`

export const GET_STUDENT_SPACE = `
query getStudent($options: FindUserByInput!) {
  getStudent(options: $options) {
      spaces {
          _id
          name
          description
          bannerImageUrl
      }
  }
}`

export const GET_QUESTION_SEARCH = `
    query searchQuestions($options: ElasticSearchInput!) {
      searchQuestions(options: $options) {
      hits {
          ...on Question {
              _id
              title
              body
              tags
              acceptedAnswer {
                _id
              }
              answerCount
              subject {
                _id
                subjectName
                color
              }
              topic {
                _id
                topicName
              }
              author {
                _id
                  userName
                  firstName
                  lastName
                  realProfilePicUrl
              }
              obType
              scope
              space {
                  name
              }
              upvotedBy {
                  _id
              }
              downvotedBy {
                  _id
              }
              upvoteCount
              downvoteCount
              commentsCount
              imageUrls
              createdAt
          }
      }
      total
  }
}`

export const GET_ANSWER_SEARCH = `
query searchAnswers($options: ElasticSearchInput!) {
  searchAnswers(options: $options) {
      hits {
          ...on Answer {
              _id
              body
              author {
                _id
                  userName
                  firstName
                  lastName
                  realProfilePicUrl
              }
              obType
              createdAt
              question {
                  _id
                  title
                  body
                  answerCount
                  tags
                  author {
                      userName
                      realProfilePicUrl
                  }
                  upvotedBy {
                      _id
                  }
                  downvotedBy {
                      _id
                  }
                  upvoteCount
                  downvoteCount
                  commentsCount
                  subject {
                      _id
                      subjectName
                      color
                  }
                  topic {
                      _id
                      topicName
                  }
              }
          }
      }
      total
  }
}`

export const GET_NOTIFICATIONS = `
query getPaginatedNotifications($options: PaginationInput!) {
  getPaginatedNotifications(options: $options) 
  {
      totalPages
      page
      offset
      docs {
          createdAt
          title
          body
          type
        #   notifyTo {
        #       _id
        #       userName
        #       realProfilePicUrl
        #   }
          payload {
              ...on AnswerPayload {
                  type
                answerBody
                answerAuthorName
                questionTitle
                answerAuthor {
                    _id
                    userName
                }
                answer {
                    _id
                    question {
                        _id
                    }
                }
              }
              ...on QuestionPayload {
                  type
                  questionAuthorName
                  questionAuthor {
                      _id
                  }
                  question {
                      _id
                  }
              }
              ...on CommentPayload {
                  type
                  answer {
                      _id
                      question {
                          _id
                      }
                  }
                  comment {
                      _id
                      question {
                          _id
                      }
                  }
                  commentAuthorName
                  commentAuthor {
                      _id
                  }
              }
              ...on QuestionCommentPayload {
                  type
                    question {
                        _id
                    }
                  questionComment {
                      _id
                      question {
                          _id
                      }
                  }
                  commentAuthorName
                  commentAuthor {
                      _id
                  }
              }
              ...on VotePayload {
                  type
                  question {
                      _id
                  }
                  answer {
                      _id
                  }
                  comment {
                      _id
                  }
                  questionComment {
                      _id
                  }
              }
          }
      }
  
  }
}
`

export const VERIFY_USERNAME = `
query checkUserName($options: checkUserNameInput!) {
  checkUserName(options: $options) 
}`

export const GET_QUESTION_WITHOUT_AUTH = `
  query getQuestionById($options: FindByIdInput!) {
    getQuestionById(options: $options) {
      _id
        body
        title
        topic {
          _id
          topicName
        }
        imageUrls
        createdAt
        upvoteCount
        downvoteCount
        answerCount
        commentsCount 
        subject {
            _id
            subjectName
            color
        }
        upvotedBy{
            _id
        }
        downvotedBy{
            _id
        }
        acceptedAnswer{
          _id
        }
        author{
          _id
          userName
          firstName
          lastName
          realProfilePicUrl
        }
  }
}`

export const GET_NOTIFY_QUESTION = `
  query getQuestionByIdWithUserAuthorization($options: getQuestionByIdWithUserAuthenticationInput!) {
    getQuestionByIdWithUserAuthorization(options: $options) {
      question {
        _id
        body
        title
        topic {
          _id
          topicName
        }
        imageUrls
        createdAt
        upvoteCount
        downvoteCount
        answerCount
        commentsCount 
        subject {
            _id
            subjectName
            color
        }
        upvotedBy{
            _id
        }
        downvotedBy{
            _id
        }
        acceptedAnswer{
          _id
        }
        author{
          _id
          userName
          firstName
          lastName
          realProfilePicUrl
        }
      }
      isAuthorizedToView
      isAuthorizedToInteract
  }
}`

export const GET_NOTIFY_ANSWER = `
  query getAnswerByIdWithUserAuthorization($options: getAnswerByIdWithUserAuthenticationInput!) {
    getAnswerByIdWithUserAuthorization(options: $options) {
      answer {
        _id
        body
        question {
          _id
        }
        imageUrls
        author {
            _id
            phone
            userName
            firstName
            lastName
        }
      }
      isAuthorizedToView
      isAuthorizedToInteract
  }
}`

export const GET_UNSOLVED_QUESTION_QUERY = `
  query getUnsolvedQuestions($options: unsolvedQuestionsInput!) {
    getUnsolvedQuestions(options: $options) {
      _id
      body
      title
      topic {
        _id
        topicName
      }
      imageUrls
      createdAt
      upvoteCount
      downvoteCount
      answerCount
      commentsCount 
      subject {
          _id
          subjectName
          color
      }
      upvotedBy{
          _id
      }
      downvotedBy{
          _id
      }
      acceptedAnswer{
        _id
      }
      author{
        _id
        userName
        firstName
        lastName
        realProfilePicUrl
      }
  }
}`

export const GET_POPULAR_TOPICS_QUERY = `
query getPopularTopics($options: GetPopularTopicsInput!) {
  getPopularTopics(options: $options) {
      _id
      topicName
      imageS3Url
      subject {
          _id
          subjectName
      }
  }
}`

export const SEARCH_POPULAR_TOPICS_BY_STRING = `
query searchTopics($options: ElasticSearchInput!) {
  searchTopics(options: $options) {
      hits {
          ...on Topic {
              _id
              topicName
              topicType
              subject {
                  _id
              }
          }
      }
      total
  }
}`

export const CREATE_TOPIC = `
mutation createTopic($options: CreateTopicInput!) {
  createTopic(options: $options) 
  {
    _id
  }
}`

export const GET_USER_BY_STR = `
query searchUsers($options: ElasticSearchInput!) {
  searchUsers(options: $options) {
      hits {
          ...on Student {
              userName
              realProfilePicUrl
              _id
          }
      }
      total
  }
}`

export const FILTER_SAVED_QUESTION = `
    query getPaginatedSavedQuestions($options: getPaginatedSavedQuestionsInput!) {
      getPaginatedSavedQuestions(options: $options) {
      page
      totalPages
      docs {
        _id
        body
        title
        topic {
          _id
          topicName
        }
        imageUrls
        createdAt
        upvoteCount
        downvoteCount
        answerCount
        commentsCount 
        subject {
            _id
            subjectName
            color
        }
        upvotedBy{
            _id
        }
        downvotedBy{
            _id
        }
        acceptedAnswer{
          _id
        }
        author{
          _id
          userName
          firstName
          lastName
          realProfilePicUrl
        }
      }
  }
}`

export const GET_PRESIGNED_S3_URL = `query getPresignedS3Url{
      getPresignedS3Url
    }
  `

//LOGIN
export const API_URL = process.env.REACT_APP_API_BASE_URL
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
