import React from "react"
import {
  Card,
  CardBody
} from "reactstrap"

function StudentSettingsTab(props) {
  return (
    <>
      <div>
        <Card>
          <CardBody className="pb-5 pt-5">
            <h5 className="font-size-14 mb-3">Student settings</h5>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default StudentSettingsTab
