import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect, useCallback } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Spinner,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  CardTitle,
} from "reactstrap"
import { withRouter, Link } from "react-router-dom"
import { connect, useSelector, useDispatch } from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"

// actions
import { getPaginatedNewsposts, submitPostLikeSave, getTalksSuccess } from "../../store/actions"

//import classnames from "classnames"
import "../../assets/css/custom.css"

//import images
import small from "../../assets/images/small/img-2.jpg"
import small2 from "../../assets/images/small/img-6.jpg"

const Talks = props => {
  const dispatch = useDispatch()
  const [activeTab, toggleTab] = useState("1")
  const [isEffects, setisEffects] = useState(false)
  const [menu, setMenu] = useState("")
  const [middleContentHeight, setMiddleContentHeight] = useState("")
  const [newsPost, setNewsPost] = useState([])
  const [showDropdown, setShowDropDown] = useState(false)
  const [hasMorePosts, setHasMorePosts] = useState(true)

  const {
    getNewsPostsData,
    getLikeUpdateData,
    getSaveUpdateData,
  } = useSelector(state => {
    return {
      getNewsPostsData:
        state.talks &&
        state.talks.newsPosts &&
        state.talks.newsPosts.getPaginatedNewsposts
          ? state.talks.newsPosts.getPaginatedNewsposts
          : "",
      getLikeUpdateData:
        state.talks &&
        state.talks.postLikeSave &&
        state.talks.postLikeSave.data &&
        state.talks.postLikeSave.data.likeNewspost
          ? state.talks.postLikeSave.data.likeNewspost
          : "",
      getSaveUpdateData:
        state.talks &&
        state.talks.postLikeSave &&
        state.talks.postLikeSave.data &&
        state.talks.postLikeSave.data.saveNewspost
          ? state.talks.postLikeSave.data.saveNewspost
          : "",
    }
  })

  const toggle = tab => {
    if (activeTab !== tab) toggleTab(tab)
  }

  useEffect(() => {
    //componentDidMount()
    let currentPage = 0
    let totalPages = 0
    let pageData = { currentPage, totalPages }

    dispatch(getPaginatedNewsposts(pageData))
  }, [])

  const componentDidMount = () => {
    const height = document.getElementById("dashboard-right-sidebar")
      .clientHeight
    setMiddleContentHeight(height - 25)
  }

  useEffect(() => {
    if (newsPost && newsPost.length > 0) {
        let newPostData = getNewsPostsData.docs
        let mergePostsData = [...newsPost, ...newPostData]
        setNewsPost(mergePostsData)

        let postsData = props.talks ? props.talks : [];
        if (postsData) {
            postsData.getPaginatedNewsposts.docs = mergePostsData;
            dispatch(getTalksSuccess(postsData))
        }
    } else {
        setNewsPost(getNewsPostsData.docs)
    }
  }, [getNewsPostsData])

  const submitLike = (postId, actionType) => {
    let likeSaveData = { postId, actionType }
    dispatch(submitPostLikeSave(likeSaveData))
  }

  useEffect(() => {
    if (getLikeUpdateData != "") {
        let postId = getLikeUpdateData._id
        let likedStatus = getLikeUpdateData.liked
        let postsData = props.talks ? props.talks : [];
        if (postsData) {
            let pIndex = postsData.getPaginatedNewsposts.docs.findIndex(x => x._id === postId);
            postsData.getPaginatedNewsposts.docs[pIndex].liked = likedStatus;

            dispatch(getTalksSuccess(postsData))
        }
    //   let pageData = { currentPage, totalPages }
    //   dispatch(getPaginatedNewsposts(pageData))
    }
  }, [getLikeUpdateData])

  const submitSave = (postId, actionType) => {
    let likeSaveData = { postId, actionType }
    dispatch(submitPostLikeSave(likeSaveData))
  }

  useEffect(() => {
    if (getSaveUpdateData != "") {
      let postId = getSaveUpdateData._id
      let savedStatus = getSaveUpdateData.saved
      let postsData = props.talks ? props.talks : [];
      if (postsData) {
        let pIndex = postsData.getPaginatedNewsposts.docs.findIndex(x => x._id === postId);
        postsData.getPaginatedNewsposts.docs[pIndex].saved = savedStatus;

        dispatch(getTalksSuccess(postsData))
      }
    }
  }, [getSaveUpdateData])

  const setMenuIdToOpen = postId => {
    setMenu(postId)
    setShowDropDown(!showDropdown)
  }

  const loadNewsPosts = useCallback(() => {
    if (getNewsPostsData.page >= getNewsPostsData.totalPages) {
        console.log('inside if ----------------------------------')
      setHasMorePosts(false)
    } else {
      let currentPage = getNewsPostsData.page
      let totalPages = getNewsPostsData.totalPages
      let pData = { currentPage, totalPages }
      console.log('inside else ---------------------------- ',pData)
      setTimeout(() => {
        dispatch(getPaginatedNewsposts(pData))
        setHasMorePosts(true)
      }, 1000)
    }
  }, [newsPost])

  return (
    <React.Fragment>
      <InfiniteScroll
        dataLength={newsPost ? newsPost.length : 0}
        next={loadNewsPosts}
        hasMore={hasMorePosts}
        //hasMore={hasMore}
        loader={
          <div className={"justify-content-center row"}>
            <Spinner type="grow" className="ms-2" color="primary" />
          </div>
        }
      >
        <div className={"justify-content-center row"}>
          <Col
            xl={9}
            lg={8}
            className="dashboard-middle-content"
            style={{ height: middleContentHeight }}
          >
            {isEffects ? (
              <Lightbox
                mainSrc={small}
                enableZoom={true}
                onCloseRequest={() => {
                  setisEffects(!isEffects)
                }}
              />
            ) : null}
            {props.talks
              && props.talks.getPaginatedNewsposts 
              && props.talks.getPaginatedNewsposts.docs 
              && props.talks.getPaginatedNewsposts.docs.length > 0
              ? props.talks.getPaginatedNewsposts.docs.map((post, index) => {
                  return (
                    <Card key={post._id}>
                      <CardTitle
                        className="mt-0 mb-3 mt-3 d-flex justify-content-between"
                        style={{ padding: "10px 20px 0px 20px" }}
                      >
                        <h5>{post.title}</h5>
                        <Dropdown
                          isOpen={menu == post._id ? showDropdown : false}
                          toggle={() => setMenuIdToOpen(post._id)}
                          className="float-end ms-2"
                        >
                          <DropdownToggle tag="i" className="text-muted">
                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem href="#">Action</DropdownItem>
                            <DropdownItem href="#">Another action</DropdownItem>
                            <DropdownItem href="#">Something else</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </CardTitle>
                      <CardBody className="pt-0">
                        <div>
                          <p
                            className="text-white"
                            style={{ paddingRight: "20px" }}
                          >
                            {post.body}
                          </p>

                          <hr className="position-relative mt-4" />

                          <ul className="list-inline mb-0">
                            <li className="list-inline-item mr-3">
                              <Link
                                to="#"
                                className="text-muted"
                                onClick={() => {
                                  submitLike(post._id, "LIKE")
                                }}
                              >
                                <i
                                  className={`bx ${
                                    post.liked ? "bxs-heart" : "bx-heart"
                                  } align-middle text-muted me-1`}
                                ></i>{" "}
                                {post.liked ? "Liked" : "Like"}
                              </Link>
                            </li>
                            <li className="list-inline-item mr-3">
                              <Link
                                to="#"
                                className="text-muted"
                                onClick={() => {
                                  submitSave(post._id, "SAVE")
                                }}
                              >
                                <i className={`bx ${ post.saved ? "bxs-download" : "bx-download" } align-middle text-muted me-1`}
                                ></i>{" "}
                                { post.saved ? "Saved" : "Save" }
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </CardBody>
                    </Card>
                  )
                })
              : ""}
          </Col>
        </div>
      </InfiniteScroll>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  //console.log('state ----------',state)
  const { error, newsPosts } = state.talks
  return {
    talks : newsPosts,
    error: error
  }
}

export default withRouter(
  connect(mapStateToProps, { getPaginatedNewsposts, submitPostLikeSave })(Talks)
)

//export default Talks;
