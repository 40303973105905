/* PROJECTS */
export const GET_SPACES = "GET_SPACES"
export const GET_SPACES_SUCCESS = "GET_SPACES_SUCCESS"
export const GET_SPACES_FAIL = "GET_SPACES_FAIL"

export const GET_STUDENT_SPACES = "GET_STUDENT_SPACES"
export const GET_STUDENT_SPACES_SUCCESS = "GET_STUDENT_SPACES_SUCCESS"
export const GET_STUDENT_SPACES_FAIL = "GET_STUDENT_SPACES_FAIL"

export const SET_CURRENT_SPACE = "SET_CURRENT_SPACE"

export const JOIN_SPACE = "JOIN_SPACE"
export const JOIN_SPACE_SUCCESS= "JOIN_SPACE_SUCCESS"
export const JOIN_SPACE_FAIL = "JOIN_SPACE_FAIL"

export const EXIT_SPACE = "EXIT_SPACE"
export const EXIT_SPACE_SUCCESS= "EXIT_SPACE_SUCCESS"
export const EXIT_SPACE_FAIL = "EXIT_SPACE_FAIL"


