import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from "react"

import { connect } from "react-redux"
import { Row, Col } from "reactstrap"

import { useLocation } from 'react-router-dom'

import { Link } from "react-router-dom"

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import megamenuImg from "../../assets/images/megamenu-img.png"
import logo from "../../assets/images/logo.svg"
import logoLightPng from "../../assets/images/logo-light.png"
import logoLightSvg from "../../assets/images/logo-light.svg"
import logoDark from "../../assets/images/logo-dark.png"

// import images
import github from "../../assets/images/brands/github.png"
import bitbucket from "../../assets/images/brands/bitbucket.png"
import dribbble from "../../assets/images/brands/dribbble.png"
import dropbox from "../../assets/images/brands/dropbox.png"
import mail_chimp from "../../assets/images/brands/mail_chimp.png"
import slack from "../../assets/images/brands/slack.png"

//i18n
import { withTranslation } from "react-i18next"

import logoWhite from "../../assets/images/anonimo-logo-white.png"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  filterQuestions,
  searchFilter,
  searchQuestions,
  searchAnswers,
  searchQuestionsSuccess,
  searchAnswersSuccess
} from "../../store/actions"

const Header = props => {
  const {
    searchFilterQuery,
    onSearchFilterQuery,
    onSearchQuestion,
    onSearchAnswers,
    onSearchQuestionsSuccess,
    onSearchAnswersSuccess
  } = props;
  const [searchInput, setSearchInput] = useState('')
  const [megaMenu, setmegaMenu] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)
  const searchInputRef = useRef();

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)


  useEffect(() => {
    if(location.pathname == "/search") {
    searchInputRef.current.focus();
    }
  }, []);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    props.toggleLeftmenu(!props.leftMenu)
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile)
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile)
    }
  }

  const goToSearchScreen = () => {
    if(location.pathname == "/search") {
      
    } else {
      window.location.href = "/search"
    }
  }

  useEffect(() => {
    if(searchInput != '') {
      let data = searchFilterQuery;
      data.searchText = searchInput;
      onSearchQuestion(searchFilterQuery);
      //onSearchAnswers(searchFilterQuery);
      onSearchFilterQuery(data);
    } else {
      onSearchQuestionsSuccess([]);
      //onSearchAnswersSuccess([]);
    }
  }, [searchInput])

  const clearFilter = ()  => {
    let d = searchFilterQuery;
    onSearchQuestionsSuccess([]);
    //onSearchAnswersSuccess([]);
    setSearchInput('');

    d.searchText = '';
    onSearchFilterQuery(
      d
    )
  }

  return (
    <React.Fragment>
      <header id="page-topbar" className={props.isSpaceScreen ? "page-top-space" : ''}>
        <div className="navbar-header">
        {props.isSpaceScreen ? <img
            src={logoWhite}
            alt=""
            className="navbar-img"
          /> : null}
          <div className="d-flex">

            {
              !props.isSpaceScreen &&
              <>
                {/* <button
                  type="button"
                  onClick={() => {
                    tToggle()
                  }}
                  className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                  id="vertical-menu-btn"
                >
                  <i className="fa fa-fw fa-bars" />
                </button> */}

                <div className="app-search d-none d-lg-block wid-search mx-3">
                  <div className="position-relative d-flex search-container">
                    <input
                      type="text"
                      className="form-control btn-search"
                      placeholder={"Search..."}
                      ref={searchInputRef}
                      onFocus={goToSearchScreen}
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                    {
                      searchInput != '' ?
                      <i className="mdi mdi-close search-icon" onClick={clearFilter} />
                      :
                      <i className="bx bx-search-alt search-icon"/>
                    }
                  </div>

                </div>
              </>
            }

          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search)
                }}
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
                <i className="mdi mdi-close" />
              </button>
              <div
                className={
                  searchInput
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                {/* <form className="p-3"> */}
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Search"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          {/* <i className="mdi mdi-magnify" /> */}
                          <i className="mdi mdi-close" />
                        </button>
                      </div>
                    </div>
                  </div>
                {/* </form> */}
              </div>
            </div>

            {/* <LanguageDropdown />

            <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp)
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon waves-effect"
                tag="button"
              >
                <i className="bx bx-customize" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg" right>
                <div className="px-lg-2">
                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={github} alt="Github" />
                        <span>GitHub</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={bitbucket} alt="bitbucket" />
                        <span>Bitbucket</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dribbble} alt="dribbble" />
                        <span>Dribbble</span>
                      </Link>
                    </Col>
                  </Row>

                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dropbox} alt="dropbox" />
                        <span>Dropbox</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={mail_chimp} alt="mail_chimp" />
                        <span>Mail Chimp</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={slack} alt="slack" />
                        <span>Slack</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown> */}

            {/* <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div> */}
            {
              !props.isSpaceScreen &&
              <NotificationDropdown />
            }
            <ProfileMenu isSpaceScreen ={props.isSpaceScreen} />

            {/* <div
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar)
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle waves-effect"
              >
                <i className="bx bx-cog bx-spin" />
              </button>
            </div> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const { error, newsPosts, spaces, talks, searches } = state
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout

  const {
    current_space
  } = state.spaces
  return {
    searchFilterQuery: searches.searchFilterQuery,
    layoutType: layoutType, 
    showRightSidebar: showRightSidebar, 
    leftMenu: leftMenu, 
    leftSideBarType: leftSideBarType, 
    current_space: current_space
  }
}

const mapDispatchToProps = dispatch => ({
  onFilterQuestions: (data) => dispatch(filterQuestions(data)),
  onSearchFilterQuery: (data) => dispatch(searchFilter(data)),
  onSearchQuestion : (data) => dispatch(searchQuestions(data)),
  onSearchAnswers : (data) => dispatch(searchAnswers(data)),
  onSearchQuestionsSuccess: (data) => dispatch(searchQuestionsSuccess([])),
  onSearchAnswersSuccess: (data) => dispatch(searchAnswersSuccess([]))
})

export default connect(mapStatetoProps, mapDispatchToProps)(Header)
