import { call, put, takeEvery } from "redux-saga/effects"

import store from "store"

// Talks Redux States
import {
  GET_PAGINATED_NEWS_POSTS,
  SUBMIT_POST_LIKE_SAVE,
  GET_UNSOLVED_QUESTION,
  GET_POPULAR_TOPICS,
  GET_QUESTIONS,
  UPVOTE_QUESTION,
  DOWNVOTE_QUESTION,
  UPVOTE_ANSWER,
  DOWNVOTE_ANSWER,
  GET_ANSWERS,
  GET_ANSWER_COMMENTS,
  GET_QUESTION_COMMENTS,
  GET_ALL_SAVED_QUESTIONS,
  SAVE_QUESTION,
  SAVE_QUESTION_COMMENT,
  SAVE_ANSWER_COMMENT,
  DELETE_ANSWER,
  DELETE_ANSWER_COMMENT,
  DELETE_QUESTION,
  DELETE_QUESTION_COMMENT,
  REPORT,
  ACCEPT_ANSWER,
  GET_SUBJECTS,
  GET_TOPICS_BY_SUBJECT,
  ADD_QUESTION,
  ADD_ANSWER,
  GET_ALL_TOPICS_BY_SUBJECT,
  FILTER_QUESTIONS,
  POST_UPDATE_ANSWER,
  POST_UPDATE_QUESTION,
  GET_SINGLE_QUESTION,
  GET_REPORTED_POST_QUESTION,
  GET_REPORTED_POST_ANSWER,
  GET_REPORTED_POST_QUESTION_COMMENT,
  GET_REPORTED_POST_ANSWER_COMMENT
} from "./actionTypes"
import {
  getTalksSuccess,
  getTalksFail,
  getPostLikeSaveSuccess,
  getPostFail,
  getUnsolvedQuestionFail,
  getUnsolvedQuestionSuccess,
  upvoteQuestionsSuccess,
  upvoteQuestionsFail,
  downvoteQuestionsSuccess,
  downvoteQuestionsFail,
  getAnswersFail,
  getAnswersSuccess,
  getAnswerCommentsFail,
  getAnswerCommentsSuccess,
  getQuestionCommentsFail,
  getQuestionCommentsSuccess,
  upvoteAnswersSuccess,
  downvoteAnswersSuccess,
  upvoteAnswersFail,
  downvoteAnswersFail,
  getAllSavedQuestionsSuccess,
  getAllSavedQuestionsFail,
  saveQuestionCommentSuccess,
  saveQuestionCommentFail,
  saveAnswerCommentFail,
  saveAnswerCommentSuccess,
  deleteQuestionSuccess,
  deleteQuestionFail,
  deleteAnswerFail,
  deleteAnswerSuccess,
  deleteQuestionCommentSuccess,
  deleteQuestionCommentFail,
  deleteAnswerCommentSuccess,
  deleteAnswerCommentFail,
  reportSuccess,
  reportFail,
  acceptAnswerSuccess,
  acceptError,
  getSubjectsSuccess,
  getSubjectsFail,
  getTopicsBySubjectSuccess,
  getTopicsBySubjectFail,
  addQuestionSuccess,
  addQuestionFail,
  getAllTopicsBySubjectSuccess,
  getAllTopicsBySubjectFail,
  filterQuestionsSuccess,
  filterQuestionsFail,
  addAnswerSuccess,
  addAnswerFail,
  updateAnswerSuccess,
  updateAnswerFail,
  updateQuestionSuccess,
  updateQuestionFail,
  getSingleQuestionSuccess,
  getSingleQuestionFail,
  getReportedPostQuestionSuccess,
  getReportedPostQuestionFail,
  getReportedPostAnswerSuccess,
  getReportedPostAnswerFail,
  getReportedPostQuestionCommentSuccess,
  getReportedPostQuestionCommentFail,
  getReportedPostAnswerCommentSuccess,
  getReportedPostAnswerCommentFail
} from "./actions"
import {
  GET_UNSOLVED_QUESTION_QUERY,
  GET_POPULAR_TOPICS_QUERY,
  GET_WORLD_QUESTION_QUERY,
  ANSWER_QUERY,
  UPVOTE_QUERY,
  DOWNVOTE_QUERY,
  COMMENT_QUESTION_QUERY,
  UPVOTE_ANSWER_QUERY,
  DOWNVOTE_ANSWER_QUERY,
  COMMENT_QUERY,
  UNBOOKMARK_QUERY,
  BOOKMARK_QUERY,
  CREATE_QUESTION_COMMENT,
  CREATE_COMMENT,
  DELETE_QUESTION_QUERY,
  DELETE_ANSWER_QUERY,
  DELETE_QUESTION_COMMENT_BY_ID,
  DELETE_ANSWER_COMMENT_BY_ID,
  REPORT_QUESTION,
  ACCEPT_ANSWER_QUERY,
  GET_SUBJECT_BY_SPACE,
  GET_SUBJECT_TOPICS,
  SEARCH_POPULAR_TOPICS_BY_STRING,
  CREATE_TOPIC,
  CREATE_QUESTION,
  CREATE_ANSWER,
  FILTER_SAVED_QUESTION,
  UPDATE_ANSWER,
  UPDATE_QUESTION,
  GET_NOTIFY_QUESTION,
  GET_REPORTED_POST_BY_STUDENT,
  GET_REPORTED_POST_ANSWER_BY_STUDENT,
  GET_REPORTED_POST_QUESTION_COMMENT_BY_STUDENT,
  GET_REPORTED_POST_ANSWER_COMMENT_BY_STUDENT,
  GET_QUESTION_WITHOUT_AUTH
} from "helpers/url_helper"
import { get, getWithAuth } from "helpers/api_helper"
import {
  getPopularTopicFail,
  getPopularTopicSuccess,
  getQuestionComments,
  getQuestionsFail,
  getQuestionsSuccess,
  getSavedQuestionsSuccess,
  saveQuestionsFail,
  saveQuestionsSuccess
} from "store/actions"

function* fetchUnsolvedQuestion({ payload: optionData }) {
  console.log('optionData.........1', optionData);
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));

  let data = {
    spaceId: currentSpace?._id,
    page: 1,
    limit: 10,
    populate: [
      { path: "author" },
      { path: "subject" },
      { path: "topic" }
    ]
  };

  if (optionData.subject != null && optionData.subject != '') {
    data.subjectIds = [optionData.subject]
  }

  let body = JSON.stringify({
    query: GET_UNSOLVED_QUESTION_QUERY,
    variables: {
      options: data
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  //console.log('response, error.......................', response);
  if (response) {
    yield put(getUnsolvedQuestionSuccess(response.getUnsolvedQuestions))
  } else {
    yield put(getUnsolvedQuestionFail(response.errors))
  }
}


function* fetchPopularTopics({ payload: optionData }) {

  console.log('optionData.........', optionData);
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));

  let data = {
    topicType: 'PRIMARY',
    spaceId: currentSpace?._id,
  };

  if (optionData.subject != null && optionData.subject != '') {
    data.subjectIds = [optionData.subject]
  }


  // if (props.questionFilterBy?.subjectQuery?.length > 0) {
  //   data.subjectIds = props.questionFilterBy.subjectQuery
  // }

  let body = JSON.stringify({
    query: GET_POPULAR_TOPICS_QUERY,
    variables: {
      options: data
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  //console.log('response, error.......................', response);
  if (response) {
    yield put(getPopularTopicSuccess(response.getPopularTopics))
  } else {
    yield put(getPopularTopicFail(response.errors))
  }
}



function* fetchQuestions({ payload: options }) {

  //console.log('options........................', options);
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));

  let sortBy = options.sort ? options.sort : '1';

  let body;

  let data = {
    scope: 'world',
    space: currentSpace?._id
  };

  if (options.savedQuestions == true) {

    let optionsData = {
      userId: authUser?._id,
      scope: 'world',
      space: currentSpace?._id,
      page: options?.page == undefined ? 1 : options?.page,
      limit: 20,
      populate: [
        { path: "author" },
        { path: "upvotedBy" },
        { path: "downvotedBy" },
        { path: "subject" },
        { path: "schoolClass" },
        { path: "space" },
        { path: "topic" }
      ],
      sort: sortBy == 1 ? { createdAt: "desc" } : { answerCount: "desc" }
    };

    let filters = {};

    if (options.myQuestions == true) {
      filters.questionAuthor = authUser?._id;
    }

    if (options.subject != null && options.subject != '') {
      filters.subjects = [options.subject];
    }

    if (options.topic != null && options.topic != '') {
      filters.topics = [options.topic]
    }

    if (options.solvedQuestions && !options.unsolvedQuestions) {
      filters.solved = true
    }

    if (options.unsolvedQuestions && !options.solvedQuestions) {
      filters.unsolved = true
    }

    if (Object.keys(filters).length > 0) {
      optionsData.filters = filters
    }

    body = JSON.stringify({
      query: FILTER_SAVED_QUESTION,
      variables: {
        options: optionsData
      }
    });

  } else {

    if (options.myQuestions == true) {
      data.author = authUser?._id;
    }

    if (options.subject != null && options.subject != '') {
      data.subject = { $in: [options.subject] }
    }

    if (options.topic != null && options.topic != '') {
      data.topic = { $in: [options.topic] }
    }

    if (options.solvedQuestions && !options.unsolvedQuestions) {
      data.acceptedAnswer = { $ne: null }
    }

    if (!options.solvedQuestions && options.unsolvedQuestions) {
      data.acceptedAnswer = { $eq: null }
    }

    body = JSON.stringify({
      query: GET_WORLD_QUESTION_QUERY,
      variables: {
        options: {
          paginateOptions: {
            page: options?.page == undefined ? 1 : options?.page,
            limit: 20,
            populate: [
              { path: "author" },
              { path: "upvotedBy" },
              { path: "downvotedBy" },
              { path: "subject" },
              { path: "schoolClass" },
              { path: "space" },
              { path: "topic" }
            ],
            sort: sortBy == 1 ? { createdAt: "desc" } : { answerCount: "desc" }
          },
          queryVals: data
        }
      }
    });

  }

  // if (props.questionFilterBy?.subjectQuery?.length > 0) {
  //   data.subjectIds = props.questionFilterBy.subjectQuery
  // }




  const response = yield call(getWithAuth, { payload: { body, history } })
  //console.log('response, error GET_WORLD_QUESTION_QUERY.......................', response);
  if (response) {
    if (options.savedQuestions == true) {
      yield put(getQuestionsSuccess(response.getPaginatedSavedQuestions));
    } else {
      yield put(getQuestionsSuccess(response.getPaginatedQuestions));
    }
  } else {
    yield put(getQuestionsFail(response?.errors))
  }
}


function* fetchAnswers({ payload: question_id }) {
  //console.log('question_id.........', question_id)
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));

  // if (props.questionFilterBy?.subjectQuery?.length > 0) {
  //   data.subjectIds = props.questionFilterBy.subjectQuery
  // }

  let body = JSON.stringify({
    query: ANSWER_QUERY,
    variables: {
      options: {
        paginateOptions: {
          page: 1,
          limit: 100,
          populate: [{ path: "question" }, { path: 'author' }, { path: "upvotedBy" }, { path: "downvotedBy" }, {path: "comments", populate: [{path: "author"}]}],
          sort: { createdAt: "desc" }
        },
        queryVals: `{question:{$in:[${question_id}]}}`
        //queryVals: "",
      },
    },
  });

  const response = yield call(get, { payload: { body, history } })
  //console.log('response, error GET_WORLD_QUESTION_QUERY.......................', response);
  if (response) {
    yield put(getAnswersSuccess(response?.getPaginatedAnswers))
  } else {
    yield put(getAnswersFail(response?.errors))
  }
}



function* fetchAnswerComment({ payload: answer_id }) {
  //console.log('question_id.........', question_id)
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  // if (props.questionFilterBy?.subjectQuery?.length > 0) {
  //   data.subjectIds = props.questionFilterBy.subjectQuery
  // }

  let body = JSON.stringify({
    query: COMMENT_QUERY,
    variables: {
      options: {
        paginateOptions: {
          page: 1,
          limit: 100,
          populate: [{ path: "answer" }, { path: "author" }],
          sort: { createdAt: "desc" }
        },
        queryVals: `{answer:{$in:[${answer_id}]}}`
        //queryVals: ""
      }
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  //console.log('response, error GET_WORLD_QUESTION_QUERY.......................', response);
  if (response) {
    yield put(getAnswerCommentsSuccess(response?.getPaginatedComments))
  } else {
    yield put(getAnswerCommentsFail(response?.errors))
  }
}



function* fetchQuestionComment({ payload: question_id }) {
  //console.log('question_id.........', question_id)
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));


  // if (props.questionFilterBy?.subjectQuery?.length > 0) {
  //   data.subjectIds = props.questionFilterBy.subjectQuery
  // }

  let body = JSON.stringify({
    query: COMMENT_QUESTION_QUERY,
    variables: {
      options: {
        paginateOptions: {
          page: 1,
          limit: 10,
          populate: [{ path: "question" }, { path: "author" }],
          sort: { createdAt: "desc" }
        },
        queryVals: `{question:${question_id}}`
        //queryVals: ""
      }
    },
  });

  const response = yield call(get, { payload: { body, history } })
  //console.log('response, error GET_WORLD_QUESTION_QUERY.......................', response);
  if (response) {
    yield put(getQuestionCommentsSuccess(response?.getPaginatedQuestionComments))
  } else {
    yield put(getQuestionCommentsFail(response?.errors))
  }
}


function* upvoteQuestions({ payload: question_id }) {
  //console.log('question_id.................', question_id);
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));

  let body = JSON.stringify({
    query: UPVOTE_QUERY,
    variables: {
      options: {
        questionId: question_id,
        userId: authUser._id,
      },
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  if (response) {
    yield put(upvoteQuestionsSuccess(response.upvoteQuestion))
  } else {
    yield put(upvoteQuestionsFail(response.errors))
  }
}

function* downvoteQuestions({ payload: question_id }) {
  console.log('question_id', question_id);
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));

  let body = JSON.stringify({
    query: DOWNVOTE_QUERY,
    variables: {
      options: {
        questionId: question_id,
        userId: authUser._id,
      },
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  if (response) {
    yield put(downvoteQuestionsSuccess(response.downvoteQuestion))
  } else {
    yield put(downvoteQuestionsSuccess(response.errors))
  }
}


function* upvoteAnswer({ payload: answer_id }) {
  //console.log('answer_id.................', answer_id);
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));

  let body = JSON.stringify({
    query: UPVOTE_ANSWER_QUERY,
    variables: {
      options: {
        answerId: answer_id,
        userId: authUser._id,
      },
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  if (response) {
    yield put(upvoteAnswersSuccess(response.upvoteAnswer))
  } else {
    yield put(upvoteAnswersFail(response.errors))
  }
}

function* downvoteAnswer({ payload: answer_id }) {
  console.log('answer_id', answer_id);
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));

  let body = JSON.stringify({
    query: DOWNVOTE_ANSWER_QUERY,
    variables: {
      options: {
        answerId: answer_id,
        userId: authUser._id,
      },
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  if (response) {
    yield put(downvoteAnswersSuccess(response.downvoteAnswer))
  } else {
    yield put(downvoteAnswersFail(response.errors))
  }
}


function fetchNewsPostsApi({ payload: { currentPage, totalPages } }) {

  let page = currentPage + 1

  const GET_NEWS_POSTS = `
    query {
        getPaginatedNewsposts(
            options: {
                paginateOptions:{
                    page:${page}
                    limit:5
                },
                    queryVals:""
                }
            ) {
                totalPages
                page
                offset
                docs {
                    _id
                    body
                    title
                    liked
                    saved
                }
            }
        }
    `

  let body = JSON.stringify({
    query: GET_NEWS_POSTS,
  })

  return new Promise(function (fulfill, reject) {
    fetch(process.env.REACT_APP_API_BASE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then(res => res.json())
      .then(response => {
        fulfill(response)
      })
      .catch(error => {
        console.log(error)
      })
  })
}

function submitNewsPostsApi({ payload: { postId, actionType } }) {
  let SUBMIT_NEWS_POSTS = '';
  if (actionType == 'LIKE') {
    SUBMIT_NEWS_POSTS = `
        mutation LikePost($options: voteNewspostInput!) {
            likeNewspost ( options: $options) {
                _id
                title
                liked
                saved
            }
        }
        `
  }

  if (actionType == 'SAVE') {
    SUBMIT_NEWS_POSTS = `
        mutation SavePost($options: voteNewspostInput!) {
            saveNewspost ( options: $options) {
                _id
                title
                liked
                saved
            }
        }
        `
  }

  let body = JSON.stringify({
    query: SUBMIT_NEWS_POSTS,
    variables: {
      options: {
        newspostId: postId
      },
    },
  })

  return new Promise(function (fulfill, reject) {
    fetch(process.env.REACT_APP_API_BASE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then(res => res.json())
      .then(response => {
        fulfill(response)
      })
      .catch(error => {
        console.log(error)
      })
  })
}

function* fetchPaginatedNewsposts({ payload: { currentPage, totalPages } }) {
  const response = yield call(fetchNewsPostsApi, { payload: { currentPage, totalPages } })
  if (response && response.data) {
    yield put(getTalksSuccess(response.data))
  } else {
    yield put(getTalksFail(response.errors[0].message))
  }
}

function* submitPostLikeSaveData({ payload: { postId, actionType } }) {
  try {
    const response = yield call(submitNewsPostsApi, { payload: { postId, actionType } })
    yield put(getPostLikeSaveSuccess(response))
  } catch (error) {
    yield put(getPostFail(error))
  }
}

function* fetchAllSavedQuestion() {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));

  let body = JSON.stringify({
    query: `query {
      getAllSavedQuestions(
          options: {
                userId: "${authUser?._id}",
                spaceId: "${currentSpace?._id}"
            }
      )
    }`,
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  //console.log('response, error.......................', response);
  if (response) {
    yield put(getAllSavedQuestionsSuccess(response?.getAllSavedQuestions?.data))
  } else {
    yield put(getAllSavedQuestionsFail(response.errors))
  }
}


function* postSaveQuestion({ payload: { questions, bookmark } }) {
  console.log('payload,,,,,,..................', questions, bookmark);
  //console.log('store,,,,,,..................', store);
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  let data = [];
  let body = JSON.stringify({
    query: bookmark ? BOOKMARK_QUERY : UNBOOKMARK_QUERY,
    variables: {
      options: {
        questionId: questions,
        userId: authUser?._id,
      },
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  //console.log('response, error.......................', response);
  if (response) {
    yield put(saveQuestionsSuccess(bookmark ? response?.saveQuestion : response?.unsaveQuestion))
  } else {
    yield put(saveQuestionsFail(response.errors))
  }
}


function* postAnswerComment({ payload: { comment, answer_id } }) {
  console.log('payload,,,,,,..................', comment, answer_id);
  //console.log('store,,,,,,..................', store);
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));

  let body = JSON.stringify({
    query: CREATE_COMMENT,
    variables: {
      options: {
        body: comment,
        answerId: answer_id,
        author: authUser?._id,
      },
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  //console.log('response, error.......................', response);
  if (response) {
    yield put(saveAnswerCommentSuccess(response?.createComment))
  } else {
    yield put(saveAnswerCommentFail(response.errors))
  }
}

function* postQuestionComment({ payload: { comment, question_id } }) {
  //console.log('payload,,,,,,..................', questions, bookmark );
  //console.log('store,,,,,,..................', store);
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  // case 3/4: correct/incorrect credentials
  let body = JSON.stringify({
    query: CREATE_QUESTION_COMMENT,
    variables: {
      options: {
        body: comment,
        questionId: question_id,
        author: authUser?._id,
      },
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  console.log('response, error.......................', response);
  if (response) {
    yield put(saveQuestionCommentSuccess(response?.createQuestionComment))
  } else {
    yield put(saveQuestionCommentFail(response.errors))
  }
}


// post delete question
function* postDeleteQuestion({ payload: question_id }) {
  //console.log('payload,,,,,,..................', questions, bookmark );
  //console.log('store,,,,,,..................', store);
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  // case 3/4: correct/incorrect credentials
  let body = JSON.stringify({
    query: DELETE_QUESTION_QUERY,
    variables: {
      options: {
        id: question_id
      },
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  //console.log('response, error.......................', response);
  if (response) {
    yield put(deleteQuestionSuccess(response?.deleteQuestionById))
  } else {
    yield put(deleteQuestionFail(response.errors))
  }
}

// delete Question comment
function* postDeleteQuestionComment({ payload: comment_id }) {
  //console.log('payload,,,,,,..................', questions, bookmark );
  //console.log('store,,,,,,..................', store);
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  // case 3/4: correct/incorrect credentials
  let body = JSON.stringify({
    query: DELETE_QUESTION_COMMENT_BY_ID,
    variables: {
      options: {
        id: comment_id,
      },
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  //console.log('response, error.......................', response);
  if (response) {
    yield put(deleteQuestionCommentSuccess(response?.deleteQuestionCommentById))
  } else {
    yield put(deleteQuestionCommentFail(response.errors))
  }
}

//delete answer
function* postDeleteAnswer({ payload: answer_id }) {
  //console.log('payload,,,,,,..................', questions, bookmark );
  //console.log('store,,,,,,..................', store);
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  // case 3/4: correct/incorrect credentials
  let body = JSON.stringify({
    query: DELETE_ANSWER_QUERY,
    variables: {
      options: {
        id: answer_id,
      },
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  //console.log('response, error.......................', response);
  if (response) {
    yield put(deleteAnswerSuccess(response?.deleteAnswerById))
  } else {
    yield put(deleteAnswerFail(response.errors))
  }
}

function* postDeleteAnswerComment({ payload: comment_id }) {
  //console.log('payload,,,,,,..................', questions, bookmark );
  //console.log('store,,,,,,..................', store);
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  // case 3/4: correct/incorrect credentials
  let body = JSON.stringify({
    query: DELETE_ANSWER_COMMENT_BY_ID,
    variables: {
      options: {
        id: comment_id,
      },
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  //console.log('response, error.......................', response);
  if (response) {
    yield put(deleteAnswerCommentSuccess(response?.deleteCommentById))
  } else {
    yield put(deleteAnswerCommentFail(response.errors))
  }
}

//report question
function* postReport({ payload: data }) {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  let body = JSON.stringify({
    query: REPORT_QUESTION,
    variables: {
      options: {
        studentId: authUser._id,
        postId: data.id,
        postType: data.type,
        reason: data.reportReason
      },
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  if (response) {
    yield put(reportSuccess({type: data.type, data: {_id : data.id}}))
  } else {
    yield put(reportFail(response.errors))
  }
}

//accept answer
function* postAcceptAnswer({ payload: { question_id, answer_id } }) {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  let body = JSON.stringify({
    query: ACCEPT_ANSWER_QUERY,
    variables: {
      options: {
        answerId: answer_id,
        questionId: question_id,
      },
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  if (response) {
    yield put(acceptAnswerSuccess(response?.acceptAnswer))
  } else {
    yield put(acceptAnswerFail(response.errors))
  }
}

//get subjects
function* getSubjects() {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  let body = JSON.stringify({
    query: GET_SUBJECT_BY_SPACE,
    variables: {
      options: {
        id: currentSpace?._id
      },
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  if (response) {
    yield put(getSubjectsSuccess(response?.getSubjectsForSpace))
  } else {
    yield put(getSubjectsFail(response.errors))
  }
}

//get subjects
function* getTopicsBySubject({ payload: { subject_id, topicInput } }) {
  //console.log('subject_id..............', subject_id, topicInput);
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  let body;
  if (topicInput != '' && topicInput != null) {
    let query = {
      query: {
        bool: {
          must: [
            { terms: { subject: [subject_id] } }
          ],
          should: [
            // {
            //   query_string: {
            //     query: text+'*',
            //     fields: [
            //       "topicName"
            //     ],
            //     boost: 5
            //   }
            // }
            {
              match: {
                topicName: {
                  query: topicInput,
                  fuzziness: "AUTO",
                  boost: 2
                }
              }
            }
          ],
          minimum_should_match: 1
        }
      },
      skip: 0,
      limit: 10
    };

    body = JSON.stringify({
      query: SEARCH_POPULAR_TOPICS_BY_STRING,
      variables: {
        options: query,
      }
    });
  } else {

    let data = {
      spaceId: currentSpace?._id,
      topicType: 'PRIMARY'
    };

    if (subject_id != null) {
      data.subjectIds = [subject_id]
    }

    body = JSON.stringify({
      query: GET_POPULAR_TOPICS_QUERY,
      variables: {
        options: data
      }
    });

  }

  const response = yield call(getWithAuth, { payload: { body, history } })

  //console.log('response............... istopics by subject', response);
  if (response) {
    if (topicInput != '' && topicInput != null) {
      yield put(getTopicsBySubjectSuccess(response?.searchTopics?.hits))
    } else {
      yield put(getTopicsBySubjectSuccess(response?.getPopularTopics))
    }
  } else {
    yield put(getTopicsBySubjectFail(response.errors))
  }
}


//add question
function* postAddQuestion({ payload: data }) {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  let q_topic = data?.new_topic == false ? data?.topic : ''

  if (data?.new_topic == true) {
    let body = JSON.stringify({
      query: CREATE_TOPIC,
      variables: {
        options: {
          topicName: data?.topic,
          subject: data?.subject,
          topicType: 'SECONDARY'
        },
      },
    });

    const topic_response = yield call(getWithAuth, { payload: { body, history } });
    if (topic_response) {
      console.log(topic_response);
    } else {
      console.log(topic_response.errors);
      //yield put(addQuestionFail(topic_response.errors))
    }

    q_topic = topic_response?.createTopic?._id;
  }

  let options = {
    //schoolClass: classes.value,
    subject: data?.subject,
    title: data?.title,
    author: authUser?._id,
    topic: q_topic,
    imageUrls: data?.imageUrls,
    scope: 'world',
    space: currentSpace?._id
  };
  if (data?.description != null && data?.description != '') {
    options.body = data?.description;
  }
  // case 3/4: correct/incorrect credentials
  let body = JSON.stringify({
    query: CREATE_QUESTION,
    variables: {
      options: options
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  if (response) {
    yield put(addQuestionSuccess(response?.createQuestion))
  } else {
    yield put(addQuestionFail(response?.errors))
  }
}


//add answer
function* postAddAnswer({ payload: data }) {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let body = JSON.stringify({
    query: CREATE_ANSWER,
    variables: {
      options: {
        body: data?.description,
        questionId: data?.questionId,
        author: authUser?._id,
        imageUrls: data?.imageUrls
      },
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  if (response) {
    yield put(addAnswerSuccess(response?.createAnswer))
  } else {
    yield put(addAnswerFail(response?.errors))
  }
}


//get subjects
function* getAllTopicsBySubject({ payload: subject_id }) {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));

  let body = JSON.stringify({
    query: GET_SUBJECT_TOPICS,
    variables: {
      options: {
        subjects: [subject_id]
      }
    }
  });

  const response = yield call(getWithAuth, { payload: { body, history } })

  //console.log('response............... istopics by subject', response);
  if (response) {
    yield put(getAllTopicsBySubjectSuccess(response?.getSubjectTopics))
  } else {
    yield put(getAllTopicsBySubjectFail(response.errors))
  }
}

//update answer
function* postUpdateAnswer({ payload: data }) {
  //console.log('payload data-------------',data)

  let body = JSON.stringify({
    query: UPDATE_ANSWER,
    variables: {
      options: {
        id: data?.answerId,
        body: data?.description,
        //questionId: data?.questionId,
        imageUrls: data?.imageUrls
      },
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  if (response) {
    //console.log('response---------',response)
    yield put(updateAnswerSuccess(response?.updateAnswer))
  } else {
    yield put(updateAnswerFail(response?.errors))
  }
}

//update question
function* postUpdateQuestion({ payload: data }) {
  //console.log('payload data-------------',data)
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));

  let body = JSON.stringify({
    query: UPDATE_QUESTION,
    variables: {
      options: {
        id: data?.questionId,
        //subject: data?.subject,
        title: data?.title,
        body: data?.description,
        author: authUser?._id,
        imageUrls: data?.imageUrls,
        scope: 'world',
        space: currentSpace?._id
      },
    },
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  if (response) {
    //console.log('response---------',response)
    yield put(updateQuestionSuccess(response?.updateQuestion))
  } else {
    yield put(updateQuestionFail(response?.errors))
  }
}

function* getSingleQuestion({ payload: question_id }) {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  //console.log('authUser.............' ,authUser);
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  let body;
  if(authUser ==  null) {
    body = JSON.stringify({
      query: GET_QUESTION_WITHOUT_AUTH,
      variables: {
        options: {
          id: question_id,
          populate: [
            { path: "author" },
            { path: "upvotedBy" },
            { path: "downvotedBy" },
            { path: "subject" },
            { path: "schoolClass" },
            { path: "space" },
            { path: "topic" }
          ],
        }
      }
    });
    const response = yield call(get, { payload: { body, history } })
    if (response) {
      //console.log('response---------', response.getQuestionByIdWithUserAuthorization)
      yield put(getSingleQuestionSuccess(response?.getQuestionById))
    } else {
      yield put(getSingleQuestionFail(response?.errors))
    }
  } else {
    body = JSON.stringify({
      query: GET_NOTIFY_QUESTION,
      variables: {
        options: {
          questionId: question_id,
          userType: 'student',
          userId: authUser?._id,
          populate: [
            { path: "author" },
            { path: "upvotedBy" },
            { path: "downvotedBy" },
            { path: "subject" },
            { path: "schoolClass" },
            { path: "space" },
            { path: "topic" }
          ],
        }
      }
    });
    const response = yield call(getWithAuth, { payload: { body, history } })
    if (response) {
      //console.log('response---------', response.getQuestionByIdWithUserAuthorization)
      yield put(getSingleQuestionSuccess(response?.getQuestionByIdWithUserAuthorization?.question))
    } else {
      yield put(getSingleQuestionFail(response?.errors))
    }
  }
}

function* fetchReportedPostQuestion() {
  let authUser = JSON.parse(localStorage.getItem("authUser"));

  let body = JSON.stringify({
    query: GET_REPORTED_POST_BY_STUDENT,
    variables: {
      options: {
        paginateOptions: {
          page: 1,
          limit: 100,
          populate: [
            { path: "student" },
            { path: "post", model: "Question" }
          ]
        },
        queryVals: `{postType: 'QUESTION', student:${authUser?._id}}`
      }
    }
  });

  const response = yield call(get, { payload: { body, history } })
  let dataList = [];
  //console.log('response, error.......................', response);
  if (response?.getPaginatedPostReports?.docs?.length > 0) {
    response.getPaginatedPostReports.docs.map((item, index) => {
      ////console.log('reported item.................', item)
      dataList.push(item.post._id);
    });
    yield put(getReportedPostQuestionSuccess(dataList))
  } else {
    yield put(getReportedPostQuestionSuccess([]))
  }
}

function* fetchReportedPostAnswer() {
  let authUser = JSON.parse(localStorage.getItem("authUser"));

  let body = JSON.stringify({
    query: GET_REPORTED_POST_ANSWER_BY_STUDENT,
    variables: {
      options: {
        paginateOptions: {
          page: 1,
          limit: 100,
          populate: [{ path: "student" }, { path: "post", model: "Answer" }],
        },
        queryVals: `{postType: 'ANSWER', student:${authUser?._id}}`,
      }
    }
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  //console.log('response, error.......................', response);
  let dataList = [];
  if (response?.getPaginatedPostReports?.docs?.length > 0) {
    response.getPaginatedPostReports.docs.map((item, index) => {
      ////console.log('reported item.................', item)
      dataList.push(item.post._id);
    });
    yield put(getReportedPostAnswerSuccess(dataList))
  } else {
    yield put(getReportedPostAnswerSuccess([]))
  }
}

function* fetchReportedPostQuestionComment() {
  let authUser = JSON.parse(localStorage.getItem("authUser"));

  let body = JSON.stringify({
    query: GET_REPORTED_POST_QUESTION_COMMENT_BY_STUDENT,
    variables: {
      options: {
        paginateOptions: {
          page: 1,
          limit: 100,
          populate: [{ path: "student" }, { path: "post", model: "QuestionComment" }],
        },
        queryVals: `{postType: 'QUESTION_COMMENT', student:${authUser?._id}}`,
      }
    }
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  let dataList = [];
  if (response?.getPaginatedPostReports?.docs?.length > 0) {
    response.getPaginatedPostReports.docs.map((item, index) => {
      ////console.log('reported item.................', item)
      dataList.push(item.post._id);
    });
    yield put(getReportedPostQuestionCommentSuccess(dataList))
  } else {
    yield put(getReportedPostQuestionCommentSuccess([]))
  }
}

function* fetchReportedPostAnswerComment() {
  let authUser = JSON.parse(localStorage.getItem("authUser"));

  let body = JSON.stringify({
    query: GET_REPORTED_POST_ANSWER_COMMENT_BY_STUDENT,
    variables: {
      options: {
        paginateOptions: {
          page: 1,
          limit: 100,
          populate: [{ path: "student" }, { path: "post", model: "Comment" }],
        },
        queryVals: `{postType: 'COMMENT', student:${authUser?._id}}`,
      }
    }
  });

  const response = yield call(getWithAuth, { payload: { body, history } })
  //console.log('response, error.......................', response);
  let dataList = [];
  if (response?.getPaginatedPostReports?.docs?.length > 0) {
    response.getPaginatedPostReports.docs.map((item, index) => {
      ////console.log('reported item.................', item)
      dataList.push(item.post._id);
    });
    yield put(getReportedPostAnswerCommentSuccess(dataList))
  } else {
    yield put(getReportedPostAnswerCommentSuccess([]))
  }
}

function* talksSaga() {
  yield takeEvery(GET_PAGINATED_NEWS_POSTS, fetchPaginatedNewsposts)
  yield takeEvery(SUBMIT_POST_LIKE_SAVE, submitPostLikeSaveData)
  yield takeEvery(GET_UNSOLVED_QUESTION, fetchUnsolvedQuestion)
  yield takeEvery(GET_POPULAR_TOPICS, fetchPopularTopics)
  yield takeEvery(GET_QUESTIONS, fetchQuestions);
  yield takeEvery(UPVOTE_QUESTION, upvoteQuestions);
  yield takeEvery(DOWNVOTE_QUESTION, downvoteQuestions);
  yield takeEvery(UPVOTE_ANSWER, upvoteAnswer);
  yield takeEvery(DOWNVOTE_ANSWER, downvoteAnswer);
  yield takeEvery(GET_ANSWERS, fetchAnswers);
  yield takeEvery(GET_ANSWER_COMMENTS, fetchAnswerComment);
  yield takeEvery(GET_ALL_SAVED_QUESTIONS, fetchAllSavedQuestion);
  yield takeEvery(GET_QUESTION_COMMENTS, fetchQuestionComment);
  yield takeEvery(SAVE_QUESTION, postSaveQuestion);
  yield takeEvery(SAVE_QUESTION_COMMENT, postQuestionComment);
  yield takeEvery(SAVE_ANSWER_COMMENT, postAnswerComment);
  yield takeEvery(DELETE_QUESTION, postDeleteQuestion);
  yield takeEvery(DELETE_QUESTION_COMMENT, postDeleteQuestionComment);
  yield takeEvery(DELETE_ANSWER, postDeleteAnswer);
  yield takeEvery(DELETE_ANSWER_COMMENT, postDeleteAnswerComment);
  yield takeEvery(REPORT, postReport);
  yield takeEvery(ACCEPT_ANSWER, postAcceptAnswer);
  yield takeEvery(GET_SUBJECTS, getSubjects);
  yield takeEvery(GET_TOPICS_BY_SUBJECT, getTopicsBySubject);
  yield takeEvery(ADD_QUESTION, postAddQuestion);
  yield takeEvery(ADD_ANSWER, postAddAnswer);
  yield takeEvery(GET_ALL_TOPICS_BY_SUBJECT, getAllTopicsBySubject);
  yield takeEvery(POST_UPDATE_ANSWER, postUpdateAnswer);
  yield takeEvery(POST_UPDATE_QUESTION, postUpdateQuestion);
  yield takeEvery(GET_SINGLE_QUESTION, getSingleQuestion);
  yield takeEvery(GET_REPORTED_POST_QUESTION, fetchReportedPostQuestion);
  yield takeEvery(GET_REPORTED_POST_ANSWER, fetchReportedPostAnswer);
  yield takeEvery(GET_REPORTED_POST_QUESTION_COMMENT, fetchReportedPostQuestionComment);
  yield takeEvery(GET_REPORTED_POST_ANSWER_COMMENT, fetchReportedPostAnswerComment);
}

export default talksSaga
