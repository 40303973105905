import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { EXIT_SPACE, 
        GET_SPACES, 
        GET_STUDENT_SPACES, 
        JOIN_SPACE,
      } from "./actionTypes"
import {
  getSpacesSuccess,
  getSpacesFail,
  getStudentSpacesSuccess,
  getStudentSpacesFail,
  joinSpaceFail,
  joinSpaceSuccess,
  setCurrentSpace,
  exitSpaceSuccess,
  exitSpaceFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  get,
} from "../../helpers/api_helper"


import { GET_SPACE, 
          GET_STUDENT_SPACE, 
          JOIN_SPACE_QUERY,
          LEAVE_SPACE  
      } from "helpers/url_helper"


function* fetchSpaces() {
  let authUser =  JSON.parse(localStorage.getItem("authUser"));
  let body = JSON.stringify({
    query: GET_SPACE,
    variables: {
      options: {
        studentId: authUser?._id,
        scope: "world"
      }
    },
  });

  const response =  yield call(get, { payload: { body, history } })
  console.log('response, error', response);
  if (response) {
    yield put(getSpacesSuccess(response.getSpaces))
  } else {
    yield put(getSpacesFail(response.errors))
  }
}



function* exitSpaces() {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  let body = JSON.stringify({
    query: LEAVE_SPACE,
    variables: {
      options: {
        studentId: authUser?._id,
        spaceId: currentSpace?._id,
        scope: "world"
      }
    }
  });

  const response =  yield call(get, { payload: { body, history } })
  //console.log('response, error', response);
  if (response) {
    //localStorage.clear();
    localStorage.removeItem("currentSpace")
    window.location.href = "/space";
  } else {
    yield put(exitSpaceFail(response.errors))
  }
}

function* joinUserSpace({payload: {space, history}}) {
  //console.log('space, history..............', history, space);
  let authUser =  JSON.parse(localStorage.getItem("authUser"));
  let body = JSON.stringify({
    query: JOIN_SPACE_QUERY,
    variables: {
      options: {
        studentId: authUser._id,
        spaceId: space._id,
        scope: "world"
      }
    },
  });

  const response =  yield call(get, { payload: { body, history } })
  //const response = true;
  console.log('joinSpace response, error', response);
  if (response) {
    yield put(joinSpaceSuccess(response?.getSpaces))
    yield put(setCurrentSpace(space))
    localStorage.setItem("currentSpace", JSON.stringify(space));
    history.push("/dashboard")
  } else {
    yield put(joinSpaceFail(response?.errors))
  }
}


function* fetchStudentSpaces() {
  
  let authUser =  JSON.parse(localStorage.getItem("authUser"));
  let body = JSON.stringify({
    query: GET_STUDENT_SPACE,
    variables: {
      options: {
        userName: authUser?.userName,
        populate: [{ path: "spaces", match: { scope: "world" } }]
      }
    },
  });


  const response =  yield call(get, { payload: { body, history } })
  console.log('response, error', response);
  if (response) {
    yield put(getStudentSpacesSuccess(response.getStudent.spaces))
  } else {
    yield put(getStudentSpacesFail(response.errors))
  }
}

function* projectsSaga() {
  yield takeEvery(GET_SPACES, fetchSpaces)
  yield takeEvery(GET_STUDENT_SPACES, fetchStudentSpaces)
  yield takeEvery(JOIN_SPACE ,joinUserSpace)
  yield takeEvery(EXIT_SPACE, exitSpaces)
}

export default projectsSaga
