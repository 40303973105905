import { GET_LEADERBOARDS, 
        GET_LEADERBOARDS_FAIL, 
        GET_LEADERBOARDS_SUCCESS,
        GET_LEADERBOARD_SCORE,
        GET_LEADERBOARD_SCORE_SUCCESS,
        GET_LEADERBOARD_SCORE_FAIL
    } from "./actionTypes"

export const getLeaderboards = (options) => ({
  type: GET_LEADERBOARDS,
  payload: options
})

export const getLeaderboardsSuccess = tasks => ({
  type: GET_LEADERBOARDS_SUCCESS,
  payload: tasks,
})

export const getLeaderboardsFail = error => ({
  type: GET_LEADERBOARDS_FAIL,
  payload: error,
})

export const getLeaderboardScore = () => ({
  type: GET_LEADERBOARD_SCORE,
})

export const getLeaderboardScoreSuccess = tasks => ({
  type: GET_LEADERBOARD_SCORE_SUCCESS,
  payload: tasks,
})

export const getLeaderboardScoreFail = error => ({
  type: GET_LEADERBOARD_SCORE_FAIL,
  payload: error,
})
