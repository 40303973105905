import React, { Component, useEffect, useState, useRef } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Media,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert
} from "reactstrap"
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
import Truncate from "react-truncate-html"
// Form Editor
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import CommonFunction from "common/CommonFunction"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import img1 from "../../assets/images/small/img-1.jpg"

import { withRouter, Link } from "react-router-dom"

import { connect, useSelector, useDispatch } from "react-redux"

import img8 from "../../assets/images/camera.png"

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import {
  upvoteQuestions,
  getQuestions,
  clearUpvoteQuestionsSuccess,
  getTopicsBySubject
} from "../../store/actions"


const AddQuestionModal = (props) => {
  const { questionModal,
    closeQuestionModal,
    subjectList,
    onGetTopicsBySubject,
    topicList
  } = props;
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedTopicText, setSelectedTopicText] = useState(null);
  const [topicInput, setTopicInput] = useState(null);
  const [questionTitle, setQuestionTitle] = useState(null);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  // useEffect(() => {
  //   let dataList = [];
  //   subjectList.map((item, index) => {
  //     let data = {}
  //     data.label = item.subjectName;
  //     data.value = item._id;
  //     dataList.push(data);
  //   })
  //   setSubjectArray(dataList);
  // }, [subjectList])

  const [addQuestionImage, setAddQuestionImage] = useState([])

  const onAddQuestionImageChange = event => {
    //console.log('on image change.................', event);
    let dataArray = []
    let files = event.target.files
    if (files.length > 0) {
      for (let i = 0; i <= files.length - 1; i++) {
        dataArray.push(URL.createObjectURL(event.target.files[i]))
      }
      setAddQuestionImage([...addQuestionImage, ...dataArray])
    }
  }

  const inputFile = useRef(null)

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click()
  }

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  }

  const getDescription = () => {
    if (editorState) {
      const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      return html;
    } else {
      return null
    }
  };

  const onchangeSubject = (e) => {
    //console.log('e............', e.target.checked, e.target.value)
    if (e.target.checked) {
      setSelectedSubject(e.target.value);
    }
  }

  const onchangeTopics = (e, topic) => {
    if (e.target.checked) {
      setSelectedTopicText(topic)
      setSelectedTopic(e.target.value);
    }
  }

  const onInputTopicChange = (e) => {
    onGetTopicsBySubject(selectedSubject, e.target.value);
    setTopicInput(e.target.value);
  }

  useEffect(() => {
    if (selectedSubject != null) {
      onGetTopicsBySubject(selectedSubject, '');
    }
  }, [selectedSubject])

  const onSelectedTopicText = () => {
    setSelectedTopicText(topicInput);
    setSelectedTopic(null);
  }

  //console.log('selectedSubject............', selectedSubject);
  const handleQuestionSubmit = () => {
    const description = getDescription()
    let payload = {
      title : questionTitle,
      description : description,
      subject : selectedSubject,
      topic: selectedTopic ? selectedTopic : selectedTopicText,
      new_topic: selectedTopic ? false : true,
      imageUrls: null
    }
    
    console.log('description---------',payload)
  }
  return (
    <Modal
      isOpen={questionModal}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="answerModal modal-dialog modal-lg"
      tabIndex="-1"
    // toggle={() => {
    //   setQuestionModal(!questionModal)
    // }}
    >
      <div className="modal-content" id="add-answer-modal">
        <ModalBody className="p-0">
          <Card>
            <CardBody>
              <Container>
                <div className="d-flex justify-content-lg-between">
                  <div className="ms-2 mt-2 mb-3">
                    <span
                      className="text-white"
                      style={{ marginLeft: "-10px" }}
                    >
                      Ask your Question
                    </span>
                  </div>

                  <div
                    className="text-white"
                    onClick={() => closeQuestionModal()}
                  >
                    <i className=" mdi mdi-close mt-2 fs-3 "></i>
                  </div>
                </div>
                <hr />

                <p className="ms-2 mt-4 fs-7 mb-2">
                Please Add Question Title
                </p>
                <input
                  className="form-control bg-white text-secondary p-3 rounded-4"
                  type="text"
                  placeholder="Please Add Question Title"

                  onChange={(e) => setQuestionTitle(e.target.value)}
                  value={questionTitle}
                />

                <p className="ms-2 mt-4 fs-7 mb-2">
                  Add Description (optional)
                </p>
                <Editor
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "fontSize",
                      "fontFamily",
                      "list",
                      "textAlign",
                      "colorPicker",
                      "link",
                      "emoji",
                    ],
                  }}
                />
                {
                  <div className="d-flex flex-wrap mt-3">
                    {addQuestionImage.length > 0 &&
                      addQuestionImage.map((img, index) => {
                        return (
                          <div className="position-relative">
                            <i
                              className=" mdi mdi-close add-answer-close-icon"
                              onClick={() => removeAddQuestionImg(img)}
                            ></i>
                            <img
                              src={img}
                              className="add-answer-img"
                              alt=""
                            />
                          </div>
                        )
                      })}
                    <input
                      type="file"
                      id="file"
                      multiple
                      onChange={onAddQuestionImageChange}
                      ref={inputFile}
                      style={{ display: "none" }}
                    />
                    {/*  <img
                  src={img8}
                  alt=""
                  onClick={onButtonClick}
                  className="add-answer-img"
                /> */}

                    <button onClick={onButtonClick} class="btn-new border-line btn-square box-add-img btn-height width-20 rounded-4 text-white"><i class="fa fa-plus fs-4 text-white"></i> Add Image</button>

                  </div>}
                <div>
                  <p className="ms-2 mt-4 fs-7 mb-2">Select Subject</p>
                  {
                    subjectList.length > 0 &&
                    subjectList.map((item, index) => {
                      return (

                        <div
                          className="btn-group btn-group-toggle"
                          data-toggle="buttons"
                        >
                          <div className="me-1">
                            <input
                              type="checkbox"
                              className="btn-check"
                              id={item._id}
                              autoComplete="off"
                              value={item._id}
                              checked={selectedSubject == item._id ? true : false}
                              onChange={(e) => { onchangeSubject(e) }}
                            />
                            <label
                              className="btn btn-outline-info rounded-pill "
                              htmlFor={item._id}
                            >
                              {item.subjectName}
                              {selectedSubject == item._id ?
                                <i class="bx bx-check font-size-16 align-middle ms-2"></i>
                                :
                                <i class="bx bx-plus font-size-16 align-middle ms-2"></i>
                              }
                            </label>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>

                <p className="ms-2 mt-4 fs-7 mb-2">Add Topic</p>
                <div className="position-relative">
                  <input
                    className="form-control bg-white text-secondary p-3 rounded-4 mb-2"
                    type="text"
                    onChange={(e) => onInputTopicChange(e)}
                    value={topicInput}
                  />
                  <button class="btn color-answer-btn text-white position-absolute"
                      style={{
                        padding: 5,
                        width: 120,
                        borderRadius: "19px",
                        border: "1px solid #0073B1",
                        top: '20%',
                        right: 10,
                      }}
                      onClick={() => onSelectedTopicText()}
                  >Add new topic</button>
                </div> 
                {
                  selectedTopicText != null && 
                  <div className="mb-1"><strong>Selected Topic:</strong> {selectedTopicText} </div>
                }
                {
                  topicList.length > 0 &&
                  topicList.map((item, index) => {
                    return (
                      <div
                        className="btn-group btn-group-toggle"
                        data-toggle="buttons"
                      >
                        <div className="me-1">
                          <input
                            type="checkbox"
                            className="btn-check"
                            id={item._id}
                            autoComplete="off"
                            value={item._id}
                            checked={selectedTopic == item._id ? true : false}
                            onChange={(e) => { onchangeTopics(e, item.topicName) }}
                          />
                          <label
                            className="btn btn-outline-info rounded-pill "
                            htmlFor={item._id}
                          >
                            {item.topicName}
                            {selectedTopic == item._id ?
                              <i class="bx bx-check font-size-16 align-middle ms-2"></i>
                              :
                              <i class="bx bx-plus font-size-16 align-middle ms-2"></i>
                            }
                          </label>
                        </div>
                      </div>
                    )
                  })
                }

                <div className="row">
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      class="btn color-answer-btn mt-5 text-white"
                      style={{
                        width: "20%",
                        borderRadius: "19px",
                        border: "1px solid #0073B1",

                      }}
                      onClick={handleQuestionSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Container>
            </CardBody>
          </Card>
        </ModalBody>
      </div>
    </Modal>
  )
}

const mapStateToProps = state => {
  //console.log("state ----------", state.talks)
  const { error, newsPosts, spaces, talks } = state
  return {
    talks: newsPosts,
    error: error,
    current_space: spaces.current_space,
    unsolvedQuestions: talks.unsolvedQuestions,
    questions: talks.questions,
    upvoteQuestionSuccessMessage: talks.upvoteQuestionSuccess,
    subjectList: talks.subjectsSuccess,
    topicList: talks.topics
  }
}

const mapDispatchToProps = dispatch => ({
  onUpvoteQuestions: (question_id) => dispatch(upvoteQuestions(question_id)),
  onUpvoteQuestionsSuccess: () => dispatch(clearUpvoteQuestionsSuccess()),
  onGetQuestions: () => dispatch(getQuestions()),
  onGetTopicsBySubject: (subject_id, topicInput) => dispatch(getTopicsBySubject(subject_id, topicInput))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddQuestionModal)
)
