import React, { Component, useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Media,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert
} from "reactstrap"

import toastr, { success } from "toastr"
import "toastr/build/toastr.min.css"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import Truncate from "react-truncate-html"
import CommonFunction from "common/CommonFunction"
import avatar1 from "../../assets/images/users/avatar-1.jpg"

import Avatar from 'react-avatar';

import { withRouter, Link } from "react-router-dom"

import { connect, useSelector, useDispatch } from "react-redux"

import img8 from "../../assets/images/camera.png"

//Lightbox
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";

import {
  upvoteQuestions,
  getQuestions,
  clearUpvoteQuestionsSuccess
} from "../../store/actions"


import ImageModal from '../../components/CommonForBoth/ImageModal'


const QuestionMiniCard = (props) => {

  //const images = [img1, img2, img3, img4, img5, img6]

  const { questionItem, onClick, selectQuestion, upvoteQuestion, downvoteQuestion, userData, savedQuestions, bookmarkQuestion, deleteQuestion, reportQuestion, isSingle, editQuestion } = props;
  const [otherMenu, setOtherMenu] = useState(false)
  const [isGallery, setisGallery] = useState(false)
  const [isGalleryZoom, setisGalleryZoom] = useState(false)
  const [photoIndex, setphotoIndex] = useState(0)
  const [gallery, setGallery] = useState(null);

  const upvote = () => {
    upvoteQuestion(questionItem._id)
  }

  const downvote = () => {
    downvoteQuestion(questionItem._id)
  }

  const bookmark = (isBookmark) => {
    console.log('isBookmark.................', isBookmark);
    bookmarkQuestion(questionItem._id, isBookmark)
  }

  const deleteQ = () => {
    deleteQuestion(questionItem._id)
  }

  const reportQ = () => {
    reportQuestion(questionItem._id)
  }

  const editQ = () => {
    editQuestion(questionItem)
  }

  const copyUrl = () => {
    navigator.clipboard.writeText(process.env.REACT_APP_APPID+'/question/'+questionItem._id)
    toastr.success("Copied!")
  }

  const onLightboxOpen = (i1) => {
    let data = [];
    questionItem?.imageUrls.map((item, index) => {
       let d = {};
       d.src = item;
       data.push(d);
    })
    setGallery(data)
    setisGallery(true)
    setphotoIndex(i1)
  }

  return (
    <Card className={selectQuestion?._id == questionItem._id ? 'active-mini-question' : ''}>
      <CardBody className={isSingle ? 'p-0' : ''}>
        <div className="d-flex justify-content-lg-between mb-3 align-items-start">
          <div className="d-flex">
            <div>
              {questionItem?.author?.realProfilePicUrl != null ? (
                <img
                  src={questionItem?.author?.realProfilePicUrl}
                  alt=""
                  className="width-60-px rounded-circle img-thumbnail"
                />
              ) : (
                <Avatar name={questionItem?.author?.userName+' '+questionItem?.author?.lastName} size="50" round="100px" />
              )}
            </div>
            <div className="ms-2 mt-2">
              <h4 className="mb-1">{questionItem?.author?.userName}</h4>
              <p className="mb-0">
                {CommonFunction.dateFormat(questionItem?.createdAt)}
              </p>
            </div>
          </div>
          <div className={'d-flex'}>
            {
              questionItem?.acceptedAnswer != null &&
              <i className="fas fa-check-circle mt-2 fs-3" style={{color: '#34c38f'}}></i>
            }
            <Dropdown
              isOpen={otherMenu}
              toggle={() => {
                setOtherMenu(!otherMenu)
              }}
            >
              <DropdownToggle
                tag="i"
                className="btn nav-btn"
                type="button"
              >
                <i className="bx bx-dots-vertical-rounded mt-0 fs-3 " />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end bg-card">
                {userData._id === questionItem?.author?._id ? (
                  <>
                    <DropdownItem onClick={editQ}>
                      Edit Question
                    </DropdownItem>
                    <DropdownItem onClick={deleteQ}>
                      Delete Question
                    </DropdownItem>
                  </>
                )
                  :
                  <DropdownItem onClick={reportQ}>
                    Report Question
                  </DropdownItem>
                }
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <p className="fs-5 text-info">{questionItem?.title}</p>
        <p className="">
          <Truncate
            debounce="1000"
            dangerouslySetInnerHTML={{
              __html: questionItem?.body != null ? questionItem?.body : "",
            }}
          />
        </p>
        <div className="d-flex justify-content-between flex-wrap">
          {/* <img src={img1} alt="" width="30%" />
          <img src={img1} className="" alt="" width="30%" />
          <img src={img1} className="" alt="" width="30%" /> */}

          <div className="zoom-gallery d-flex flex-wrap">
            {
              questionItem?.imageUrls &&
              questionItem?.imageUrls.length > 0 &&
              questionItem?.imageUrls.map((d, i1) => {
                return (
                  i1 < 4 &&
                <div className={'position-reletive image-with-overlay d-flex me-2'}>
                  <img
                    key={'gallery_' + i1}
                    src={d}
                    onClick={() => onLightboxOpen(i1)}
                    alt={d}
                  />
                  {
                    i1 == 3 && questionItem?.imageUrls.length > 4  &&
                    <div className={'image-overlay'}
                    onClick={() => onLightboxOpen(i1)}
                    >
                      + {questionItem?.imageUrls.length - 4}
                    </div>
                  }
                </div>
                )
              })
            }
          </div>
        </div>
        {
           isGallery && questionItem?.imageUrls != null &&
           // <Lightbox images={questionItem?.imageUrls} 
           //   onClose={() => { setTimeout(() => {setisGallery(false)}, 400) } }
           // />
 
           <ImageModal 
             images={questionItem?.imageUrls} 
             isGallery={isGallery}
             onClose={() => { setTimeout(() => {setisGallery(false)}, 400) } }
           ></ImageModal>
        }
        <div className="subjectAnswerRow d-flex justify-content-between mt-3 ">
          <div>
            <Badge
              className="rounded-pill py-1 px-3 badge mb-1 me-1"
              style={{
                color: "#fff",
                borderColor: questionItem?.subject?.color
                  ? "#" + questionItem?.subject.color
                  : "blue",
                borderStyle: "solid",
                borderWidth: 1,
                backgroundColor: questionItem?.subject?.color
                  ? "#" + questionItem?.subject.color
                  : "blue",
              }}
            >
            {questionItem?.subject.subjectName}
            </Badge>
            <Badge
              className="me-2 rounded-pill py-1 px-3"
              style={{
                color: questionItem?.subject?.color
                  ? "#" + questionItem?.subject.color
                  : "blue",
                borderColor: questionItem?.subject?.color
                  ? "#" + questionItem?.subject.color
                  : "blue",
                borderStyle: "solid",
                borderWidth: 1,
                backgroundColor: "transparent",
              }}
            >
            {questionItem?.topic.topicName}
            </Badge>
          </div>
          <div>
            <span className="d-flex text-white">
              <h5 className="text-info mb-0 me-1">{questionItem?.answerCount}</h5>
              Answer
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-around mt-5 like-border bottom-action-button">
          {
            questionItem.upvotedBy &&
              questionItem.upvotedBy.length > 0 && userData != null &&
              questionItem.upvotedBy.some((e) => e._id == userData._id) ?
              <div className="like-color text-center d-flex" onClick={upvote}>

                <i className="bx bxs-like fs-3"></i>
                <h6 className="mt-1 ms-2 like-color">
                  {questionItem?.upvoteCount}
                </h6>
              </div>
              :
              <div className="text-center d-flex" onClick={upvote}>
                <i className="bx bxs-like fs-3 text-white"></i>
                <h6 className="mt-1 ms-1 ">
                  {questionItem?.upvoteCount}
                </h6>
              </div>
          }
          {
            questionItem.downvotedBy &&
              questionItem.downvotedBy.length > 0 && userData != null &&
              questionItem.downvotedBy.some((e) => e._id == userData._id) ?
              <div className="like-colortext-center topic-name d-flex" onClick={downvote}>
                <i className="bx bxs-dislike like-color fs-3 "></i>
                <h6 className="mt-1 ms-2 like-color">{questionItem?.downvoteCount}</h6>
              </div>
              :
              <div className="text-center topic-name d-flex" onClick={downvote}>
                <i className="bx bxs-dislike fs-3 text-white"></i>
                <h6 className="mt-1 ms-1 ">
                  {questionItem?.downvoteCount}
                </h6>
              </div>
          }
          <div className="text-center topic-name d-flex">
            <i className="bx bxs-comment fs-3"></i>
            <h6 className="mt-1 ms-2">{questionItem?.commentsCount}</h6>
          </div>
          <div className="text-center  topic-name d-flex" onClick={() => copyUrl()}>
            <i className="bx bxs-copy-alt fs-3"></i>
            <h6 className="mt-1 ms-2">Copy</h6>
          </div> 
          <div className="text-white text-center d-flex" onClick={() => bookmark(!(savedQuestions && savedQuestions.includes(questionItem._id)))}>
            {
              savedQuestions && savedQuestions.includes(questionItem._id) ?
                <>
                  <i className="bx bxs-bookmark fs-3 like-color "></i>
                  <h6 className="mt-1 ms-2 like-color">Save</h6>
                </>
                :
                <>
                  <i className="bx bxs-bookmark fs-3 "></i>
                  <h6 className="mt-1 ms-2 text-white">Save</h6>
                </>
            }
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

const mapStateToProps = state => {
  //console.log("state ----------", state.talks)
  const { error, newsPosts, spaces, talks } = state
  return {
    talks: newsPosts,
    error: error,
    current_space: spaces.current_space,
    unsolvedQuestions: talks.unsolvedQuestions,
    popularTopics: talks.popularTopics,
    questions: talks.questions,
    upvoteQuestionSuccessMessage: talks.upvoteQuestionSuccess
  }
}

const mapDispatchToProps = dispatch => ({
  onUpvoteQuestions: (question_id) => dispatch(upvoteQuestions(question_id)),
  onUpvoteQuestionsSuccess: () => dispatch(clearUpvoteQuestionsSuccess()),
  onGetQuestions: () => dispatch(getQuestions()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuestionMiniCard)
)
