import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import {
    getNotification
  } from "store/actions"
  import { connect } from "react-redux"
//i18n
import { withTranslation } from "react-i18next"

import toastr, { success, error } from "toastr"
import "toastr/build/toastr.min.css"

import CommonFunction from "common/CommonFunction"

const NotificationDropdown = props => {
  const history = useHistory();
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const { 
    notificationsData,
    onGetNotification 
  } = props;

  useEffect(() => {
    onGetNotification();
  }, []);

  const goToSinglePage = (question_id) => {

    //console.log('question_id.........', question_id);
    let q_id;
    if (question_id?.payload?.question != undefined) {
      q_id = question_id?.payload?.question?._id;
    } else if (question_id?.payload?.answer?.question != undefined) {
      q_id = question_id?.payload?.answer?.question?._id;
    } else {
      q_id = question_id?.payload?._id;
    }
    //console.log('q_id..................', q_id);
    if(q_id == undefined) {
      if(question_id.type != "NEW_QUESTION")
      toastr.error("No Question found with this id!")
    } else {
      history.push('/singleQuestion/'+q_id+'/home');
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon" // waves-effect
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell" /> 
          {/* bx-tada */}
          {/* <span className="badge bg-danger rounded-pill">3</span> */}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end notification-width rounded-4 bg-card">
          <div className="p-3">
            <Row className="align-items-center ">
              <Col>
                <h3 className="m-0"> {"Notifications"} </h3>
              </Col>
            </Row>
          </div>
          <hr className="m-0"></hr>
          <SimpleBar style={{ maxHeight: "300px" }}>
            <div className="card-body px-3 py-2">
              <div className="vstack gap-3 mt-0">
                {
                  notificationsData.length > 0 && 
                  notificationsData.map((item, index) => {
                    return (
                      <div className="shadow-lg p-2 mb-2 bg-card rounded d-flex notification-single justify-content-between" key={'noti_'+index} onClick={() => goToSinglePage(item)}>
                        <div style={{width: '70%'}}>
                        <p class="mb-1" style={{flex: 0.9}}>
                          {item?.title}
                        </p>
                        {
                          item.isNew &&
                          <span class="me-1 badge bg-info">New</span>
                        }
                        </div>
                        <p className="text-info mb-0">{CommonFunction.dateFormat(item?.createdAt)}</p>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}


const mapStatetoProps = state => {
  const { error, newsPosts, spaces, talks, searches, notifications } = state
  const {
    current_space
  } = state.spaces
  return {
    notificationsData: notifications.notificationList,
    current_space: current_space
  }
}

const mapDispatchToProps = dispatch => ({
  onGetNotification: () => dispatch(getNotification()),
})

export default connect(mapStatetoProps, mapDispatchToProps)(NotificationDropdown)

