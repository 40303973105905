import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { SUBMIT_CHANGE_PASSWORD } from "./actionTypes"
import { getChangePasswordSuccess, getChangePasswordFail } from "./actions"

function submitChangePasswordApi({ payload: passwordData }) {
    //console.log('passwordData ---------------------------- ',passwordData)

  const CHANGE_PASSWORD_QUERY = `mutation ChangePassword($input: AuthInput!){
                                    changePassword(input: $input)
                                }`;

    let body = JSON.stringify({
        query: CHANGE_PASSWORD_QUERY,
        variables: {
            input: {
                userName: passwordData.username,
                password: passwordData.password,
                userType: "manager",
            },
        },
    });

    return new Promise(function (fulfill, reject) {
        fetch(process.env.REACT_APP_API_BASE_URL, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: body
            })
            .then((res) => res.json())
            .then(response => {
            //console.log(response);
            fulfill(response);
            })
            .catch(error => {
            console.log(error)
            }) 
    });
}

function* submitChangePassword({ payload: passwordData }) {
    const response =  yield call(submitChangePasswordApi, { payload: passwordData  })
    console.log('response, error', response);
    if (response && response.data) {
        yield put(getChangePasswordSuccess(response.data))
    } else {
        yield put(getChangePasswordFail(response.errors[0].message))
    }
}

function* settingChangePasswordSaga() {
  yield takeEvery(SUBMIT_CHANGE_PASSWORD, submitChangePassword)
}

export default settingChangePasswordSaga
