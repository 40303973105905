import React from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Col } from "reactstrap"

import bg from "../../assets/images/login-bg.png"
import logoWhite from "../../assets/images/anonimo-logo-white.png"

const CarouselPage = () => {
  return (
    <React.Fragment>
      <Col xl={6}>
        <div className="auth-full-bg p-0">
          <div className="w-100">
            
          <div className='welcome-border left-login-panel'>
          <img
            src={logoWhite}
            alt=""
          />
          </div>
          <img
            src={bg}
            className="w-100 bg-img"
            alt=""
          />
            {/* <div className="d-flex h-100 flex-column justify-content-center">
              <div className="row justify-content-center">
                <div className="col-lg-8 text-center">
                  <img
                    src={logo}
                    className="w-100"
                    alt=""
                  />
                  <h3>Welcome Nimo</h3>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage
