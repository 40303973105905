import { 
    GET_LEADERBOARDS_SUCCESS, 
    GET_LEADERBOARDS_FAIL,
    GET_LEADERBOARD_SCORE_SUCCESS, 
    GET_LEADERBOARD_SCORE_FAIL 
} from "./actionTypes"

const INIT_STATE = {
  leaderboard: [],
  error: {},
  leaderboardScore: {},
  leaderboardScoreError: {},
}

const leaderboards = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LEADERBOARDS_SUCCESS:
      return {
        ...state,
        leaderboard: action.payload,
      }

    case GET_LEADERBOARDS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case GET_LEADERBOARD_SCORE_SUCCESS:
        return {
          ...state,
          leaderboardScore: action.payload,
        }
  
      case GET_LEADERBOARD_SCORE_FAIL:
        return {
          ...state,
          leaderboardScoreError: action.payload,
        }

    default:
      return state
  }
}

export default leaderboards
