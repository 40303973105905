import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  PHONE_VERIFICATION,
  PHONE_VERIFICATION_SUCCESSFUL,
  PHONE_VERIFICATION_FAILED,
  OTP_VERIFICATION,
  OTP_VERIFICATION_SUCCESSFUL,
  OTP_VERIFICATION_FAILED,
  GET_AVATARS,
  GET_AVATARS_SUCCESSFUL,
  GET_AVATARS_FAILED,
} from "./actionTypes"

export const registerUser = user => {
  return {
    type: REGISTER_USER,
    payload: user,
  }
}

export const registerUserSuccessful = user => {
  console.log('reponse user.............', user);
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}


export const phoneVerification = phone => {
  return {
    type: PHONE_VERIFICATION,
    payload: phone,
  }
}

export const phoneVerificationSuccessful = user => {
  return {
    type: PHONE_VERIFICATION_SUCCESSFUL,
    payload: user,
  }
}

export const phoneVerificationFail = error => {
  return {
    type: PHONE_VERIFICATION_FAILED,
    payload: error,
  }
}

export const otpVerification = (otp, phone, id) => {
  return {
    type: OTP_VERIFICATION,
    payload: {otp, phone, id},
  }
}

export const otpVerificationSuccessful = user => {
  return {
    type: OTP_VERIFICATION_SUCCESSFUL,
    payload: user,
  }
}

export const otpVerificationFail = error => {
  return {
    type: OTP_VERIFICATION_FAILED,
    payload: error,
  }
}
export const getAvatars = () => {
  return {
    type: GET_AVATARS,
  }
}

export const getAvatarsSuccessful = user => {
  return {
    type: GET_AVATARS_SUCCESSFUL,
    payload: user,
  }
}

export const getAvatarsFail = error => {
  return {
    type: GET_AVATARS_FAILED,
    payload: error,
  }
}

