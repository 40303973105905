export const FORGET_PASSWORD = "FORGET_PASSWORD"
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS"
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR"

export const SEND_MOBILE_OTP="SEND_MOBILE_OTP"
export const SEND_MOBILE_OTP_SUCCESS="SEND_MOBILE_OTP_SUCCESS"
export const SEND_MOBILE_OTP_FAIL="SEND_MOBILE_OTP_FAIL"

export const VERIFY_MOBILE_OTP="VERIFY_MOBILE_OTP"
export const VERIFY_MOBILE_OTP_SUCCESS="VERIFY_MOBILE_OTP_SUCCESS"
export const VERIFY_MOBILE_OTP_FAIL="VERIFY_MOBILE_OTP_FAIL"

export const SET_NEW_PASSWORD="SET_NEW_PASSWORD"
export const SET_NEW_PASSWORD_SUCCESS="SET_NEW_PASSWORD_SUCCESS"
export const SET_NEW_PASSWORD_FAIL="SET_NEW_PASSWORD_FAIL"
