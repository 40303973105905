import React, { Component, useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Media,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert
} from "reactstrap"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Truncate from "react-truncate-html"
import CommonFunction from "common/CommonFunction"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import img1 from "../../assets/images/small/img-1.jpg"

import { withRouter, Link } from "react-router-dom"

import { connect, useSelector, useDispatch } from "react-redux"

import Avatar from "react-avatar";

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import {
  upvoteQuestions,
  getQuestions,
  clearUpvoteQuestionsSuccess
} from "../../store/actions"


const AnswerMiniCard = (props) => {
  const { answerItem, onClick, selectQuestion, upvoteAnswer, downvoteAnswer, userData, getAnswerComment, deleteAnswer, reportAnswer, handleAcceptAnswer } = props;
  const [otherMenu, setOtherMenu] = useState(false)
  const [isGallery, setisGallery] = useState(false)
  const [isGalleryZoom, setisGalleryZoom] = useState(false)
  const [photoIndex, setphotoIndex] = useState(0)
  const [gallery, setGallery] = useState(null);

  const upvote = () => {
    upvoteAnswer(answerItem._id)
  }

  const downvote = () => {
    //console.log('answerItem............', answerItem);
    downvoteAnswer(answerItem._id)
  }

  const fetchAnswerComment = () => {
    getAnswerComment(answerItem._id);
  }

  const deleteA = () => {
    deleteAnswer(answerItem._id)
  }

  const reportA = () => {
    reportAnswer(answerItem._id)
  }

  const acceptAnswer = () => {
    handleAcceptAnswer(answerItem?.question?._id, answerItem._id)
  }

  console.log(answerItem?.question);

  return (
    <Card  onClick={() => onClick(answerItem?.question)} className={'mini-answer'}>
      <CardBody>
        <h4 className="mb-1">Question</h4>
        <div className="d-flex justify-content-lg-between mb-3 ms-3">
          <div className="d-flex">
            <div>
              {answerItem?.author?.realProfilePicUrl != null ? (
                <img
                  src={answerItem?.author?.realProfilePicUrl}
                  alt=""
                  className="width-50-px rounded-circle img-thumbnail"
                />
              ) : (

                <Avatar name={answerItem?.author?.userName + ' ' + answerItem?.author?.lastName} size="50" round="100px" />
              )}
            </div>
            <div className="ms-2 mt-2">
              <h4 className="mb-1">{answerItem?.author?.userName}</h4>
              <p className="mb-0">
                {CommonFunction.dateFormat(answerItem?.createdAt)}
              </p>
            </div>
          </div>
        </div>
        <p className="fs-5 text-info ms-3 mb-1">{answerItem?.question?.title}</p>
        {
          answerItem?.question?.body &&
          <p className="ms-3">
          <Truncate
            debounce="1000"
            dangerouslySetInnerHTML={{
              __html: answerItem?.question?.body != null ? answerItem?.question?.body : "",
            }}
          />
        </p>
        }
        <div>
          <Badge
            className="rounded-pill py-1 px-3 badge mb-1 me-1 ms-3"
            style={{
              color: "#fff",
              borderColor: answerItem?.question?.subject?.color
                ? "#" + answerItem?.question?.subject?.color
                : "#fff",
              borderStyle: "solid",
              borderWidth: 1,
              backgroundColor: answerItem?.question?.subject?.color
                ? "#" + answerItem?.question?.subject.color
                : "blue",
            }}
          >
            {answerItem?.question?.subject.subjectName}
          </Badge>
          <Badge
            className="me-2 rounded-pill py-1 px-3  "
            style={{
              color: answerItem?.question?.subject?.color
                ? "#" + answerItem?.question?.subject.color
                : "blue",
              borderColor: answerItem?.question?.subject?.color
                ? "#" + answerItem?.question?.subject.color
                : "blue",
              borderStyle: "solid",
              borderWidth: 1,
              backgroundColor: "transparent",
            }}
          >
            {answerItem?.question?.topic.topicName}
          </Badge>
        </div>
        <h4 className="mb-1 mt-2">Answer</h4>
        <p className="ms-3">
          <Truncate
            debounce="1000"
            dangerouslySetInnerHTML={{
              __html: answerItem?.body != null ? answerItem?.body : "",
            }}
          />
        </p>
      </CardBody>
    </Card >
  )
}

const mapStateToProps = state => {
  //console.log("state ----------", state.talks)
  const { error, newsPosts, spaces, talks } = state
  return {
    talks: newsPosts,
    error: error,
    current_space: spaces.current_space,
    unsolvedQuestions: talks.unsolvedQuestions,
    popularTopics: talks.popularTopics,
    questions: talks.questions,
    upvoteQuestionSuccessMessage: talks.upvoteQuestionSuccess
  }
}

const mapDispatchToProps = dispatch => ({
  onUpvoteQuestions: (question_id) => dispatch(upvoteQuestions(question_id)),
  onUpvoteQuestionsSuccess: () => dispatch(clearUpvoteQuestionsSuccess()),
  onGetQuestions: () => dispatch(getQuestions()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnswerMiniCard)
)
