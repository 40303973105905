import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  SEND_MOBILE_OTP_SUCCESS,
  SEND_MOBILE_OTP_FAIL,
  VERIFY_MOBILE_OTP_SUCCESS,
  VERIFY_MOBILE_OTP_FAIL,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAIL
} from "./actionTypes"

const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
  sendMobileOtpSuccess: null,
  sendMobileOtpError: null,
  verifyMobileOtpSuccess: null,
  verifyMobileOtpError: null,
  setNewPasswordSuccess: null,
  setNewPasswordError: null
}

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      state = {
        ...state,
        forgetSuccessMsg: null,
        forgetError: null,
      }
      break
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgetSuccessMsg: action.payload,
      }
      break
    case FORGET_PASSWORD_ERROR:
      state = { ...state, forgetError: action.payload }
      break
    case SEND_MOBILE_OTP_SUCCESS:
      state = {
        ...state,
        sendMobileOtpSuccess: action.payload,
      }
      break
    case SEND_MOBILE_OTP_FAIL:
      state = {
        ...state,
        sendMobileOtpError: action.payload,
      }
      break
    case VERIFY_MOBILE_OTP_SUCCESS:
      state = {
        ...state,
        verifyMobileOtpSuccess: action.payload,
      }
      break
    case VERIFY_MOBILE_OTP_FAIL:
      state = {
        ...state,
        verifyMobileOtpError: action.payload,
      }
      break
    case SET_NEW_PASSWORD_SUCCESS:
      state = {
        ...state,
        setNewPasswordSuccess: action.payload,
      }
      break
    case SET_NEW_PASSWORD_FAIL:
      state = {
        ...state,
        setNewPasswordError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default forgetPassword
