import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_SAVED_QUESTIONS,
  GET_MY_ACTIVITIES,
  GET_STUDENT_DETAILS,
  GET_ALL_USERS,
  UPDATE_USER
} from "./actionTypes"
import {
  getMyActivitiesDetailFail,
  getMyActivitiesDetailSuccess,
  getSavedQuestionsFail,
  getSavedQuestionsSuccess,
  getStudentDetailsFail,
  getStudentDetailsSuccess,
  getAllusersFail,
  getAllusersSuccess,
  updateUserFail,
  updateUserSuccess
} from "./actions"

//Include Both Helper File with needed methods
import {
  FILTER_SAVED_QUESTION,
  GET_STUDENT,
  GET_USER_BY_STR,
  VERIFY_USERNAME,
  UPDATE_STUDENT
} from "helpers/url_helper"

import { get, getWithAuth, getWithouAuth } from "helpers/api_helper"

function* fetchSavedQuestion() {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));

  let options = {
    userId: authUser?._id,
    scope: 'world',
    space: currentSpace?._id,
    page: 1,
    limit: 20,
    populate: [
      { path: "author" },
      { path: "upvotedBy" },
      { path: "downvotedBy" },
      { path: "subject" },
      { path: "schoolClass" },
      { path: "space" },
      { path: "topic" }
    ],
    sort: { createdAt: "desc" }
  };

  let body = JSON.stringify({
    query: FILTER_SAVED_QUESTION,
    variables: {
      options: options
    }
  });

  try {
    const response = yield call(getWithAuth, { payload: { body, history } })
    yield put(getSavedQuestionsSuccess(response?.getPaginatedSavedQuestions))
  } catch (error) {
    yield put(getSavedQuestionsFail(error))
  }
}

function* fetchStudentDetails() {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  let body = JSON.stringify({
    query: GET_STUDENT,
    variables: {
      options: {
        userName: authUser.userName,
        populate: [
          {
            path: "savedQuestions", populate: { path: "author" }, options: {
              skip: 0,
              limit: 1000
            }
          },
          { path: "leaderboardScores", populate: { path: "space" } }
        ]
      },
    },
  });

  // if (props.questionFilterBy?.subjectQuery?.length > 0) {
  //   data.subjectIds = props.questionFilterBy.subjectQuery
  // }

  try {
    const response = yield call(getWithAuth, { payload: { body, history } })
    console.log('response....................', response);
    yield put(getStudentDetailsSuccess(response.getStudent))
  } catch (error) {
    yield put(getStudentDetailsFail(error))
  }
}

function* fetchMyActivities() {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));

  // if (props.questionFilterBy?.subjectQuery?.length > 0) {
  //   data.subjectIds = props.questionFilterBy.subjectQuery
  // }

  let body = JSON.stringify({
    query: `query {
    getPaginatedStudentActivities(
      options: {
        paginateOptions:{
            page:1
            limit:100
            populate: [{path: "data"}, {path: "student"}, {path: "space"}, {path: "subject"}, {path: "topic"}],
            sort: {createdAt: "desc"}
        },
         queryVals:{student: "${authUser?._id}", space: "${currentSpace?._id}"}
      }
    ) {
        totalPages
        page
        offset
        docs {
             _id
             createdAt
             dataResolve
             activityType
             subject {
                 _id
                 subjectName
                 color
             }
             topic {
                 _id
                 topicName
             }
             student {
                 userName
                 firstName
                 lastName
             }
            data {
             ... on Question {
                 _id
                 title
                 questionBody:body
                 subject {
                     _id
                 }
             }
             ... on Answer {
                 _id
                 body
                 question {
                     _id
                 }
             }
             ... on Comment {
                 _id
                 body
                 question {
                     _id
                 }
                 answer {
                     _id
                 }
             }
             ... on QuestionComment {
                 _id
                 body
                 question {
                     _id
                 }
             }
            }
        }
    }
  }`,
  });

  try {
    const response = yield call(getWithAuth, { payload: { body, history } })
    yield put(getMyActivitiesDetailSuccess(response.getPaginatedStudentActivities))
  } catch (error) {
    yield put(getMyActivitiesDetailFail(error))
  }
}

function* getAllusers() {
  let body = JSON.stringify({
    query: GET_USER_BY_STR,
    variables: {
      options: {
        query: {
          bool: {
            must: [
              { term: { isRegistered: true } }
            ],
            should: [
              {
                query_string: {
                  query: "*",
                  fields: [
                    "userName"
                  ],
                  boost: 5
                }
              }
            ],
            minimum_should_match: 1
          }
        },
        skip: 0,
        limit: 1000
      }
    }
  });

  try {
    const response = yield call(getWithAuth, { payload: { body, history } })
    yield put(getAllusersSuccess(response?.searchUsers?.hits))
  } catch (error) {
    yield put(getAllusersFail(error))
  }
}

function* udpateUser({ payload: user }) {

  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  //console.log('user..................',user)

  try {

    if (authUser.userName != user.username) {
      let body = JSON.stringify({
        query: VERIFY_USERNAME,
        variables: {
          options: {
            userName: user.username
          }
        }
      });
      const response = yield call(getWithouAuth, { payload: { body, history } })
      //console.log('response------------->>>>>>>>',response)
      if (response?.data?.checkUserName == true) {
        let dataBody = {
          id: user.id,
          userName: user.username,
          profileDesc: user.about,
          //approvalStatusHome: 'NOT_INITIATED',
          realProfilePicUrl: user.realProfilePicUrl,
          firstName: user.firstname,
          lastName: user.lastname,
          //password: user.password,
          isRegistered: true,
          registrationType: 'SELF',    //values: SELF, PRE
        }

        let body = JSON.stringify({
          query: UPDATE_STUDENT,
          variables: {
            options: dataBody
          }
        });

        const response1 = yield call(getWithouAuth, { payload: { body, history } })
        if (response1 && response1?.data?.updateStudent) {
          let body = JSON.stringify({
            query: GET_STUDENT,
            variables: {
              options: {
                userName: user.username,
                populate: [
                  {
                    path: "savedQuestions", populate: { path: "author" }, options: {
                      skip: 0,
                      limit: 1000
                    }
                  },
                  { path: "leaderboardScores", populate: { path: "space" } }
                ]
              },
            },
          });
          const response2 = yield call(get, { payload: { body, history } })
          //console.log('response2..............', response2)
          if(response2?.getStudent) {
            localStorage.setItem("authUser", JSON.stringify(response2.getStudent))
          }
          yield put(updateUserSuccess({ data: true }))
        } else {
          yield put(updateUserFail(response1?.errors))
        }
      } else {
        yield put(updateUserFail({ message: 'Username already exist!' }))
      }
    } else {
      let dataBody = {
        id: user.id,
        profileDesc: user.about,
        //approvalStatusHome: 'NOT_INITIATED',
        realProfilePicUrl: user.realProfilePicUrl,
        firstName: user.firstname,
        lastName: user.lastname,
        //password: user.password,
        isRegistered: true,
        registrationType: 'SELF',    //values: SELF, PRE
      }

      let body = JSON.stringify({
        query: UPDATE_STUDENT,
        variables: {
          options: dataBody
        }
      });

      const response1 = yield call(getWithouAuth, { payload: { body, history } })
      if (response1 && response1?.data?.updateStudent) {
        // 
          let body = JSON.stringify({
            query: GET_STUDENT,
            variables: {
              options: {
                userName: authUser.userName,
                populate: [
                  {
                    path: "savedQuestions", populate: { path: "author" }, options: {
                      skip: 0,
                      limit: 1000
                    }
                  },
                  { path: "leaderboardScores", populate: { path: "space" } }
                ]
              },
            },
          });
          const response2 = yield call(get, { payload: { body, history } })
          //console.log('response2..............', response2)
          if(response2?.getStudent) {
            localStorage.setItem("authUser", JSON.stringify(response2.getStudent))
          }

        // 
        yield put(updateUserSuccess({ data: true }))
      } else {
        yield put(updateUserFail(response1?.errors))
      }
    }
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* projectsSaga() {
  yield takeEvery(GET_SAVED_QUESTIONS, fetchSavedQuestion)
  yield takeEvery(GET_MY_ACTIVITIES, fetchMyActivities)
  yield takeEvery(GET_STUDENT_DETAILS, fetchStudentDetails)
  yield takeEvery(GET_ALL_USERS, getAllusers)
  yield takeEvery(UPDATE_USER, udpateUser)
}

export default projectsSaga
