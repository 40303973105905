import React, { Component, useEffect, useState, useRef } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Media,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert
} from "reactstrap"
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
import Truncate from "react-truncate-html"
// Form Editor
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import CommonFunction from "common/CommonFunction"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import img1 from "../../assets/images/small/img-1.jpg"

import { withRouter, Link } from "react-router-dom"

import { connect, useSelector, useDispatch } from "react-redux"
import { uploadFile } from "helpers/UploadToServerHelper";
import img8 from "../../assets/images/camera.png"

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import {
  upvoteQuestions,
  getQuestions,
  clearUpvoteQuestionsSuccess,
  getTopicsBySubject,
  addQuestion,
  updateQuestion
} from "../../store/actions"


const AddQuestionModal = (props) => {
  const { questionModal,
    closeQuestionModal,
    subjectList,
    onGetTopicsBySubject,
    topicList,
    onPostAddQuestion,
    addQuestionSuccess,
    handleAddQuestionSuccess,
    isEditQuestion,
    selectQuestion,
    onPostUpdateQuestion,
    updateQuestionSuccess,
    handleUpdateQuestionSuccess
  } = props;
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedTopicText, setSelectedTopicText] = useState(null);
  const [topicInput, setTopicInput] = useState(null);
  const [questionTitle, setQuestionTitle] = useState(null);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [titleError, setTitleError] = useState(null);
  const [subjectError, setSubjectError] = useState(null);
  const [topicError, setTopicError] = useState(null);
  const [imageLimitError, setImageLimitError] = useState(null)
  // useEffect(() => {
  //   let dataList = [];
  //   subjectList.map((item, index) => {
  //     let data = {}
  //     data.label = item.subjectName;
  //     data.value = item._id;
  //     dataList.push(data);
  //   })
  //   setSubjectArray(dataList);
  // }, [subjectList])

  const [addQuestionImage, setAddQuestionImage] = useState([])
  const [oldQuestionImage, setOldQuestionImage] = useState([])

  const onAddQuestionImageChange = event => {
    //console.log('on image change.................', event);
    let dataArray = []
    let files = event.target.files

    if ((oldQuestionImage.length + addQuestionImage.length + files.length) > 10) {
      setImageLimitError('Allow maximum 10 images.')
    } else {
      setImageLimitError(null)
    }

    if (files.length > 0) {
      let files_length = files.length <= 10 ? files.length : 10
      if (oldQuestionImage.length > 0 || addQuestionImage.length > 0) {
        let new_files_length = 10 - (oldQuestionImage.length + addQuestionImage.length);
        files_length =  files_length <= new_files_length ? files_length : new_files_length;
      }
      //console.log('files_length................',files_length)  
      for (let i = 0; i <= files_length - 1; i++) {
        //dataArray.push(URL.createObjectURL(event.target.files[i]))
        const file = {
          uri: event.target.files[i],
          url: URL.createObjectURL(event.target.files[i]),
          name: files[i].name,
          type: files[i].type,
        };
        dataArray.push(file);
      }
      
      setAddQuestionImage([...addQuestionImage, ...dataArray])
    }
  }

  const removeAddQuestionImg = item => {
    //console.log('remove index....................', index);
    let dataArray = addQuestionImage
    dataArray = dataArray.filter(function (e) {
      return e !== item
    })
    setAddQuestionImage(dataArray)
    if ((dataArray.length + oldQuestionImage.length) < 10) {
      setImageLimitError(null)
    }
  }

  const inputFile = useRef(null)

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click()
  }

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  }

  const getDescription = () => {
    if (editorState) {
      const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      return html;
    } else {
      return null
    }
  };

  const onchangeSubject = (e) => {
    //console.log('e............', e.target.checked, e.target.value)
    if (e.target.checked) {
      setSelectedSubject(e.target.value);
      setSubjectError(null)
    }
  }

  const onchangeTopics = (e, topic) => {
    if (e.target.checked) {
      setSelectedTopicText(topic)
      setSelectedTopic(e.target.value);
      setTopicError(null)
    }
  }

  const onInputTopicChange = (e) => {
    onGetTopicsBySubject(selectedSubject, e.target.value);
    setTopicInput(e.target.value);
  }

  useEffect(() => {
    if (selectedSubject != null) {
      onGetTopicsBySubject(selectedSubject, '');
    }
  }, [selectedSubject])

  const onSelectedTopicText = () => {
    setSelectedTopicText(topicInput);
    setSelectedTopic(null);
  }

  const handleQuestionTitle = (event) => {
    setQuestionTitle(event.target.value)
    if (event.target.value == "" || event.target.value == null || event.target.value.trim().length == 0) {
      setTitleError('Please add question title.')
    } else {
      setTitleError('')
    }
  }

  //console.log('selectedSubject............', selectedSubject);
  const handleQuestionSubmit = async(event) => {
    event.preventDefault();
    let validate = true;
    if (
      questionTitle === "" ||
      questionTitle === null ||
      questionTitle.trim().length == 0
    ) {
      validate = false
      setTitleError('Please add question title.')
    } else {
      setTitleError(null)
    }

    if ((oldQuestionImage.length + addQuestionImage.length) > 10) {
      validate = false
      setImageLimitError('Allow maximum 10 images.')
    } else {
      setImageLimitError(null)
    }

    if (!isEditQuestion && (selectedSubject === "" || selectedSubject === null)) {
      validate = false
      setSubjectError('Please select a subject')
    } else {
      setSubjectError(null);
    }

    if (!isEditQuestion && ((selectedTopic === "" || selectedTopic === null) && (selectedTopicText === "" || selectedTopicText === null))) {
      validate = false
      setTopicError('Please select or create a topic')
    } else {
      setTopicError(null);
    }

    if (validate) {
      let imgArr = [];

      const description = getDescription()
      let payload = {
        title : questionTitle,
        description : description,
        subject : selectedSubject,
        topic: selectedTopic ? selectedTopic : selectedTopicText,
        new_topic: selectedTopic ? false : true,
        imageUrls: imgArr
      }

      if (addQuestionImage?.length > 0) {
        let s_path = addQuestionImage.map( async (img) => {

          console.log(img);
          const uploadResult = await uploadFile(img);
          imgArr.push(uploadResult.location);
          // const index = addQuestionImage.findIndex((res) => res.path === uploadResult.filepath);
          // imgArr.push(uploadResult.Location);

          if(addQuestionImage.length == imgArr.length) {
            if (isEditQuestion) {
              const allImages = [...oldQuestionImage, ...imgArr]
              payload.questionId = selectQuestion?._id;
              payload.imageUrls = allImages;
              onPostUpdateQuestion(payload)
            } else {
              payload.imageUrls = imgArr;
              onPostAddQuestion(payload)
            }
          }
        })
      } else {
        if (isEditQuestion) {
          payload.questionId = selectQuestion?._id;
          payload.imageUrls = oldQuestionImage;
          onPostUpdateQuestion(payload)
        } else {
          onPostAddQuestion(payload)
        }
      }
    }
  }

  useEffect(() => {
    if (Object.keys(addQuestionSuccess).length) {
      handleAddQuestionSuccess(addQuestionSuccess);
      resetFormFields();
    }
  }, [addQuestionSuccess])

  const handleCloseQuestionModal = () => {
    resetFormFields()
    closeQuestionModal()
  }

  const resetFormFields = () => {
    setEditorState(() => EditorState.createEmpty())
    setQuestionTitle(null)
    setSelectedSubject(null)
    setSelectedTopic(null)
    setSelectedTopicText(null)
    setAddQuestionImage([])
    setOldQuestionImage([])
    setTitleError(null)
    setImageLimitError(null)
    setSubjectError(null)
    setTopicError(null)
  }

  useEffect(() => {
    if (isEditQuestion && selectQuestion) {
      console.log('selectQuestion........',selectQuestion)
      setQuestionTitle(selectQuestion?.title)
      setSelectedSubject(selectQuestion?.subject?._id)
      setSelectedTopic(selectQuestion?.topic?._id)
      if (selectQuestion?.body) {
        const blocksFromHtml = htmlToDraft(selectQuestion?.body);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setEditorState(EditorState.createWithContent(contentState));
      }
      
      if (selectQuestion?.imageUrls) {
        setOldQuestionImage(selectQuestion?.imageUrls)
      }
    }
  },[selectQuestion, isEditQuestion])

  const removeOldQuestionImg = item => {
    let dataArray = oldQuestionImage
    dataArray = dataArray.filter(function (e) {
      return e !== item
    })
    setOldQuestionImage(dataArray)
    if ((dataArray.length + addQuestionImage.length) < 10) {
      setImageLimitError(null)
    }
  }

  useEffect(() => {
    if (Object.keys(updateQuestionSuccess).length) {
      handleUpdateQuestionSuccess(updateQuestionSuccess);
      resetFormFields()
    }
  }, [updateQuestionSuccess])

  return (
    <Modal
      isOpen={questionModal}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="answerModal modal-dialog modal-lg"
      tabIndex="-1"
      backdrop="static"
    // toggle={() => {
    //   setQuestionModal(!questionModal)
    // }}
    >
      <div className="modal-content" id="add-answer-modal">
        <ModalBody className="p-0">
          <Card>
            <CardBody>
              <Container>
                <div className="d-flex justify-content-lg-between align-items-center">
                  <div className="ms-0 mt-0 mb-0">
                    <span
                      className="text-white lebel-head"
                    >
                      {isEditQuestion ? 'Edit' : 'Ask'} your Question
                    </span>
                  </div>

                  <div
                    className="text-white"
                    onClick={() => handleCloseQuestionModal()}
                  >
                    <i className=" mdi mdi-close mt-2 fs-3 "></i>
                  </div>
                </div>
                <hr />
                {
                  isEditQuestion && (
                    <>
                    <div className="d-flex ">
                    <p className="ms-2 mt-4 fs-7 mb-2 mx-2">Subject</p> <span className="ms-2 mt-4 fs-7 mb-2 color-answer-btn px-2 py-1 rounded-4 text-white">{selectQuestion?.subject?.subjectName}</span>
                    </div>
                    <div className="d-flex">
                    <p className="ms-2 mt-4 fs-7 mb-2 mx-2">Topic</p> <span className="ms-2 mt-4 fs-7 mb-2 color-answer-btn px-2 py-1 rounded-4 text-white">{selectQuestion?.topic?.topicName}</span>
                    </div>
                    </>
                  )
                }
                

                <p className="ms-2 mt-4 fs-7 mb-2 label-text">
                Please Add Question Title<span className="text-danger fs-3">*</span>
                </p>
                <input
                  className="form-control bg-input text-black text-secondary p-3 rounded-4"
                  type="text"
                  placeholder=""
                  onChange={(e) => { handleQuestionTitle(e) }}
                  value={questionTitle}
                />
                {
                  titleError && <div class="invalid-feedback d-block">{titleError}</div>
                }

                <p className="ms-2 mt-4 fs-7 mb-2 label-text">
                  Add Description (optional)
                </p>
                <Editor
                  wrapperClassName="wrapper-class bg-input"
                  editorClassName="editor-class bg-input text-black"
                  toolbarClassName="toolbar-class bg-input"
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "fontSize",
                      "fontFamily",
                      "list",
                      "textAlign",
                      "colorPicker",
                      "link",
                      "emoji",
                    ],
                  }}
                />
                {
                  <div className="d-flex flex-wrap mt-3">
                  {
                    oldQuestionImage.length > 0 &&
                    oldQuestionImage.map((img, index) => {
                        return (
                          <div className="position-relative">
                            <i
                              className="mdi mdi-close add-answer-close-icon"
                              onClick={() => removeOldQuestionImg(img)}
                            ></i>
                            <img
                              src={img}
                              className="add-answer-img"
                              alt=""
                            />
                          </div>
                        )
                      })
                    }

                    {addQuestionImage.length > 0 &&
                      addQuestionImage.map((img, index) => {
                        return (
                          <div className="position-relative">
                            <i
                              className=" mdi mdi-close add-answer-close-icon"
                              onClick={() => removeAddQuestionImg(img)}
                            ></i>
                            <img
                              src={img.url}
                              className="add-answer-img"
                              alt=""
                            />
                          </div>
                        )
                      })}
                    <input
                      type="file"
                      id="file"
                      multiple
                      onChange={onAddQuestionImageChange}
                      ref={inputFile}
                      style={{ display: "none" }}
                    />
                    {/*  <img
                  src={img8}
                  alt=""
                  onClick={onButtonClick}
                  className="add-answer-img"
                /> */}

                    <button onClick={onButtonClick} class="btn-new border-line btn-square box-add-img btn-height width-20 rounded-4 text-white"><i class="fa fa-plus fs-4 text-white"></i> Add Image</button>
                    {
                      imageLimitError && <div class="invalid-feedback d-block">{imageLimitError}</div>
                    }
                  </div>
                  }
                {
                  !isEditQuestion && (
                    <>
                      <div>
                        <p className="ms-2 mt-4 fs-7 mb-2 label-text">Select Subject<span className="text-danger fs-3">*</span></p>
                        {
                          subjectList.length > 0 &&
                          subjectList.map((item, index) => {
                            return (

                              <div
                                className="btn-group btn-group-toggle"
                                data-toggle="buttons"
                              >
                                <div className="me-1">
                                  <input
                                    type="checkbox"
                                    className="btn-check"
                                    id={item._id}
                                    autoComplete="off"
                                    value={item._id}
                                    checked={selectedSubject == item._id ? true : false}
                                    onChange={(e) => { onchangeSubject(e) }}
                                  />
                                  <label
                                    className="btn btn-outline-info rounded-pill px-4"
                                    htmlFor={item._id}
                                  >
                                    {item.subjectName}
                                    {selectedSubject == item._id ?
                                      <i class="bx bx-check font-size-16 align-middle ms-2"></i>
                                      :
                                      <i class="bx bx-plus font-size-16 align-middle ms-2"></i>
                                    }
                                  </label>
                                </div>
                              </div>
                            )
                          })
                        }
                        {
                          subjectError && <div class="invalid-feedback d-block">{subjectError}</div>
                        }
                      </div>
                      <p className="ms-2 mt-4 fs-7 mb-2 label-text">Add Topic<span className="text-danger fs-3">*</span></p>
                      <div className="position-relative">
                        <input
                          className="form-control bg-input text-black text-secondary p-3 rounded-4 mb-2"
                          type="text"
                          onChange={(e) => onInputTopicChange(e)}
                          value={topicInput}
                        />
                        <button class="btn color-answer-btn text-white position-absolute"
                            style={{
                              padding: 5,
                              width: 120,
                              borderRadius: "19px",
                              border: "1px solid #0073B1",
                              top: '20%',
                              right: 10,
                            }}
                            onClick={() => onSelectedTopicText()}
                        >Add new topic</button>
                      </div>
                      {
                          topicError && <div class="invalid-feedback d-block">{topicError}</div>
                      }
                      {
                        selectedTopicText != null && 
                        <div className="mb-1"><strong>Selected Topic:</strong> {selectedTopicText} </div>
                      }
                      {
                        topicList.length > 0 &&
                        topicList.map((item, index) => {
                          return (
                            <div
                              className="btn-group btn-group-toggle"
                              data-toggle="buttons"
                            >
                              <div className="me-1">
                                <input
                                  type="checkbox"
                                  className="btn-check"
                                  id={item._id}
                                  autoComplete="off"
                                  value={item._id}
                                  checked={selectedTopic == item._id ? true : false}
                                  onChange={(e) => { onchangeTopics(e, item.topicName) }}
                                />
                                <label
                                  className="btn btn-outline-info rounded-pill px-4"
                                  htmlFor={item._id}
                                >
                                  {item.topicName}
                                  {selectedTopic == item._id ?
                                    <i class="bx bx-check font-size-16 align-middle ms-2"></i>
                                    :
                                    <i class="bx bx-plus font-size-16 align-middle ms-2"></i>
                                  }
                                </label>
                              </div>
                            </div>
                          )
                        })
                      }
                    </>
                  )
                }

                <div className="row">
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      class="btn color-answer-btn mt-5 text-white"
                      style={{
                        width: "20%",
                        borderRadius: "19px",
                        border: "1px solid #0073B1",

                      }}
                      onClick={e => {handleQuestionSubmit(e)}}
                    >
                      {isEditQuestion ? 'Update' : 'Submit'}
                    </button>
                  </div>
                </div>
              </Container>
            </CardBody>
          </Card>
        </ModalBody>
      </div>
    </Modal>
  )
}

const mapStateToProps = state => {
  //console.log("state ----------", state.talks)
  const { error, newsPosts, spaces, talks } = state
  return {
    talks: newsPosts,
    error: error,
    current_space: spaces.current_space,
    unsolvedQuestions: talks.unsolvedQuestions,
    questions: talks.questions,
    upvoteQuestionSuccessMessage: talks.upvoteQuestionSuccess,
    subjectList: talks.subjectsSuccess,
    topicList: talks.topics,
    addQuestionSuccess: talks.addQuestion,
    updateQuestionSuccess: talks.updateQuestion
  }
}

const mapDispatchToProps = dispatch => ({
  onUpvoteQuestions: (question_id) => dispatch(upvoteQuestions(question_id)),
  onUpvoteQuestionsSuccess: () => dispatch(clearUpvoteQuestionsSuccess()),
  onGetQuestions: () => dispatch(getQuestions()),
  onGetTopicsBySubject: (subject_id, topicInput) => dispatch(getTopicsBySubject(subject_id, topicInput)),
  onPostAddQuestion: (data) => dispatch(addQuestion(data)),
  onPostUpdateQuestion: (data) => dispatch(updateQuestion(data))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddQuestionModal)
)
