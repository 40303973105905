import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { 
    SEARCH_ANSWERS, 
    SEARCH_QUESTIONS 
  } from "./actionTypes"
import {
  searchQuestionsSuccess,
  searchQuestionsFail,
  searchAnswersSuccess,
  searchAnswersFail,
} from "./actions"

import { get, getWithAuth } from "helpers/api_helper"

//Include Both Helper File with needed methods
import { GET_QUESTION_SEARCH,
  GET_ANSWER_SEARCH } from "helpers/url_helper"

function* postSearchQuestions({payload: optionData}) {
  console.log('optionData...........', optionData);
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  if(optionData.searchText != '') {
    try {
      let queQuery = {
        bool: {
          must: [
            { term: { scope: "world" } },
            { term: { space: currentSpace._id } },
          ],
          should: [
            {
              match: {
                title: {
                  query: optionData.searchText,
                  fuzziness: "AUTO",
                  boost: 2
                }
              }
            },
            {
              match: {
                body: {
                  query: optionData.searchText,
                  fuzziness: "AUTO",
                  boost: 1
                }
              }
            },
            {
              query_string: {
                query: optionData.searchText + "*",
                fields: [
                  "title",
                  "body",
                  "tags"
                ],
                boost: 5
              }
            }
          ],
          minimum_should_match: 1,
        }
      };
  
      if (optionData.myQuestions == true) {
        queQuery.bool.must.push({ term: { author: authUser?._id } });
      }
  
      if (optionData.subject != null && optionData.subject != '') {
        queQuery.bool.must.push({ terms: { subject: [optionData.subject] } });
      }
  
      if (optionData.topic != null && optionData.topic != '') {
        queQuery.bool.must.push({ terms: { topic: [optionData.topic] } });
      }
  
      if (optionData.solvedQuestions && !optionData.unsolvedQuestions) {
        queQuery.bool.must.push({ exists: { field: "acceptedAnswer" } });
      }
  
      if (!optionData.solvedQuestions && optionData.unsolvedQuestions) {
        queQuery.bool.must_not = {
          exists: { field: "acceptedAnswer" }
        }
      }
  
      let sortby = {createdAt: "desc"};
  
      if(optionData.sort == 2) {
        sortby = {answerCount: "desc"}
      }
  
      let body = JSON.stringify({
        query: GET_QUESTION_SEARCH,
        variables: {
          options: {
            query: queQuery,
            skip: 0,
            limit: 10,
            sort: [
              sortby
            ]
          }
        },
      });
  
      const response = yield call(getWithAuth, { payload: { body, history } })
      //console.log('response...........', response);
      yield put(searchQuestionsSuccess(response != null && response?.searchQuestions ? response?.searchQuestions : []))
    } catch (error) {
      yield put(searchQuestionsFail(error))
    }
  } else {
    yield put(searchQuestionsSuccess([]))
  }
}

function* postSearchAnswers({payload: optionData}) {

  //  console.log('optionData....................', optionData);
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let currentSpace = JSON.parse(localStorage.getItem("currentSpace"));
  try {
    let answerFilterQuery = {}

    // if (optionData.myQuestions == true) {
    //   answerFilterQuery.questionAuthor = authUser?._id;
    // }

    // if (optionData.subject != null && optionData.subject != '') {
    //   answerFilterQuery.subjects = optionData.subject
    // }

    // if (optionData.topic != null && optionData.topic != '') {
    //   answerFilterQuery.topics = optionData.topic
    // }

    // if (optionData.solvedQuestions && !optionData.unsolvedQuestions) {
    //   answerFilterQuery.solved = true;
    // }

    // if (!optionData.solvedQuestions && optionData.unsolvedQuestions) {
    //   answerFilterQuery.unsolved = true;
    // }

    let sortby = {createdAt: "desc"};

    if(optionData.sort == 2) {
      sortby = {answerCount: "desc"}
    }

    let body = JSON.stringify({
      query: GET_ANSWER_SEARCH,
      variables: {
        options: {
          query: {
            bool: {
              must: [
                { term: { scope: "world" } },
                { term: { space: currentSpace._id } },
              ],
              should: [
                {
                  match: {
                    body: {
                      query: optionData.searchText,
                      fuzziness: "AUTO",
                      boost: 1
                    }
                  }
                },
                {
                  query_string: {
                    query: optionData.searchText + "*",
                    fields: [
                      "body"
                    ],
                    boost: 5
                  }
                }
              ],
              minimum_should_match: 1
            }
          },
          filters: answerFilterQuery,
          skip: 0,
          limit: 10,
          sort: [
            sortby
          ]
        }
      }

    });
    console.log('body............', body);
    const response = yield call(getWithAuth, { payload: { body, history } })
    console.log('body............', response);
    yield put(searchAnswersSuccess(response != null && response?.searchAnswers ? response?.searchAnswers : []))
  } catch (error) {
    console.log('error............', error);
    yield put(searchAnswersFail(error))
  }
}

function* invoiceSaga() {
  yield takeEvery(SEARCH_ANSWERS, postSearchAnswers)
  yield takeEvery(SEARCH_QUESTIONS, postSearchQuestions)
}

export default invoiceSaga
