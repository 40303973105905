import React, { Component, useEffect, useState, useRef } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Media,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert
} from "reactstrap"
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
import Truncate from "react-truncate-html"
// Form Editor
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import CommonFunction from "common/CommonFunction"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import img1 from "../../assets/images/small/img-1.jpg"

import { withRouter, Link } from "react-router-dom"

import { connect, useSelector, useDispatch } from "react-redux"
import { uploadFile } from "helpers/UploadToServerHelper";

import img8 from "../../assets/images/camera.png"

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import {
  upvoteQuestions,
  getQuestions,
  clearUpvoteQuestionsSuccess,
  getTopicsBySubject,
  addAnswer,
  updateAnswer
} from "../../store/actions"


const AddAnswerModal = (props) => {
  const { answerModal,
    closeAnswerModal,
    subjectList,
    onGetTopicsBySubject,
    topicList,
    selectQuestion,
    onPostAddAnswer,
    addAnswerSuccess,
    handleAddAnswerSuccess,
    isEditAnswer,
    selectAnswer,
    onPostUpdateAnswer,
    updateAnswerSuccess,
    handleUpdateAnswerSuccess
  } = props;
  const [addAnswerImage, setAddAnswerImage] = useState([])
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [answerError, setAnswerError] = useState(null);
  const [imageLimitError, setImageLimitError] = useState(null)
  const [oldAnswerImage, setOldAnswerImage] = useState([])

  const onAddAnserImageChange = event => {
    //console.log('on image change.................', event);
    let dataArray = []
    let files = event.target.files

    if ((oldAnswerImage.length + addAnswerImage.length + files.length) > 10) {
      setImageLimitError('Allow maximum 10 images.')
    } else {
      setImageLimitError(null)
    }

    if (files.length > 0) {
      let files_length = files.length <= 10 ? files.length : 10
      if (oldAnswerImage.length > 0 || addAnswerImage.length > 0) {
        let new_files_length = 10 - (oldAnswerImage.length + addAnswerImage.length);
        files_length =  files_length <= new_files_length ? files_length : new_files_length;
      }

      for (let i = 0; i <= files_length - 1; i++) {
        //dataArray.push(URL.createObjectURL(event.target.files[i]))
        const file = {
          uri: event.target.files[i],
          url: URL.createObjectURL(event.target.files[i]),
          name: files[i].name,
          type: files[i].type,
        };
        dataArray.push(file);
      }
      setAddAnswerImage([...addAnswerImage, ...dataArray])
    }
  }

  const removeAddAnsImg = item => {
    //console.log('remove index....................', index);
    let dataArray = addAnswerImage
    dataArray = dataArray.filter(function (e) {
      return e !== item
    })
    setAddAnswerImage(dataArray)
    if ((dataArray.length + oldAnswerImage.length) < 10) {
      setImageLimitError(null)
    }
  }


  const inputFile = useRef(null)

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click()
  }

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);

    const isEmpty = isEditorEmpty(editorState);
    if (isEmpty) {
      setAnswerError('Please add your answer');
    }else {
      setAnswerError(null)
    }
  }

  const getAnswerDescription = () => {
    if (editorState) {
      const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      return html;
    } else {
      return null
    }
  };

  // Function to check if the editor content is empty
  const isEditorEmpty = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const blocks = rawContentState.blocks;
    
    // Loop through the blocks and check for text
    for (const block of blocks) {
      if (block.text.trim().length > 0) {
        return false; // If any block has text, return false (not empty)
      }
    }

    return true; // If no block has text, return true (empty)
  };

  const handleAnswerSubmit = async(event) => {
    event.preventDefault();
    let validate = true;
    const isEmpty = isEditorEmpty(editorState);
    
    if (isEmpty) {
      validate = false
      setAnswerError('Please add your answer');
    } else {
      setAnswerError(null)
    }

    if ((oldAnswerImage.length + addAnswerImage.length) > 10) {
      validate = false
      setImageLimitError('Allow maximum 10 images.')
    } else {
      setImageLimitError(null)
    }

    if (validate) {
      let imgArr = [];
      const description = getAnswerDescription();
      let payload = {
        description : description,
        questionId : selectQuestion?._id,
        imageUrls: imgArr
      }

      //console.log('addAnswerImage...........', addAnswerImage);
      if (addAnswerImage?.length > 0) {
        let s_path = addAnswerImage.map( async (img) => {
          const uploadResult = await uploadFile(img);
          imgArr.push(uploadResult.location);

          if(addAnswerImage.length == imgArr.length) {
            if (isEditAnswer) {
              const allImages = [...oldAnswerImage, ...imgArr]
              payload.answerId = selectAnswer?._id;
              payload.imageUrls = allImages;
              //console.log('payload-----------------',payload)
              onPostUpdateAnswer(payload)
            } else {
              payload.imageUrls = imgArr;
              //console.log('payload-----------------',payload)
              onPostAddAnswer(payload)
            }
          }
        })
      } else {
        if (isEditAnswer) {
          payload.answerId = selectAnswer?._id;
          payload.imageUrls = oldAnswerImage;
          onPostUpdateAnswer(payload)
        } else {
          onPostAddAnswer(payload)
        }
      }
    }
  }

  useEffect(() => {
    if (Object.keys(addAnswerSuccess).length) {
      handleAddAnswerSuccess(addAnswerSuccess);
      setEditorState(() => EditorState.createEmpty())
      setAddAnswerImage([])
    }
  }, [addAnswerSuccess])

  const handleCloseAnswerModal = () => {
    setEditorState(() => EditorState.createEmpty())
    setAddAnswerImage([])
    setOldAnswerImage([])
    setAnswerError(null)
    setImageLimitError(null)
    closeAnswerModal(false)
  }

  useEffect(() => {
    if (isEditAnswer && selectAnswer) {
      if (selectAnswer?.body) {
        const blocksFromHtml = htmlToDraft(selectAnswer?.body);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setEditorState(EditorState.createWithContent(contentState));
      }
      
      if (selectAnswer?.imageUrls) {
        setOldAnswerImage(selectAnswer?.imageUrls)
      }
    }
  },[selectAnswer, isEditAnswer])

  const removeOldAnsImg = item => {
    let dataArray = oldAnswerImage
    dataArray = dataArray.filter(function (e) {
      return e !== item
    })
    setOldAnswerImage(dataArray)
    if ((dataArray.length + addAnswerImage.length) < 10) {
      setImageLimitError(null)
    }
  }

  useEffect(() => {
    if (Object.keys(updateAnswerSuccess).length) {
      handleUpdateAnswerSuccess(updateAnswerSuccess);
      setEditorState(() => EditorState.createEmpty())
      setAddAnswerImage([])
      setOldAnswerImage([])
    }
  }, [updateAnswerSuccess])

  return (
    <Modal
    isOpen={answerModal}
    role="dialog"
    autoFocus={true}
    centered={true}
    className="answerModal modal-dialog modal-lg"
    tabIndex="-1"
    backdrop="static"
    // toggle={() => {
    //   setAnswerModal(!answerModal)
    // }}
  >
    <div className="modal-content" id="add-answer-modal">
      <ModalBody className="p-0">
        <Card>
          <CardBody>
            <Container>
              <div className="d-flex justify-content-lg-between align-items-center">
                <div className="ms-0 mt-2 mb-0">
                  <span
                    className="text-white lebel-head"
                  >
                    {isEditAnswer ? 'Edit' : 'Add'} your Answer
                  </span>
                </div>

                <div
                  className="text-white"
                  onClick={() => handleCloseAnswerModal()}
                >
                  <i className=" mdi mdi-close mt-2 fs-3 "></i>
                </div>
              </div>
              <hr />
              <p className="ms-2 mt-4 fs-7 mb-2 label-text">
                Please describe your answer<span className="text-danger fs-4">*</span>
              </p>
              <Editor
                wrapperClassName="wrapper-class bg-input"
                editorClassName="editor-class bg-input text-black"
                toolbarClassName="toolbar-class  bg-input"
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "fontFamily",
                    "list",
                    "textAlign",
                    "colorPicker",
                    "link",
                    "emoji",
                  ],
                }}
              />
              {
                answerError && <div class="invalid-feedback d-block">{answerError}</div>
              }

              <div className="d-flex flex-wrap mt-3">
              {
                oldAnswerImage.length > 0 &&
                oldAnswerImage.map((img, index) => {
                    return (
                      <div className="position-relative">
                        <i
                          className="mdi mdi-close add-answer-close-icon"
                          onClick={() => removeOldAnsImg(img)}
                        ></i>
                        <img
                          src={img}
                          className="add-answer-img"
                          alt=""
                        />
                      </div>
                    )
                  })
                }

                {addAnswerImage.length > 0 &&
                  addAnswerImage.map((img, index) => {
                    return (
                      <div className="position-relative">
                        <i
                          className="mdi mdi-close add-answer-close-icon"
                          onClick={() => removeAddAnsImg(img)}
                        ></i>
                        <img
                          src={img.url}
                          className="add-answer-img"
                          alt=""
                        />
                      </div>
                    )
                  })}
                <input
                  type="file"
                  id="file"
                  multiple
                  onChange={onAddAnserImageChange}
                  ref={inputFile}
                  style={{ display: "none" }}
                />
                {/* <img
                  src={img8}
                  alt=""
                  onClick={onButtonClick}
                  className="add-answer-img"
                /> */}
                <button onClick={onButtonClick} class="btn-new border-line btn-square box-add-img btn-height width-20 rounded-4 text-white"><i class="fa fa-plus fs-4 text-white"></i> Add Image</button>
                {
                  imageLimitError && <div class="invalid-feedback d-block">{imageLimitError}</div>
                }
              </div>
              <div className="row">
                <div className="">
                  <button
                      type="button"
                      class="btn color-answer-btn mt-5 text-white"
                      style={{
                        width: "20%",
                        borderRadius: "19px",
                        border: "1px solid #0073B1",

                      }}
                      onClick={e => {handleAnswerSubmit(e)}}
                    >
                      {isEditAnswer ? 'Update' : 'Submit'}
                    </button>
                </div>
              </div>
            </Container>
          </CardBody>
        </Card>
      </ModalBody>
    </div>
  </Modal>
  )
}

const mapStateToProps = state => {
  //console.log("state ----------", state.talks)
  const { error, newsPosts, spaces, talks } = state
  return {
    talks: newsPosts,
    error: error,
    current_space: spaces.current_space,
    unsolvedQuestions: talks.unsolvedQuestions,
    questions: talks.questions,
    upvoteQuestionSuccessMessage: talks.upvoteQuestionSuccess,
    subjectList: talks.subjectsSuccess,
    topicList: talks.topics,
    addAnswerSuccess: talks.addAnswer,
    updateAnswerSuccess: talks.updateAnswer
  }
}

const mapDispatchToProps = dispatch => ({
  onUpvoteQuestions: (question_id) => dispatch(upvoteQuestions(question_id)),
  onUpvoteQuestionsSuccess: () => dispatch(clearUpvoteQuestionsSuccess()),
  onGetQuestions: () => dispatch(getQuestions()),
  onGetTopicsBySubject: (subject_id, topicInput) => dispatch(getTopicsBySubject(subject_id, topicInput)),
  onPostAddAnswer: (data) => dispatch(addAnswer(data)),
  onPostUpdateAnswer: (data) => dispatch(updateAnswer(data))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddAnswerModal)
)
