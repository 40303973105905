import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import OtpInput from 'react-otp-input';
import {
  Col,
  Container,
  Row,
  Alert,
  Modal,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavItem,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

import { loginUser, 
          apiError, 
          socialLogin, 
          userForgetPassword, 
          phoneVerification,
          otpVerification ,
          sendMobileOtp,
          verifyMobileOtp,
          getAvatars,
          setNewPassword,
          registerUser
      } from "../../store/actions"
import { useSelector } from "react-redux"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//custom css
import "../../assets/css/custom.css"

import { uploadFile } from 'helpers/UploadToServerHelper';

// import images
import CarouselPage from "./CarouselPage"
import emailEllipse from "../../assets/images/email-ellipse/email-ellipse.png"
import successEllipse from "../../assets/images/success-ellipse/success-ellipse.png"
import successImage from '../../assets/images/success.png'
import profile from '../../assets/images/profile-avatr.webp';
import profilePic from 'assets/images/user_profile.avif'

import toastr, { success, error } from "toastr"
import "toastr/build/toastr.min.css"
import PhoneNumberModal from './PhoneNumberModal';
import OtpVerifyModal from './OtpVerifyModal';

const Login2 = props => {

  const { error } = useSelector(state => (
    {
      error: state.Login && state.Login.error ? state.Login.error : null,
    }
  ));


  const {
    onPhoneVerification,
    phoneVarificationMessage,
    phoneVarificationError,
    onVerifyOtp,
    otpVarificationError,
    otpVarificationMessage,
    onSendMobileOtp,
    sendMobileOtpError,
    sendMobileOtpSuccess,
    onVerifyMobileOtp,
    verifyMobileOtpSuccess,
    verifyMobileOtpError,
    getAvatarList,
    onGetAvatars,
    onSetNewPassword,
    setNewPasswordError,
    setNewPasswordSuccess,
    registeredUser,
    onCreateAccountSubmit,
    registrationError
  } = props;


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordValues, setValues] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [passwordClass, setPasswordClass] = useState("");
  const [modal_standard, setmodal_standard] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [createAccountModel, setCreateAccountModel] = useState(false)
  const [mobileVal, setMobileVal] = useState('');
  const [newPasswordModal, setNewPasswordModal] = useState(false);
  const [newPassSuccessModal, setNewPasswordSuccessModal] = useState(false);
  const [otpModel, setOtpModel] = useState(false)
  const [numberVal, setNumberVal] = useState('')
  const [otpVerifiedModal, setOtpVerifiedModal] = useState(false)
  const [profileModel, setProfileModel] = useState(false)
  const [otp, setOtp] = useState('');
  const [separator, setSeparotor] = useState('-');
  const [addProfileImage, setAddProfileImage] = useState(null);
  const [isError, setIsError] = useState(false)
  const [isResendMobileOtp, setIsResendMobileOtp] = useState(false)
  const [phoneNumberId, setPhoneNumberId] = useState({})
  const [registerSuccessModal, setRegisterSuccessModal] = useState(false);

  const [otherMenu, setOtherMenu] = useState(false);
  const [avatarModel, setAvatarModel] = useState(false);
  const [selectedImageAvatar, setSelectedImageAvatar] = useState('https://school-student-app.s3.ap-south-1.amazonaws.com/avatar_images/curious_peers/curious_men9.png');
  const [createProfileFormdata, setCreateProfileFormdata] = useState({
      'firstname' : '',
      'lastname' : '',
      'password' : '',
      'username' : '',
      'about': '',
      'id': phoneNumberId?.id
  })

  const handleCreateAccountSubmit = async() => {

    if (addProfileImage?.uri != null && addProfileImage?.url != null) {
      const uploadResult = await uploadFile(addProfileImage);
      if(uploadResult?.location != '') {
        let data = createProfileFormdata;
        data.realProfilePicUrl = uploadResult.location,
        onCreateAccountSubmit(data);
      }
    } else if(addProfileImage?.uri == null && addProfileImage?.url != null) {
      let data = createProfileFormdata;
      data.realProfilePicUrl = addProfileImage?.url,
      onCreateAccountSubmit(data);
    } else {
      let data = createProfileFormdata;
      onCreateAccountSubmit(data);
    }
    //console.log('test.............',createProfileFormdata )
    
  }

  const HandleTextChange = (val, type) => {
      setCreateProfileFormdata({...createProfileFormdata, [type]: val})
  }

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, props.history)
  }

  const closeOtpModel = () => {
    setOtpModel(false);
  }

  const passwordShow = () => {
    setValues(!passwordValues);
    setIsRevealPwd(!isRevealPwd);
  }

  useEffect(() => {
    setEmail(email);
    setPassword(password);
    //onSelectAvatarModel();
    onGetAvatars()
  }, []);

  function forgetPassword() {
    setMobileVal('')
    setEmailModal(false);
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }
  function createAccount() {
    setEmailModal(false);
    setmodal_standard(false)
    setCreateAccountModel(!createAccountModel)
    removeBodyCss()
  }

  function getOtpModel() {
    setmodal_standard(false)
    setCreateAccountModel(false)
    setOtpModel(!otpModel)
    removeBodyCss();
  }

  function onSelectAvatarModel() {
    setAvatarModel(true)
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function handleValidForgetPasswordSubmit(event, values) {
    if (!values.mobile || values.mobile.length != 10) {
      toastr.error("Invalid mobile number")
      return;
    }

    if (values.mobile.length == 10) {
      onSendMobileOtp(values.mobile)
    }
    //props.userForgetPassword(values, props.history)
  }

  function handleValidMobileNumber(event, values) {

    console.log('event, values.....................', event, values);
    if (!values.email || values.email.length === 0) {
      ////console.log("\nEmpty username or password");
      toastr.error("Empty input!")
      return;
    }

    onPhoneVerification(values.email)
  }

  function handleVerifyOtp(otp) {
    console.log('otp......................', otp);
    if (!otp || otp.length === 0) {
      ////console.log("\nEmpty username or password");
      toastr.error("Empty input!")
      return;
    }

    if (otp.length != 4) {
      ////console.log("\nEmpty username or password");
      toastr.error("Invalid OTP!")
      return;
    }

    onVerifyOtp(otp, phoneVarificationMessage?.data?.phone, phoneVarificationMessage?.data?.id)
  }

  useEffect(() => {
    console.log(phoneVarificationMessage);
    if (
      Object.keys(phoneVarificationMessage).length != 0 &&
      phoneVarificationMessage?.data != null
    ) {
      //console.log('phoneVarificationMessage............', phoneVarificationMessage);
      //setPhoneNumberId({phone: phoneVarificationMessage?.data?.phone, id: phoneVarificationMessage?.data?.id})
      setCreateProfileFormdata({...createProfileFormdata, id: phoneVarificationMessage?.data?.id})
      getOtpModel()
    }

  }, [phoneVarificationMessage])

  //console.log('phoneNumberId....................', phoneNumberId);

  useEffect(() => {
    //console.log('registeredUser...................', registeredUser);
    if(registeredUser?.data == true) {
      setProfileModel(false);
      setRegisterSuccessModal(true);
      toastr.success('Registration completed successfully!');
    }
  }, [registeredUser])

  useEffect(() => {
    if(registrationError?.message) {
      toastr.error(registrationError?.message);
    }
  }, [registrationError])


  useEffect(() => {
    if (otpVarificationError != undefined && otpVarificationError.length > 0) {
      toastr.error(otpVarificationError[0].message);
    }
  }, [otpVarificationError])

  useEffect(() => {
    console.log(otpVarificationMessage);
    if (
      Object.keys(otpVarificationMessage).length != 0 &&
      otpVarificationMessage?.data != null
    ) {
      setOtpModel(false);
      setOtpVerifiedModal(true);
    }
  }, [otpVarificationMessage])


  useEffect(() => {
    //console.log('phoneVarificationError.........', phoneVarificationError);
    if (phoneVarificationError != undefined && phoneVarificationError.length > 0) {
      toastr.error(phoneVarificationError[0].message);
    }
  }, [phoneVarificationError])

  useEffect(() => {
    console.log('sendMobileOtpError.........', sendMobileOtpError);
    if(sendMobileOtpError != undefined && sendMobileOtpError.length > 0) {
      toastr.error(sendMobileOtpError[0].message);
    }
  }, [sendMobileOtpError])

  useEffect(() => {
    if(sendMobileOtpSuccess != undefined && sendMobileOtpSuccess?.data?.sendOTP == true) {
      if (otpModel && phoneVarificationMessage?.data?.phone) {
        toastr.success('OTP resent on mobile number '+phoneVarificationMessage?.data?.phone);
      } else if (isResendMobileOtp) {
        toastr.success('OTP resent on mobile number 91'+mobileVal);
      } else {
        forgetPasswordEmailModal()
      }
      //toastr.success(sendMobileOtpSuccess?.data?.message);
    }
  }, [sendMobileOtpSuccess])

  useEffect(() => {
    if(verifyMobileOtpError != undefined && verifyMobileOtpError.length > 0) {
      toastr.error(verifyMobileOtpError[0].message);
      setIsResendMobileOtp(false)
    }
    if (verifyMobileOtpError != undefined && verifyMobileOtpError?.data?.verifyOTP == false) {
      toastr.error("Invalid OTP. Please enter valid OTP.");
    }
  }, [verifyMobileOtpError])

  useEffect(() => {
    if(verifyMobileOtpSuccess != undefined && verifyMobileOtpSuccess?.data?.verifyOTP == true) {
      setEmailModal(false);
      setNewPasswordModal(true);
      setIsResendMobileOtp(false)
      setOtp('')
    }
  }, [verifyMobileOtpSuccess])

  useEffect(() => {
    if(setNewPasswordError != undefined && setNewPasswordError.length > 0) {
      toastr.error(setNewPasswordError[0].message);
    }
    if (setNewPasswordError != undefined && setNewPasswordError?.data?.changePassword == false) {
      toastr.error("Something went wrong! Please try again after 10 minutes.");
    }
  }, [setNewPasswordError])

  useEffect(() => {
    if(setNewPasswordSuccess != undefined && setNewPasswordSuccess?.data?.changePassword == true) {
      setMobileVal('')
      setNewPasswordModal(false)
      newPasswordSuccessModal()
    }
  }, [setNewPasswordSuccess])

  const forgetPasswordEmailModal = () => {
    setmodal_standard(false);
    setEmailModal(!emailModal);
    removeBodyCss();
  }

  const setNewPassword = () => {
    //window.location.href = `mailto:${mobileVal}`;
    if (otp.length == 4 && mobileVal.length == 10) {
      onVerifyMobileOtp(otp, mobileVal)
    } else {
      toastr.error("Invalid OTP. Please enter valid OTP.");
    }
  }

  const handleValidNewPasswordSubmit = (event, values) => {
    if (values?.new_password === values?.new_confirm_password && mobileVal.length == 10) {
      onSetNewPassword(values?.new_password, mobileVal)
    } else if (values?.new_password === values?.new_confirm_password && mobileVal.length != 10) {
      toastr.error("Something went wrong! Please try again after 10 minutes.");
    }else {
      toastr.error("Passwords do not match");
    }
  }

  const newPasswordSuccessModal = () => {
    setNewPasswordSuccessModal(true);
  }
  function createProfile() {
    setProfileModel(true)
    setOtpVerifiedModal(false)
  }

  const inputFile = useRef(null)

  const onButtonClick = () => {
    inputFile.current.click();
  };
  const onaddProfileImageChange = (event) => {
    let files = event.target.files;
    let dataArray = []
    if (files.length > 0) {
      const file = {
        uri: event.target.files[0],
        url: URL.createObjectURL(event.target.files[0]),
        name: files[0].name,
        type: files[0].type,
      };
      setAddProfileImage(file)
    }
    event.target.value = '';
  }

  const onSelectAvatar = () => {
    const file = {
      uri: null,
      url: selectedImageAvatar,
      name: null,
      type: null,
    };
    setAddProfileImage(file)
    setAvatarModel(false);
  }

  const gotoLogin = () => {
    setNewPasswordSuccessModal(false);
  }

  const resendMobileOtp = (type) => {
    if (mobileVal.length == 10) {
      setIsResendMobileOtp(true)
      setOtp('')
      onSendMobileOtp(mobileVal)
    }
  }

  const handleCreateResendMobileOtp = () => {
    if (phoneVarificationMessage?.data?.phone) {
      onSendMobileOtp(phoneVarificationMessage?.data?.phone)
    }
  }

  const slider = document.querySelector('.items_1');
  let isDown = false;
  let startX;
  let scrollLeft;

  slider?.addEventListener('mousedown', (e) => {
    isDown = true;
    slider.classList.add('active');
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider?.addEventListener('mouseleave', () => {
    isDown = false;
    slider.classList.remove('active');
  });
  slider?.addEventListener('mouseup', () => {
    isDown = false;
    slider.classList.remove('active');
  });
  slider?.addEventListener('mousemove', (e) => {
    if(!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 2; //scroll-fast
    slider.scrollLeft = scrollLeft - walk;
    //console.log(walk);
  });

  const slider2 = document.querySelector('.items_2');
  let isDown2 = false;
  let startX2;
  let scrollLeft2;

  slider2?.addEventListener('mousedown', (e) => {
    isDown2 = true;
    slider2.classList.add('active');
    startX2 = e.pageX - slider2.offsetLeft;
    scrollLeft2 = slider2.scrollLeft;
  });
  slider2?.addEventListener('mouseleave', () => {
    isDown2 = false;
    slider2.classList.remove('active');
  });
  slider2?.addEventListener('mouseup', () => {
    isDown2 = false;
    slider2.classList.remove('active');
  });
  slider2?.addEventListener('mousemove', (e) => {
    if(!isDown2) return;
    e.preventDefault();
    const x = e.pageX - slider2.offsetLeft;
    const walk = (x - startX2) * 2; //scroll-fast
    slider2.scrollLeft = scrollLeft2 - walk;
    //console.log(walk);
  });


  const slider3 = document.querySelector('.items_3');
  let isDown3 = false;
  let startX3;
  let scrollLeft3;

  slider3?.addEventListener('mousedown', (e) => {
    isDown3 = true;
    slider3.classList.add('active');
    startX3 = e.pageX - slider3.offsetLeft;
    scrollLeft3 = slider3.scrollLeft;
  });
  slider3?.addEventListener('mouseleave', () => {
    isDown3 = false;
    slider3.classList.remove('active');
  });
  slider3?.addEventListener('mouseup', () => {
    isDown3 = false;
    slider3.classList.remove('active');
  });
  slider3?.addEventListener('mousemove', (e) => {
    if(!isDown3) return;
    e.preventDefault();
    const x = e.pageX - slider3.offsetLeft;
    const walk = (x - startX3) * 2; //scroll-fast
    slider3.scrollLeft = scrollLeft3 - walk;
    console.log(walk);
  });



  const slider4 = document.querySelector('.items_4');
  let isDowm4 = false;
  let startX4;
  let scrollLeft4;

  slider4?.addEventListener('mousedown', (e) => {
    isDowm4 = true;
    slider4.classList.add('active');
    startX4 = e.pageX - slider4.offsetLeft;
    scrollLeft4 = slider4.scrollLeft;
  });
  slider4?.addEventListener('mouseleave', () => {
    isDowm4 = false;
    slider4.classList.remove('active');
  });
  slider4?.addEventListener('mouseup', () => {
    isDowm4 = false;
    slider4.classList.remove('active');
  });
  slider4?.addEventListener('mousemove', (e) => {
    if(!isDowm4) return;
    e.preventDefault();
    const x = e.pageX - slider4.offsetLeft;
    const walk = (x - startX4) * 2; //scroll-fast
    slider4.scrollLeft = scrollLeft4 - walk;
    //console.log(walk);
  });

  
  const slider5 = document.querySelector('.items_5');
  let isDowm5 = false;
  let startX5;
  let scrollLeft5;

  slider5?.addEventListener('mousedown', (e) => {
    isDowm5 = true;
    slider5.classList.add('active');
    startX5 = e.pageX - slider5.offsetLeft;
    scrollLeft5 = slider5.scrollLeft;
  });
  slider5?.addEventListener('mouseleave', () => {
    isDowm5 = false;
    slider5.classList.remove('active');
  });
  slider5?.addEventListener('mouseup', () => {
    isDowm5 = false;
    slider5.classList.remove('active');
  });
  slider5?.addEventListener('mousemove', (e) => {
    if(!isDowm5) return;
    e.preventDefault();
    const x = e.pageX - slider5.offsetLeft;
    const walk = (x - startX5) * 2; //scroll-fast
    slider5.scrollLeft = scrollLeft5 - walk;
    //console.log(walk);
  });

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Login | School Web App</title>
        </MetaTags>
        <Container fluid className="p-0 login">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={6} className={'xl-5'}>
              <div className="auth-full-page-content p-md-5 p-4 dark-bg-custom">
                <div className="w-100">
                  <div className="d-flex flex-column h-100 mx-5">
                    <div className="my-auto mx-5">
                      <div className='welcome-border'>
                        <h1 className="text-center text-white">Welcome</h1>
                      </div>
                      {props.loginError && typeof props.loginError === "string" ? (
                        <Alert color="danger">{props.loginError}</Alert>
                      ) : null}
                      <div className="mt-5">

                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v)
                          }}
                          autoComplete="fdsf ds fdsf dsf ds fds"
                        >
                          <div className="mb-3 position-relative">
                            <AvField
                              name="username"
                              //value=""
                              className="form-control ps-5 bg-white border border-secondary rounded-3"
                              placeholder="Enter Username"
                              type="text"
                              required
                              value={email} onChange={(e) => setEmail(e.target.value)}
                              autocomplete="off" list="autocompleteOff" 
                            />
                              <i className="bx bx-user fa-lg position-absolute login-icon"></i>

                          </div>

                          <div className="mb-2" style={{ position: 'relative' }}>
                            
                            <i class="bx bx-lock fa-lg position-absolute login-icon  "></i>
                            <div className='d-flex password-input align-items-baseline'>
                              <AvField
                                name="password"
                                //value=""
                                className="form-control ps-5 bg-white border border-secondary rounded-3 w-100 password-input-custom"
                                type={isRevealPwd ? "text" : "password"}
                                required
                                placeholder="Enter Password"
                                value={password} onChange={(e) => setPassword(e.target.value)}
                                autoComplete="off"
                              />
                              <span
                                //className={((email && password) || (!email && !password)) ?(props.error ? 'password-show password-show-4 d-flex icon-margin-right': 'password-show password-show-1 d-flex icon-margin-right') : (!email ? 'password-show password-show-2 d-flex icon-margin-right' : 'password-show password-show-3 d-flex icon-margin-right' )  }
                                className={((email && password) || (!email && !password)) ? (!password ? 'd-flex icon-margin-right password-show' : 'd-flex icon-margin-right password-show') : 'd-flex icon-margin-right password-show '}
                                onClick={() => passwordShow()}>
                                {passwordValues ? <button
                                        class="btn btn-light comment-send-btn"
                                        type="button"
                                        id="password-addon"
                                      >
                                        <i class="bx bxs-hide"></i>
                                      </button> : <button
                                        class="btn btn-light comment-send-btn"
                                        type="button"
                                        id="password-addon"
                                      >
                                        <i class="bx bx-show"></i>
                                      </button>}
                              </span>
                            </div>
                            
                          </div>

                          {/* <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label text-primary"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div> */}

                          <div className='d-flex justify-content-end'>
                            <div className="mt-0">
                              {/* <Link to="/forgot-password" className="text-muted">
                              <i className="mdi mdi-lock me-1" />
                              Forgot your password?
                            </Link> */}
                              <Link to="#" className="text-white" onClick={() => { forgetPassword() }}>
                                <i className="mdi mdi-lock me-1" />
                                Forgot your password?
                              </Link>
                            </div>
                          </div>
                            
                            <div className="mt-4 d-grid">
                              <button
                                className="btn btn-rounded btn-block waves-effect waves-light"
                                type="submit"
                                style={{ backgroundColor: '#009C9B', color: '#ffffff' }}
                              >
                                Log In
                              </button>
                            </div>
                            <div className='mt-4 d-flex justify-content-center'>
                              <p className="me-1">Don't have an account? </p>
                              <Link to='#' className="text-white" onClick={() => { createAccount() }}>
                                <i className='mdi mdi-lock' />
                                Sign Up
                              </Link>
                            </div>
  
                        </AvForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Forget Password Modal */}
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          forgetPassword()
        }}
        centered={true}
        backdrop="static"
      >
        <div className="modal-header justify-content-center border-bottom-0">
         
          <h1 className="text-info text-center modal-title mt-0" id="myModalLabel" style={{ margin: '0px auto', paddingRight: '30px' }}>ANONIMO</h1>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false)
            }}
            data-dismiss="modal"
            aria-label="Close"
            style={{ right: '0', top: '18px', color: '#ffffff', backgroundColor: '#1D2226', fontSize: '18px' }}
            className="border-0"
          >
            <span aria-hidden="true">&#10006;</span>
          </button>
        </div>
        <div className="modal-body pt-5">
          <div className="text-center">
            <h3>Forgot password?</h3>
            {/*   <p>No worries, we’ll send you rest instructions.</p> */}
          </div>
          <div className="p-2">
            {props.forgetError && props.forgetError ? (
              <Alert color="danger" style={{ marginTop: "13px" }}>
                {props.forgetError}
              </Alert>
            ) : null}
            {props.forgetSuccessMsg ? (
              <Alert color="success" style={{ marginTop: "13px" }}>
                {props.forgetSuccessMsg}
              </Alert>
            ) : null}

            <AvForm
              className=""
              onValidSubmit={(e, v) => handleValidForgetPasswordSubmit(e, v)}
            >
              <div className='mb-5'>
                <Row className='g-1 ps-3 pe-3'>
                  <Col xs="3" sm="2">
                    <AvField name="number"
                      className="form-control bg-transparent p-3 border border-white"
                      placeholder="+91"
                      value="+91"
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col xs="9" sm="10" >
                    <AvField
                      name="mobile"
                      className="form-control bg-transparent p-3 number-feild profile-input border border-white"
                      placeholder="Enter your mobile number "
                      type="number"
                      required
                      onInput={e => setMobileVal(e.target.value)}
                      value={mobileVal}
                      validate={{
                        required: { value: true },
                        pattern: {value: '^[0-9]+$'},
                        minLength: {value: 10},
                        maxLength: {value: 10}
                      }}
                      min="0"
                    />
                  </Col>
                </Row>
              </div>
              <Row className="mb-3">
                <Col className="text-right pt-3">
                  <button
                    className="btn btn-rounded btn-block waves-effect waves-light"
                    type="submit"
                    style={{ backgroundColor: '#0073B1', color: '#ffffff', width: '100%' }}
                  >
                    Get OTP
                  </button>
                </Col>
              </Row>
            </AvForm>
          </div>
          <div className="modal-footer border-top-0 p-5"></div>
        </div>
      </Modal>
      {/* Check Your Email Modal */}
      <Modal
        isOpen={emailModal}
        toggle={() => {
          forgetPassword()
        }}
        centered={true}
        backdrop="static"
      >
        <div className="modal-header justify-content-center border-bottom-0" >
         
          <h1 className="text-info text-center modal-title mt-0" id="myModalLabel" style={{ margin: '0px auto', paddingRight: '30px' }}>ANONIMO</h1>
          <button
            type="button"
            onClick={() => {
              setEmailModal(false)
            }}
            data-dismiss="modal"
            aria-label="Close"
            style={{ left: '0', top: '18px', color: '#ffffff', backgroundColor: '#1D2226', fontSize: '18px' }}
            className="border-0"
          >
            <span aria-hidden="true">&#10006;</span>
          </button>
        </div>
        <div className="modal-body pt-5">
          <div className="text-center">
            <h3>Verification</h3>
            <p>A security code has been {isResendMobileOtp ? 'resent' : 'sent'} to <strong>91{mobileVal}</strong></p>
          </div>
          <div className="p-2">
            {props.forgetError && props.forgetError ? (
              <Alert color="danger" style={{ marginTop: "13px" }}>
                {props.forgetError}
              </Alert>
            ) : null}
            {props.forgetSuccessMsg ? (
              <Alert color="success" style={{ marginTop: "13px" }}>
                {props.forgetSuccessMsg}
              </Alert>
            ) : null}

            <Row className="mb-2">
              <Col className="text-right">
                {/*  <div className="text-center mb-5">
                  <img
                    src={emailEllipse}
                    alt=""
                    className="avatar-md rounded-circle img-thumbnail"
                  />
                </div> */}
                <div className='d-flex justify-content-center' >
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    inputStyle='inputStyle'
                    containerStyle='containerStyle'
                    renderseparator={<span>{separator}</span>}
                    renderInput={(props) => <input {...props} className='inputStyle' style={{ background: '#1D2226', borderRadius: '8px', border: "1px solid white,", padding: '20px', color: "white", fontSize: '20px', textAlign: 'center', boxShadow: 'none' }} />}
                  />
                </div>
                <button
                  className="btn btn-rounded btn-block waves-effect waves-light mb-4 mt-3"
                  type='button'//type="submit"
                  style={{ backgroundColor: '#0073B1', color: '#ffffff', width: '100%' }}
                  //onClick={() => { forgetPasswordEmailModal() }}
                  onClick={() => {
                    setNewPassword()
                  }}
                >
                  Verify
                </button>
                <p className='text-center'>
                  Don’t receive OTP?{" "}
                  <Link onClick={() => {resendMobileOtp(true)} } className="text-info">
                    Resend OTP
                  </Link>
                </p>
              </Col>
            </Row>
          </div>
          <div className="modal-footer border-top-0 p-3"></div>
        </div>
      </Modal>
      {/* Set New Password Modal */}
      <Modal
        isOpen={newPasswordModal}
        // toggle={() => {
        //   forgetPassword()
        // }}
        centered={true}
        backdrop="static"
      >
        <div className="modal-header justify-content-center border-bottom-0">
          
          <h1 className="text-info text-center modal-title mt-0" id="myModalLabel" style={{ margin: '0px auto', paddingRight: '30px' }}>ANONIMO</h1>
          <button
            type="button"
            onClick={() => {
              setNewPasswordModal(false)
            }}
            data-dismiss="modal"
            aria-label="Close"
            style={{ left: '0', top: '18px', color: '#ffffff', backgroundColor: '#1D2226', fontSize: '18px' }}
            className="border-0"
          >
            <span aria-hidden="true">&#10006;</span>
          </button>
        </div>
        <div className="modal-body pt-5" >
          <div className="text-center mb-3">
            <h4>Set new password</h4>
          </div>
          <div className="p-2 ">
            {props.forgetError && props.forgetError ? (
              <Alert color="danger" style={{ marginTop: "13px" }}>
                {props.forgetError}
              </Alert>
            ) : null}
            {props.forgetSuccessMsg ? (
              <Alert color="success" style={{ marginTop: "13px" }}>
                {props.forgetSuccessMsg}
              </Alert>
            ) : null}

            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => handleValidNewPasswordSubmit(e, v)}
            >
              <div className='mb-4'>
                <AvField
                  name="new_password"
                  className="form-control bg-transparent border border-white"
                  placeholder="Password"
                  type="password"
                  required
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Please enter a password',
                    },
                    //minLength: {value: 6, errorMessage: 'Password must be at least 6 characters'},
                  }}
                />
              </div>

              <div className='mb-5'>
                <AvField
                  name="new_confirm_password"
                  className="form-control bg-transparent border border-white"
                  placeholder="Confirm Password"
                  type="password"
                  required
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Please confirm your password',
                    },
                    match: {
                      value: 'new_password',
                      errorMessage: 'Passwords do not match',
                    },
                  }}
                />
              </div>

              <Row className="mb-5">
                <Col className="text-right pt-3">
                  <button
                    className="btn btn-rounded btn-block waves-effect waves-light"
                    type="submit"
                    style={{ backgroundColor: '#0073B1', color: '#ffffff', width: '100%' }}
                  >
                Reset password
                    </button>
                </Col>
              </Row>
            </AvForm>
          </div>
        </div>
      </Modal>
      {/* New Password Success Modal */}
      <Modal
        isOpen={newPassSuccessModal}
        // toggle={() => {
        //   forgetPassword()
        // }}
        centered={true}
        backdrop="static"
      >
        <div className="modal-header justify-content-center border-bottom-0" >
         
          <h1 className="text-info text-center modal-title mt-0" id="myModalLabel" style={{ margin: '0px auto', paddingRight: '30px' }}>ANONIMO</h1>
          <button
            type="button"
            onClick={() => {
              setNewPasswordSuccessModal(false)
            }}
            data-dismiss="modal"
            aria-label="Close"
            style={{ left: '0', top: '18px', color: '#ffffff', backgroundColor: '#1D2226', fontSize: '18px' }}
            className="border-0"
          >
            <span aria-hidden="true">&#10006;</span>
          </button>
        </div>
        <div className="modal-body pt-5" >
          <div className="text-center">
            <h3>Password Changed successfully !</h3>
            <p className="fs-6 text-white">Your password has been successfully reset. <br /> click to below to log in </p>
          </div>
          <div className="p-2">
            {props.forgetError && props.forgetError ? (
              <Alert color="danger" style={{ marginTop: "13px" }}>
                {props.forgetError}
              </Alert>
            ) : null}
            {props.forgetSuccessMsg ? (
              <Alert color="success" style={{ marginTop: "13px" }}>
                {props.forgetSuccessMsg}
              </Alert>
            ) : null}

            <Row className="mb-2">
              <Col className="text-right">
                <div className="text-center mb-5">
                  <img
                    src={successEllipse}
                    alt=""
                    className="avatar-md rounded-circle img-thumbnail"
                  />
                </div>
                <button
                  className="btn btn-rounded btn-block waves-effect waves-light mb-4"
                  type='button'//type="submit"
                  style={{ backgroundColor: '#0073B1', color: '#ffffff', width: '100%' }}
                  onClick={() => {
                    gotoLogin()
                  }}
                >
                  Login Now
                </button>
                {/* <p className='text-center'>
                  <i class="bx bx-arrow-back"></i> {" "} Back to {" "}
                  <Link to="#" onClick={() => { gotoLogin() }}>
                    login!
                  </Link>
                </p> */}
              </Col>
            </Row>
          </div>
          <div className="modal-footer border-top-0 p-3"></div>
        </div>
      </Modal>

      {/* Create Account model*/}
      <PhoneNumberModal createAccountModel={createAccountModel} setCreateAccountModel={setCreateAccountModel} handleValidMobileNumber={handleValidMobileNumber} />

      {/*get otp model*/}
      <OtpVerifyModal createAccount={createAccount} otpModel={otpModel} closeOtpModel={closeOtpModel} handleVerifyOtp={handleVerifyOtp} phoneVarificationMessage={phoneVarificationMessage} handleCreateResendMobileOtp={handleCreateResendMobileOtp} />
      {/* SuccessVerified model*/}



      <Modal
        isOpen={otpVerifiedModal}
        // toggle={() => {
        //   forgetPassword()
        // }}
        centered={true}
        backdrop="static"
      >
        <div className="modal-header  justify-content-end border-bottom-0" >
          <button
            type="button"
            onClick={() => {
              setOtpVerifiedModal(false)
            }}
            data-dismiss="modal"
            aria-label="Close"
            style={{ float: 'right', top: '18px', color: '#ffffff', backgroundColor: '#1D2226', fontSize: '18px' }}
            className="border-0"
          >
            <span aria-hidden="true">&#10006;</span>
          </button>

        </div>
        <div className="modal-body pt-5" >

          <div className="p-2">

            <Row className="mb-2">
              <Col>
                <div className="text-center mb-4" >
                  <img
                    src={successImage}
                    alt=""
                    className="  bg-transparent success"
                    width={318}
                    height={248}
                  />
                </div>

                <Row>
                  <Col className='text-center text-white bg-transparent'>
                    <h3>Mobile Number Verified <br />Successfully</h3>
                  </Col>
                </Row>
                <button
                  className="btn btn-rounded btn-block waves-effect waves-light mt-5"
                  type='button'//type="submit"
                  style={{ backgroundColor: '#0073B1', color: '#ffffff', width: '100%' }}
                  //onClick={() => { forgetPasswordEmailModal() }}
                  onClick={() => {
                    createProfile()
                  }}
                >
                  Done
                </button>
              </Col>
            </Row>
          </div>
          <div className="modal-footer border-top-0 p-3"></div>
        </div>
      </Modal>

      {/* created account successfully modal */}
      <Modal
        isOpen={registerSuccessModal}
        // toggle={() => {
        //   forgetPassword()
        // }}
        centered={true}
        backdrop="static"
      >
        <div className="modal-body pt-5" >

          <div className="p-2">

            <Row className="mb-2">
              <Col>
                <div className="text-center mb-4" >
                  <img
                    src={successImage}
                    alt=""
                    className="  bg-transparent success"
                    width={318}
                    height={248}
                  />
                </div>

                <Row>
                  <Col className='text-center text-white bg-transparent'>
                    <h3>Account created Successfully!</h3>
                  </Col>
                </Row>
                <button
                  className="btn btn-rounded btn-block waves-effect waves-light mt-5"
                  type='button'//type="submit"
                  style={{ backgroundColor: '#0073B1', color: '#ffffff', width: '100%' }}
                  //onClick={() => { forgetPasswordEmailModal() }}
                  onClick={() => {
                    setRegisterSuccessModal(false)
                  }}
                >
                  Login Now
                </button>
              </Col>
            </Row>
          </div>
          <div className="modal-footer border-top-0 p-3"></div>
        </div>
      </Modal>

      {/*profile model*/}
      <Modal isOpen={profileModel}
        fullscreen
        size='xl'
        // toggle={() => {
        //   createAccount()
        // }}
        centered={true} backdrop="static">
        <div className='modal-header border-bottom-0' style={{ backgroundColor: '#1D2226', paddingLeft: '40px' }}>
          <h1 className=" text-left modal-title mt-0" id="myModalLabel" style={{ color: ' #76A9FF' }}>CREATE PROFILE</h1>
        </div>
        <div className="modal-body pt-4 " style={{ backgroundColor: '#1D2226' }}>
          <Container style={{ width: '80%' }}>
            <Row >
              <Col xs='4' className={'profile-div'}>

                <div style={{ background: '#1D2226' }} className='circle'>
                  {
                    addProfileImage?.url != null ?
                      <img alt='avatar' className='profile-pic' height="220" width="220" src={addProfileImage?.url} />
                      :
                      <>
                        <div className="default-profile">
                          <img src={profilePic} />
                        </div>
                      </>
                  }

                </div>
                <input type='file' id='file' onChange={onaddProfileImageChange} ref={inputFile} style={{ display: 'none' }} />
                <Dropdown
                  isOpen={otherMenu}
                  toggle={() => {
                    setOtherMenu(!otherMenu)
                  }}
                >
                  <DropdownToggle
                    tag="i"
                    className="btn nav-btn"
                    type="button"
                  >
                    <i className="mdi mdi-face-shimmer mt-0 prof-avatar" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end bg-card">
                    <>
                      <DropdownItem onClick={onButtonClick}>
                        Select Image
                      </DropdownItem>
                      <DropdownItem onClick={onSelectAvatarModel}>
                        Select Avatar
                      </DropdownItem>
                    </>
                  </DropdownMenu>
                </Dropdown>
                <p className="text-white ms-4 mt-2 fs-5">Set Your Profile Picture</p>
              </Col>
              <Col xs='8'>
                <div>
                  <AvForm>
                    <FormGroup>
                      <Label for="exampleText" className='text-white h5'>About me</Label>
                      <textarea 
                        className='bg-transparent border border-primary p-5 overflow-hidden form-control profile-input' 
                        type="textarea" 
                        name="text" 
                        id="exampleText" 
                        placeholder='Eg-&#10;I am a Math Enthusiastics😎&#10;Love Solving Claculus !! Aspiring for JEE' 
                        style={{ resize: 'none' }} 
                        onChange={(e) => HandleTextChange(e.target.value, 'about')}
                      />
                    </FormGroup>
                    <FormGroup className='pt-4'>
                      <Label for="about" className='text-white h5'>User Name</Label>
                      <input 
                        className='bg-transparent border border-primary p-3  form-control profile-input' 
                        type="text" 
                        name="text" 
                        id="about" 
                        placeholder='Eg- GhostRider,nighttow007,etc'
                        onChange={(e) => HandleTextChange(e.target.value, 'username')} 
                      />
                    </FormGroup>
                    <Row className='pt-4' >
                      <Col xs='6'>
                        <Label for="firstname" className='text-white h5'>First Name</Label>

                        <AvField
                          id='firstname'
                          name="firstname"
                          className='bg-transparent border border-primary p-3  form-control'
                          onChange={(e) => HandleTextChange(e.target.value, 'firstname')}
                        />
                      </Col>
                      <Col xs='6'>
                        <Label for='lastname' className='text-white h5'>Last Name</Label>
                        <AvField
                          id='lastname'
                          name="lastname"
                          className='bg-transparent border border-primary p-3  form-control '
                          onChange={(e) => HandleTextChange(e.target.value, 'lastname')}
                        />
                      </Col>

                    </Row>
                    <FormGroup className='pt-4'>
                      <Label for='setpassword' className='text-white h5'>Set Your Password</Label>
                      <AvField
                        type='text'
                        id='setpassword'
                        placeholder='Enter a Strong Password'
                        className='bg-transparent border border-primary p-3  form-control profile-input'
                        name='setpassword'
                        onChange={(e) => HandleTextChange(e.target.value, 'password')}
                      />
                    </FormGroup>
                  </AvForm>
                </div>
              </Col>
            </Row>
          </Container>
          <Row>
            <div className='d-flex justify-content-end pt-4'>
              <button className='let_get-started-btn' onClick={handleCreateAccountSubmit}> Let's Get Started
                <svg viewBox="0 0 16 16" class="bi bi-arrow-right ms-5" fill="currentColor" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" fill-rule="evenodd"></path>
                </svg>
              </button>
            </div>
          </Row>
        </div>

      </Modal>

        {/*profile model*/}
        <Modal 
        isOpen={avatarModel} //avatarModel
        fullscreen
        size='lg'
        className="avatarModal"
        // toggle={() => {
        //   setAvatarModel(!avatarModel)
        // }}
        backdrop="static"
        centered={true}>
        <div className='modal-header border-bottom-0' style={{ backgroundColor: '#1D2226', paddingLeft: '40px' }}>
          <h1 className=" text-left modal-title mt-0" id="myModalLabel" style={{ color: ' #76A9FF' }}>Select Avatar</h1>   <button
            type="button"
            onClick={() => {
              setAvatarModel(!avatarModel)
            }}
            data-dismiss="modal"
            aria-label="Close"
            style={{ right: '0', top: '18px', color: '#ffffff', backgroundColor: '#1D2226', fontSize: '18px' }}
            className="border-0"
          >
            <span aria-hidden="true">&#10006;</span>
          </button>
        </div>
        <div className="modal-body pt-4 " style={{ backgroundColor: '#1D2226' }}>
          <Container style={{ width: '100%' }}>
            <div className='text-center'>
              <img 
                width="100px" 
                height="100px" 
                style={{borderRadius: '50%'}}
                src={selectedImageAvatar} 
              />
            </div>
            <div className="selectAvtarBody">
              {
                getAvatarList.length > 0 &&
                getAvatarList.map((item, index) => {
                    return (
                    item.urls.length > 0 &&
                      <div key={"avt_"+index} >
                        <h4 className="mb-2 mt-4 ms-3">{item.category}</h4>
                        <div className={'avt_img_row items_'+index}>
                            {
                              item.urls.map((i, ind) => {
                                  return (
                                    <img 
                                      width="100px" 
                                      height="100px" 
                                      src={'https://school-student-app.s3.ap-south-1.amazonaws.com/'+i} 
                                      key={"avt_img_"+index+'__'+ind} 
                                      className={`https://school-student-app.s3.ap-south-1.amazonaws.com/${i}` == selectedImageAvatar ? 'active item' : 'item'}
                                      onClick={() => setSelectedImageAvatar('https://school-student-app.s3.ap-south-1.amazonaws.com/'+i)} 
                                    />
                                  )
                              })
                            }
                        </div>
                      </div>
                    )
                })
              }
            </div>
          </Container>
          <Row>
            <div className='d-flex justify-content-end pt-4'>
              <button className='let_get-started-btn' 
                onClick={onSelectAvatar} 
              > Select
                <svg viewBox="0 0 16 16" class="bi bi-arrow-right" fill="currentColor" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" fill-rule="evenodd"></path>
                </svg>
              </button>
            </div>
          </Row>
        </div>

      </Modal>

    </React.Fragment>

  )
}

const mapStateToProps = state => {
  const { loginError } = state.Login
const { forgetError, forgetSuccessMsg, sendMobileOtpError , sendMobileOtpSuccess, verifyMobileOtpSuccess, verifyMobileOtpError, setNewPasswordSuccess, setNewPasswordError} = state.ForgetPassword
  const { phoneVarificationSuccess, 
          phoneVarificationError, 
          otpVarificationSuccess, 
          otpVarificationError, 
          getAvatarSuccess,
          registeredUser,
          registrationError
        } = state.Account
  return {
    forgetError, forgetSuccessMsg, loginError,
    phoneVarificationMessage: phoneVarificationSuccess,
    phoneVarificationError: phoneVarificationError,
    otpVarificationMessage: otpVarificationSuccess,
    otpVarificationError: otpVarificationError,
    getAvatarList: getAvatarSuccess,
    sendMobileOtpError: sendMobileOtpError,
    sendMobileOtpSuccess: sendMobileOtpSuccess,
    verifyMobileOtpSuccess: verifyMobileOtpSuccess,
    verifyMobileOtpError: verifyMobileOtpError,
    setNewPasswordSuccess: setNewPasswordSuccess,
    setNewPasswordError: setNewPasswordError,
    registeredUser: registeredUser,
    registrationError: registrationError
  }
}

const mapDispatchToProps = dispatch => ({
  onPhoneVerification: (number) => dispatch(phoneVerification(number)),
  loginUser: (data, history) => dispatch(loginUser(data, history)),
  apiError: () => dispatch(apiError()),
  userForgetPassword: (data, history) => dispatch(userForgetPassword(data, history)),
  onVerifyOtp: (otp, phone, id) => dispatch(otpVerification(otp, phone, id)),
  onSendMobileOtp: (phone) => dispatch(sendMobileOtp(phone)),
  onVerifyMobileOtp: (otp, phone) => dispatch(verifyMobileOtp(otp, phone)),
  onSetNewPassword: (new_password, phone) => dispatch(setNewPassword(new_password, phone)),
  onGetAvatars: () => dispatch(getAvatars()),
  onCreateAccountSubmit: (data) => dispatch(registerUser(data))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Login2)
)

Login2.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  userForgetPassword: PropTypes.func
}