import { GET_PRESIGNED_S3_URL } from "./url_helper"
import { get } from "./api_helper"

const uploadToS3 = async (url, file) => {
  try {
    const uploadResponse = await fetch(url, {
      method: "PUT",
      body: file.uri,
      headers: {
        "Content-Type": file.type,
      },
    })
    if (!uploadResponse.ok) {
      throw `Failed to upload file ${file.name} to S3`
    }
  } catch (error) {
    console.error("Error uploading image:", error)
    throw `Failed to upload file ${file.name} to S3`
  }
}

export const uploadFile = async filePath => {
  try {
    let body = JSON.stringify({
      query: GET_PRESIGNED_S3_URL,
    })
    const {
      getPresignedS3Url: { url, key },
    } = await get({ payload: { body } })

    if (url) {
      await uploadToS3(url, filePath)
      return {
        location: key,
        filepath: filePath.uri,
      }
    }
    throw ""
  } catch (err) {
    console.log("upload err", err)
    throw err
  }
}
