import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";
import store from "store";

//pass new generated access token here
const token = accessToken;

const API_URL = process.env.REACT_APP_API_BASE_URL; 

//console.log('API_URL..............', API_URL);

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token;

// axiosApi.interceptors.response.use(
//   response => response,
//   error => Promise.reject(error)
// );
axiosApi.defaults.params = {};
axiosApi.interceptors.request.use(
  request => {
    let user = {};
    if(request.url.includes('/admin')){
      user =  localStorage.getItem("authToken");
    }
    if (user) {
      //const userData = JSON.parse(user)
      request.headers = { 
        'Authorization': `${user}`,
      }
    }
    return request;
  },
  error => {
    Promise.reject(error)
});

// Request interceptor for API calls
// axiosApi.interceptors.request.use(
//   async config => {
//     const value = await localStorage.getItem("authUser")
//     const keys = JSON.parse(value)
//     config.headers = { 
//       'Authorization': `${keys.token}`,
//       'Accept': 'application/json',
//       'Content-Type': 'application/x-www-form-urlencoded'
//     }
//     return config;
//   },
//   error => {
//     Promise.reject(error)
// });
// export async function get(paylaod) {
//   //console.log('payload.............', paylaod.user);
//   const GET_STUDENT = `
//   query Login($input: AuthInput!) {
//     login(input: $input) {
//       accessToken
//       error
//     }
//   }
// `;

// let body = JSON.stringify({
//   query: GET_STUDENT,
//   variables: {
//     input: {
//       userName: paylaod.user.username,
//       password: paylaod.user.password,
//       userType: "student",
//     },
//   },
// });

//   const options = {
// 		method: 'POST',
// 		url: API_URL,
// 		headers: {
// 			'content-type': 'application/json'
// 		},
// 		data: body
// 	};

//   await axios({
//     url: process.env.REACT_APP_API_BASE_URL,
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     data: {
//       query: `
//         mutation updateUserCity($id: Int!, $city: String!) {
//           updateUserCity(userID: $id, city: $city) {
//             id
//             name
//             age
//             city
//             knowledge {
//               language
//               frameworks
//             }
//           }
//         }
//       `,
//       variables: {
//         id: 2,
//         city: 'Test'
//       }
//     }
//   });


//   // return await axios.post(API_URL , body
//   // ).then(res => {
//   //     console.log(res.data)
//   // })

//   //return await axiosApi.request(options).then(response => response.data)


// 	// axios
//   // .request(options)
//   // .then(function (response) {
//   //   console.log('response.data.........', response); // Response
//   //   return response.data;
//   // });
//   // // .catch(function (error) {
//   // //   console.error('error............', error);
//   // // });

// }

export async function get({ payload: { body, history } }) {

  console.log('payload body', body);
  return new Promise(function (fulfill, reject) {
    fetch(process.env.REACT_APP_API_BASE_URL, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: body
      })
      .then((res) => res.json())
      .then(response => {
        console.log(response);
        fulfill(response.data);
      })
      .catch(error => {
        console.log(error)
      }) 
    });
}

export async function getWithouAuth({ payload: { body, history } }) {

  console.log('payload body', body);
  return new Promise(function (fulfill, reject) {
    fetch(process.env.REACT_APP_API_BASE_URL, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: body
      })
      .then((res) => res.json())
      .then(response => {
        fulfill(response);
      })
      .catch(error => {
        console.log(error)
      }) 
    });
}

export async function getWithAuth({ payload: { body, history } }) {
  //console.log('payload body', body);
  let authToken =  JSON.parse(localStorage.getItem("authToken"));
  //console.log('authToken.login.accessToken...............', authToken.login.accessToken);
  return new Promise(function (fulfill, reject) {
    fetch(process.env.REACT_APP_API_BASE_URL, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin':'*',
          "Authorization": authToken.login.accessToken
        },
        body: body
      })
      .then((res) => res.json())
      .then(response => {
        //console.log('response in getwithauth.............', response);
        fulfill(response.data);
      })
      .catch(error => {
        console.log(error)
      }) 
    });
} 

export async function getParams(url, id,config = {}) {
  let urlFull = `${url}/${id}`;
  return await axiosApi.get(urlFull, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function postMedia(url, data, config = { headers: {'Content-Type': 'application/x-www-form-urlencoded', 'enctype': 'multipart/form-data'} }) {
  return axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
}

export async function putMedia(url, data, config = { headers: {'Content-Type': 'application/x-www-form-urlencoded', 'enctype': 'multipart/form-data'} }) {
  return axiosApi
    .put(url, data, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
