import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN, USER_SUCCESS } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess, userSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  get,
} from "../../../helpers/api_helper"

import { LOGIN, GET_STUDENT, GET_USER_STUDENT } from "helpers/url_helper"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {

  console.log('user.username..........', user.username);
  let body;
  if(isNaN(user.username))
  {
    console.log('is in if')
    // If the Given Value is Not Number Then It Will Return True and This Part Will Execute.
    body = JSON.stringify({
      query: LOGIN,
      variables: {
        input: {
          userName: user.username,
          password: user.password,
          userType: 'student',
        },
      },
    });
  }
  else
  {
    console.log('is in eles')
    // If the Given Value is Number Then It Will Return False and This Part Will Execute.
    body = JSON.stringify({
      query: LOGIN,
      variables: {
        input: {
          phone: parseInt('91'+user.username),
          password: user.password,
          userType: 'student',
        },
      },
    });
  }
  // let body = JSON.stringify({
  //   query: LOGIN,
  //   variables: {
  //     input: {
  //       userName: user.username,
  //       password: user.password,
  //       userType: "student",
  //     },
  //   },
  // });

  const response = yield call(get, { payload: { body, history } })
  console.log('response, error', response);
  if (response && response?.login?.accessToken != null) {

    if(isNaN(user.username))
    {
      // If the Given Value is Not Number Then It Will Return True and This Part Will Execute.
      body = JSON.stringify({
        query: GET_USER_STUDENT,
        variables: {
          options: {
            userName: user.username,
            populate: [{path: "savedQuestions", populate: {path: "author"}, options: {
              skip: 0,
              limit: 1000
            }},
            {path: "leaderboardScores", populate: {path: "space"}}]
          },
        },
      });
    }
    else
    {
      // If the Given Value is Number Then It Will Return False and This Part Will Execute.
      body = JSON.stringify({
        query: GET_USER_STUDENT,
        variables: {
          options: {
            phone: parseInt('91'+user.username),
            populate: [{path: "savedQuestions", populate: {path: "author"}, options: {
              skip: 0,
              limit: 1000
            }},
            {path: "leaderboardScores", populate: {path: "space"}}]
          },
        },
      });
    }

    // //yield put(loginSuccess(response.data))
    //   body = JSON.stringify({
    //   query: GET_USER_STUDENT,
    //   variables: {
    //     options: {
    //       userName: user.username,
    //       populate: [
    //         {
    //           path: "savedQuestions", populate: { path: "author" }, options: {
    //             skip: 0,
    //             limit: 1000
    //           }
    //         },
    //         { path: "leaderboardScores", populate: { path: "space" } }
    //       ]
    //     },
    //   },
      
    //});
    console.log('student body, error', body);
    const studResponse = yield call(get, { payload: { body, history } });
    console.log('student response, error', studResponse);
    if (studResponse) {
      yield put(userSuccess(studResponse.getStudent))
      localStorage.setItem("authToken", JSON.stringify(response))
      localStorage.setItem("authUser", JSON.stringify(studResponse.getStudent))
      setTimeout(() => {
        history.push("/space")
      }, 500)
    } else {
      //console.log(response?.login?.error)
      yield put(apiError(response?.login?.error))
    }
  } else {
    //console.log(response?.login?.error)
    yield put(apiError(response?.login?.error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    localStorage.removeItem("authToken")
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(apiError(''))
      yield put(logoutUserSuccess(response))
    }
    yield put(apiError(''))
    window.location = '/login';
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type,
      )
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/space")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
