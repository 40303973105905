import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"

const MonthlyEarning = () => {
  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardBody>
          <CardTitle className="mb-4 text-fff">Requests from Nimo’s</CardTitle>
          <Card className="card-red">
            <CardBody>
              <CardTitle className="mb-4 text-fff">School bus timming need to change</CardTitle>
              <p className="mb-0 text-fff">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, 
              </p>
            </CardBody>

          </Card>

          <Card className="card-yellow">
            <CardBody>
              <CardTitle className="mb-4 text-fff">Teacher details</CardTitle>
              <p className="mb-0 text-fff">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
              </p>
            </CardBody>
          </Card>

          <Card className="card-green">
            <CardBody>
              <CardTitle className="mb-4 text-fff">Class Xii the growth</CardTitle>
              <p className="mb-0 text-fff">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, 
              </p>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default MonthlyEarning
