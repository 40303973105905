import {
  GET_PAGINATED_NEWS_POSTS,
  GET_TALKS_SUCCESS,
  GET_TALKS_FAIL,
  SUBMIT_POST_LIKE_SAVE,
  GET_POST_LIKE_SAVE,
  GET_POST_FAIL,
  GET_UNSOLVED_QUESTION,
  GET_UNSOLVED_QUESTION_SUCCESS,
  GET_UNSOLVED_QUESTION_FAIL,
  GET_POPULAR_TOPICS,
  GET_POPULAR_TOPICS_SUCCESS,
  GET_POPULAR_TOPICS_FAIL,
  GET_QUESTIONS,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAIL,
  UPVOTE_QUESTION,
  UPVOTE_QUESTION_SUCCESS,
  UPVOTE_QUESTION_FAIL,
  DOWNVOTE_QUESTION,
  DOWNVOTE_QUESTION_SUCCESS,
  DOWNVOTE_QUESTION_FAIL,
  GET_ANSWERS,
  GET_ANSWERS_SUCCESS,
  GET_ANSWERS_FAIL,
  CLEAR_UPVOTE_DATA,
  GET_QUESTION_COMMENTS,
  GET_QUESTION_COMMENTS_SUCCESS,
  GET_QUESTION_COMMENTS_FAIL,
  GET_ANSWER_COMMENTS,
  GET_ANSWER_COMMENTS_SUCCESS,
  GET_ANSWER_COMMENTS_FAIL,
  UPVOTE_ANSWER,
  UPVOTE_ANSWER_SUCCESS,
  UPVOTE_ANSWER_FAIL,
  DOWNVOTE_ANSWER,
  DOWNVOTE_ANSWER_SUCCESS,
  DOWNVOTE_ANSWER_FAIL,
  GET_ALL_SAVED_QUESTIONS,
  GET_ALL_SAVED_QUESTIONS_SUCCESS,
  GET_ALL_SAVED_QUESTIONS_FAIL,
  SAVE_QUESTION,
  SAVE_QUESTION_SUCCESS,
  SAVE_QUESTION_FAIL,
  SAVE_QUESTION_COMMENT,
  SAVE_QUESTION_COMMENT_SUCCESS,
  SAVE_QUESTION_COMMENT_FAIL,
  SAVE_ANSWER_COMMENT,
  SAVE_ANSWER_COMMENT_SUCCESS,
  SAVE_ANSWER_COMMENT_FAIL,
  DELETE_QUESTION,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAIL,
  DELETE_ANSWER,
  DELETE_ANSWER_SUCCESS,
  DELETE_ANSWER_FAIL,
  DELETE_QUESTION_COMMENT,
  DELETE_QUESTION_COMMENT_SUCCESS,
  DELETE_QUESTION_COMMENT_FAIL,
  DELETE_ANSWER_COMMENT,
  DELETE_ANSWER_COMMENT_SUCCESS,
  DELETE_ANSWER_COMMENT_FAIL,
  REPORT,
  REPORT_SUCCESS,
  REPORT_FAIL,
  ACCEPT_ANSWER,
  ACCEPT_ANSWER_SUCCESS,
  ACCEPT_ANSWER_FAIL,
  GET_SUBJECTS,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_FAIL,
  GET_TOPICS_BY_SUBJECT,
  GET_TOPICS_BY_SUBJECT_SUCCESS,
  GET_TOPICS_BY_SUBJECT_FAIL,
  ADD_QUESTION,
  ADD_QUESTION_SUCCESS,
  ADD_QUESTION_FAIL,
  ADD_ANSWER,
  ADD_ANSWER_SUCCESS,
  ADD_ANSWER_FAIL,
  GET_ALL_TOPICS_BY_SUBJECT,
  GET_ALL_TOPICS_BY_SUBJECT_SUCCESS,
  GET_ALL_TOPICS_BY_SUBJECT_FAIL,
  FILTER_QUESTIONS,
  FILTER_QUESTIONS_FAIL,
  FILTER_QUESTIONS_SUCCESS,
  POST_UPDATE_ANSWER,
  POST_UPDATE_ANSWER_SUCCESS,
  POST_UPDATE_ANSWER_FAIL,
  POST_UPDATE_QUESTION,
  POST_UPDATE_QUESTION_SUCCESS,
  POST_UPDATE_QUESTION_FAIL,
  GET_SINGLE_QUESTION,
  GET_SINGLE_QUESTION_SUCCESS,
  GET_SINGLE_QUESTION_FAIL,
  GET_REPORTED_POST_QUESTION,
  GET_REPORTED_POST_QUESTION_SUCCESS,
  GET_REPORTED_POST_QUESTION_FAIL,
  GET_REPORTED_POST_ANSWER,
  GET_REPORTED_POST_ANSWER_SUCCESS,
  GET_REPORTED_POST_ANSWER_FAIL,
  GET_REPORTED_POST_QUESTION_COMMENT,
  GET_REPORTED_POST_QUESTION_COMMENT_SUCCESS,
  GET_REPORTED_POST_QUESTION_COMMENT_FAIL,
  GET_REPORTED_POST_ANSWER_COMMENT,
  GET_REPORTED_POST_ANSWER_COMMENT_SUCCESS,
  GET_REPORTED_POST_ANSWER_COMMENT_FAIL
} from "./actionTypes"

export const getAllSavedQuestions = () => ({
  type: GET_ALL_SAVED_QUESTIONS,
})

export const getAllSavedQuestionsSuccess = questions => ({
  type: GET_ALL_SAVED_QUESTIONS_SUCCESS,
  payload: questions,
})

export const getAllSavedQuestionsFail = error => ({
  type: GET_ALL_SAVED_QUESTIONS_FAIL,
  payload: error,
})

export const getPaginatedNewsposts = pageData => ({
  type: GET_PAGINATED_NEWS_POSTS,
  payload: pageData
})

export const getTalksSuccess = talks => ({
  type: GET_TALKS_SUCCESS,
  payload: talks,
})

export const getTalksFail = error => ({
  type: GET_TALKS_FAIL,
  payload: error,
})

export const submitPostLikeSave = likeSaveData => ({
  type: SUBMIT_POST_LIKE_SAVE,
  payload: likeSaveData
})

export const getPostLikeSaveSuccess = postLikeSave => ({
  type: GET_POST_LIKE_SAVE,
  payload: postLikeSave,
})

export const getPostFail = error => ({
  type: GET_POST_FAIL,
  payload: error,
})

//for unsolved question
export const getUnsolvedQuestion = (optionData) => ({
  type: GET_UNSOLVED_QUESTION,
  payload: optionData
})

export const getUnsolvedQuestionSuccess = questions => ({
  type: GET_UNSOLVED_QUESTION_SUCCESS,
  payload: questions,
})

export const getUnsolvedQuestionFail = error => ({
  type: GET_UNSOLVED_QUESTION_FAIL,
  payload: error,
})


//for popular topics
export const getPopularTopic = (optionData) => ({
  type: GET_POPULAR_TOPICS,
  payload: optionData
})

export const getPopularTopicSuccess = topics => ({
  type: GET_POPULAR_TOPICS_SUCCESS,
  payload: topics,
})

export const getPopularTopicFail = error => ({
  type: GET_POPULAR_TOPICS_FAIL,
  payload: error,
})

//for popular topics
export const getQuestions = (options) => ({
  type: GET_QUESTIONS,
  payload: options,
})

export const getQuestionsSuccess = questions => ({
  type: GET_QUESTIONS_SUCCESS,
  payload: questions,
})

export const getQuestionsFail = error => ({
  type: GET_QUESTIONS_FAIL,
  payload: error,
})

//for upvode question
export const upvoteQuestions = question_id => (
  {
    type: UPVOTE_QUESTION,
    payload: question_id
  })

export const upvoteQuestionsSuccess = questions => (
  //console.log('questions..........upvote question', questions),
  {
    type: UPVOTE_QUESTION_SUCCESS,
    payload: questions,
  })

export const clearUpvoteQuestionsSuccess = () => ({
  type: CLEAR_UPVOTE_DATA,
})

export const upvoteQuestionsFail = error => ({
  type: UPVOTE_QUESTION_FAIL,
  payload: error,
})

//for downvote question
export const downvoteQuestions = questions => ({
  type: DOWNVOTE_QUESTION,
  payload: questions,
})

export const downvoteQuestionsSuccess = questions => ({
  type: DOWNVOTE_QUESTION_SUCCESS,
  payload: questions,
})

export const downvoteQuestionsFail = error => ({
  type: DOWNVOTE_QUESTION_FAIL,
  payload: error,
})


//for downvote question
export const saveQuestions = (questions, bookmark) => (
  //console.log('questions, bookmark...................', questions, bookmark),
{
  type: SAVE_QUESTION,
  payload: { questions, bookmark },
})

export const saveQuestionsSuccess = questions => ({
  type: SAVE_QUESTION_SUCCESS,
  payload: questions,
})

export const saveQuestionsFail = error => ({
  type: SAVE_QUESTION_FAIL,
  payload: error,
})


//for downvote question
export const saveQuestionComment = (comment, question_id) => (
{
  type: SAVE_QUESTION_COMMENT,
  payload: {comment, question_id},
})

export const saveQuestionCommentSuccess = comment => ({
  type: SAVE_QUESTION_COMMENT_SUCCESS,
  payload: comment,
})

export const saveQuestionCommentFail = error => ({
  type: SAVE_QUESTION_COMMENT_FAIL,
  payload: error,
})


//for downvote question
export const saveAnswerComment = (comment, answer_id) => (
{
  type: SAVE_ANSWER_COMMENT,
  payload: {comment, answer_id},
})

export const saveAnswerCommentSuccess = comment => ({
  type: SAVE_ANSWER_COMMENT_SUCCESS,
  payload: comment,
})

export const saveAnswerCommentFail = error => ({
  type: SAVE_ANSWER_COMMENT_FAIL,
  payload: error,
})


//for downvote question
export const getAnswerByQuestionId = questions => ({
  type: GET_ANSWERS,
  payload: questions,
})

export const getAnswersSuccess = questions => ({
  type: GET_ANSWERS_SUCCESS,
  payload: questions,
})

export const getAnswersFail = error => ({
  type: GET_ANSWERS_FAIL,
  payload: error,
})


//for question comment
export const getQuestionComments = questions => ({
  type: GET_QUESTION_COMMENTS,
  payload: questions,
})

export const getQuestionCommentsSuccess = questions => ({
  type: GET_QUESTION_COMMENTS_SUCCESS,
  payload: questions,
})

export const getQuestionCommentsFail = error => ({
  type: GET_QUESTION_COMMENTS_FAIL,
  payload: error,
})


//for answer comment
export const getAnswerComments = answer_id => ({
  type: GET_ANSWER_COMMENTS,
  payload: answer_id,
})

export const getAnswerCommentsSuccess = answerComment => ({
  type: GET_ANSWER_COMMENTS_SUCCESS,
  payload: answerComment,
})

export const getAnswerCommentsFail = error => ({
  type: GET_ANSWER_COMMENTS_FAIL,
  payload: error,
})

export const clearDownvoteQuestionsSuccess = () => ({
  type: CLEAR_UPVOTE_DATA,
})


//for upvode question
export const upvoteAnswers = answer_id => (
  {
    type: UPVOTE_ANSWER,
    payload: answer_id
  })

export const upvoteAnswersSuccess = answers => (
  //console.log('questions..........upvote question', questions),
  {
    type: UPVOTE_ANSWER_SUCCESS,
    payload: answers,
  })

export const clearUpvoteAnswersSuccess = () => ({
  type: CLEAR_UPVOTE_DATA,
})

export const upvoteAnswersFail = error => ({
  type: UPVOTE_ANSWER_FAIL,
  payload: error,
})

//for downvote question
export const downvoteAnswers = answer_id => ({
  type: DOWNVOTE_ANSWER,
  payload: answer_id,
})

export const downvoteAnswersSuccess = answers => ({
  type: DOWNVOTE_ANSWER_SUCCESS,
  payload: answers,
})

export const downvoteAnswersFail = error => ({
  type: DOWNVOTE_ANSWER_FAIL,
  payload: error,
})



//for delete question
export const deleteQuestion = question_id => ({
  type: DELETE_QUESTION,
  payload: question_id,
})

export const deleteQuestionSuccess = questions => ({
  type: DELETE_QUESTION_SUCCESS,
  payload: questions,
})

export const deleteQuestionFail = error => ({
  type: DELETE_QUESTION_FAIL,
  payload: error,
})



//for delete answer
export const deleteAnswer = answer_id => ({
  type: DELETE_ANSWER,
  payload: answer_id,
})

export const deleteAnswerSuccess = answers => ({
  type: DELETE_ANSWER_SUCCESS,
  payload: answers,
})

export const deleteAnswerFail = error => ({
  type: DELETE_ANSWER_FAIL,
  payload: error,
})



//for delete question comment
export const deleteQuestionComment = comment_id => ({
  type: DELETE_QUESTION_COMMENT,
  payload: comment_id,
})

export const deleteQuestionCommentSuccess = comments => ({
  type: DELETE_QUESTION_COMMENT_SUCCESS,
  payload: comments,
})

export const deleteQuestionCommentFail = error => ({
  type: DELETE_QUESTION_COMMENT_FAIL,
  payload: error,
})


//for delete answer comment
export const deleteAnswerComment = comment_id => ({
  type: DELETE_ANSWER_COMMENT,
  payload: comment_id,
})

export const deleteAnswerCommentSuccess = comments => ({
  type: DELETE_ANSWER_COMMENT_SUCCESS,
  payload: comments,
})

export const deleteAnswerCommentFail = error => ({
  type: DELETE_ANSWER_COMMENT_FAIL,
  payload: error,
})

//for all type report
export const report = data => ({
  type: REPORT,
  payload: data,
})

export const reportSuccess = report => ({
  type: REPORT_SUCCESS,
  payload: report,
})

export const reportFail = error => ({
  type: REPORT_FAIL,
  payload: error,
})

//for accept answer
export const acceptAnswer = (question_id, answer_id) => ({
  type: ACCEPT_ANSWER,
  payload: {question_id, answer_id},
})

export const acceptAnswerSuccess = answers => ({
  type: ACCEPT_ANSWER_SUCCESS,
  payload: answers,
})

export const acceptAnswerFail = error => ({
  type: ACCEPT_ANSWER_FAIL,
  payload: error,
})

//for question comment
export const getSubjects = questions => ({
  type: GET_SUBJECTS,
  payload: questions,
})

export const getSubjectsSuccess = questions => ({
  type: GET_SUBJECTS_SUCCESS,
  payload: questions,
})

export const getSubjectsFail = error => ({
  type: GET_SUBJECTS_FAIL,
  payload: error,
})

//for getting only popular topics by subject
export const getTopicsBySubject = (subject_id, topicInput) => ({
  type: GET_TOPICS_BY_SUBJECT,
  payload: {subject_id, topicInput},
})

export const getTopicsBySubjectSuccess = topics => ({
  type: GET_TOPICS_BY_SUBJECT_SUCCESS,
  payload: topics,
})

export const getTopicsBySubjectFail = error => ({
  type: GET_TOPICS_BY_SUBJECT_FAIL,
  payload: error,
})

//for save question
export const addQuestion = (data) => ({
  type: ADD_QUESTION,
  payload: data,
})

export const addQuestionSuccess = question => ({
  type: ADD_QUESTION_SUCCESS,
  payload: question,
})

export const addQuestionFail = error => ({
  type: ADD_QUESTION_FAIL,
  payload: error,
})

//for save answer
export const addAnswer = (data) => ({
  type: ADD_ANSWER,
  payload: data,
})

export const addAnswerSuccess = answer => ({
  type: ADD_ANSWER_SUCCESS,
  payload: answer,
})

export const addAnswerFail = error => ({
  type: ADD_ANSWER_FAIL,
  payload: error,
})

//for all topics by subject
export const getAllTopicsBySubject = (subject_id) => ({
  type: GET_ALL_TOPICS_BY_SUBJECT,
  payload: subject_id,
})

export const getAllTopicsBySubjectSuccess = topics => ({
  type: GET_ALL_TOPICS_BY_SUBJECT_SUCCESS,
  payload: topics,
})

export const getAllTopicsBySubjectFail = error => ({
  type: GET_ALL_TOPICS_BY_SUBJECT_FAIL,
  payload: error,
})



export const filterQuestions = () => ({
  type: FILTER_QUESTIONS,
})

export const filterQuestionsSuccess = data => ({
  type: FILTER_QUESTIONS_SUCCESS,
  payload: data,
})

export const filterQuestionsFail = error => ({
  type: FILTER_QUESTIONS_FAIL,
  payload: error,
})

//for update answer
export const updateAnswer = (data) => ({
  type: POST_UPDATE_ANSWER,
  payload: data,
})

export const updateAnswerSuccess = answer => ({
  type: POST_UPDATE_ANSWER_SUCCESS,
  payload: answer,
})

export const updateAnswerFail = error => ({
  type: POST_UPDATE_ANSWER_FAIL,
  payload: error,
})

//for update question
export const updateQuestion = (data) => ({
  type: POST_UPDATE_QUESTION,
  payload: data,
})

export const updateQuestionSuccess = answer => ({
  type: POST_UPDATE_QUESTION_SUCCESS,
  payload: answer,
})

export const updateQuestionFail = error => ({
  type: POST_UPDATE_QUESTION_FAIL,
  payload: error,
})

//for update question
export const getSingleQuestion = (question_id) => ({
  type: GET_SINGLE_QUESTION,
  payload: question_id,
})

export const getSingleQuestionSuccess = answer => (
  {
  type: GET_SINGLE_QUESTION_SUCCESS,
  payload: answer,
})

export const getSingleQuestionFail = error => ({
  type: GET_SINGLE_QUESTION_FAIL,
  payload: error,
})

//for get reported question
export const getReportedPostQuestion = () => ({
  type: GET_REPORTED_POST_QUESTION
})

export const getReportedPostQuestionSuccess = question => (
  {
  type: GET_REPORTED_POST_QUESTION_SUCCESS,
  payload: question,
})

export const getReportedPostQuestionFail = error => ({
  type: GET_REPORTED_POST_QUESTION_FAIL,
  payload: error,
})

//for get reported question
export const getReportedPostAnswer = () => ({
  type: GET_REPORTED_POST_ANSWER
})

export const getReportedPostAnswerSuccess = answer => ({
  type: GET_REPORTED_POST_ANSWER_SUCCESS,
  payload: answer,
})

export const getReportedPostAnswerFail = error => ({
  type: GET_REPORTED_POST_ANSWER_FAIL,
  payload: error,
})

//for get reported question comment
export const getReportedPostQuestionComment = () => ({
  type: GET_REPORTED_POST_QUESTION_COMMENT
})

export const getReportedPostQuestionCommentSuccess = question => (
  {
  type: GET_REPORTED_POST_QUESTION_COMMENT_SUCCESS,
  payload: question,
})

export const getReportedPostQuestionCommentFail = error => ({
  type: GET_REPORTED_POST_QUESTION_COMMENT_FAIL,
  payload: error,
})

//for get reported answer comment
export const getReportedPostAnswerComment = () => ({
  type: GET_REPORTED_POST_ANSWER_COMMENT
})

export const getReportedPostAnswerCommentSuccess = answer => ({
  type: GET_REPORTED_POST_ANSWER_COMMENT_SUCCESS,
  payload: answer,
})

export const getReportedPostAnswerCommentFail = error => ({
  type: GET_REPORTED_POST_ANSWER_COMMENT_FAIL,
  payload: error,
})