import {
    SUBMIT_CHANGE_PASSWORD,
    GET_CHANGE_PASSWORD_SUCCESS,
    GET_CHANGE_PASSWORD_FAIL
  } from "./actionTypes"
  
  const initialState = {
    error: "",
    change_password: [],
  }
  
  const changePassword = (state = initialState, action) => {
    switch (action.type) {
      case SUBMIT_CHANGE_PASSWORD:
        state = {
          ...state,
          change_password: action.payload.values,
        }
        break
      case GET_CHANGE_PASSWORD_SUCCESS:
        state = {
          ...state,
          change_password: action.payload,
        }
        break
      case GET_CHANGE_PASSWORD_FAIL:
        state = { 
            ...state,
            error: action.payload,
        }
        break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default changePassword
  