export const GET_SAVED_QUESTIONS = "GET_SAVED_QUESTIONS"
export const GET_SAVED_QUESTIONS_FAIL = "GET_SAVED_QUESTIONS_FAIL"
export const GET_SAVED_QUESTIONS_SUCCESS = "GET_SAVED_QUESTIONS_SUCCESS"

export const GET_MY_ACTIVITIES = "GET_MY_ACTIVITIES"
export const GET_MY_ACTIVITIES_FAIL = "GET_MY_ACTIVITIES_FAIL"
export const GET_MY_ACTIVITIES_SUCCESS = "GET_MY_ACTIVITIES_SUCCESS"



export const GET_STUDENT_DETAILS = "GET_STUDENT_DETAILS"
export const GET_STUDENT_DETAILS_FAIL = "GET_STUDENT_DETAILS_FAIL"
export const GET_STUDENT_DETAILS_SUCCESS = "GET_STUDENT_DETAILS_SUCCESS"

export const GET_ALL_USERS = "GET_ALL_USERS"
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL"
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS"

export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
