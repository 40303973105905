import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Label,
  Modal,
  Spinner
} from "reactstrap"
import { withRouter, Link } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroll-component"
import { connect, useSelector, useDispatch } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"

import { getLeaderboards, getLeaderboardScore } from 'store/actions'

import AccordionComponent from "./AccordionComponent"
const Leaderboard = props => {

  const { onGetLeaderboards,
    leaderboardList,
    onGetLeaderboardScore,
    leaderboardScoreData
  } = props;

  const [isModalAvatar, setIsModalOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    page: 1
  });
  const [hasMoreLeaderBoard, setHasMoreLeaderBoard] = useState(true)
  const [allLeaderBoards, setAllLeaderBoards] = useState([])
  const [togggle, setTogggle] = useState(true);

  const toggleModal = () => {
    setIsModalOpen(!isModalAvatar);
  }
  useEffect(() => {
    setTogggle(false)
    onGetLeaderboards(filterData);
    onGetLeaderboardScore();
  }, []);

  useEffect(() => {
    //console.log('leaderboardList.......', leaderboardList);

    setTogggle(false);
    //console.log('leaderboardList..........', leaderboardList.page);
    if (allLeaderBoards?.docs?.length > 0 && leaderboardList.page > 1) {
      //console.log('inside----------')
      let lbData = allLeaderBoards;
      let A = lbData.docs;
      let B = leaderboardList.docs;
      let lbDocs = A.concat(B.filter(bo => A.every(ao => ao._id != bo._id)));
      //console.log('inside----------1')
      lbData.page = allLeaderBoards?.page;
      lbData.docs = lbDocs;
      //console.log('inside----------2', lbData);
      setAllLeaderBoards(lbData);
      setTogggle(false);
    } else {
      setAllLeaderBoards(leaderboardList);
      setTogggle(true);

      //console.log('leaderboardList..........', leaderboardList.page, leaderboardList?.totalPages, leaderboardList);
      if (leaderboardList?.docs?.length > 0 && leaderboardList?.totalPages == leaderboardList.page) {
        //console.log('in useeffect if.........')
        setHasMoreLeaderBoard(false);
      }
    }
  }, [leaderboardList])

  useEffect(() => {
    setTogggle(true);
  }, [togggle])

  const loadMoreLeaderBoards = () => {
    setTogggle(false);
    if (leaderboardList?.page == leaderboardList?.totalPages) {
      console.log('in if........................')
      setHasMoreLeaderBoard(false);
      setTogggle(false);
    } else {
      console.log('in else........................')
      setTogggle(false);
      let currentPage = leaderboardList?.page
      const updatedFilterData = { ...filterData, page: currentPage + 1, old_leaderboards: leaderboardList };
      //console.log('updatedFilterData------------',updatedFilterData)
      onGetLeaderboards(updatedFilterData);
      setHasMoreLeaderBoard(true)
      setTogggle(true);
    }
  }
  return (
    <React.Fragment>
      <div className="page-content bg-black">
        <MetaTags>
          <title>Student Doubt Resolution App</title>
        </MetaTags>
        <Container fluid>
          {/* <Breadcrumbs
                title={props.t("Nimo Rising Star")}
                breadcrumbItem={props.t("Nimo Rising Star")}
            /> */}
          <Row>
            <Col lg={12} className="nimo-rising-star-header align-items-center mb-2">
              <div className='pt-0 d-flex  justify-content-between'>
                <h3>LeaderBoard</h3>
                <div><i className="dripicons-question fs-3" onClick={toggleModal}></i></div>
              </div>
              <div className='pt-0 d-flex  justify-content-center'>
                <h3>Your rank is {leaderboardScoreData != null && leaderboardScoreData?.rank} with score of {leaderboardScoreData != null && leaderboardScoreData?.leaderboardscores?.score} !</h3>
              </div>
              {/* <div className="d-flex align-items-center filter-section">
                <Label className="mr-2 mb-0">Filter :</Label>
                <select className="form-select">
                  <option value="">Select</option>
                  <option value="class_I">Class I</option>
                  <option value="class_II">Class II</option>
                  <option value="class_III">Class III</option>
                  <option value="class_IV">Class IV</option>
                  <option value="class_V">Class V</option>
                  <option value="class_VI">Class VI</option>
                  <option value="class_VII">Class VII</option>
                  <option value="class_VIII">Class VIII</option>
                  <option value="class_IX">Class IX</option>
                  <option value="class_X">Class X</option>
                  <option value="class_XI">Class XI</option>
                  <option value="class_XII" defaultValue>Class XII</option>
                </select>
                <select className="form-select">
                  <option value="">Select</option>
                  <option value="arts">Arts</option>
                  <option value="science" defaultValue>Science</option>
                </select>
              </div>
              <div>
                <Link to="#">
                  <span className="text-secondary">View All</span>
                </Link>
              </div> */}
            </Col>
            <Col xl="12">
              <InfiniteScroll
                dataLength={allLeaderBoards?.docs ? allLeaderBoards?.docs?.length : 0}
                next={loadMoreLeaderBoards}
                hasMore={hasMoreLeaderBoard}
                loader={
                  <div className={"justify-content-center row"}>
                    <Spinner type="grow" className="ms-2" color="primary" />
                  </div>
                }
              >
                <AccordionComponent leaderboardList={allLeaderBoards} />
              </InfiniteScroll>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={isModalAvatar}
        fullscreen
        size='lg'
        className="leaderboardModal"
        backdrop="static"
        centered={true}
        toggle={toggleModal}
      >
        <div>  
            <div className='modal-header border-bottom-0' style={{ backgroundColor: '#ffff' }}>
              <h1 className=" text-left modal-title mt-0" id="myModalLabel" style={{ color: ' #76A9FF' }}>Leaderboard Scores</h1>   <button
                type="button"
                onClick={() => {
                  setIsModalOpen(!isModalAvatar)
                }}
                data-dismiss="modal"
                aria-label="Close"
                style={{ right: '0', top: '18px', color: '#1D2226', backgroundColor: '#ffff', fontSize: '18px' }}
                className="border-0"
              >
                <span aria-hidden="true">&#10006;</span>
              </button>
            </div>
            <div className="modal-body" style={{ backgroundColor: '#ffff', color: 'black' }}>

              The leaderboard score is calculated by summing up the merit and impact scores, indicating your level of contribution and problem-solving ability within the community.

              <h5 className='my-3 text-black'>1. MERITS:</h5>

              <p>a. What is a Merit?</p>
              <p>Merit is a way of measuring your performance in helping the community and the trust you have earned from the community. A high score indicates that you have demonstrated a high level of skill and effectiveness in helping others, as well as earned trust and respect from the community. Conversely, a low score indicates that your performance may be lacking or your contributions may not be seen as valuable to others.</p>

              <p>a. How do I earn Merits?</p>
              <p>In short, you just have to ask good questions, post reliable answers and be a good critique to others' questions and answers.</p>

              <p>To earn Merits:</p>
              <p>i. Your question receives an upvote (+50)</p>
              <p>ii. Your answer receives an upvote (+70)</p>
              <p>iii. Your answer is accepted as the best answer (+100)</p>
              <p>iv. You accept an answer to your question (+15)</p>

              <p>To lose Impacts:</p>
              <p>i. Your question receives a downvote (-50)</p>
              <p>ii. Your answer receives a downvote (-50)</p>
              <p>iii. You downvote an answer (-5)</p>
              <p>iv. You downvote a question (-5)</p>
              <p>iv. Your post is reported by three peers (-200)</p>
              <p>The merits cannot go below 1.</p>
              <p>Keep a high merit score to showcase your achievements and standout in the community as a top performer.</p>

              <h5 className='my-3 text-black'>1. IMPACTS:</h5>
              <p>a. What is an Impact?</p>
              <p>Impact is a metric that measures the tangible positive influence you have on the community through your contributions. It signifies the extent to which you are making a difference or participating actively in the community. A high score indicates that your contributions are making a meaningful difference in the community, while a low score suggests that your contributions may not be as impactful or valuable to others.</p>

              <p>b. How do I earn Impacts?</p>
              <p>In short, you can earn Impacts by engaging yourself in asking, answering and supporting other questions and answers by commenting and giving valuable suggestions.
                To earn Impacts:</p>

              <p>i. You post a question (+10)</p>
              <p>ii. You post an answer (+10)</p>
              <p>iii. You post a comment (+2)</p>
              <p>iv. You upvote an answer (+1)</p>
              <p>iv. You upvote a question (+1)</p>

              <p>Impacts cannot be lost.</p>
              <p>Strive to earn a high Impact score by consistently posting high-quality questions, answers, and comments that provide value and help to the community.</p>
            </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Leaderboard.propTypes = {
  t: PropTypes.any
}


const mapStateToProps = state => {
  console.log("state ----------", state)
  const { error, newsPosts, spaces, talks, leaderboards } = state
  return {
    error: error,
    current_space: spaces.current_space,
    leaderboardList: leaderboards.leaderboard,
    leaderboardScoreData: leaderboards.leaderboardScore,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetLeaderboards: (options) => dispatch(getLeaderboards(options)),
  onGetLeaderboardScore: () => dispatch(getLeaderboardScore())
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Leaderboard)
)
