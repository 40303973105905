import { 
    SUBMIT_CHANGE_PASSWORD,
    GET_CHANGE_PASSWORD_SUCCESS,
    GET_CHANGE_PASSWORD_FAIL
  } from "./actionTypes"
  
  export const submitChangePassword = passwordData => ({
    type: SUBMIT_CHANGE_PASSWORD,
    payload: passwordData,
  })
  
  export const getChangePasswordSuccess = change_password => ({
    type: GET_CHANGE_PASSWORD_SUCCESS,
    payload: change_password,
  })
  
  export const getChangePasswordFail = error => ({
    type: GET_CHANGE_PASSWORD_FAIL,
    payload: error,
  })
  
  
  