import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import Gauge from "../AllCharts/echart/gaugechart"
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  CardTitle
} from "reactstrap"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

//i18n
import { withTranslation } from "react-i18next"
import MiniWidget from "./mini-widget"
import '../../assets/css/custom.css'
import TabComponent from './TabComponent'

import Line from "../AllCharts/echart/linechart"

const Dashboard = (props) => {
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)

  //Bitcoin Chart
const series1 = [
  { name: "BTC", data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14] },
]
const options1 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width:0 },
  colors: ["#C8372D"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.95,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
}

//Etherium Chart
const series2 = [
  { name: "ETH", data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54] },
]
const options2 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 0 },
  colors: ["#f1b34c"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.95,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
}

//LiteCoin Chart
const series3 = [
  { name: "LTC", data: [35, 53, 93, 47, 54, 24, 47, 75, 65, 19, 14] },
]
const options3 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 0 },
  colors: ["#02a499"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.95,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
}

  const reports = [
    {
      title: "Negative",
      icon: "mdi mdi-bitcoin",
      color: "danger",
      value: "12.8%",
      series: series1,
      options: options1,
      arrowUpDown : 'mdi mdi-menu-down ms-1 text-danger',
      progressColor : 'danger',
      progressValue : 25,
      leftContent : 'IX'
    },
    {
      title: "Satisfied",
      icon: "mdi mdi-ethereum",
      color: "warning",
      value: "22.8%",
      series: series2,
      options: options2,
      arrowUpDown : 'mdi mdi-menu-up ms-1 text-warning',
      progressColor : 'warning',
      progressValue : 30,
      leftContent : 'X'
    },
    {
      title: "Neutral",
      icon: "mdi mdi-litecoin",
      color: "success",
      value: "06.8%",
      series: series3,
      options: options3,
      arrowUpDown : 'mdi mdi-menu-up ms-1 text-success',
      progressColor : 'success',
      progressValue : 35,
      leftContent : 'XII'
    },
  ]
  const email = [
    { title: "Week", linkto: "#", isActive: false },
    { title: "Month", linkto: "#", isActive: false },
    { title: "Year", linkto: "#", isActive: true },
  ]


  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000);
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Student Doubt Resolution App</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          /> */}

          <Row>
            <Col lg="12" className='mb-4'>
              <div className='pt-3'>
                <h1>Nimo Sense</h1>
              </div>
            </Col>
            <Col lg="4">
              <Card>
                <CardBody className='pb-0'>
                  <CardTitle>School PH Score</CardTitle>
                  <div id="gauge-chart" className="e-chart">
                    <Gauge />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="8">
              <Row>
                <Col lg="12" className='mb-3 mt-3'>
                  <div className="button-items">
                    <Button
                      color="primary"
                      outline
                      className="btn-rounded"
                    >
                      By Class
                    </Button>
                    <Button
                      color="primary"
                      outline
                      className="btn-rounded "
                    >
                      By Subject
                    </Button>
                  </div>
                </Col>
              {/* Reports Render */}
              <MiniWidget reports={reports} />
            </Row>
            </Col>
          </Row>
          <Row>
            <Col xl="8">
              <Card>
                <CardBody>
                  <TabComponent />
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardBody style={{ padding: '1.25rem 0rem'}}>
                  <CardTitle style={{ padding: '0rem 1.25rem'}} >Lorem Ipsum</CardTitle>
                  <div id="line-chart" className="e-chart">
                    <Line />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal)
        }}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              setmodal(!modal)
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-right">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-right">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-right">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal)
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)
